import { ERROR_CALC, ERROR_VALUE, ERROR_NA, MISSING, BLANK, ERROR_REF, ERROR_NUM } from '../constants.js';
import Matrix, { isMatrix } from '../Matrix.js';
import { isBool, isStr, isNum, isErr, toNum, applyPermutation } from './utils.js';
import { calcSortingPermutation } from './utils-sort.js';
import Reference, { isRef } from '../Reference.js';
import { a1ToRowColumn } from '../referenceParser/a1.js';
import { invariant } from '../../validation';
import { unbox } from '../ValueBox.js';
import { MODE_GOOGLE } from '../../mode.js';
import { filter } from './filter.js';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, isLambda } from '../lambda';

/**
 * Detail message for #VALUE! errors returned in single-cell formula evaluation.
 *
 * TODO: maybe give specific error messages for the different ways this happens?
 * I _think_ this one was originally chosen to match Google Sheets, but right
 * now I see Google Sheets use the detail message:
 *
 * > The default output of this reference is a single cell in the same row but a
 * > matching value could not be found. To get the values for the entire range
 * > use the ARRAYFORMULA function.
 */
export const SINGLE_CELL_ERROR_DETAIL_MSG = 'An array value could not be found.';

/**
 * @template {ArrayValue} T
 * @typedef { import("./../ValueBox").MaybeBoxed<T> } MaybeBoxed<T>
 */

/**
 * @param {...Matrix} args
 * @returns {Matrix}
 */
export function ARRAY (...args) {
  return vstackAll(args, null);
}

/**
 * @param {...MaybeBoxedFormulaValue} args
 * @returns {Matrix}
 * @throws {FormulaError} if one of `args` evaluates as a lambda
 */
export function ARRAYROW (...args) {
  const matrices = args.map(arg => {
    if (isRef(arg)) {
      const mat = arg.toMatrix(false);
      if (isErr(mat)) {
        return Matrix.of([ [ mat ] ]);
      }
      return mat;
    }
    if (isMatrix(arg)) {
      return arg;
    }
    if (isLambda(arg)) {
      throw ERROR_CALC_LAMBDA_NOT_ALLOWED;
    }
    return Matrix.of([ [ arg ] ]);
  });
  return hstackAll(matrices, null);
}

/**
 * Sort the rows of a given array by the values in one or more columns.
 *
 * Excel and Google Sheets have different implementations of the SORT function but, fortunately, you
 * can tell them apart by inspecting the provided parameters. This function hands off to the correct
 * implementation using the following rules.
 *
 * - If one or two parameters were given, use Excel's SORT function
 * - If three parameters were given:
 *   - If the second parameter is a number or array, use Excel's SORT function
 *   - If the second parameter is a range with a size > 1, use the Google Sheets SORT function
 * - If there's a fourth parameter:
 *   - If the fourth parameter is a boolean, use Excel's SORT function
 *   - Otherwise, return #VALUE!
 * - If there are five or more parameters, use the Google Sheets SORT function
 *
 * @this {EvaluationContext}
 * @param  {...FormulaArgument} params
 * @returns {Matrix | FormulaError}
 */
export function SORT (...params) {
  if (this.mode === MODE_GOOGLE) {
    // @ts-expect-error
    return GSHEETS_SORT(...params);
  }
  else {
    // @ts-expect-error
    return EXCEL_SORT(...params);
  }
}

/**
 * Sort the rows of a given array or range by the values in one or more columns.
 *
 * This is the Google Sheets form of the SORT function. It has similar functionality to Excel's SORT
 * function but the function signature differs between the two.
 *
 * Syntax:
 *
 *     =SORT(range, sort_column, is_ascending, [sort_column2, is_ascending2, ...])
 *
 * @see https://support.google.com/docs/answer/3093150
 *
 * @param  {Matrix} array
 * @param  {...FormulaArgument} sortParams Parameters defining the sort order; must have even length ≥ 2
 * @returns {Matrix | FormulaError}
 */
export function GSHEETS_SORT (array, ...sortParams) {
  if (array == null) {
    return ERROR_NA;
  }
  if (sortParams.length === 0 || (sortParams[0] == null && sortParams[1] == null)) {
    sortParams = [ 1, true ];
  }
  if ((sortParams.length & 1) !== 0) {
    return ERROR_NA.detailed('Number of sort parameters must be a multiple of two');
  }
  /** @type {ArrayValue[][]} */
  const sortValuesArrays = [];
  /** @type {boolean[]} */
  const sortOrders = [];
  for (let i = 0; i < sortParams.length; i += 2) {
    let sortColumn = sortParams[i];
    invariant(isMatrix(sortColumn) || isNum(sortColumn));
    const sortOrder = sortParams[i + 1];
    invariant(isBool(sortOrder));

    if (isMatrix(sortColumn) && sortColumn.size === 1) {
      sortColumn = toNum(sortColumn.get(0, 0));
      if (isErr(sortColumn)) {
        return sortColumn;
      }
    }

    if (isMatrix(sortColumn)) {
      // Sort column must be one-dimensional and the same height as the first
      // parameter's columns.
      if (sortColumn.width !== 1) {
        return ERROR_VALUE.detailed('Sort column argument not one dimensional');
      }
      if (array.height !== sortColumn.height) {
        return ERROR_NA.detailed('Sort column height not same as first argument');
      }
    }
    else {
      sortColumn = array.collapseToColumn(Math.floor(sortColumn) - 1);
      if (isErr(sortColumn)) {
        // Out of bounds. Google Sheets seems to just ignore this case (?)
        continue;
      }
    }
    sortValuesArrays.push(sortColumn.resolveRange({ skipBlanks: 'none' }));
    sortOrders.push(sortOrder);
  }

  const permutation = calcSortingPermutation(
    sortValuesArrays.map(sortValues => {
      return i => sortValues[i];
    }),
    sortOrders,
    array.height > array.populatedHeight ? array.populatedHeight + 1 : array.height,
  );
  return array.permuteRows(permutation);
}

/**
 * @param {ArrayValue} value
 * @returns {boolean | FormulaError}
 */
function excelToSortOrder (value) {
  value = toNum(value);
  if (isErr(value)) {
    return value;
  }
  value = Math.floor(value);
  if (value === 1 || value === -1) {
    return value === 1;
  }
  return ERROR_VALUE;
}

/**
 * Sort the contents of a range or array.
 *
 * This is the Excel form of the SORT function. It has similar functionality to Google Sheets's SORT
 * function but the function signature differs between the two.
 *
 * Syntax:
 *
 *     =SORT(array, [sort_index], [sort_order], [by_col])
 *
 * @see https://support.microsoft.com/office/sort-function-22f63bd0-ccc8-492f-953d-c20e8e44b86c
 *
 * @param  {Matrix} array Range or array to sort.
 * @param  {Matrix} [sortIndices] One or more column index to use for
 *     sorting. Defaults to 1.
 * @param  {Matrix} [sortOrders] 1 = Ascending, -1 = Descending.
 *     Default is ascending order.
 * @param  {boolean} [byCol] Desired sort direction; `false` to sort by row (default), `true`
 *                                 to sort by column
 * @returns {Matrix | FormulaError}
 */
export function EXCEL_SORT (array, sortIndices, sortOrders, byCol) {
  // Handle blank/missing arguments. They're allowed by the signature.
  if (array == null) {
    array = Matrix.of(null);
  }
  if (sortIndices == null) {
    sortIndices = Matrix.of(1);
  }
  if (sortOrders == null) {
    sortOrders = Matrix.of(1);
  }

  if (!(sortIndices.is1D() && sortOrders.is1D())) {
    return ERROR_VALUE.detailed('Both sort indices and sort orders must be one dimensional');
  }

  if (sortOrders.size > 1 && sortOrders.size !== sortIndices.size) {
    return ERROR_VALUE.detailed(`Mismatch: ${sortIndices.size} sort indices but ${sortOrders.size} sort orders`);
  }

  const minorLength = byCol ? array.height : array.width;

  /** @type {number[]} */
  const sortIndexNums = [];
  for (let sortIndex of sortIndices.resolveRange({ skipBlanks: 'none' })) {
    sortIndex = toNum(sortIndex);
    if (isErr(sortIndex)) {
      return ERROR_VALUE.detailed(`Invalid sort index (${sortIndex})`);
    }
    sortIndex = Math.floor(sortIndex);
    if (sortIndex < 1 || sortIndex > minorLength) {
      return ERROR_VALUE.detailed(`Sort index (${sortIndex}) out of bounds`);
    }
    sortIndexNums.push(sortIndex - 1);
  }

  /** @type {boolean[]} */
  let sortOrderBools = [];
  if (sortOrders.size === 1) {
    const order = excelToSortOrder(sortOrders.get(0, 0));
    if (isErr(order)) {
      return order;
    }
    sortOrderBools = sortIndexNums.map(() => order);
  }
  else {
    const resolvedSortOrders = sortOrders.resolveRange({ skipBlanks: 'none' });
    for (let i = 0; i < resolvedSortOrders.length; i += 1) {
      const order = excelToSortOrder(resolvedSortOrders[i]);
      if (isErr(order)) {
        return ERROR_VALUE.detailed(`Invalid sort order (${order})`);
      }
      sortOrderBools[i] = order;
    }
  }

  if (byCol) {
    const permutation = calcSortingPermutation(
      sortIndexNums.map(sortIdx => {
        return i => array.get(i, sortIdx);
      }),
      sortOrderBools,
      array.width > array.populatedWidth ? array.populatedWidth + 1 : array.width,
    );
    return array.permuteColumns(permutation);
  }
  else {
    const permutation = calcSortingPermutation(
      sortIndexNums.map(sortIdx => {
        return i => array.get(sortIdx, i);
      }),
      sortOrderBools,
      array.height > array.populatedHeight ? array.populatedHeight + 1 : array.height,
    );
    return array.permuteRows(permutation);
  }
}

/**
 * Syntax
 *     =SORTBY (array, by_array, [sort_order], [array/order], ...)
 * @param {null | Matrix} arrayArg Array to sort.
 * @param  {...(null | Matrix | undefined | number)} pairs Alternating `by_array` and `sort_order` arguments:
 *   by_array - Range or array to sort by.
 *   sort_order - [optional] Sort order.
 *     * 1  = ascending (default)
 *     * -1 = descending.
 * @returns {Matrix | FormulaError}
 */
export function SORTBY (arrayArg, ...pairs) {
  const array = arrayArg == null ? Matrix.of(null) : arrayArg;

  const orderBys = pairs
    .filter((_, i) => i % 2 === 0)
    .map(ob => {
      if (!isMatrix(ob)) {
        return Matrix.of(null);
      }
      return ob;
    });
  const sortOrders = pairs.filter((_, i) => i % 2 !== 0);

  const boolOrders = sortOrders.map(() => false);
  for (let i = 0; i < orderBys.length; i += 1) {
    const order = sortOrders[i];
    // Undefined because orderBys.length > sortOrders.length, or because the
    // argument is blank.
    if (order == null) {
      boolOrders[i] = true;
    }
    else {
      // Ensured by the `varTypes` of the signature, having ruled out nullish
      // above.
      invariant(isNum(order));
      const boolOrder = excelToSortOrder(order);
      if (isErr(boolOrder)) {
        return boolOrder;
      }
      boolOrders[i] = boolOrder;
    }
  }

  const horizontalOrderBys = [];
  const horizontalBoolOrders = [];
  const verticalOrderBys = [];
  const verticalBoolOrders = [];
  for (let i = 0; i < orderBys.length; i += 1) {
    const orderBy = orderBys[i];
    const boolOrder = boolOrders[i];
    if (orderBy.width === array.width && orderBy.height === 1) {
      horizontalOrderBys.push(orderBy);
      horizontalBoolOrders.push(boolOrder);
    }
    else if (orderBy.height === array.height && orderBy.width === 1) {
      verticalOrderBys.push(orderBy);
      verticalBoolOrders.push(boolOrder);
    }
    else {
      return ERROR_VALUE.detailed('Given order-bys should have same width or same height as first argument');
    }
  }

  if (verticalBoolOrders.length !== 0 && horizontalBoolOrders.length !== 0) {
    // @Árni: I don't see a reason why Excel doesn't allow this. We could remove
    // this check in GRID Scratchpad, making `SORTBY` more powerful while
    // remaining entirely backwards compatible, modulo omission of this `#VALUE!`.
    return ERROR_VALUE.detailed("Can't sort both horizontally and vertically");
  }

  /**
   * @type {MaybeBoxed<CellValue>[][]}
   */
  let arrayValues = array.resolveAreaBoxed();
  if (horizontalBoolOrders.length > 0) {
    // Sort horizontally
    const horizontalPermutation = calcSortingPermutation(
      horizontalOrderBys.map(m => {
        const values = m.resolveRange({ skipBlanks: 'none' });
        return i => values[i];
      }),
      horizontalBoolOrders,
      array.width > array.populatedWidth ? array.populatedWidth + 1 : array.width,
    );
    arrayValues = arrayValues.map(row => applyPermutation(horizontalPermutation, row));
  }
  else {
    // Sort vertically
    const verticalPermutation = calcSortingPermutation(
      verticalOrderBys.map(m => {
        const values = m.resolveRange({ skipBlanks: 'none' });
        return i => values[i];
      }),
      verticalBoolOrders,
      array.height > array.populatedHeight ? array.populatedHeight + 1 : array.height,
    );
    arrayValues = applyPermutation(verticalPermutation, arrayValues);
  }
  return Matrix.of(arrayValues);
}

/**
 * This function is an injective mapping between range areas/arrays and strings.
 * This mapping is needed because arrays don't work as hash-map keys in
 * JavaScript.
 * @param {MaybeBoxed<ArrayValue> | MaybeBoxed<ArrayValue>[]} value
 * @returns {string}
 */
export function uniqueHashable (value) {
  if (Array.isArray(value)) {
    const parts = value.map(uniqueHashable);
    return '[' + parts.join(',') + ']';
  }
  value = unbox(value);
  // Values within arrays / ranges are allowed to have the following types:
  // * BLANK
  // * Number
  // * Boolean
  // * Error
  // * String
  // * Lambda
  // All of which are handled here.
  if (isErr(value)) {
    // The "E" is there to make sure that errors are not confused with numbers.
    return 'E' + value.code;
  }
  if (isLambda(value)) {
    return 'L';
  }
  if (isStr(value)) {
    value = value.toLowerCase();
  }
  // BLANK, number or boolean
  return JSON.stringify(value);
}

/**
 * Syntax:
 *     =UNIQUE (array, [by_col], [exactly_once])
 * @param {null | Matrix} array Range or array from which to extract unique values.
 * @param {boolean} byCol How to compare and extract. By row = FALSE (default); by column = TRUE.
 * @param {boolean} exactlyOnce TRUE = values that occur once, FALSE= all unique values (default).
 * @returns {Matrix} Unique rows/columns of the array.
 */
export function UNIQUE (array, byCol, exactlyOnce) {
  const matrix = array == null ? Matrix.of(null) : array;

  const reverseHashables = new Map();
  const hashableCounts = new Map();
  if (!byCol) {
    for (const { y, row } of matrix.iterRowsBoxed()) {
      if (y > matrix.populatedHeight) {
        break; // remaining rows will all be identical to last one
      }
      const hashable = uniqueHashable(row);
      const count = hashableCounts.get(hashable) ?? 0;
      hashableCounts.set(hashable, count + 1);
      if (count === 0) {
        reverseHashables.set(hashable, row);
      }
    }
    const newRows = [];
    for (const [ hashable, count ] of hashableCounts.entries()) {
      if (count === 1 || !exactlyOnce) {
        newRows.push(reverseHashables.get(hashable));
      }
    }
    return Matrix.of(newRows);
  }
  else {
    for (const { x, column } of matrix.iterColumnsBoxed()) {
      if (x > matrix.populatedWidth) {
        break; // remaining columns will all be identical to last one
      }
      const hashable = uniqueHashable(column);
      const count = hashableCounts.get(hashable) ?? 0;
      hashableCounts.set(hashable, count + 1);
      if (count === 0) {
        reverseHashables.set(hashable, column);
      }
    }
    const newColumns = [];
    for (const [ hashable, count ] of hashableCounts.entries()) {
      if (count === 1 || !exactlyOnce) {
        newColumns.push(reverseHashables.get(hashable));
      }
    }
    return Matrix.ofTransposed(newColumns);
  }
}

const FILTER_NO_MATCH_MESSAGE = 'No matches are found in FILTER evaluation';

/** @type {import('./filter.js').FilterContext} */
const googleFilterContext = {
  ifEmpty: ERROR_NA.detailed('No matches are found in FILTER evaluation'),
  errorType: ERROR_NA,
  returnSingletonError: true,
  expandSingletonIncludes: false,
  errorsMeanFalse: true,
  refErrorOnNonResolvingArray: true,
  funcName: 'FILTER',
};

/** @type {Omit<import('./filter.js').FilterContext, 'ifEmpty'>} */
const nonGoogleFilterContext = {
  errorType: ERROR_VALUE,
  returnSingletonError: false,
  expandSingletonIncludes: true,
  errorsMeanFalse: false,
  refErrorOnNonResolvingArray: false,
  funcName: 'FILTER',
};

/**
 * Syntax
 *     =FILTER (array, include, [if_empty])
 * @this {EvaluationContext}
 * @param {Matrix | Reference} array Range or array to filter.
 * @param {Matrix | Reference} include Boolean array, supplied as criteria.
 * @param {Matrix | CellValue | undefined} thirdArg third argument (ifEmpty in Excel mode, first
 *   extra include arg in Google mode)
 * @param {(Matrix | Reference | undefined | FormulaError)[]} remainingArgs
 *   further include arguments, in Google mode. Never present in Excel mode.
 * @returns {CellValue | Matrix} Filtered array or value
 */
export function FILTER (array, include, thirdArg, ...remainingArgs) {
  if (this.mode === MODE_GOOGLE) {
    invariant(thirdArg === MISSING || isErr(thirdArg) || isMatrix(thirdArg) || isRef(thirdArg));
    const includeArgs =
      thirdArg === MISSING && remainingArgs.length === 0 ? [ include ] : [ include, thirdArg, ...remainingArgs ];
    return filter(googleFilterContext, array, ...includeArgs);
  }
  else {
    invariant(remainingArgs.length === 0);
    let ifEmpty = thirdArg;
    if (ifEmpty === MISSING) {
      ifEmpty = ERROR_CALC.detailed(FILTER_NO_MATCH_MESSAGE);
    }
    return filter({ ifEmpty, ...nonGoogleFilterContext }, array, include);
  }
}

/**
 * @param {Matrix | Reference} array Range or array to filter
 * @param {(Matrix | Reference | undefined)[]} includeArgs include arguments
 * @returns {MaybeBoxedFormulaValue} Filtered array or value
 */
export function FILTER_GOOGLE (array, ...includeArgs) {
  return filter(
    {
      ...googleFilterContext,
      funcName: 'FILTER.GOOGLE',
      ifEmpty: ERROR_NA.detailed('No matches are found in FILTER.GOOGLE evaluation'),
    },
    array,
    ...includeArgs,
  );
}

// Syntax:
//     =RANDARRAY ([rows], [columns], [min], [max], [integer])
// Arguments:
//     rows (Missing, Number):
//       [optional] Number of rows to return. Default = 1.
//     columns (Missing, Number):
//       [optional] Number of columns to return. Default = 1.
//     min (Missing, Number):
//       [optional] Minimum value to return. Default = 0.
//     max (Missing, Number):
//       [optional] Maximum value to return. Default = 1.
//     integer (Missing, Boolean):
//       [optional] Return whole numbers. Boolean, TRUE or FALSE. Default = FALSE.
// Return value (Array):
//   Array of random values
/**
 * @this {EvaluationContext}
 */
export function RANDARRAY (rows, columns, min, max, integer) {
  rows = rows === MISSING ? 1 : Math.floor(rows);
  columns = columns === MISSING ? 1 : Math.floor(columns);
  if (min === MISSING) {
    min = 0;
  }
  if (max === MISSING) {
    max = 1;
  }
  if (integer === MISSING) {
    integer = false;
  }
  if (rows < 0 || columns < 0 || min > max) {
    return ERROR_VALUE;
  }
  if (integer && !(Number.isInteger(min) && Number.isInteger(max))) {
    return ERROR_VALUE;
  }
  if (this.mode === MODE_GOOGLE && (rows === 0 || columns === 0)) {
    return ERROR_NUM.detailed('RANDARRAY rows and columns arguments should be nonzero');
  }

  const result = new Matrix(columns, rows);
  for (let r = 0; r < rows; r += 1) {
    for (let c = 0; c < columns; c += 1) {
      let rand;
      if (min === max) {
        rand = min;
      }
      else if (integer) {
        rand = Math.floor(Math.random() * (max - min + 1) + min);
      }
      else {
        rand = Math.random() * (max - min) + min;
      }
      result.set(c, r, rand);
    }
  }
  return result;
}

/**
 * Collapse a reference/matrix to a single cell by implicit intersection (for
 * references) or choosing the top-left value (for arrays). No-op if `value` is
 * already a single cell value.
 * @param {ArrayValue | Reference | Matrix | undefined} value
 * @this {EvaluationContext}
 * @returns {ArrayValue | FormulaError | MISSING}
 */
export function SINGLE (value) {
  if (!isRef(value) && !isMatrix(value)) {
    return value;
  }
  if (isMatrix(value) || value.size === 1) {
    if (value.size === 0) {
      return ERROR_CALC;
    }
    const collapsed = value.collapseToCell(0, 0);
    invariant(!isErr(collapsed));
    return collapsed.resolveSingle();
  }

  // Value is a reference
  const collapsed = implicitIntersection(this.cellId, value);
  if (isErr(collapsed)) {
    return collapsed;
  }
  return collapsed.resolveSingle();
}

/**
 * @param {string | null | undefined} cellId
 * @param {Reference} ref
 * @returns {Reference | FormulaError}
 */
export function implicitIntersection (cellId, ref) {
  if (!ref.is1D()) {
    return ERROR_VALUE.detailed(SINGLE_CELL_ERROR_DETAIL_MSG);
  }
  if (cellId == null) {
    // The `cellId` is null if this function is called from a context outside
    // of function evaluation within a worksheet.
    return ERROR_VALUE.detailed(SINGLE_CELL_ERROR_DETAIL_MSG);
  }
  const [ row, col ] = a1ToRowColumn(cellId);
  if (ref.width > ref.height) {
    if (col < ref.left || col > ref.right) {
      return ERROR_VALUE.detailed(SINGLE_CELL_ERROR_DETAIL_MSG);
    }
    return ref.collapseToColumn(col - ref.left);
  }
  else {
    if (row < ref.top || row > ref.bottom) {
      return ERROR_VALUE.detailed(SINGLE_CELL_ERROR_DETAIL_MSG);
    }
    return ref.collapseToRow(row - ref.top);
  }
}

function chosenNumbers (args) {
  const allNums = [];
  for (const num of args) {
    if (isMatrix(num)) {
      if (!num.is1D()) {
        return ERROR_VALUE;
      }
      const resolved = num.resolveRange({ skipBlanks: 'none' });
      if (resolved.some(n => !isNum(n))) {
        return ERROR_VALUE;
      }
      allNums.push(...resolved);
    }
    else {
      allNums.push(num);
    }
  }
  return allNums;
}

/**
 * Normalises an array of indices.
 * @param {Array<number>} indices Array of 1-based indices, positive or negative.
 * @param {number} length Length of the collection which will be indexed into.
 * @returns {Array<number>} Array of 0-based indices, all positive.
 */
function normaliseIndices (indices, length) {
  return indices.map(n => {
    if (n < 0) {
      return length + n;
    }
    return n - 1;
  });
}

/**
 * @param {Matrix | Reference} array
 * @param {(Matrix | number)[]} colNums
 */
export function CHOOSECOLS (array, ...colNums) {
  const matrix = array.toMatrix(false);
  if (isErr(matrix)) {
    return matrix;
  }
  let chosenCols = chosenNumbers(colNums);
  if (isErr(chosenCols)) {
    return chosenCols;
  }
  if (chosenCols.some(n => Math.abs(n) > matrix.width || n === 0)) {
    return ERROR_VALUE;
  }
  chosenCols = normaliseIndices(chosenCols, matrix.width);
  let result = new Matrix(0, 0);
  for (const col of chosenCols) {
    const colMatrix = matrix.collapseToColumn(col);
    if (isErr(colMatrix)) {
      return colMatrix;
    }
    result = result.hstack(colMatrix);
  }
  return result;
}

/**
 * @param {Matrix | Reference} array
 * @param {(Matrix | number)[]} rowNums
 */
export function CHOOSEROWS (array, ...rowNums) {
  const matrix = array.toMatrix(false);
  if (isErr(matrix)) {
    return matrix;
  }
  let chosenRows = chosenNumbers(rowNums);
  if (isErr(chosenRows)) {
    return chosenRows;
  }
  if (chosenRows.some(n => Math.abs(n) > matrix.height || n === 0)) {
    return ERROR_VALUE;
  }
  chosenRows = normaliseIndices(chosenRows, matrix.height);
  let result = new Matrix(0, 0);
  for (const row of chosenRows) {
    const rowMatrix = matrix.collapseToRow(row);
    if (isErr(rowMatrix)) {
      return rowMatrix;
    }
    result = result.vstack(rowMatrix);
  }
  return result;
}

/**
 * @param {(Matrix | Reference)[]} arraysOrRefs
 * @param {CellValue} padWith
 * @returns {Matrix}
 */
function hstackAll (arraysOrRefs, padWith) {
  const matrices = arraysOrRefs.map(a => {
    const m = a.toMatrix(false);
    if (isErr(m)) {
      return new Matrix(a.width, a.height, m);
    }
    return m;
  });
  return matrices.reduce((acc, next) => {
    if (acc.height < next.height) {
      acc = acc.expand(padWith, next.height, acc.width);
    }
    else if (next.height < acc.height) {
      next = next.expand(padWith, acc.height, next.width);
    }
    return acc.hstack(next);
  });
}

/**
 * @param  {...(Matrix | Reference)} arrays
 * @returns {Matrix}
 */
export function HSTACK (...arrays) {
  return hstackAll(arrays, ERROR_NA);
}

/**
 * @param {(Matrix | Reference)[]} arraysOrRefs
 * @param {MaybeBoxed<ArrayValue>} padWith
 * @returns {Matrix}
 */
export function vstackAll (arraysOrRefs, padWith) {
  const arrays = arraysOrRefs.map(a => {
    const m = a.toMatrix(false);
    if (isErr(m)) {
      return new Matrix(a.width, a.height, m);
    }
    return m;
  });
  return arrays.reduce((/** @type {Matrix} */ acc, /** @type {Matrix} */ next) => {
    if (acc.width < next.width) {
      acc = acc.expand(padWith, acc.height, next.width);
    }
    else if (next.width < acc.width) {
      next = next.expand(padWith, next.height, acc.width);
    }
    return acc.vstack(next);
  });
}

/**
 * @param {...(Matrix | Reference)} arrays
 * @returns {Matrix}
 */
export function VSTACK (...arrays) {
  return vstackAll(arrays, ERROR_NA);
}

/**
 * @this {EvaluationContext}
 * @param {Reference} rangeRef
 */
export function ANCHORARRAY (rangeRef) {
  if (rangeRef.size !== 1) {
    return ERROR_REF.detailed('ANCHORARRAY reference should be of size 1');
  }
  const sheet = this.resolveSheet(
    rangeRef.sheetName || rangeRef.ctx?.sheetName,
    rangeRef.workbookName || rangeRef.ctx?.workbookName,
  );
  const spilledRange = sheet?.getSpillAnchoredAtRange(rangeRef);
  if (!spilledRange) {
    return ERROR_REF.detailed('ANCHORARRAY reference should point to a formula cell');
  }
  return new Reference(spilledRange, { sheetName: rangeRef.sheetName, workbookName: rangeRef.workbookName });
}
/**
 * Common plumbing shared between TOROW and TOCOL function implementations.
 *
 * Return a list of values from `array`, in column or row order, optionally skipping blanks and/or errors.
 * @param {Matrix | Reference} array
 * @param {number | undefined} ignore: 1 to skip blanks, 2 to skip errors, 3 to skip both. All other values skip nothing.
 * @param {boolean | undefined} scanByColumn
 * @returns {MaybeBoxed<ArrayValue>[] | FormulaError}
 */
function getValuesForToRowAndToCol (array, ignore, scanByColumn) {
  if (!ignore) {
    ignore = 0;
  }
  else {
    ignore = Math.floor(ignore);
    // Excel doesn't seem to do _any_ input validation on the `ignore` argument. What?!
    // Documented options are [0, 1, 2, 3]. Other values seem to allow everything, so
    // we just convert those to zeroes. Maybe this will change once the functions
    // are out of beta.
    if (![ 0, 1, 2, 3 ].includes(ignore)) {
      ignore = 0;
    }
  }
  const ignoreBlanks = (ignore & 1) !== 0;
  const ignoreErrors = (ignore & 2) !== 0;

  if (scanByColumn === MISSING) {
    scanByColumn = false;
  }

  const matrix = array.toMatrix(false);
  if (isErr(matrix)) {
    return matrix;
  }
  const boxedValues = scanByColumn ? matrix.iterAllColumnWise(true) : matrix.iterAll({ leaveBoxed: true });

  const data = [];
  for (const { value } of boxedValues) {
    if (!((ignoreBlanks && unbox(value) === BLANK) || (ignoreErrors && isErr(value)))) {
      data.push(value);
    }
  }
  return data;
}

/**
 * @param {Matrix | Reference} array
 * @param {number} [ignore]
 * @param {boolean} [scanByColumn]
 * @returns {Matrix | FormulaError}
 */
export function TOROW (array, ignore, scanByColumn) {
  const row = getValuesForToRowAndToCol(array, ignore, scanByColumn);
  if (isErr(row)) {
    return row;
  }
  return Matrix.createRow(row);
}

/**
 * @param {Matrix | Reference} array
 * @param {number} [ignore]
 * @param {boolean} [scanByColumn]
 * @returns {Matrix | FormulaError}
 */
export function TOCOL (array, ignore, scanByColumn) {
  const column = getValuesForToRowAndToCol(array, ignore, scanByColumn);
  if (isErr(column)) {
    return column;
  }
  return Matrix.createColumn(column);
}

/**
 * @param {number} length
 * @param {number} majorDim
 * @returns {number}
 */
function calculateMinorDimension (length, majorDim) {
  return Math.ceil(length / majorDim);
}

/**
 * @param {Matrix} vector
 * @param {number} wrapCount
 * @param {CellValue | Lambda} [padWith]
 * @returns {Matrix | FormulaError}
 */
export function WRAPCOLS (vector, wrapCount, padWith) {
  if (!vector.is1D()) {
    return ERROR_VALUE;
  }
  wrapCount = Math.trunc(wrapCount);
  if (wrapCount < 1) {
    return ERROR_NUM;
  }
  else if (padWith === MISSING) {
    padWith = ERROR_NA;
  }
  const resolved = vector.resolveRange({ leaveBoxed: true });
  const colsNeeded = calculateMinorDimension(resolved.length, wrapCount);
  const result = new Matrix(colsNeeded, wrapCount);
  let i = 0;
  for (let col = 0; col < colsNeeded; col += 1) {
    for (let row = 0; row < wrapCount; row += 1) {
      if (i < resolved.length) {
        result.set(col, row, resolved[i]);
        i += 1;
      }
      else {
        result.set(col, row, padWith);
      }
    }
  }
  return result;
}

/**
 * @param {Matrix} vector
 * @param {number} wrapCount
 * @param {CellValue | Lambda} [padWith]
 * @returns {Matrix | FormulaError}
 */
export function WRAPROWS (vector, wrapCount, padWith) {
  const result = WRAPCOLS(vector, wrapCount, padWith);
  if (isErr(result)) {
    return result;
  }
  return Matrix.ofTransposed(result);
}

/**
 * @param {number | undefined} dim
 * @param {number} dimLength
 * @returns {[number, number]}
 */
function preprocessTakeSpan (dim, dimLength) {
  if (dim === MISSING) {
    dim = dimLength;
  }
  else {
    dim = Math.trunc(dim);
    if (Math.abs(dim) > dimLength) {
      dim = dimLength;
    }
  }
  let start;
  if (dim > 0) {
    start = 0;
  }
  else {
    start = dimLength + dim;
  }
  return [ start, Math.abs(dim) ];
}

/**
 * @param {Matrix | Reference} array
 * @param {number} [rows]
 * @param {number} [columns]
 * @returns {Matrix | Reference | FormulaError}
 */
export function TAKE (array, rows, columns) {
  const [ startRow, numRows ] = preprocessTakeSpan(rows, array.height);
  const [ startCol, numCols ] = preprocessTakeSpan(columns, array.width);
  if (numRows === 0 || numCols === 0) {
    return ERROR_VALUE;
  }
  if (isMatrix(array)) {
    const result = new Matrix(numCols, numRows, array._defaultValue);
    const dataHeight = Math.min(array.populatedHeight - startRow, numRows);
    const dataWidth = Math.min(array.populatedWidth - startCol, numCols);
    for (let row = 0; row < dataHeight; row += 1) {
      for (let col = 0; col < dataWidth; col += 1) {
        result.set(col, row, array.getBoxed(startCol + col, startRow + row));
      }
    }
    if (dataWidth < numCols) {
      result._defaultColumn = array._defaultColumn.slice(0, dataHeight);
    }
    if (dataHeight < numRows) {
      result._defaultRow = array._defaultRow.slice(0, dataWidth);
    }
    return result;
  }
  else {
    return array.offset(startRow, startCol, numRows, numCols);
  }
}

/**
 * @param {number} length
 * @param {number} arg
 * @returns {number}
 */
function dropArgToTakeArg (length, arg) {
  if (arg > 0) {
    return -length + arg;
  }
  else {
    return length + arg;
  }
}

/**
 * @param {Matrix | Reference} array
 * @param {number} [rows]
 * @param {number} [columns]
 * @returns {Matrix | Reference | FormulaError}
 */
export function DROP (array, rows, columns) {
  rows = rows === MISSING ? 0 : Math.trunc(rows);
  if (Math.abs(rows) >= array.height) {
    return ERROR_VALUE;
  }
  columns = columns === MISSING ? 0 : Math.trunc(columns);
  if (Math.abs(columns) >= array.width) {
    return ERROR_VALUE;
  }
  return TAKE(array, dropArgToTakeArg(array.height, rows), dropArgToTakeArg(array.width, columns));
}

/**
 * @param {Matrix} array
 * @param {number} [rows]
 * @param {number} [columns]
 * @param {ArrayValue} [padWith]
 * @returns {Matrix | FormulaError}
 */
export function EXPAND (array, rows, columns, padWith) {
  if (isMatrix(padWith)) {
    // FIXME: We haven't figured out what an array `padWith` argument is
    // supposed to do. It's not documented anywhere, and only ever seems to
    // return `#VALUE!`.
    return ERROR_VALUE;
  }
  if (rows === MISSING) {
    rows = array.height;
  }
  rows = Math.trunc(rows);
  if (columns === MISSING) {
    columns = array.width;
  }
  columns = Math.trunc(columns);
  if (rows === array.height && columns === array.width) {
    // Nothing to do
    return array;
  }
  if (rows < array.height || columns < array.width) {
    return ERROR_VALUE;
  }
  if (arguments.length < 4) {
    padWith = ERROR_NA;
  }
  else if (padWith === MISSING) {
    padWith = BLANK;
  }
  return array.expand(padWith, rows, columns);
}
