/** @module */
import { ERROR_VALUE } from '../constants.js';
import { toNum } from './utils.js';
import { isNum, isStr, isErr, isRef, isMatrix } from '../../utils.js';

/** Filters an argument list down to numbers or error but allows fewer things through
 * than the toNumList method used by aggregate functions.
 * Conforms to the behaviour of XNPV (in Excel).
 * @param {FormulaArgument[]} args zero or more arguments
 * @param {FormulaError} [errorType] error value to return if a non-number is encountered
 * @returns {number[] | FormulaError}
 */
export function toNumListStrict (args, errorType = ERROR_VALUE) {
  const res = [];
  let r = 0;
  for (const arg of args) {
    const value = arg;
    if (isNum(value) && isFinite(value)) {
      res[r++] = value;
    }
    else if (isStr(value)) {
      const n = value ? toNum(value) : errorType;
      if (isErr(n) || !isFinite(n)) {
        return errorType;
      }
      res[r++] = n;
    }
    else if (isRef(value) || isMatrix(value)) {
      const sub = value.resolveRange({ skipBlanks: 'none' });
      if (isErr(sub)) {
        return sub;
      }
      for (const subValue of sub) {
        if (isNum(subValue) && isFinite(subValue)) {
          res[r++] = subValue;
        }
        else {
          return errorType;
        }
      }
    }
    else {
      return errorType;
    }
  }
  return res;
}
