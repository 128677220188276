import { ERROR_CALC, ERROR_NA, ERROR_VALUE, MISSING } from '../constants';
import { Lambda, isLambda } from '../lambda';
import FormulaError from '../FormulaError';
import Reference, { isRef } from '../Reference';
import Matrix, { isMatrix } from '../Matrix';
import { FormulaArgument, CellValueAtom, ArrayValue, MaybeBoxedFormulaArgument } from '../types';
import { isErr } from './utils';
import { EvaluationContext } from '../EvaluationContext';
import { MaybeBoxed } from '../ValueBox';
import { invariant } from '../../validation';

function validateLambda (
  arg: Reference | Matrix | FormulaError | Lambda | CellValueAtom,
  numArgs: number,
): Lambda | FormulaError {
  if (isErr(arg)) {
    return arg;
  }
  if (isLambda(arg)) {
    if (arg.numParams !== numArgs) {
      return ERROR_VALUE.detailed(`Lambda given ${numArgs} arguments, but it accepts ${arg.numParams}`);
    }
    return arg;
  }
  return ERROR_VALUE;
}

function coerceLambdaResult (v: MaybeBoxedFormulaArgument): [true, MaybeBoxed<ArrayValue>] | [false, FormulaError] {
  if (isMatrix(v) || isRef(v)) {
    if (v.size !== 1) {
      return [ false, ERROR_CALC ];
    }
    v = v.resolveSingleBoxed();
  }
  // TODO: It's not actually correct to coerce `MISSING` to `BLANK` here. We do
  // it because our `Matrix` class doesn't support `MISSING` values yet.
  v = v ?? null;
  return [ true, v ];
}

/**
 * MAKEARRAY(rows, cols, lambda(row, col))
 * Returns a calculated array of a specified row and column size, by applying a LAMBDA.
 */
export function MAKEARRAY (
  this: EvaluationContext,
  rows: number | undefined,
  cols: number | undefined,
  lambdaArg: Reference | Matrix | FormulaError | Lambda | CellValueAtom,
) {
  rows = rows === MISSING ? 1 : Math.floor(rows);
  cols = cols === MISSING ? 1 : Math.floor(cols);
  const lambda = validateLambda(lambdaArg, 2);
  if (isErr(lambda)) {
    return lambda;
  }
  if (rows < 1 || cols < 1) {
    return ERROR_VALUE;
  }
  const result = new Matrix(cols, rows);
  for (let r = 1; r <= rows; r++) {
    for (let c = 1; c <= cols; c++) {
      const [ ok, v ] = coerceLambdaResult(lambda.call(this, [ r, c ]));
      if (!ok) {
        return v;
      }
      result.set(c - 1, r - 1, v);
    }
  }
  return result;
}

/**
 * BYROW(array, lambda(row))
 * Applies a LAMBDA to each row and returns an array of the results.
 */
export function BYROW (
  this: EvaluationContext,
  array: Reference | Matrix,
  lambdaArg: FormulaError | Lambda | CellValueAtom,
): Matrix | FormulaError {
  const lambda = validateLambda(lambdaArg, 1);
  if (isErr(lambda)) {
    return lambda;
  }
  const result = new Matrix(1, array.height);
  for (let i = 0; i < array.height; i++) {
    const row = array.collapseToRow(i);
    invariant(!isErr(row));
    const [ ok, v ] = coerceLambdaResult(lambda.call(this, [ row ]));
    if (!ok) {
      return v;
    }
    result.set(0, i, v);
  }
  return result;
}

/**
 * BYCOL (array, lambda(column))
 * Applies a LAMBDA to each column and returns an array of the results.
 */
export function BYCOL (
  this: EvaluationContext,
  array: Reference | Matrix,
  lambdaArg: FormulaError | Lambda | CellValueAtom,
): Matrix | FormulaError {
  const lambda = validateLambda(lambdaArg, 1);
  if (isErr(lambda)) {
    return lambda;
  }
  const result = new Matrix(array.width, 1);
  for (let i = 0; i < array.width; i++) {
    const column = array.collapseToColumn(i);
    invariant(!isErr(column));
    const [ ok, v ] = coerceLambdaResult(lambda.call(this, [ column ]));
    if (!ok) {
      return v;
    }
    result.set(i, 0, v);
  }
  return result;
}

// A shared implementation of both SCAN and REDUCE
function scanReduce (
  ctx: EvaluationContext,
  initialValue: FormulaArgument,
  array: Reference | Matrix,
  lambdaArg: FormulaError | Lambda | CellValueAtom,
  isScan: boolean,
) {
  const lambda = validateLambda(lambdaArg, 2);
  if (isErr(lambda)) {
    return lambda;
  }
  const values = array.toMatrix(false);
  if (isErr(values)) {
    return values;
  }
  const scanMatrix = isScan ? new Matrix(array.width, array.height) : null;
  let acc: MaybeBoxedFormulaArgument = initialValue;
  for (let r = 0; r < array.height; r++) {
    for (let c = 0; c < array.width; c++) {
      const lambdaResult =
        r === 0 && c === 0 && initialValue === MISSING
          ? values.getBoxed(c, r)
          : lambda.call(ctx, [ acc, values.getBoxed(c, r) ]);
      if (scanMatrix != null) {
        const [ ok, coercedLambdaResult ] = coerceLambdaResult(lambdaResult);
        if (!ok) {
          return coercedLambdaResult;
        }
        scanMatrix.set(c, r, coercedLambdaResult);
        acc = coercedLambdaResult;
      }
      else {
        acc = lambdaResult;
      }
    }
  }
  return isScan ? scanMatrix : acc;
}

/**
 * SCAN ([initial_value], array, lambda(accumulator, value, body))
 * Scans an array by applying a LAMBDA to each value and returns an array that
 * has each intermediate value.
 */
export function SCAN (
  this: EvaluationContext,
  initialValue: FormulaArgument,
  array: Reference | Matrix,
  lambdaArg: FormulaError | Lambda | CellValueAtom,
) {
  return scanReduce(this, initialValue, array, lambdaArg, true);
}

/**
 * REDUCE([initial_value], array, lambda(accumulator, value, body))
 * Reduces an array to an accumulated value by applying a LAMBDA to each value
 * and returning the total value in the accumulator.
 */
export function REDUCE (
  this: EvaluationContext,
  initialValue: FormulaArgument,
  array: Reference | Matrix,
  lambdaArg: FormulaError | Lambda | CellValueAtom,
) {
  return scanReduce(this, initialValue, array, lambdaArg, false);
}

/**
 * MAP (array1, lambda_or_array<#>)
 * Returns an array formed by mapping each value in the array(s) to a new value
 * by applying a LAMBDA to create a new value.
 */
export function MAP (this: EvaluationContext, ...args: (Reference | Matrix | FormulaError | Lambda | CellValueAtom)[]) {
  const arrayArgs = args.slice(0, -1);
  let maxWidth = 0;
  let maxHeight = 0;
  let minHeight = Infinity;
  let minWidth = Infinity;
  const arrays: Matrix[] = [];
  for (const arrayArg of arrayArgs) {
    if (!isMatrix(arrayArg) && !isRef(arrayArg)) {
      return ERROR_VALUE;
    }
    const array = arrayArg.toMatrix();
    if (isErr(array)) {
      return array;
    }
    arrays.push(array);
    maxWidth = Math.max(maxWidth, array.width);
    minWidth = Math.min(minWidth, array.width);
    maxHeight = Math.max(maxHeight, array.height);
    minHeight = Math.min(minHeight, array.height);
  }
  const lambdaArg = args[args.length - 1];
  const lambda = validateLambda(lambdaArg, arrayArgs.length);
  if (isErr(lambda)) {
    return lambda;
  }
  const result = new Matrix(maxWidth, maxHeight, ERROR_NA);
  for (let x = 0; x < minWidth; x++) {
    for (let y = 0; y < minHeight; y++) {
      const [ ok, coercedLambdaResult ] = coerceLambdaResult(
        lambda.call(
          this,
          arrays.map(array => array.getBoxed(x, y)),
        ),
      );
      if (!ok) {
        return coercedLambdaResult;
      }
      result.set(x, y, coercedLambdaResult);
    }
  }
  return result;
}
