import { ERROR_DIV0, ERROR_NUM, ERROR_NA, BLANK, ERROR_VALUE, ERROR_REF } from '../constants.js';
import {
  isRef,
  isMatrix,
  isErr,
  toNum,
  toNumList,
  stdDev,
  rank as getRank,
  bisectLeft,
  sortingPermutation,
  applyPermutation,
  invertPermutation,
  toBool,
  toNumEngineering,
} from './utils.js';
import { round15 } from './utils-number';
import { combinations, percentile, power_over_factorial } from './utils-math';
import { TYPE_ERROR, TYPE_NUM, TYPE_BOOL, TYPE_STRING, TYPE_MISSING, type ArrayValue, CellValueAtom } from '../types';
import { valueToType } from '../coerce';
import jstat from './jstat.js';
import {
  arraySpecializeFilterMap,
  collectNumbers,
  standardFilterMap,
  aggregationAFilterMap,
  average,
  count,
  min,
  max,
  numberFilterMap,
} from './utils-visit.js';
import Matrix from '../Matrix.js';
import { ascending } from '../../algorithm/utils';
import { floydRivest } from '../../algorithm/floyd-rivest.js';
import { isNum, isStr } from '../../utils.js';
import { invariant } from '../../validation';
import type FormulaError from '../FormulaError.js';
import type Reference from '../Reference.js';
import { range, zip } from '@grid-is/iterators';
import { MMULT } from './matrix-math.js';
import { toNumListStrict } from './utils-financial.js';
import { unbox } from '../ValueBox.js';
import { Lambda, isLambda } from '../lambda';

// AVEDEV(value1, [value2, ...])
// Calculates the average of the magnitudes of deviations of data from a dataset's mean.
export function AVEDEV () {
  return null;
}

/**
 * AVERAGE(value1, [value2, ...])
 * Returns the numerical average value in a dataset, ignoring text.
 */
export function AVERAGE (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return average(args, standardFilterMap);
}

/**
 * AVERAGEA(value1, [value2, ...])
 * Returns the numerical average value in a dataset.
 */
export function AVERAGEA (...args: (number | Matrix | Reference)[]): number | FormulaError {
  // Unlike aggregationAFilterMap, AVERAGEA doesn't seem to convert booleans
  // inside arrays.
  function arrayFilterMap (x: ArrayValue | undefined): [true, number | FormulaError | Lambda] | [false, null] {
    if (isNum(x) || isErr(x) || isLambda(x)) {
      return [ true, x ];
    }
    if (isStr(x)) {
      return [ true, 0 ];
    }
    return [ false, null ];
  }
  return average(args, arraySpecializeFilterMap(aggregationAFilterMap, arrayFilterMap));
}

/**
 * BETA.DIST(value, alpha, beta, cumulative, lower_bound, upper_bound)
 * Returns the probability of a given value as defined by the beta distribution function.
 */
export function BETA_DIST (
  x: number,
  alpha: number,
  beta: number,
  cumulative: boolean = false,
  lo: number = 0,
  hi: number = 1,
): number | FormulaError {
  if (alpha <= 0 || beta <= 0) {
    return ERROR_NUM;
  }
  if (x < lo || x > hi || lo === hi) {
    return ERROR_NUM;
  }

  const range = hi - lo;
  x = (x - lo) / range;
  if (cumulative) {
    return jstat.beta.cdf(x, alpha, beta);
  }
  else {
    return jstat.beta.pdf(x, alpha, beta) / range;
  }
}

export function BETADIST (x: number, alpha: number, beta: number, lo?: number, hi?: number): number | FormulaError {
  return BETA_DIST(x, alpha, beta, true, lo, hi);
}

/**
 * BETA.INV(probability, alpha, beta, lower_bound, upper_bound)
 * Returns the value of the inverse beta distribution function for a given probability.
 */
export function BETA_INV (
  prob: number,
  alpha: number,
  beta: number,
  lo: number = 0,
  hi: number = 1,
): number | FormulaError {
  if (alpha <= 0 || beta <= 0 || prob <= 0 || prob >= 1 || lo > hi) {
    return ERROR_NUM;
  }

  return jstat.beta.inv(prob, alpha, beta) * (hi - lo) + lo;
}

/**
 * BETA.INV(probability, alpha, beta, lower_bound, upper_bound)
 * Returns the value of the inverse beta distribution function for a given probability.
 */
export function BETAINV (prob: number, alpha: number, beta: number, lo?: number, hi?: number): number | FormulaError {
  return BETA_INV(prob, alpha, beta, lo, hi);
}

/**
 * BINOM.DIST(number_s, trials, probability_s, cumulative)
 * The probability of getting less than or equal to a particular value in a binomial distribution.
 */
export function BINOM_DIST (
  num_success: number,
  trials: number,
  prob_success: number,
  cumulative: boolean,
): number | FormulaError {
  num_success = Math.floor(num_success);
  trials = Math.floor(trials);
  if (num_success < 0 || num_success > trials) {
    return ERROR_NUM;
  }
  if (prob_success < 0 || prob_success > 1) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.binomial.cdf(num_success, trials, prob_success);
  }
  else {
    return jstat.binomial.pdf(num_success, trials, prob_success);
  }
}

/**
 * Old version of BINOM.DIST
 */
export function BINOMDIST (
  num_success: number,
  trials: number,
  prob_success: number,
  cumulative: boolean,
): number | FormulaError {
  return BINOM_DIST(num_success, trials, prob_success, cumulative);
}

/**
 * BINOM.DIST.RANGE(trials,probability_s,number_s,[number_s2])
 * The probability of a trial result using a binomial distribution.
 */
export function BINOM_DIST_RANGE (
  trials: number,
  prob_success: number,
  num_success1: number,
  num_success2: number,
): number | FormulaError {
  trials = Math.trunc(trials);
  num_success1 = Math.trunc(num_success1);
  if (num_success2 == null) {
    num_success2 = num_success1;
  }
  else {
    num_success2 = Math.trunc(num_success2);
  }

  if (
    prob_success < 0 ||
    prob_success > 1 ||
    num_success1 < 0 ||
    num_success2 > trials ||
    num_success1 > num_success2
  ) {
    return ERROR_NUM;
  }

  let result = 0;
  for (let i = num_success1; i <= num_success2; i += 1) {
    const nCombinations = combinations(trials, i);
    invariant(isNum(nCombinations));
    result += nCombinations * prob_success ** i * (1 - prob_success) ** (trials - i);
  }
  return result;
}

/**
 * BINOM.INV(trials, probability_s, alpha)
 * Returns the smallest value for which the cumulative binomial distribution
 * is greater than or equal to a criterion value.
 */
export function BINOM_INV (trials: number, prob_success: number, alpha: number): number | FormulaError {
  if (trials < 0 || prob_success <= 0 || prob_success >= 1 || alpha <= 0 || alpha >= 1) {
    return ERROR_NUM;
  }
  trials = Math.trunc(trials);
  // Perform binary search to find the tipping point where the CDF >= alpha.
  let lo = 0;
  let hi = trials;
  while (lo < hi) {
    const mid = lo + Math.floor((hi - lo) / 2);
    if (jstat.binomial.cdf(mid, trials, prob_success) >= alpha) {
      hi = mid;
    }
    else {
      lo = mid + 1;
    }
  }
  return hi;
}

/**
 * Old version of BINOM.INV
 */
export function CRITBINOM (trials: number, prob_success: number, alpha: number) {
  return BINOM_INV(trials, prob_success, alpha);
}

/**
 * CHISQ.DIST(x, degrees_freedom, cumulative)
 * Calculates the left-tailed chi-squared distribution, often used in hypothesis testing.
 */
export function CHISQ_DIST (x: number, df: number, cumulative: boolean): number | FormulaError {
  df = Math.floor(df);
  if (x < 0 || df < 1 || df > 10 ** 10) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.chisquare.cdf(x, df);
  }
  else {
    return jstat.chisquare.pdf(x, df);
  }
}

/**
 * CHISQ.DIST.RT(x, degrees_freedom)
 * Calculates the right-tailed chi-squared distribution, often used in hypothesis testing.
 */
export function CHISQ_DIST_RT (x: number, df: number): number | FormulaError {
  df = Math.floor(df);
  if (x < 0 || df < 1 || df > 10 ** 10) {
    return ERROR_NUM;
  }
  return 1 - jstat.chisquare.cdf(x, df);
}

/**
 * Old version of CHISQ.DIST.RT
 */
export function CHIDIST (x: number, df: number): number | FormulaError {
  return CHISQ_DIST_RT(x, df);
}

/**
 * CHISQ.INV(probability, degrees_freedom)
 * Calculates the inverse of the left-tailed chi-squared distribution.
 */
export function CHISQ_INV (prob: number, df: number): number | FormulaError {
  df = Math.floor(df);
  if (prob < 0 || prob >= 1 || df < 1 || df > 10 ** 10) {
    return ERROR_NUM;
  }
  return jstat.chisquare.inv(prob, df);
}

export function CHIINV (prob: number, df: number): number | FormulaError {
  return CHISQ_INV_RT(prob, df);
}

/**
 * CHISQ.INV.RT(probability, degrees_freedom)
 * Calculates the inverse of the right-tailed chi-squared distribution.
 */
export function CHISQ_INV_RT (prob: number, df: number): number | FormulaError {
  df = Math.floor(df);
  if (prob <= 0 || prob > 1 || df < 1 || df > 10 ** 10) {
    return ERROR_NUM;
  }
  return jstat.chisquare.inv(1 - prob, df);
}

// CHISQ.TEST(???)
// The value from the chi-squared distribution.
export function CHISQ_TEST () {
  return null;
}

/**
 * CONFIDENCE.NORM(alpha, standard_deviation, pop_size)
 * Calculates the width of half the confidence interval for a normal distribution.
 */
export function CONFIDENCE_NORM (alpha: number, std_dev: number, pop_size: number): number | FormulaError {
  pop_size = Math.trunc(pop_size);
  if (alpha <= 0 || alpha >= 1 || std_dev <= 0 || pop_size < 1) {
    return ERROR_NUM;
  }
  return jstat.normalci(1, alpha, std_dev, pop_size)[1] - 1;
}

/**
 * Old version of CONFIDENCE.NORM
 */
export function CONFIDENCE (alpha: number, std_dev: number, pop_size: number): number | FormulaError {
  return CONFIDENCE_NORM(alpha, std_dev, pop_size);
}

/**
 * CONFIDENCE.T(alpha, standard_deviation, pop_size)
 * The confidence interval for a population mean using a t distribution.
 */
export function CONFIDENCE_T (alpha: number, std_dev: number, pop_size: number): number | FormulaError {
  pop_size = Math.trunc(pop_size);
  if (alpha <= 0 || alpha >= 1 || std_dev <= 0) {
    return ERROR_NUM;
  }
  if (pop_size === 1) {
    return ERROR_DIV0;
  }
  if (pop_size < 1) {
    return ERROR_NUM;
  }
  return jstat.tci(1, alpha, std_dev, pop_size)[1] - 1;
}

/**
 * CORREL(array1, array2)
 * Calculates r, the Pearson product-moment correlation coefficient of a dataset.
 */
export function CORREL (array1: Matrix, array2: Matrix): number | FormulaError {
  const prep = prepCorrelOrCovariance(array1, array2, 'CORREL');
  if (isErr(prep)) {
    return prep;
  }
  const [ seq1, seq2 ] = prep;
  const corr = jstat.corrcoeff(seq1, seq2);
  return isNaN(corr) ? ERROR_DIV0 : corr;
}

/**
 * COUNT(value1, [value2, ...])
 */
export function COUNT (...args: (number | Matrix | Reference | FormulaError)[]): number {
  return count(args, x => [ isNum(x), x ]);
}

/**
 * COUNTA(value1, [value2, ...])
 */
export function COUNTA (...args: (number | Matrix | Reference | FormulaError | undefined)[]): number {
  const acceptedTypesMask = TYPE_ERROR | TYPE_NUM | TYPE_STRING | TYPE_BOOL | TYPE_MISSING;
  return count(args, x => [ (valueToType(x) & acceptedTypesMask) !== 0, x ]);
}

/**
 * COUNTBLANK(value1, [value2, ...])
 * Excel only allows a single argument. We and Google Sheets both allow multiple arguments.
 */
export function COUNTBLANK (...args: (Matrix | Reference)[]): number {
  return count(args, x => [ x === BLANK || x === '', x ]);
}

/**
 * COVARIANCE.P(array1, array2)
 * Returns population covariance, the average of the products of deviations for
 * each data point pair in two data sets.
 */
export function COVARIANCE_P (array1: Matrix, array2: Matrix): number | FormulaError {
  const prep = prepCorrelOrCovariance(array1, array2, 'COVARIANCE.P');
  if (isErr(prep)) {
    return prep;
  }
  const [ seq1, seq2 ] = prep;
  const N = seq1.length;
  if (N <= 1) {
    return 0;
  }
  return jstat.covariance(seq1, seq2) * ((N - 1) / N);
}

/**
 * COVARIANCE.S(array1, array2)
 * Returns the sample covariance, the average of the products of deviations for
 * each data point pair in two data sets.
 */
export function COVARIANCE_S (array1: Matrix, array2: Matrix): number | FormulaError {
  const prep = prepCorrelOrCovariance(array1, array2, 'COVARIANCE.S');
  if (isErr(prep)) {
    return prep;
  }
  const [ seq1, seq2 ] = prep;
  if (seq1.length <= 1) {
    return ERROR_DIV0;
  }
  return jstat.covariance(seq1, seq2);
}

/**
 * COVAR(array1, array2)
 * Returns covariance, the average of the products of deviations for each data
 * point pair in two data sets.
 * This is by definition the same as COVARIANCE.P, but Excel vaguely specifies:
 * “This function has been replaced with one or more new functions that may
 * provide improved accuracy and whose names better reflect their usage.”
 * Whatever inaccuracies may exist in Excel's implementation of this legacy
 * function, they are probably not very important to replicate. So for now we
 * just treat it as an alias.
 */
export const COVAR = COVARIANCE_P;

function prepCorrelOrCovariance (array1: Matrix, array2: Matrix, name: string): [number[], number[]] | FormulaError {
  const seq1 = array1.resolveRange(); // { skipBlanks: 'none' });
  const seq2 = array2.resolveRange();
  if (seq1.length !== seq2.length) {
    return ERROR_NA.detailed(name + ' requires equal-length arrays');
  }
  const numbers1: number[] = [];
  const numbers2: number[] = [];
  for (let i = 0; i < seq1.length; i++) {
    const value1 = seq1[i];
    if (isErr(value1)) {
      return value1;
    }
    const value2 = seq2[i];
    if (isErr(value2)) {
      return value2;
    }
    if (isNum(value1) && isNum(value2)) {
      numbers1.push(value1);
      numbers2.push(value2);
    }
  }
  return [ numbers1, numbers2 ];
}

// DEVSQ(value1, value2)
// Calculates the sum of squares of deviations based on a sample.
export function DEVSQ () {
  return null;
}

/**
 * EXPON.DIST(x, lambda, cumulative)
 * Returns the value of the exponential distribution function with a specified lambda at a specified value.
 */
export function EXPON_DIST (x: number, lambda: number, cumulative: boolean): number | FormulaError {
  if (x < 0 || lambda <= 0) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.exponential.cdf(x, lambda);
  }
  else {
    return jstat.exponential.pdf(x, lambda);
  }
}

/**
 * Old version of EXPON.DIST
 */
export function EXPONDIST (x: number, lambda: number, cumulative: boolean): number | FormulaError {
  return EXPON_DIST(x, lambda, cumulative);
}

/**
 * F.DIST(x, degrees_freedom1, degrees_freedom2, cumulative)
 * Calculates the left-tailed F probability distribution (degree of diversity) for two data sets with given input x.
 * Alternately called Fisher-Snedecor distribution or Snedecor's F distribution.
 */
export function F_DIST (x: number, df1: number, df2: number, cumulative: boolean): number | FormulaError {
  df1 = Math.trunc(df1);
  df2 = Math.trunc(df2);
  if (x < 0 || df1 < 1 || df2 < 1) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.centralF.cdf(x, df1, df2);
  }
  else {
    return jstat.centralF.pdf(x, df1, df2);
  }
}

/**
 * F.DIST.RT(x, degrees_freedom1, degrees_freedom2)
 * Calculates the right-tailed F probability distribution (degree of diversity) for two data sets with given input x.
 * Alternately called Fisher-Snedecor distribution or Snedecor's F distribution.
 */
export function F_DIST_RT (x: number, df1: number, df2: number): number | FormulaError {
  df1 = Math.trunc(df1);
  df2 = Math.trunc(df2);
  if (x < 0 || df1 < 1 || df2 < 1) {
    return ERROR_NUM;
  }
  return 1 - jstat.centralF.cdf(x, df1, df2);
}

/**
 * FDIST(x, degrees_freedom1, degrees_freedom2)
 */
export function FDIST (x: number, df1: number, df2: number): number | FormulaError {
  return F_DIST_RT(x, df1, df2);
}

/**
 * F.INV(probability, degrees_freedom1, degrees_freedom2)
 * Calculates the inverse of the left-tailed F probability distribution. Also
 * called the Fisher-Snedecor distribution or Snedecor's F distribution.
 */
export function F_INV (prob: number, df1: number, df2: number): number | FormulaError {
  df1 = Math.trunc(df1);
  df2 = Math.trunc(df2);
  if (prob < 0 || prob >= 1 || df1 < 1 || df2 < 1) {
    return ERROR_NUM;
  }
  return jstat.centralF.inv(prob, df1, df2);
}

/**
 * F.INV.RT(probability, degrees_freedom1, degrees_freedom2)
 * Calculates the inverse of the right-tailed F probability distribution. Also
 * called the Fisher-Snedecor distribution or Snedecor's F distribution.
 */
export function F_INV_RT (prob: number, df1: number, df2: number): number | FormulaError {
  df1 = Math.trunc(df1);
  df2 = Math.trunc(df2);
  if (prob <= 0 || prob > 1 || df1 < 1 || df2 < 1) {
    return ERROR_NUM;
  }
  return jstat.centralF.inv(1 - prob, df1, df2);
}

// F.TEST(range1, range2)
// Returns the probability associated with an F-test for equality of variances. Determines whether two samples are likely to have come from populations with the same variance.
export function F_TEST () {
  return null;
}

/**
 * FINV(probability, deg_freedom1, deg_freedom2)
 * The Microsoft documentation says this is an old version of F.INV, but its
 * output is actually that of `F.INV.RT`.
 */
export function FINV (prob: number, df1: number, df2: number): number | FormulaError {
  return F_INV_RT(prob, df1, df2);
}

/**
 * FISHER(value)
 * Returns the Fisher transformation of a specified value.
 */
export function FISHER (x: number): number | FormulaError {
  if (x <= -1 || x >= 1) {
    return ERROR_NUM;
  }
  return Math.log((1 + x) / (1 - x)) / 2;
}

/**
 * FISHERINV(value)
 * Returns the inverse Fisher transformation of a specified value.
 */
export function FISHERINV (y: number): number {
  const e_power_of_2y = Math.exp(2 * y);
  return (e_power_of_2y - 1) / (e_power_of_2y + 1);
}

/**
 * FORECAST(x, data_y, data_x)
 */
export const FORECAST = FORECAST_LINEAR;

// FORECAST.ETS(???)
// The future value based on historical values.
export function FORECAST_ETS () {
  return null;
}

// FORECAST.ETS.CONFINT(???)
// Statistical: Returns a confidence interval for the forecast value at the specified target date
export function FORECAST_ETS_CONFINT () {
  return null;
}

// FORECAST.ETS.SEASONALITY(???)
// Statistical: Returns the length of the repetitive pattern Excel detects for the specified time series
export function FORECAST_ETS_SEASONALITY () {
  return null;
}

// FORECAST.ETS.STAT(???)
// Statistical: Returns a statistical value as a result of time series forecasting
export function FORECAST_ETS_STAT () {
  return null;
}

/**
 * FORECAST.LINEAR(x, data_y, data_x)
 */
export function FORECAST_LINEAR (x: number, data_y: Matrix, data_x: Matrix) {
  const lineEquation = computeRegressionLine(data_y, data_x);
  if (isErr(lineEquation)) {
    return lineEquation;
  }
  const { avgY, avgX, sumdxdy, sumsqdx } = lineEquation;
  const slope = sumdxdy / sumsqdx;
  const intercept = avgY - slope * avgX;
  return intercept + slope * x;
}

/**
 * FREQUENCY (data_array, bins_array)
 * @param data values for which you want to get frequencies
 * @param bins intervals ("bins") for grouping values
 * @returns A vertical array of frequencies
 */
export function FREQUENCY (data: Matrix | Reference, bins: Matrix | Reference): Matrix | FormulaError {
  const dataValues = collectNumbers(data, standardFilterMap);
  if (isErr(dataValues)) {
    return dataValues;
  }
  const binValues = collectNumbers(bins, numberFilterMap);
  if (isErr(binValues)) {
    return binValues;
  }
  if (binValues.length === 0) {
    binValues.push(0);
  }
  const result = new Array(binValues.length + 1).fill(0);
  // Use binary search so that time complexity is O(|data| * log(|bins|) + |bins|log(|bins|))
  // rather than O(|data| * |bins|). This is an improvement assuming that |data| >> |bins|.
  const sortPerm = sortingPermutation(binValues);
  const permutedBinValues = applyPermutation(sortPerm, binValues);
  for (const num of dataValues) {
    result[bisectLeft(permutedBinValues, num)] += 1;
  }
  const inverseSortPerm = invertPermutation(sortPerm);
  // This is needed because `result.length === bins.length + 1`. The additional
  // element which comes at the end should remain at the end in the de-sorted
  // result.
  inverseSortPerm.push(permutedBinValues.length);
  return Matrix.createColumn(applyPermutation(inverseSortPerm, result));
}

/**
 * GAMMA(number)
 * The gamma function value.
 */
export function GAMMA (x: number): number | FormulaError {
  if (Number.isInteger(x) && x <= 0) {
    return ERROR_NUM;
  }
  const result = jstat.gammafn(x);
  return isFinite(result) ? result : ERROR_NUM;
}

/**
 * GAMMA.DIST(x, alpha, beta, cumulative)
 * Calculates the gamma distribution, a two-parameter continuous probability distribution.
 */
export function GAMMA_DIST (x: number, alpha: number, beta: number, cumulative: boolean) {
  if (x < 0 || alpha <= 0 || beta <= 0) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.gamma.cdf(x, alpha, beta, true);
  }
  else {
    return jstat.gamma.pdf(x, alpha, beta, false);
  }
}

export function GAMMADIST (x: number, alpha: number, beta: number, cumulative: boolean) {
  return GAMMA_DIST(x, alpha, beta, cumulative);
}

/**
 * GAMMA.INV(probability, alpha, beta)
 * Returns the value of the inverse gamma cumulative distribution function for the specified probability and alpha and
 * beta parameters.
 */
export function GAMMA_INV (prob: number, alpha: number, beta: number): number | FormulaError {
  if (prob < 0 || prob >= 1 || alpha <= 0 || beta <= 0) {
    return ERROR_NUM;
  }
  return jstat.gamma.inv(prob, alpha, beta);
}

export function GAMMAINV (prob: number, alpha: number, beta: number): number | FormulaError {
  return GAMMA_INV(prob, alpha, beta);
}

/**
 * GAMMALN(x)
 * Returns the natural logarithm of the gamma function, Γ(x).
 */
export function GAMMALN (x: number): number | FormulaError {
  if (x <= 0) {
    return ERROR_NUM;
  }
  return jstat.gammaln(x);
}

/**
 * GAMMALN.PRECISE(x)
 * The natural logarithm of the gamma function.
 */
export function GAMMALN_PRECISE (x: number): number | FormulaError {
  return GAMMALN(x);
}

/**
 * GAUSS(z)
 * Probability of a standard normal variable falling within z standard deviations of the mean.
 */
export function GAUSS (z: number): number | FormulaError {
  return jstat.normal.cdf(z, 0, 1) - 0.5;
}

/**
 * Returns the geometric mean of an array or range of positive data.
 *
 * Logical values and text representations of numbers that are included in the
 * list of arguments are counted. However, if an array or reference argument
 * contains text, logical values, or empty cells, those values are ignored.
 * Cells with the value zero are included (and result in an error).
 *
 * Text values that are included as arguments and cannot be translated into
 * numbers cause errors.
 *
 * The geometric mean applies only to positive numbers. If any data point <= 0
 * then the function returns #NUM!.
 *
 * https://support.microsoft.com/office/geomean-function-db1ac48d-25a5-40a0-ab83-0b38980e40d5
 */
export function GEOMEAN (...values: (number | Matrix | Reference)[]): number | FormulaError {
  const valuesAndCounts = values.flatMap(v => {
    if (isRef(v) || isMatrix(v)) {
      const matrix = v.toMatrix(false);
      if (isErr(matrix)) {
        return { value: matrix, count: 1 };
      }
      else {
        return [ ...matrix.iterAllWithCount() ].filter(({ value }) => Number.isFinite(value));
      }
    }
    else {
      return { value: toNum(v), count: 1 };
    }
  });
  // Return an error if there are no valid values or if there's at least one
  // numeric value ≤ 0.
  if (valuesAndCounts.length === 0 || valuesAndCounts.some(({ value }) => !isNum(value) || value <= 0)) {
    return ERROR_NUM;
  }
  const result = valuesAndCounts.reduce(
    (acc, item) => {
      invariant(isNum(item.value));
      return {
        product: acc.product * item.value ** item.count,
        count: acc.count + item.count,
      };
    },
    { product: 1, count: 0 },
  );
  return result.product ** (1 / result.count);
}

// GROWTH(known_data_y, [known_data_x], [new_data_x], [b])
// Given partial data about an exponential growth trend, fits an ideal exponential growth trend and/or predicts further values.
export function GROWTH () {
  return null;
}

// HARMEAN(value1, value2)
// Calculates the harmonic mean of a dataset.
export function HARMEAN () {
  return null;
}

/**
 * HYPGEOM.DIST(sample_s, number_sample, population_s, number_pop, cumulative)
 * The probability of getting less than or equal to a particular value in a hyper geometric distribution (finite
 * population).
 */
export function HYPGEOM_DIST (
  num_sample_succ: number,
  num_sample: number,
  num_pop_succ: number,
  num_pop: number,
  cumulative: boolean = false,
): number | FormulaError {
  num_sample_succ = Math.trunc(num_sample_succ);
  num_sample = Math.trunc(num_sample);
  num_pop_succ = Math.trunc(num_pop_succ);
  num_pop = Math.trunc(num_pop);
  if (num_sample > num_pop || num_pop_succ > num_pop) {
    return ERROR_NUM;
  }
  if (num_pop_succ < 0 || num_sample < 0 || num_sample_succ < 0) {
    return ERROR_NUM;
  }
  // If we take Microsoft's own documentation seriously, these cases below should
  // all return ERROR_NUM. Alas, that is not the case, so we must replicate the same
  // behaviour.
  if (num_sample_succ > Math.min(num_sample, num_pop_succ)) {
    return +cumulative;
  }
  if (num_sample_succ < Math.max(0, num_sample - num_pop + num_pop_succ)) {
    return 0;
  }
  if (num_pop_succ === 0 || num_sample === 0) {
    return 1;
  }
  if (cumulative) {
    return jstat.hypgeom.cdf(num_sample_succ, num_pop, num_pop_succ, num_sample);
  }
  else {
    return jstat.hypgeom.pdf(num_sample_succ, num_pop, num_pop_succ, num_sample);
  }
}

export function HYPGEOMDIST (
  num_sample_succ: number,
  num_sample: number,
  num_pop_succ: number,
  num_pop: number,
): number | FormulaError {
  return HYPGEOM_DIST(num_sample_succ, num_sample, num_pop_succ, num_pop, false);
}

/**
 * INTERCEPT(data_y, data_x)
 * Calculates the y-value at which the line resulting from linear regression of a dataset will intersect the y-axis (x=0).
 */
export function INTERCEPT (data_y: Matrix, data_x: Matrix) {
  const lineEquation = computeRegressionLine(data_y, data_x);
  if (isErr(lineEquation)) {
    return lineEquation;
  }
  const { avgY, avgX, sumdxdy, sumsqdx } = lineEquation;
  const slope = sumdxdy / sumsqdx;
  return avgY - slope * avgX;
}

/**
 * KURT(...args)
 * Kurtosis of a data set. Kurtosis characterizes the relative peakedness or flatness of a distribution compared with the normal distribution. Positive kurtosis indicates a relatively peaked distribution. Negative kurtosis indicates a relatively flat distribution.
 */
export function KURT (...args: Array<number | Matrix | Reference>): number | FormulaError {
  const xn = toNumList(args);
  if (isErr(xn)) {
    return xn;
  }
  // Not using jstat's kurtosis because that computes Pearson's excess kurtosis;
  // Excel's KURT function returns the adjusted Fisher–Pearson standardized
  // moment coefficient. So just do this directly.
  const n = xn.length;
  if (n <= 3) {
    return ERROR_DIV0;
  }
  const { mean, stdDev: s } = stdDev(xn, true);
  if (s == null || s === 0) {
    return ERROR_DIV0;
  }
  let kurtSum = 0;
  for (const x of xn) {
    kurtSum += ((x - mean) / s) ** 4;
  }
  return ((n * (n + 1)) / ((n - 1) * (n - 2) * (n - 3))) * kurtSum - (3 * (n - 1) ** 2) / ((n - 2) * (n - 3));
}

/**
 * LINEST(known_data_y, [known_data_x], [calculate_b], [verbose])
 * Given partial data about a linear trend, calculates various parameters about
 * the ideal linear trend using the least-squares method.
 * ARR | RANGE, MISSING | ARR | RANGE, MISSING | RANGE | BOOL, MISSING | RANGE | BOOL
 */
export function LINEST (
  known_data_y: Matrix | Reference,
  known_data_x?: Matrix | Reference | undefined,
  calculate_b?: Reference | boolean | undefined,
  verbose?: Reference | boolean | undefined,
): Matrix | FormulaError {
  const knownDataY = known_data_y.toMatrix(false);
  if (isErr(knownDataY)) {
    return knownDataY;
  }
  if (!knownDataY.is1D || knownDataY.size === 1) {
    return ERROR_VALUE.detailed(`LINEST needs y to be a row or column, not ${knownDataY.width}x${knownDataY.height}`);
  }
  const y = requireNumeric1DArray(knownDataY.resolveRange());
  if (isErr(y)) {
    return y.detailed('LINEST needs y to be numeric');
  }
  const horizontal = knownDataY.width > 1;
  const knownDataX =
    known_data_x == null
      ? (horizontal ? Matrix.createRow : Matrix.createColumn)([ ...range(1, y.length + 1) ])
      : known_data_x.toMatrix(false);
  if (isErr(knownDataX)) {
    return knownDataX;
  }
  if (horizontal ? knownDataX?.width !== knownDataY.width : knownDataX?.height !== knownDataY.height) {
    return ERROR_REF.detailed('LINEST needs x to be the same length as y');
  }
  const calculateB = calculate_b == null ? true : toBool(calculate_b);
  if (isErr(calculateB)) {
    return calculateB;
  }
  // Design matrix for OLS has opposite shape from y (for matrix multiplication)
  // and has an additional column/row of ones (for the intercept).
  const designMatrix = requireNumeric2DArray(
    (horizontal ? Matrix.ofTransposed(knownDataX) : knownDataX).resolveAreaValues().concat(),
  );
  if (isErr(designMatrix)) {
    return designMatrix.detailed('LINEST needs x to be numeric');
  }
  if (calculateB) {
    designMatrix.forEach(row => row.splice(0, 0, 1));
  }
  const verboseBool = verbose == null ? false : toBool(verbose);
  if (isErr(verboseBool)) {
    return verboseBool;
  }
  // Excel likes to return the coefficients backwards \o/
  // Note that this mutates arrays inside the model object; we don't need them
  // for anything else, but if we did, it would be a good idea to clone these.
  const model = ordinaryLeastSquares(y, designMatrix);
  if (isErr(model)) {
    return model;
  }
  model.coef.reverse();
  model.t.se.reverse();
  const coef = calculateB ? model.coef : [ ...model.coef, 0 ];
  let output = Matrix.createRow(coef);
  if (verboseBool) {
    const rowWithPadding = numbers => {
      if (numbers.length < output.width) {
        numbers = numbers.concat(Array(output.width - numbers.length).fill(ERROR_NA));
      }
      return Matrix.createRow(numbers);
    };
    const sumSqr = (arr: number[]) => arr.reduce((accum, v) => accum + v ** 2, 0);
    const sumSqrYHat = sumSqr(model.predict);
    const sumSqrY = sumSqr(y);
    // Excel uses this other definition for the coefficient of determination
    // in the case of regression-through-the-origin (when calculateB is false).
    const Rsquare = calculateB ? model.R2 : sumSqrYHat / sumSqrY;
    const sse = calculateB ? model.SSE : sumSqrYHat;

    const residualVariance = model.SSR / model.df_resid;
    const F = calculateB ? model.f.F_statistic : sumSqrYHat / (model.df_model + 1) / residualVariance;

    output = output
      .vstack(rowWithPadding(model.t.se))
      .vstack(rowWithPadding([ Rsquare, model.t.sigmaHat ]))
      .vstack(rowWithPadding([ isFinite(F) ? F : ERROR_NUM, model.df_resid ]))
      .vstack(rowWithPadding([ sse, model.SSR ]));
  }
  return output;
}

function requireNumeric1DArray (values: unknown[]): number[] | FormulaError {
  for (const value of values) {
    if (!isNum(value)) {
      return ERROR_VALUE;
    }
  }
  return values as number[];
}
function requireNumeric2DArray (values: unknown[][]): number[][] | FormulaError {
  for (const row of values) {
    for (const element of row) {
      if (!isNum(element)) {
        return ERROR_VALUE;
      }
    }
  }
  return values as number[][];
}

type OLSModel = {
  df_model: number,
  predict: number[],
  nobs: number,
  coef: number[],
  df_resid: number,
  R2: number,
  SSE: number,
  SSR: number,
  t: {
    se: number[],
    sigmaHat: number,
  },
  f: { F_statistic: number, pvalue: number },
};

function ordinaryLeastSquares (y: number[], designMatrix: number[][]): OLSModel | FormulaError {
  try {
    const model = jstat.models.ols(y, designMatrix) as unknown;
    if (!isOLSModel(model)) {
      const msg = 'Unexpected result of Ordinary Least Squares computation';
      console.error(msg, model);
      return ERROR_VALUE.detailed(msg);
    }
    return model;
  }
  catch (err) {
    const msg = 'Unknown failure in Ordinary Least Squares computation';
    console.error('msg', err);
    return ERROR_VALUE.detailed(msg);
  }
}

function isOLSModel (model: unknown): model is OLSModel {
  return (
    model != null &&
    typeof model === 'object' &&
    'coef' in model &&
    Array.isArray(model.coef) &&
    model.coef.every(isNum)
  );
}

// LOGEST(known_data_y, [known_data_x], [b], [verbose])
// Given partial data about an exponential growth curve, calculates various parameters about the best fit ideal exponential growth curve.
export function LOGEST () {
  return null;
}

/**
 * LOGNORM.DIST(x, mean, standard_dev, cumulative)
 * The probability of getting less than or equal to a particular value in a lognormal distribution.
 */
export function LOGNORM_DIST (
  x: number,
  mean: number,
  std_dev: number,
  cumulative: boolean = true,
): number | FormulaError {
  if (x <= 0 || std_dev <= 0) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.lognormal.cdf(x, mean, std_dev);
  }
  else {
    return jstat.lognormal.pdf(x, mean, std_dev);
  }
}

/**
 * LOGNORMDIST(x, mean, standard_dev)
 * The probability of getting less than or equal to a particular value in a lognormal distribution.
 */
export function LOGNORMDIST (x: number, mean: number, std_dev: number): number | FormulaError {
  return LOGNORM_DIST(x, mean, std_dev, true);
}

/**
 * LOGNORM.INV(probability, mean, standard_dev)
 * The probability of getting greater than a particular value in a lognormal distribution.
 */
export function LOGNORM_INV (prob: number, mean: number, std_dev: number): number | FormulaError {
  if (prob <= 0 || prob >= 1 || std_dev <= 0) {
    return ERROR_NUM;
  }
  return round15(jstat.lognormal.inv(prob, mean, std_dev));
}

export function LOGINV (prob: number, mean: number, std_dev: number): number | FormulaError {
  return LOGNORM_INV(prob, mean, std_dev);
}

/**
 * MAX(value1, [value2, ...])
 * Returns the maximum value in a numeric dataset.
 */
export function MAX (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return max(args, standardFilterMap);
}

/**
 * MAXA(value1, [value2, ...])
 * Returns the maximum numeric value in a dataset.
 */
export function MAXA (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return max(args, arraySpecializeFilterMap(aggregationAFilterMap, standardFilterMap));
}

/**
 * MIN(value1, [value2, ...])
 * Returns the minimum value in a numeric dataset.
 */
export function MIN (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return min(args, standardFilterMap);
}

/**
 * MINA(value1, value2)
 * Returns the minimum numeric value in a dataset.
 */
export function MINA (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return min(args, arraySpecializeFilterMap(aggregationAFilterMap, standardFilterMap));
}

/**
 * MEDIAN(value1, [value2, ...])
 * Returns the median value in a numeric dataset.
 */
export function MEDIAN (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args);
  if (isErr(arr)) {
    return arr;
  }
  if (!arr.length) {
    return ERROR_NUM;
  }
  arr.sort(ascending);
  const mid = Math.floor(arr.length / 2);
  if (arr.length % 2) {
    return arr[mid];
  }
  return (arr[mid - 1] + arr[mid]) / 2;
}

// number1, [number2, ...]
// The array of most frequent or repetitive values.
export function MODE_MULT () {
  return null;
}

// number1, [number2, ...]
// The value that occurs most frequently in a list or array of numbers.
export function MODE_SNGL () {
  return null;
}

/**
 * NEGBINOM.DIST(number_f, number_s, probability_s, cumulative)
 * The probability of getting less than or equal to a particular value in a negative binomial distribution.
 */
export function NEGBINOM_DIST (
  num_fail: number,
  num_success: number,
  prob_success: number,
  cumulative: boolean = false,
): number | FormulaError {
  num_fail = Math.trunc(num_fail);
  num_success = Math.trunc(num_success);
  if (prob_success <= 0 || prob_success >= 1 || num_fail < 0 || num_success < 1) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.negbin.cdf(num_fail, num_success, prob_success);
  }
  else {
    return jstat.negbin.pdf(num_fail, num_success, prob_success);
  }
}

/**
 * Old version of NEGBINOM.DIST
 */
export function NEGBINOMDIST (num_fail: number, num_success: number, prob_success: number): number | FormulaError {
  return NEGBINOM_DIST(num_fail, num_success, prob_success, false);
}

/**
 * NORM.DIST(x, mean, standard_dev, cumulative)
 * The probability of getting less than or equal to a particular value in a normal distribution.
 */
export function NORM_DIST (x: number, mean: number, std_dev: number, cumulative: boolean): number | FormulaError {
  if (std_dev <= 0) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.normal.cdf(x, mean, std_dev);
  }
  else {
    return jstat.normal.pdf(x, mean, std_dev);
  }
}

/**
 * NORMDIST(x, mean, standard_dev, cumulative)
 * Old version of NORM.DIST
 */
export function NORMDIST (x: number, mean: number, std_dev: number, cumulative: boolean): number | FormulaError {
  return NORM_DIST(x, mean, std_dev, cumulative);
}

/**
 * NORM.INV(probability, mean, standard_dev)
 * Returns the inverse of the normal cumulative distribution for the specified mean and standard deviation.
 */
export function NORM_INV (prob: number, mean: number, std_dev: number): number | FormulaError {
  if (prob <= 0 || prob >= 1 || std_dev <= 0) {
    return ERROR_NUM;
  }
  return jstat.normal.inv(prob, mean, std_dev);
}

/**
 * NORMINV(probability, mean, standard_dev)
 * Old version of NORM.INV
 */
export function NORMINV (prob: number, mean: number, std_dev: number): number | FormulaError {
  return NORM_INV(prob, mean, std_dev);
}

/**
 * NORM.S.DIST(z, cumulative)
 * Returns the standard normal distribution (has a mean of zero and a standard deviation of one).
 */
export function NORM_S_DIST (z: number, cumulative: boolean = true): number | FormulaError {
  return NORM_DIST(z, 0, 1, cumulative);
}

/**
 * Old version of NORM.S.DIST
 */
export function NORMSDIST (z: number): number | FormulaError {
  return NORM_S_DIST(z, true);
}

/**
 * NORM.S.INV(probability)
 * The x variable corresponding to less than a particular probability.
 */
export function NORM_S_INV (prob: number): number | FormulaError {
  return NORM_INV(prob, 0, 1);
}

/**
 * Old version of NORM.S.INV
 */
export function NORMSINV (prob: number): number | FormulaError {
  return NORM_S_INV(prob);
}

// PEARSON(data_y, data_x)
// Calculates r, the Pearson product-moment correlation coefficient of a dataset.
export function PEARSON () {
  return null;
}

/**
 * This function is deprecated in favour of PERCENTILE.INC, which has identical
 * functionality. This function is retained for backwards compatibility.
 *
 * https://support.microsoft.com/office/percentile-function-91b43a53-543c-4708-93de-d626debdddca
 */
export const PERCENTILE = PERCENTILE_INC;

/**
 * Returns k-th percentile of values in a range, where k is 0..1 inclusive.
 *
 * If `data` is empty or if `k` is outside the range 0..1 inclusive, the `#NUM!`
 * error value is returned.
 *
 * https://support.microsoft.com/office/percentile-inc-function-680f9539-45eb-410b-9a5e-c1355e5fe2ed
 * @param data The array or range of numeric data values
 * @param k The percentile value in the range 0..1 inclusive
 */
export function PERCENTILE_INC (data: number | Matrix | Reference, k: number): number | FormulaError {
  let values: number[];
  if (isNum(data)) {
    values = [ data ];
  }
  else {
    const resolved = data.resolveRange({ skipBlanks: 'none' });
    if (isErr(resolved)) {
      return resolved;
    }
    values = resolved.filter(v => isNum(v)) as number[];
  }
  try {
    return percentile(values, k);
  }
  catch (err) {
    return ERROR_NUM;
  }
}

/**
 * Returns k-th percentile of values in a range, where k is 0..1 exclusive.
 *
 * If `k` < 1/(n+1) or `k` > n/(n+1), where n is the number of values, then the
 * `#NUM!` error value is returned.
 *
 * https://support.microsoft.com/office/percentile-exc-function-bbaa7204-e9e1-4010-85bf-c31dc5dce4ba
 * @param data The array or range of numeric data valies
 * @param k The percentile value in the range 0..1 exclusive.
 */
export function PERCENTILE_EXC (data: number | Matrix | Reference, k: number): number | FormulaError {
  let values: number[];
  if (isNum(data)) {
    values = [ data ];
  }
  else {
    const resolved = data.resolveRange({ skipBlanks: 'none' });
    if (isErr(resolved)) {
      return resolved;
    }
    values = resolved.filter(v => isNum(v)) as number[];
  }
  try {
    return percentile(values, k, false);
  }
  catch (err) {
    return ERROR_NUM;
  }
}

/**
 * PERCENTRANK.INC(data, value, [significance])
 * Returns the rank of a value in a data set as a percentage of the data set.
 */
export function PERCENTRANK_INC (data: number | Matrix | Reference, value: number, significance: number = 3) {
  return percentRankImpl(data, value, significance);
}

/**
 * PERCENTRANK.EXC(data, value, [significance])
 * The rank of a value in a data set as a percentage (0..1, inclusive) of the data set.
 */
export function PERCENTRANK_EXC (data: number | Matrix | Reference, value: number, significance: number = 3) {
  return percentRankImpl(data, value, significance, true);
}

function percentRankImpl (
  data: number | Matrix | Reference,
  value: number,
  significance: number = 3,
  exclusive: boolean = false,
) {
  if (isNum(data)) {
    return data === value ? 1 : ERROR_NA;
  }
  significance = Math.floor(significance);

  if (significance < 1) {
    return ERROR_NUM;
  }

  const resolved = data.resolveRange();
  if (isErr(resolved)) {
    return resolved;
  }

  const values: number[] = resolved.filter(v => isNum(v)) as number[];
  values.sort((a, b) => a - b);

  if (values.length === 0) {
    return ERROR_NA;
  }

  if (value < values[0] || value > values[values.length - 1]) {
    return ERROR_NA;
  }

  if (values.length === 1) {
    return 1;
  }

  if (exclusive) {
    values.splice(0, 0, -Infinity);
    values.push(Infinity);
  }

  const valueCount = values.length;
  const valueAdjustor = valueCount - 1;

  let pos = values.indexOf(value);

  if (pos === -1) {
    let testValue = values[0];
    while (testValue < value) {
      testValue = values[++pos + 1];
    }

    pos += (value - values[pos]) / (testValue - values[pos]);
  }

  return Math.floor((pos / valueAdjustor) * 10 ** significance) / 10 ** significance;
}

/**
 * PERCENTRANK(data, value, [significance])
 * The rank of a value in a data set as a percentage (0..1, inclusive) of the data set.
 */
export const PERCENTRANK = PERCENTRANK_INC;

/**
 * PERMUT(n, k)
 * Returns the number of ways to choose some number of objects from a pool of a given size of objects,
 * considering order.
 */
export function PERMUT (n: number, k: number): number | FormulaError {
  n = Math.trunc(n);
  k = Math.trunc(k);
  if (n < 0 || k < 0 || k > n) {
    return ERROR_NUM;
  }
  let result = 1;
  for (let i = n; i > n - k; i -= 1) {
    result *= i;
  }
  if (!isFinite(result)) {
    return ERROR_NUM;
  }
  return result;
}

/**
 * PERMUTATIONA(number, number_chosen)
 * The number of permutations for a subset of objects or events (with repetition).
 */
export function PERMUTATIONA (n: number, k: number): number | FormulaError {
  n = Math.trunc(n);
  k = Math.trunc(k);
  if (n < 0 || k < 0) {
    return ERROR_NUM;
  }
  const result = n ** k;
  if (!isFinite(result)) {
    return ERROR_NUM;
  }
  return result;
}

/**
 * PHI(x)
 * Returns the value of the normal distribution with mean 0 and standard deviation 1.
 */
export function PHI (x: number): number {
  const SQRT_2PI = 2.5066282746310002;
  return Math.exp(-0.5 * x * x) / SQRT_2PI;
}

/**
 * POISSON.DIST(x, mean, cumulative)
 * Returns the value of the Poisson distribution function (or Poisson cumulative
 * distribution function) for a specified value and mean.
 */
export function POISSON_DIST (x: number, mean: number, cumulative: boolean = false): number | FormulaError {
  function pdf (k: number, l: number): number {
    return Math.exp(-l) * power_over_factorial(l, k, k);
  }
  function cdf (x: number, l: number): number {
    // Copied from jstat
    const sum_arr: number[] = [];
    let k = 0;
    for (; k <= x; k++) {
      sum_arr.push(pdf(k, l));
    }
    return jstat.sum(sum_arr);
  }
  x = Math.trunc(x);
  if (x < 0 || mean < 0) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return cdf(x, mean);
  }
  else {
    return pdf(x, mean);
  }
}

// Old version of POISSON.DIST
export const POISSON = POISSON_DIST;

// PROB(data, probabilities, low_limit, [high_limit])
// Given a set of values and corresponding probabilities, calculates the
// probability that a value chosen at random falls between two limits.
export function PROB () {
  return null;
}

/**
 * This function is deprecated in favour of QUARTILE.INC, which has identical
 * functionality. This function is retained for backwards compatibility.
 *
 * https://support.microsoft.com/office/quartile-function-93cf8f62-60cd-4fdb-8a92-8451041e1a2a
 */
export const QUARTILE = QUARTILE_INC;

/**
 * Returns the quartile of a range, using percentiles from 0..1 inclusive.
 *
 * If `data` is empty then #NUM! is returned. If `quartile` < 0 or > 4 then
 * #NUM! is returned. If `quartile` is not an integer it's truncated.
 *
 * https://support.microsoft.com/office/quartile-inc-function-1bbacc80-5075-42f1-aed6-47d735c4819d
 * @param data Array or cell range of numeric values for which to calculate the
 *   quartile value
 * @param quartile 0 (minimum value), 1 (first quartile / 25th percentile), 2 (median value / 50th percentile),
 *   3 (third quartile / 75th percentile), or 4 (maximum value)
 */
export function QUARTILE_INC (data: number | Matrix | Reference, quartile: number) {
  if (quartile < 0 || quartile > 4) {
    return ERROR_NUM;
  }
  let values: number[];
  if (isNum(data)) {
    values = [ data ];
  }
  else {
    const resolved = data.resolveRange();
    if (isErr(resolved)) {
      return resolved;
    }
    values = resolved.filter(v => isNum(v)) as number[];
  }
  if (values.length === 0) {
    return ERROR_NUM;
  }
  if (quartile === 0) {
    // The zeroth quartile should simply return the minimum value.
    return Math.min(...values);
  }
  return percentile(values, 1 / (4 / Math.floor(quartile)));
}

/**
 * Returns the quartile of a range, using percentiles from 0..1 exclusive.
 *
 * If `data` is empty then #NUM! is returned. If `quartile` < 0 or > 4 then
 * #NUM! is returned. If `quartile` is not an integer it's truncated.
 *
 * https://support.microsoft.com/office/quartile-exc-function-5a355b7a-840b-4a01-b0f1-f538c2864cad
 *
 * @param data Array or cell range of numeric values for which to calculate the
 *    quartile value
 * @param quartile 0 (minimum value), 1 (first quartile / 25th percentile),
 *    2 (median value / 50th percentile), 3 (third quartile / 75th percentile),
 *    or 4 (maximum value)
 */
export function QUARTILE_EXC (data: Matrix | Reference | number, quartile: number): number | FormulaError {
  if (quartile < 0 || quartile > 4) {
    return ERROR_NUM;
  }
  let values: number[];
  if (isNum(data)) {
    values = [ data ];
  }
  else {
    const resolved = data.resolveRange();
    if (isErr(resolved)) {
      return resolved;
    }
    values = resolved.filter(v => isNum(v)) as number[];
  }
  // If quartile is 0, set k to 0 explicitly to avoid a divide-by-zero error.
  // Otherwise the percentile can be calculated from the quantile as 1/(4/q).
  // The quartile is floored to match Excel's behaviour with floats.
  const k = quartile === 0 ? 0 : 1 / (4 / Math.floor(quartile));
  try {
    return percentile(values, k, false);
  }
  catch (err) {
    return ERROR_NUM;
  }
}

/**
 * RANK(value, data, [order])
 * Returns the rank of a number in a list of numbers. The rank of a number is its size relative to other values in a
 * list. (If you were to sort the list, the rank of the number would be its position.)
 */
export function RANK (value: number, data: Reference, order: boolean): number | FormulaError {
  const results = getRank(value, data, order);
  if (isErr(results)) {
    return results;
  }

  return results.rank;
}

/**
 * RANK.AVG(value, data, [order])
 * Returns the rank of a specified value in a dataset. If there is more than one entry of the same value in the dataset,
 * the average rank of the entries will be returned.
 */
export function RANK_AVG (value: number, data: Reference, order: boolean): number | FormulaError {
  const results = getRank(value, data, order);
  if (isErr(results)) {
    return results;
  }

  const { rank, valueCount } = results;
  if (valueCount > 1) {
    let sum = 0;
    for (let i = 0; i < valueCount; i++) {
      sum = sum + (rank + i);
    }
    return sum / valueCount;
  }

  return rank;
}

/**
 * RANK.EQ(value, data, [order])
 * Returns the rank of a specified value in a dataset. If there is more than one entry of the same value in the dataset,
 * the top rank of the entries will be returned.
 */
export const RANK_EQ = RANK;

// RSQ(data_y, data_x)
// Calculates the square of r, the Pearson product-moment correlation coefficient of a dataset.
export function RSQ () {
  return null;
}

/**
 * SKEW(...args)
 * Skewness of a distribution. Skewness characterizes the degree of asymmetry of
 * a distribution around its mean. Positive skewness indicates a distribution
 * with an asymmetric tail extending toward more positive values. Negative
 * skewness indicates a distribution with an asymmetric tail extending toward
 * more negative values.
 */
export function SKEW (...args: Array<number | Matrix | Reference>): number | FormulaError {
  const xn = toNumList(args);
  if (isErr(xn)) {
    return xn;
  }
  const N = xn.length;
  return (jstat.skewness(xn) * Math.sqrt(N * (N - 1))) / (N - 2);
}

/**
 * SKEW.P(...args)
 * Skewness of a distribution based on a population: a characterization of the
 * degree of asymmetry of a distribution around its mean.
 */
export function SKEW_P (...args: Array<number | Matrix | Reference>): number | FormulaError {
  const xn = toNumList(args);
  if (isErr(xn)) {
    return xn;
  }
  return jstat.skewness(xn);
}

/**
 * SLOPE(data_y, data_x)
 * Calculates the slope of the line resulting from linear regression of a dataset.
 */
export function SLOPE (data_y: Matrix, data_x: Matrix) {
  const lineEquation = computeRegressionLine(data_y, data_x);
  if (isErr(lineEquation)) {
    return lineEquation;
  }
  const { sumdxdy, sumsqdx } = lineEquation;
  return sumdxdy / sumsqdx;
}

export function smallLarge (data: number | Matrix | Reference, n: number, is_small: boolean): number | FormulaError {
  let values: ArrayValue[] | FormulaError;
  if (isRef(data) || isMatrix(data)) {
    values = data.resolveRange();
    if (isErr(values)) {
      return values;
    }
  }
  else {
    const num = toNum(data);
    return isErr(num) ? num : ERROR_NUM;
  }
  const flattened = values.flat();
  for (const v of flattened) {
    if (isErr(v)) {
      return v;
    }
  }
  // The filtering ensures this type cast
  const numbers: number[] = flattened.filter(v => isNum(v)) as number[];
  if (n < 1 || n > numbers.length) {
    return ERROR_NUM;
  }
  let idx: number;
  let comparisonFunc: (a: number, b: number) => number;
  if (is_small) {
    idx = Math.floor(n) - 1;
    comparisonFunc = ascending;
  }
  else {
    idx = Math.ceil(n) - 1;
    comparisonFunc = (x, y) => ascending(y, x);
  }
  floydRivest(numbers, idx, comparisonFunc);
  return numbers[idx];
}

/**
 * SMALL(data, n)
 * Returns the nth smallest element from a data set, where n is user-defined.
 */
export function SMALL (data: number | Matrix | Reference, n: number): number | FormulaError {
  return smallLarge(data, n, true);
}

/**
 * LARGE(data, n)
 * Returns the nth largest element from a data set, where n is user-defined.
 */
export function LARGE (data: number | Matrix | Reference, n: number): number | FormulaError {
  return smallLarge(data, n, false);
}

// STANDARDIZE(value, mean, standard_deviation)
// Calculates the normalized equivalent of a random variable given mean and standard deviation of the distribution.
export function STANDARDIZE (x: number, mean: number, stdDev: number): number | FormulaError {
  if (stdDev <= 0) {
    return ERROR_NUM;
  }
  return (x - mean) / stdDev;
}

/**
 * STDEV(number1, [number2, ...])
 * Estimates standard deviation based on a sample.
 */
export function STDEV (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr, true).stdDev ?? ERROR_DIV0;
}

/**
 * STDEVP(number1, [number2, ...])
 * Calculates standard deviation based on the entire population given as arguments.
 */
export function STDEVP (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr).stdDev ?? 0;
}

/**
 * STDEV.P(number1, [number2, ...])
 * The standard deviation based on an entire population.
 */
export function STDEV_P (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return STDEVP(...args);
}

/**
 * STDEV.S(number1, [number2, ...])
 * The standard deviation based on a sample.
 */
export function STDEV_S (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return STDEV(...args);
}

/**
 * STDEVA(number1, [number2, ...])
 * Calculates the standard deviation based on a sample, setting text to the value `0`.
 */
export function STDEVA (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args, true);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr, true).stdDev ?? ERROR_DIV0;
}

/**
 * STDEVPA(value1, [value2, ...])
 * Calculates the standard deviation based on an entire population, setting text to the value `0`.
 */
export function STDEVPA (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args, true);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr).stdDev ?? 0;
}

// STEYX(data_y, data_x)
// Calculates the standard error of the predicted y-value for each x in the regression of a dataset.
export function STEYX () {
  return null;
}

/**
 * T.DIST(x, deg_freedom, cumulative)
 * The probability of getting less than or equal to a particular value in a t distribution (left tailed).
 */
export function T_DIST (x: number, df: number, cumulative: boolean): number | FormulaError {
  df = Math.trunc(df);
  if (df === 0 && !cumulative) {
    return ERROR_DIV0;
  }
  if (df < 1) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return jstat.studentt.cdf(x, df);
  }
  else {
    return jstat.studentt.pdf(x, df);
  }
}

/**
 * T.DIST.2T(x, deg_freedom)
 * The percentage points probability for the t distribution.
 */
export function T_DIST_2T (x: number, df: number): number | FormulaError {
  df = Math.trunc(df);
  if (x < 0 || df < 1) {
    return ERROR_NUM;
  }
  return (1 - jstat.studentt.cdf(x, df)) * 2;
}

/**
 * T.DIST.RT(x, deg_freedom)
 * The probability of getting greater than a particular value in a t distribution (right tailed).
 */
export function T_DIST_RT (x: number, df: number): number | FormulaError {
  df = Math.trunc(df);
  if (df < 1) {
    return ERROR_NUM;
  }
  return 1 - jstat.studentt.cdf(x, df);
}

/**
 * Old version of T.DIST.RT and T.DIST.2T
 */
export function TDIST (x: number, df: number, tails: number): number | FormulaError {
  tails = Math.trunc(tails);
  if (tails === 2) {
    return T_DIST_2T(x, df);
  }
  else if (tails === 1) {
    if (x < 0) {
      // T.DIST.RT normally accepts negative input. TDIST doesn't.
      return ERROR_NUM;
    }
    return T_DIST_RT(x, df);
  }
  else {
    return ERROR_NUM;
  }
}

/**
 * T.INV(probability, degrees_freedom)
 * Calculates the negative inverse of the one-tailed TDIST function.
 */
export function T_INV (prob: number, df: number): number | FormulaError {
  df = Math.trunc(df);
  if (prob <= 0 || prob >= 1 || df < 1) {
    return ERROR_NUM;
  }
  return jstat.studentt.inv(prob, df);
}

/**
 * T.INV.2T(probability, degrees_freedom)
 * Calculates the inverse of the two-tailed TDIST function.
 */
export function T_INV_2T (prob: number, df: number): number | FormulaError {
  df = Math.trunc(df);
  if (prob <= 0 || prob > 1 || df < 1) {
    return ERROR_NUM;
  }
  return Math.abs(jstat.studentt.inv(prob / 2, df));
}

/**
 * Old version of T.INV.2T
 */
export function TINV (prob: number, df: number): number | FormulaError {
  return T_INV_2T(prob, df);
}

// T.TEST(range1, range2, tails, type)
// t-test. Returns the probability associated with Student's t-test. Determines
// whether two samples are likely to have come from the same two underlying
// populations that have the same mean.
export function T_TEST () {
  // if (isRef(range1) || isMatrix(range1)) {
  //   range1 = range1.resolveRange();
  // }
  // else {
  //   return ERROR_NUM;
  // }
  // if (isRef(range2) || isMatrix(range2)) {
  //   range2 = range2.resolveRange();
  // }
  // else {
  //   return ERROR_NUM;
  // }
  // const converted = toTypes("int int", tails, type);
  // if (isErr(converted)) {
  //   return converted;
  // }
  // [ tails, type ] = converted;
  // if (range1.length !== range2.length && type === 1) {
  //   return ERROR_NA;
  // }
  // if (tails < 1 || tails > 2) {
  //   return ERROR_NUM;
  // }
}

// TREND(known_data_y, [known_data_x], [new_data_x], [b])
// Given partial data about a linear trend, fits an ideal linear trend using the least squares method and/or predicts further values.
export function TREND (
  known_data_y: Matrix | Reference,
  known_data_x: Matrix | Reference | undefined,
  new_data_x: Matrix | Reference | undefined,
  calculate_b: Reference | boolean | undefined,
): Matrix | FormulaError {
  const coefMatrix = LINEST(known_data_y, known_data_x, calculate_b, false);
  if (isErr(coefMatrix)) {
    if (coefMatrix.detail?.startsWith('LINEST ')) {
      return coefMatrix.detailed(coefMatrix.detail.replace(/^LINEST /, 'TREND '));
    }
    return coefMatrix;
  }
  const coefs = toNumListStrict(coefMatrix.resolveRange());
  if (isErr(coefs)) {
    return coefs;
  }
  const yHorizontal = known_data_y.width > 1;
  const newXRefOrMatrix = new_data_x || known_data_x;
  let newX = newXRefOrMatrix?.toMatrix(false);
  if (isErr(newX)) {
    return newX;
  }
  if (!newX) {
    newX = yHorizontal
      ? Matrix.createRow([ ...range(1, known_data_y.width + 1) ])
      : Matrix.createColumn([ ...range(1, known_data_y.height + 1) ]);
  }
  // LINEST reverses the coefficients for some reason, so reverse them back
  coefs.reverse();
  const newXHorizontal = newX.width > newX.height;
  return newXHorizontal
    ? MMULT(Matrix.createRow(coefs), Matrix.createRow(Array(newX.width).fill(1)).vstack(newX))
    : MMULT(Matrix.createColumn(Array(newX.height).fill(1)).hstack(newX), Matrix.createColumn(coefs));
}

// TRIMMEAN(data, exclude_proportion)
// Calculates the mean of a dataset excluding some proportion of data from the high and low ends of the dataset.
export function TRIMMEAN () {
  return null;
}

/**
 * VAR(value1, [value2, ...])
 * Estimates variance based on a sample.
 */
export function VAR (...args: (number | Matrix | Reference)[]): number | FormulaError {
  return VAR_S(...args);
}

/**
 * VAR.P(value1, [value2, ...])
 * The variance based on an entire population.
 */
export function VAR_P (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr).var ?? 0;
}

/**
 * VAR.S(value1, [value2, ...])
 * The variance based on a sample.
 */
export function VAR_S (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr, true).var ?? ERROR_DIV0;
}

/**
 * VARA(value1, [value2, ...])
 * Calculates the variance based on a sample, setting text to the value `0`.
 */
export function VARA (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args, true);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr, true).var ?? ERROR_DIV0;
}

/**
 * VARPA(value1, [value2, ...])
 * Calculates the variance based on an entire population, setting text to the value `0`.
 */
export function VARPA (...args: (number | Matrix | Reference)[]): number | FormulaError {
  const arr = toNumList(args, true);
  if (isErr(arr)) {
    return arr;
  }
  return stdDev(arr).var ?? 0;
}

/**
 * WEIBULL.DIST(x,alpha,beta,cumulative)
 * The probability of getting less than or equal to a particular value in a weibull distribution.
 */
export function WEIBULL_DIST (x: number, alpha: number, beta: number, cumulative: boolean): number | FormulaError {
  if (x < 0 || alpha <= 0 || beta <= 0) {
    return ERROR_NUM;
  }
  if (cumulative) {
    return 1 - Math.exp(-((x / beta) ** alpha));
  }
  else {
    return (x ** (alpha - 1) * Math.exp(-((x / beta) ** alpha)) * alpha) / beta ** alpha;
  }
}

export const WEIBULL = WEIBULL_DIST;

// Z.TEST(data, value, [standard_deviation])
// Returns the one-tailed p-value of a Z-test with standard distribution.
export function Z_TEST () {
  return null;
}

function computeRegressionLine (data_y: Matrix, data_x: Matrix) {
  if (data_y.size !== data_x.size) {
    return ERROR_NA;
  }
  let sumX = 0;
  let sumY = 0;
  const points: Array<[x: number, y: number]> = [];
  for (const [ ix, iy ] of zip(data_x.iterAll(), data_y.iterAll())) {
    const x = unbox(ix.value);
    const y = unbox(iy.value);
    if (typeof x === 'number' && typeof y === 'number') {
      sumX += x;
      sumY += y;
      points.push([ x, y ]);
    }
    else if (isErr(y)) {
      return y;
    }
    else if (isErr(x)) {
      return x;
    }
  }
  const avgX = sumX / points.length;
  const avgY = sumY / points.length;
  let sumdxdy = 0;
  let sumsqdx = 0;
  for (const [ x, y ] of points) {
    sumdxdy += (x - avgX) * (y - avgY);
    sumsqdx += (x - avgX) ** 2;
  }
  if (sumsqdx === 0) {
    return ERROR_DIV0;
  }
  return { avgY, avgX, sumdxdy, sumsqdx };
}

/**
 * ERF(upper_limit)
 * ERF(lower_limit, upper_limit)
 * Returns the value of the error function integrated between two limits.
 * If a single argument is given, that is taken as the upper limit, and the
 * lower is then 0.
 */
export function ERF (
  lowArg: Reference | FormulaError | undefined | CellValueAtom,
  highArg: Reference | FormulaError | undefined | CellValueAtom,
): FormulaError | number {
  if (arguments.length === 1) {
    highArg = lowArg;
    lowArg = 0;
  }
  const low = toNumEngineering(lowArg) ?? 0;
  if (isErr(low)) {
    return low;
  }
  const high = toNumEngineering(highArg) ?? 0;
  if (isErr(high)) {
    return high;
  }
  return jstat.erf(high) - jstat.erf(low);
}

export const ERF_PRECISE = ERF;

/**
 * ERFC(x)
 * Returns the complementary error function integrated between a limit and infinity.
 */
export function ERFC (x: Reference | FormulaError | undefined | CellValueAtom): FormulaError | number {
  const erfResult = ERF(0, x);
  if (isErr(erfResult)) {
    return erfResult;
  }
  return 1 - erfResult;
}

export const ERFC_PRECISE = ERFC;
