import type Reference from './excel/Reference';

/**
 * Error thrown when formula evaluation encounters a dependency that is not UPTODATE.
 * This happens in OFFSET and INDIRECT, which need to have their parameter evaluated to find their target cell.
 * The intended handling is to catch the exception and retry evaluation of the failing formula after evaluating
 * its dynamically discovered dependency.
 */
class EvaluationOrderException extends Error {
  public ref: Reference;

  /**
   * @param message
   * @param ref the reference that is not fully up to date. If this is an
   *   address reference, then it must have a sheet name.
   */
  constructor (message: string, ref: Reference) {
    super(message);
    this.ref = ref;
  }

  valueOf () {
    return this.toString();
  }

  toString () {
    return this.message + ': ' + this.ref.valueOf();
  }
}

export default EvaluationOrderException;
