import { CellVertexId, NameVertexId, RangeVertexId, vertexIdFromRange } from './DependencyGraph';
import { NameNotFoundError, SheetNotFoundError, WorkbookNotFoundError } from './errors';
import Reference from './excel/Reference.js';
import { a1ToRowColumnOrNull } from './excel/referenceParser/a1.js';
import { isErr } from './typeguards.js';
import { invariant } from './validation';

/**
 * @param {Cell} cell
 * @returns {CellVertexId | NameVertexId}
 */
export function cellToVertexId (cell) {
  if (cell.sheetIndex == null) {
    return new NameVertexId(cell.workbookKey, null, cell.id);
  }
  else {
    const addr = a1ToRowColumnOrNull(cell.id);
    if (addr) {
      const [ row, col ] = addr;
      return new CellVertexId(cell.workbookKey, cell.sheetIndex, row, col);
    }
    else {
      return new NameVertexId(cell.workbookKey, cell.sheetIndex, cell.id);
    }
  }
}

/**
 * @param {Model} model
 * @param {Reference} reference
 * @param {boolean} [allowNewName=false]
 * @returns {import('./DependencyGraph').KnownVertexId}
 */
export function referenceToVertexId (model, reference, allowNewName = false) {
  /** @type {number | null} */
  let workbookKey = null;
  /** @type {Workbook | undefined} */
  let wb;
  if (
    reference.name &&
    reference.workbookName &&
    !reference.sheetName &&
    model.resolveSheet(reference.workbookName) &&
    !model.resolveWorkbook(reference.workbookName)
  ) {
    // reference prefixed with sheet name; reference parser treats this as
    // always a workbook prefix, but if a sheet by that name exists, it must be
    // treated as a sheet prefix (thanks Excel)
    reference = reference.withPrefix({ sheetName: reference.workbookName, workbookName: '' });
  }
  if (reference.workbookName) {
    wb = model.getWorkbook(reference.workbookName);
    if (wb == null) {
      throw new WorkbookNotFoundError(reference.workbookName);
    }
    workbookKey = wb.keyInDepGraph;
  }
  else if (reference.sheetName) {
    const sheet = model.getWorkbook(reference.ctx?.workbookName)?.resolveSheet(reference.sheetName);
    if (!sheet) {
      throw new SheetNotFoundError(reference.sheetName, null);
    }
    wb = model.getWorkbookByKey(sheet.workbookKey);
    workbookKey = sheet.workbookKey;
  }
  else if (reference.ctx?.workbookName) {
    wb = model.getWorkbook(reference.ctx.workbookName);
    if (wb == null && reference.ctx && 'keyInDepGraph' in reference.ctx) {
      // Happens when we are called during Workbook construction, before it is
      // added to the model; reference context is the workbook instance itself.
      wb = /** @type {Workbook} */ (reference.ctx);
    }
    if (reference.name && reference.ctx?.sheetName) {
      const sheet = model.resolveSheet(reference.ctx.sheetName);
      if (sheet && sheet.locallyScopedNames[reference.name.toLowerCase()]) {
        wb = model.getWorkbookByKey(sheet.workbookKey);
        workbookKey = sheet.workbookKey;
      }
    }
  }
  else {
    wb = model.getWorkbook();
    if (wb == null) {
      throw new WorkbookNotFoundError('');
    }
  }
  invariant(wb);
  if (reference.isAddress) {
    return addressReferenceToVertexId(model, reference, wb, workbookKey);
  }
  else {
    return nameReferenceToVertexId(model, reference, workbookKey, wb, allowNewName);
  }
}

/**
 * @param {Model} model
 * @param {Reference} reference
 * @param {Workbook} wb
 * @param {number | null} workbookKey
 */
function addressReferenceToVertexId (model, reference, wb, workbookKey) {
  let sheetIndex = 0;
  const sheetName = reference.sheetName || reference.ctx?.sheetName;
  if (sheetName) {
    const sheet = wb ? wb.resolveSheet(sheetName) : model.resolveSheet(sheetName, reference.workbookName);
    if (sheet == null) {
      throw reference.isAddress || (reference.workbookName && model.getWorkbook(reference.workbookName))
        ? new SheetNotFoundError(sheetName, reference.workbookName || null)
        : new WorkbookNotFoundError(reference.workbookName || sheetName);
    }
    if (workbookKey == null) {
      workbookKey = sheet.workbookKey;
    }
    sheetIndex = sheet.index;
  }
  if (workbookKey == null) {
    workbookKey = wb.keyInDepGraph;
  }
  return vertexIdFromRange(workbookKey, sheetIndex, reference.range);
}

/**
 * @param {Model} model
 * @param {Reference} reference
 * @param {number | null} workbookKey
 * @param {Workbook} wb
 * @param {boolean} allowNewName
 */
function nameReferenceToVertexId (model, reference, workbookKey, wb, allowNewName) {
  if (workbookKey == null) {
    let cell = wb.resolveName(reference.name, reference.sheetName);
    if (isErr(cell)) {
      cell = model.resolveName(reference.name, reference.sheetName);
    }
    if (isErr(cell)) {
      if (allowNewName) {
        workbookKey = wb.keyInDepGraph;
      }
      else {
        throw new NameNotFoundError(reference.name, null);
      }
    }
    else {
      return new NameVertexId(cell.workbookKey, cell.sheetIndex, cell.id);
    }
  }
  const nameLowerCase = reference.name.toLowerCase();
  let sheet;
  if (reference.sheetName) {
    sheet = wb.resolveSheet(reference.sheetName);
  }
  else if (reference.ctx?.sheetName) {
    const contextSheet = wb.resolveSheet(reference.ctx.sheetName);
    sheet = contextSheet?.locallyScopedNames[nameLowerCase] ? contextSheet : null;
  }
  const collection = sheet == null ? wb._globals : sheet.locallyScopedNames;
  if (!collection[nameLowerCase] && !allowNewName) {
    throw new NameNotFoundError(reference.name, reference.workbookName);
  }
  return new NameVertexId(workbookKey, sheet?.index ?? null, reference.name);
}

/**
 * @param {Model | Workbook} modelOrWb
 * @param {NameVertexId | CellVertexId} vertexId
 * @param {boolean} [formulaCell=false] true if the desired cell is known to be
 *   a formula cell; this may permit a quicker lookup
 * @returns {Cell | null}
 */
export function vertexIdToCell (modelOrWb, vertexId, formulaCell = false) {
  let wb;
  if ('_workbooks' in modelOrWb) {
    wb = modelOrWb._workbooks.find(wb => wb.keyInDepGraph === vertexId.workbookKey);
    if (wb == null) {
      return null;
    }
  }
  else {
    wb = modelOrWb;
    if (wb.keyInDepGraph !== vertexId.workbookKey) {
      return null;
    }
  }
  if (vertexId instanceof CellVertexId) {
    const sheet = wb._sheets[vertexId.sheetIndex];
    return sheet ? sheet.getCellByCoords(vertexId.rowIndex, vertexId.colIndex, false, formulaCell) : null;
  }
  else {
    const collection = vertexId.sheetIndex == null ? wb._globals : wb._sheets[vertexId.sheetIndex]?.locallyScopedNames;
    invariant(collection != null);
    return collection[vertexId.name.toLowerCase()];
  }
}

/**
 * @param {Model} model
 * @param {import('./DependencyGraph').KnownVertexId} vertexId
 * @returns {Reference | null}
 */
export function vertexIdToReference (model, vertexId) {
  const wb = model.getWorkbookByKey(vertexId.workbookKey);
  if (wb == null) {
    return null;
  }
  const sheet = wb.getSheetByIndex(vertexId.sheetIndex);
  if (vertexId instanceof NameVertexId) {
    const sheetName = vertexId.sheetIndex != null ? sheet?.name : null;
    return new Reference(vertexId.name, { sheetName, workbookName: wb.name });
  }
  else {
    if (sheet == null) {
      return null;
    }
    return new Reference(vertexId.toRange(), { sheetName: sheet.name, workbookName: wb.name });
  }
}

/**
 * @param {Model} model
 * @param {import('./DependencyGraph').KnownVertexId} vertexId
 * @param {(c: Cell) => void} callback
 * @param {import('./Cells').RTreeMatrixNode | null} [omitCellsInSpill] the spill R-Tree node for 'vertexId', if 'vertexId' is the spill's anchor cell.
 */
export function visitFormulaCellsForVertexId (model, vertexId, callback, omitCellsInSpill) {
  if (vertexId instanceof RangeVertexId) {
    const wb = model._workbooks.find(wb => wb.keyInDepGraph === vertexId.workbookKey);
    if (wb == null) {
      // Reference to workbook which we don't have access to (was not added to
      // GRID document). For the purposes of iterating over formula cells, we
      // can ignore it. Throwing a `WorkbookNotFoundError` just causes trouble.
      return;
    }
    const sheet = wb._sheets[vertexId.sheetIndex];
    sheet._cells.visitFormulaCellsIntersecting(vertexId, callback);
  }
  else {
    const cell = vertexIdToCell(model, vertexId);
    if (!cell) {
      return;
    }
    if (cell.f) {
      callback(cell);
    }
    // For a cell that is part of a spill, resolve its spill anchor cell since
    // its the formula cell that produced the value of the cell.
    //
    // If present, the 'omitCellsInSpill' argument represents the spill R-Tree
    // node for the cell represented by 'vertexId'. We do not yield the spill
    // anchor if the anchor cell itself depends on a cell in its current spill
    // range. If we did, that would cause a circular dependency error to be
    // raised, which _may_ be incorrect, not because the dependency isn't
    // circular (it is) but because the dependency is not known for certain to
    // be in effect. That depends on the new spill range of the anchor cell,
    // which is not yet known. The new spill range may have different bounds
    // that do not include this cell. So the dependency via this cell is a
    // _conditional_ (thus dynamic) circular dependency. Thus, in static
    // dependency handling, this dependency should be omitted. That is
    // accomplished by passing its current spill range as `omitCellsInSpill`.
    // If the dependency then turns to indeed be in effect, i.e. the new spill
    // range does contain the dependency cell, then redo propagation takes care
    // of that.
    else if (cell.isSpilled() && cell._spill !== omitCellsInSpill) {
      const { minX, minY } = /** @type {import('./Cells').RTreeMatrixNode} */ (cell._spill);
      const sheetIndex = /** @type {number} */ (cell.sheetIndex);
      const anchorCell = vertexIdToCell(model, new CellVertexId(vertexId.workbookKey, sheetIndex, minY, minX), true);
      if (anchorCell?.f) {
        callback(anchorCell);
      }
    }
  }
}

/**
 * @param {Model} model
 * @param {RangeVertexId} vertexId
 * @param {(cell: Cell) => void} callback
 */
export function visitFormulaCellsDependingOnRange (model, vertexId, callback) {
  model._graph._visitInEdgesOfRange(vertexId, edge => {
    const formulaCell = edge.from.data;
    if (formulaCell) {
      callback(formulaCell);
    }
  });
}

/**
 * @param {Map<string, Cell[]>} memo
 * @param {Model} model
 * @param {Cell} cell
 * @returns {Array<{ dependencyCell: Cell, conditional: boolean }>}
 */
export function getFormulaCellsInDependencies (memo, model, cell) {
  /** @type {Array<{ dependencyCell: Cell, conditional: boolean }>} */
  const results = [];
  const cellVertexId = cellToVertexId(cell);
  model._graph.visitOutgoingEdges(cellVertexId, outEdge => {
    if (outEdge.kind !== 'nonvalue') {
      const conditional = outEdge.kind === 'conditional';
      const dependencyCell = outEdge.to.data;
      if (dependencyCell?.f) {
        // Vertex data cell exists and has a formula, so it is not spilled from
        // another cell. We can skip the more expensive formulaCellsForVertexId
        // lookup in this case, as we already have the one cell for this edge.
        results.push({ dependencyCell, conditional });
      }
      else {
        for (const dependencyCell of formulaCellsForVertexId(memo, model, outEdge.to.id, cell._spill)) {
          results.push({ dependencyCell, conditional });
        }
      }
    }
  });
  return results;
}

/**
 * @param {Map<string, Cell[]>} memo
 * @param {Model} model
 * @param {import('./DependencyGraph').KnownVertexId} vertexId
 * @param {import('./Cells').RTreeMatrixNode | null} [omitCellsInSpill]
 * @returns {Cell[]}
 */
export function formulaCellsForVertexId (memo, model, vertexId, omitCellsInSpill) {
  if (vertexId instanceof RangeVertexId) {
    const memoized = memo.get(vertexId.key);
    if (memoized) {
      return memoized;
    }
  }
  /** @type {Cell[]} */
  const cells = [];
  visitFormulaCellsForVertexId(model, vertexId, c => cells.push(c), omitCellsInSpill);
  if (vertexId instanceof RangeVertexId) {
    memo.set(vertexId.key, cells);
  }
  return cells;
}
