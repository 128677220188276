import { invariant } from './validation';

/**
 * Assigns to each name a positive integer, ensuring no collisions
 * take place. Used to convert workbook names into dependency graph IDs.
 *
 * It's important that we know the dependency graph key of a workbook, even
 * before it is added. Take the following example:
 *
 * 1. `Workbook1` is added, references `Workbook2`.
 * 2. Add `Workbook1` to dependency graph (requires us to know key of `Workbook2`).
 * 3. `Workbook2` is added.
 * 4. Add `Workbook2` to dependency graph.
 */
class NameIdContainer {
  constructor () {
    /** @type {number} */
    this.__nextId = 0;
    /** @type {Map<string, number>} */
    this.__usedIds = new Map();
  }

  /**
   * @param {string} name
   * @returns {number}
   */
  get (name) {
    invariant(typeof name === 'string' && name.length > 0, 'only non-empty strings can have IDs');
    name = name.toLowerCase();
    let id = this.__usedIds.get(name);
    if (id != null) {
      return id;
    }
    id = this.__nextId;
    this.__usedIds.set(name, id);
    this.__nextId += 1;
    return id;
  }
}

// We use global state here because we may need to know a workbook's dependency
// graph key before the model is known. So this instance must be global rather
// than a part of `Model`.
const singleton = new NameIdContainer();

/**
 * @param {string} name
 * @returns {number}
 */
export default function wbNameToKey (name) {
  return singleton.get(name);
}

export function resetWbNameToKey () {
  singleton.__nextId = 0;
  singleton.__usedIds.clear();
}
