export { isRef } from './excel/Reference.js';
export { isMatrix } from './excel/Matrix.js';
export { isNum, isStr, isBool, isErr } from './typeguards.js';
import { colFromOffs } from './excel/referenceParser/index.js';
import { isStr } from './typeguards.js';
import { invariant } from './validation';

/**
 * @param {number} bitField
 * @param {number} max
 * @returns {(1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | 4096 | 8192 | 16384 | 32768 | 65536 | 131072 | 262144 | 524288 | 1048576 | 2097152 | 4194304 | 8388608 | 16777216 | 33554432 | 67108864 | 134217728 | 268435456 | 536870912 | 1073741824 | 2147483648)[]}
 */
export function individualBits (bitField, max) {
  invariant(max <= 2147483648, `${max} > 2147483648`);
  const bits = [];
  let bit = 1;
  while (bit <= max) {
    if ((bit & bitField) !== 0) {
      bits.push(bit);
    }
    bit <<= 1;
  }
  // @ts-expect-error We know the bits are a power of two
  return bits;
}

/**
 * Format a list of things in plain english, using an Oxford comma. For example:
 *   ['duck'] -> "duck"
 *   ['cows', 'horses'] -> "cows and horses"
 *   ['cats', 'dogs', 'rabbits'] -> "cats, dogs, and rabbits"
 * @param {string[]} parts
 */
export function oxfordCommaJoin (parts, word = 'and') {
  if (parts.length > 2) {
    return parts.slice(0, -1).join(', ') + `, ${word} ${parts[parts.length - 1]}`;
  }
  return parts.join(` ${word} `);
}

const VOWELS = new Set([ 'a', 'e', 'i', 'o', 'u', 'y' ]);

/**
 * Either `an` or `a` depending on the given word. This will not work accurately
 * for all English words. For our use case it's fine though. For example:
 * "eagle" -> "an" | an eagle
 * "bird" -> "a"   | a bird
 * "range" -> "a"  | a range
 * @param {string} word
 * @returns {string}
 */
export function englishArticle (word) {
  if (VOWELS.has(word[0].toLowerCase())) {
    return 'an';
  }
  else {
    return 'a';
  }
}

/**
 * A utility function to compare two strings disregarding case
 * @param {string | null | undefined} a subject string to compare
 * @param {string | null | undefined} b subject string to compare
 * @return {boolean} true if a and b are considered equal
 */
export function excelEqual (a, b) {
  if (a === b || (a == null && b == null)) {
    return true;
  }
  if (isStr(a) && isStr(b)) {
    return a.toLowerCase() === b.toLowerCase();
  }
  // these are not strings and not equal
  return false;
}

/**
 * @param {number} row
 * @param {number} col
 * @returns {string}
 */
export function constructCellID (row, col) {
  return `${colFromOffs(col)}${row + 1}`;
}
