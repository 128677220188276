import type Workbook from './Workbook';
import type WorkSheet from './WorkSheet';
import type Cell from './excel/Cell';
import type { EvaluationContext, MetricsEvent } from './excel/EvaluationContext';
import type Reference from './excel/Reference';
import { MODE_GOOGLE } from './mode';
import { invariant } from './validation';
import { evaluateAST, evaluateASTNodeUnbound } from './excel/evaluate';
import { NUM_STATES } from './recalculate';
import { Profile } from './Profile';
import type { ASTNode } from './excel/ast-types';
import { supportedFunctionNames } from './excel/functions';

export class CellEvaluator {
  /**
   * Shared evaluation context. The object itself is immutable, but the data
   * underlying it will be mutated before each cell evaluation.
   */
  private readonly sharedCtx: EvaluationContext;

  // Mutable data underlying the shared evaluation context.
  private sheet: WorkSheet | null = null;
  private checkDirty: boolean = false;
  // eslint-disable-next-line no-undefined
  private recordDependencyUse: ((ref: Reference) => void) | undefined = undefined;
  private profile: Profile | null = null;
  // We will always assign these before use. The non-null assertions quell
  // TypeScript's complaints about these not being assigned in the constructor.
  private cell!: Cell;
  private ref!: Reference;
  private hasFormulaTypes!: boolean;

  constructor (workbook: Workbook) {
    const container = this;

    const { mode, coerceNullToZero, hasFormulaTypes } = workbook;
    this.hasFormulaTypes = hasFormulaTypes;

    this.sharedCtx = Object.freeze({
      mode,
      coerceNullToZero,
      allowMatrices: true,
      env: workbook._model.env,
      supportedFunctionNames,
      writeState: workbook.writeState.bind(workbook),
      getWorkbookByKey: workbook.getWorkbookByKey.bind(workbook),
      resolveName: (name: string, sheetName?: string | null) => {
        const { cell } = container;
        if (sheetName) {
          const sheet = this.sharedCtx.resolveSheet(sheetName);
          if (sheet) {
            return sheet.locallyScopedNames[name.toLowerCase()];
          }
          return workbook.resolveName(name);
        }
        if (cell.sheetIndex != null) {
          const sheet = workbook._sheets[cell.sheetIndex];
          const localName = sheet.locallyScopedNames[name.toLowerCase()];
          if (localName) {
            return localName;
          }
        }
        return workbook.resolveName(name, sheetName);
      },
      resolveTable: workbook.resolveTable.bind(workbook),
      resolveWorkbook: workbook.resolveWorkbook.bind(workbook),
      resolveSheet (sheetName?: string | null, workbookName?: string | null) {
        const { ref } = container;
        return workbook.resolveSheet(sheetName || ref?.sheetName, workbookName);
      },
      evaluateAST: function (ast: ASTNode, opts?: Partial<EvaluationContext>) {
        return evaluateAST(ast, { ...this, ...opts });
      },
      evaluateASTNode: function (ast: ASTNode) {
        return evaluateASTNodeUnbound.call(this, ast);
      },
      isDirtyFormulaCell (cell: Cell | null) {
        if (!container.checkDirty) {
          return false;
        }
        return !!(
          cell &&
          cell.f &&
          cell.state % NUM_STATES !== 0 &&
          cell.state > workbook._model._recalcState.upToDateState
        );
      },
      getContextTable () {
        const { ref } = container;
        if (ref.isAddress) {
          const { sheet, cell } = container;
          invariant(sheet && cell);
          return workbook.getTableContainingCell(sheet, cell);
        }
        return null;
      },
      get workbookName () {
        return workbook.name;
      },
      get cell () {
        return container.cell;
      },
      get cellId () {
        const { ref } = container;
        if (ref?.isAddress) {
          return ref.getCellId();
        }
        return null;
      },
      get singleCell (): boolean {
        return (container.hasFormulaTypes || this.mode === MODE_GOOGLE) && container.cell.ft !== 'a';
      },
      get metricsCallback () {
        return workbook.hasListeners('metrics') ? (e: MetricsEvent) => workbook.emit('metrics', e) : null;
      },
      get rawOutput () {
        return container.cell.isDefinedName;
      },
      get sheetName () {
        return container.sheet?.name;
      },
      get recordDependencyUse () {
        return container.recordDependencyUse;
      },
      get profile () {
        return container.profile;
      },
    });
  }

  evaluate (
    cell: Cell,
    ref: Reference,
    sheet: WorkSheet | null,
    checkDirty: boolean,
    recordDependencyUse: ((ref: Reference) => void) | undefined,
    profile: Profile | null,
  ) {
    const solveOpts = this.mutateAndBorrow(cell, ref, sheet, checkDirty, recordDependencyUse, profile);
    // If no formula, treat the cell's value as a new value for the purposes
    // of evaluating spill range changes. This happens in the case of
    // cells which were previously formula cells but were changed and the
    // spill implications left to the current recalculation to sort out.
    if (!cell.f) {
      return cell.v;
    }

    const end = profile?.category('calcCell')?.start(profile.canonicalize(ref, solveOpts.workbookName), cell.f);
    try {
      return evaluateAST(cell._ast, solveOpts);
    }
    finally {
      end?.();
    }
  }

  /**
   * Mutates the cell evaluation context, and returns it.
   *
   * The returned evaluation context should be used immediately after borrowing
   * it. It will be mutated again upon the next cell evaluation, so it should
   * not be stored for future use or used in an asynchronous context.
   *
   * We reuse a single evaluation context object for performance reasons.
   * Reconstructing it for each cell evaluation was quite expensive.
   */
  private mutateAndBorrow (
    cell: Cell,
    ref: Reference,
    sheet: WorkSheet | null,
    checkDirty: boolean,
    recordDependencyUse: ((ref: Reference) => void) | undefined,
    profile: Profile | null,
  ) {
    this.cell = cell;
    this.ref = ref;
    this.sheet = sheet;
    this.checkDirty = checkDirty;
    this.recordDependencyUse = recordDependencyUse;
    this.profile = profile;
    return this.sharedCtx;
  }
}
