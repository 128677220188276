import { ERROR_VALUE, errorTable, MAX_COL, MAX_ROW, MODE_EXCEL, MODE_GOOGLE } from './constants.js';
import { a1ToRowColumn, a1ToRowColumnOrNull } from './referenceParser/a1.js';
import Range from './referenceParser/Range.js';
import { unbox, isBoxed, MaybeBoxed, box } from './ValueBox.js';
import type { CellAttrs, CellContainer, RTreeMatrixNode } from '../Cells.js';
import type { ASTRootNode } from './ast-types';
import type { CellCSF } from '../csf';
import type { CellValue, FormulaValue, MaybeBoxedFormulaValue } from './types';
import FormulaError from './FormulaError';

export const NO_PROPAGATE_NUMBER_FORMAT_MODES = MODE_EXCEL | MODE_GOOGLE;

export const ERROR_INVALID_CELLVALUE = ERROR_VALUE.detailed('Invalid cell value');

export function normalizeValue (value: CellValue | undefined): CellValue {
  if (value == null) {
    return null;
  }
  const typ = typeof value;
  if (typ === 'string') {
    if (errorTable[value as string] != null) {
      return errorTable[value as string]!;
    }
  }
  else if (typ === 'object' && !(value instanceof FormulaError)) {
    // object and not null and not a FormulaError. So not a valid `CellValue`.
    return ERROR_INVALID_CELLVALUE;
  }
  return value;
}

/**
 * Cell object. Instances of this are stored in the `cell` map of each sheet of each Workbook.
 */
class Cell {
  _container: CellContainer | null = null;
  /** this cell's address ID in A1 format (unprefixed) */
  id: string;
  /**
   * The cell's value, maybe containing the formula-assigned number format.
   *
   * Only cells representing defined names can have Matrix/Reference/Lambda values.
   */
  private _boxedValue: MaybeBoxedFormulaValue;
  /**
   * The cell's reset value (used for model reset), maybe containing the
   * formula-assigned number format.
   *
   * Only cells representing defined names can have Matrix/Reference values.
   */
  private _boxedResetValue: MaybeBoxedFormulaValue;
  /**
   * The user-assigned number format, which is preferred over the formula-assigned
   * number format that is propagated from other cells.
   */
  userZ: string | null;
  /** formula, if any */
  f: string | null;
  /** Formula type, 'a' for array formula, absent for single-cell formula. */
  ft?: 'a' | null;

  /** top-left anchor cell-id of merge-area if cell is merge */
  M: string | null;
  /**
   * Bookkeeping for spilling implementation. This is a reference to the
   * R-Tree node of the spilled range containing this cell.
   */
  _spill: RTreeMatrixNode | null;
  /**
   * Cached abstract syntax tree, if `this.f` has been parsed.
   *
   * Slight wart: an ASTNode can be a literal cell value, including null, but in this attribute, null means
   * that the cell does not have an AST, not that it has an AST consisting of a single null node. This does not
   * cause serious ambiguity in practice, because a null AST node only really makes sense in particular contexts,
   * i.e. as elements of arrays and function call arguments, not as the _root_ of an abstract syntax tree.
   */
  _ast: ASTRootNode | null;
  /** State for recalcWithMarkAndEvalQueue. The delta `cell.state - wb._recalcState.upToDateState` is what's meaningful:
   *
   * * +1   == DIRTY - *may* require recomputation (because an ancestor changed)
   *             but has not been enqueued for recomputation (because the
   *             recalculation has not propagated to this cell or another that
   *             depends transitively on it)
   * * +2   == ENQUEUEING - the cell's *dependencies* are being enqueued (state
   *             used to detect static circular dependencies)
   * * +3   == ENQUEUED_MAYBE - enqueued to ensure correct evaluation order
   *             because some other cell that depends transitively on this cell
   *             has been enqueued for recalculation, but this cell is not yet
   *             *known* to require recomputation, because we have not observed
   *             a change in a direct dependency of this cell
   * * +3   == ENQUEUED_CALC - enqueued and *known* to require recomputation,
   *             because some direct dependency of this cell has changed
   * * +5   == the new UPTODATE, during a recalculation (at the end of which
   *             `wb._recalcState.upToDateState` gets set to this)
   * * >+5  can't happen
   * * <= 0 == UPTODATE - the cell value is known to be up-to-date (it may have
   *             been dirtied in an earlier recalculation which then did not
   *             propagate to it, because each actual dependency path from a
   *             recalculation root to this cell included a cell that evaluated
   *             to the same value as before)
   *
   * At the end of a recalculation, `wb._recalcState.upToDateState` is
   * incremented by NUM_STATES, so any cells that were not reached by the
   * recalculation (because all their dependencies were unchanged) will then
   * have this delta < 0 and thus be automatically deemed up-to-date at the next
   * recalculation.
   */
  state: number;

  /** Formula which was neutralized by a write to/overlapping this cell */
  neutralizedFormula: string | null = null;

  /**
   * True if this is a defined name (including sheet-scoped names).
   * (This is derived from .id and .sheetIndex so could be a getter, but that
   * nontrivially affects performance because of the parsing of .id each time.)
   */
  isDefinedName: boolean = false;

  /**
   * Construct a Cell instance.
   * @param orgCell object to copy attributes from (all optional, this may be empty)
   * @param id unqualified address in A1 format, or defined name (defaults to empty string)
   * @param container
   */
  constructor (orgCell: CellCSF | Cell, id?: string | null, container?: CellContainer) {
    this.id = id || '';

    Object.defineProperty(this, 'isDefinedName', {
      enumerable: false,
      writable: false,
      value: (container && container.sheetIndex == null) || (id && a1ToRowColumnOrNull(id) == null),
    });

    // This is writable to support cell moves in `Cells` in a sheet. A write of
    // this property should never change whether or not `_container.sheetIndex`
    // is null, and thus it is OK that `isDefinedName` is a plain property, not
    // a getter based on this.
    Object.defineProperty(this, '_container', {
      enumerable: false,
      writable: true,
      value: container || null,
    });

    if (orgCell instanceof Cell) {
      this._boxedValue = orgCell._boxedValue;
      this._boxedResetValue = orgCell._boxedResetValue;
      this.userZ = orgCell.userZ;
    }
    else {
      let v: MaybeBoxed<CellValue> = normalizeValue(orgCell.v);
      if (orgCell.zf) {
        v = box(v, { numberFormat: orgCell.zf });
      }
      this._boxedValue = v;
      this._boxedResetValue = v;
      this.userZ = orgCell.z ?? null;
    }

    const { f, ft: t } = orgCell;
    this.f = f ?? null;
    this.ft = t || null;
    this.M = null;
    this._spill = null;
    this._ast = null;
    this.state = 0;
  }

  get v (): FormulaValue {
    return unbox(this._boxedValue);
  }

  /**
   * Set the value of the cell. If the value is a boxed value, the number format
   * will become the cell's formula-assigned number format.
   */
  set v (value: MaybeBoxedFormulaValue) {
    this._boxedValue = value;
  }

  get _v (): FormulaValue {
    return unbox(this._boxedResetValue);
  }

  /**
   * Set the reset value of the cell. If the value is a boxed value, the number
   * format will become the cell's formula-assigned number format on reset.
   */
  set _v (value: MaybeBoxedFormulaValue) {
    this._boxedResetValue = value;
  }

  /**
   * The value of the cell. If the cell's value has a formula-assigned number
   * format, the value will be a boxed value containing that number format.
   */
  get valueBoxed () {
    return this._boxedValue;
  }

  /**
   * The reset value of the cell. If the cell's reset value has a
   * formula-assigned number format, the value will be a boxed value containing
   * that number format.
   */
  get resetValueBoxed () {
    return this._boxedResetValue;
  }

  get workbookKey (): number {
    return this._container?.workbookKey ?? -1;
  }

  get sheetIndex (): number | null {
    return this._container?.sheetIndex ?? null;
  }

  get s () {
    return this._container?.getAttribute(this.id, 's') ?? null;
  }

  set s (s: CellAttrs['s'] | null) {
    this._container?.setAttributes(this.id, { s });
  }

  get href () {
    return this._container?.getAttribute(this.id, 'href') ?? null;
  }

  set href (href: CellAttrs['href'] | null) {
    this._container?.setAttributes(this.id, { href });
  }

  /**
   * The effective number format of the cell.
   *
   * The effective number format is the user-assigned number format, if present.
   * Otherwise, it is the formula-assigned number format.
   */
  get z () {
    return this.userZ ?? this.formulaZ;
  }

  /**
   * The cell's formula-assigned number format, if present.
   */
  get formulaZ () {
    const allowNumberFormatPropagation =
      !this._container || (this._container.mode & NO_PROPAGATE_NUMBER_FORMAT_MODES) === 0;
    if (allowNumberFormatPropagation && isBoxed(this._boxedValue)) {
      return this._boxedValue.metadata.numberFormat ?? null;
    }
    return null;
  }

  get F () {
    const spill = this._spill;
    if (!spill || !spill.valid) {
      return null;
    }
    const range = new Range({
      top: spill.minY,
      bottom: spill.masked ? spill.minY : Math.min(spill.maxY, MAX_ROW),
      left: spill.minX,
      right: spill.masked ? spill.minX : Math.min(spill.maxX, MAX_COL),
    });
    return range.toString();
  }

  edit (cellData: CellCSF) {
    const editingValue = 'v' in cellData;
    const editingFormula = 'f' in cellData;
    const removingFormula = editingFormula && !cellData.f && this.f;

    if (removingFormula && !editingValue) {
      // If we just remove the formula and do not also remove the value, then
      // the cell could be hanging onto a stale value.
      cellData = { ...cellData, v: null };
    }
    else if (editingValue && !editingFormula) {
      // Editing the value of a cell implicitly removes the formula. Otherwise
      // the value will be overwritten on recalculation.
      cellData = { ...cellData, f: null };
    }

    // update formula
    if ('f' in cellData) {
      this.f = cellData.f ?? null;
      this._ast = null;
    }
    // update value
    if ('v' in cellData) {
      this.v = normalizeValue(cellData.v) ?? null;
      this._v = cellData.v ?? null;
    }
    // update number format
    if ('z' in cellData) {
      this.userZ = cellData.z ?? null;
    }
    // style information
    if ('s' in cellData) {
      this.s = cellData.s ?? null;
    }
  }

  toString () {
    return `{${this.id}:${this.v || '-'}}`;
  }

  // Is part of a spilled range.
  isSpilled (): this is { _spill: { valid: true }, F: string } {
    return this._spill != null && this._spill.valid;
  }

  spillWidth () {
    if (!this.isSpilled()) {
      return 1;
    }
    return this._spill.maxX - this._spill.minX + 1;
  }

  spillHeight () {
    if (!this.isSpilled()) {
      return 1;
    }
    return this._spill.maxY - this._spill.minY + 1;
  }

  isBlank () {
    if (this.f != null) {
      return false;
    }
    return this.v == null || (this._v == null && !this.isSpilled());
  }

  hasValue () {
    return !this.isBlank();
  }

  clear () {
    this.v = null;
    this._v = null;
    this.f = null;
    this._ast = null;
  }

  isSpillAnchor () {
    if (this._spill && this._spill.valid) {
      const [ row, column ] = a1ToRowColumn(this.id);
      if (this._spill.minX === column && this._spill.minY === row) {
        return true;
      }
    }
    return false;
  }
}

export default Cell;

// A cell object wrapper around a non-existent/undefined cell
export const BLANK_CELL = Object.freeze(new Cell({ v: null })) as Cell;

/** A cell whose `.v` property we know more specifically than `typeof Cell.v` */
export type CellWithValueOfType<T extends Cell['v']> = Cell & { v: T };

/** A cell which we know is not a defined name with a Reference/Matrix value. */
export type CellWithCellValue = CellWithValueOfType<CellValue>;
