import {
  ARRAY,
  ARRAYROW,
  CHOOSEROWS,
  CHOOSECOLS,
  SORT,
  EXCEL_SORT,
  GSHEETS_SORT,
  SORTBY,
  UNIQUE,
  FILTER,
  FILTER_GOOGLE,
  RANDARRAY,
  SINGLE,
  HSTACK,
  VSTACK,
  ANCHORARRAY,
  TOROW,
  TOCOL,
  WRAPCOLS,
  WRAPROWS,
  TAKE,
  DROP,
  EXPAND,
} from './array.js';
import { DAVERAGE, DCOUNT, DCOUNTA, DGET, DMAX, DMIN, DPRODUCT, DSTDEV, DSTDEVP, DSUM, DVAR, DVARP } from './database';
import { MAKEARRAY, MAP, BYROW, BYCOL, SCAN, REDUCE } from './lambda';
import * as logical from './logical.js';
import {
  CELL,
  ERROR_TYPE,
  INFO,
  ISBLANK,
  ISOMITTED,
  ISERR,
  ISERROR,
  ISEVEN,
  ISFORMULA,
  ISLOGICAL,
  ISNA,
  ISNONTEXT,
  ISNUMBER,
  ISODD,
  ISREF,
  ISTEXT,
  N,
  NA,
  TYPE,
} from './info';
import {
  ISOWEEKNUM,
  DATE,
  DATEDIF,
  DATEVALUE,
  DAY,
  DAYS,
  DAYS360,
  EDATE,
  EOMONTH,
  HOUR,
  MINUTE,
  MONTH,
  NOW,
  SECOND,
  TIME,
  TIMEVALUE,
  TODAY,
  WEEKNUM,
  WEEKDAY,
  YEAR,
  YEARFRAC,
  NETWORKDAYS_INTL,
  NETWORKDAYS,
  WORKDAY,
  WORKDAY_INTL,
} from './datetime.js';
import * as canvas from './canvas.js';
import {
  BAHTTEXT,
  CHAR,
  CONCAT,
  CONCATENATE,
  ENCODEURL,
  EXACT,
  FIXED,
  DOLLAR,
  FIND,
  FINDB,
  LEFT,
  LEFTB,
  LEN,
  LENB,
  LOWER,
  MID,
  MIDB,
  NUMBERVALUE,
  REPLACE,
  REPLACEB,
  REPT,
  RIGHT,
  RIGHTB,
  SUBSTITUTE,
  T,
  TEXT,
  TEXTJOIN,
  TEXTSPLIT,
  TEXTBEFORE,
  TEXTAFTER,
  JOIN,
  TRIM,
  UPPER,
  VALUE,
  SEARCH,
  SEARCHB,
  SPLIT,
  CODE,
  CLEAN,
  UNICHAR,
  UNICODE,
  PROPER,
} from './text';
import {
  ABS,
  ACOS,
  ACOSH,
  ACOT,
  ACOTH,
  ARABIC,
  ASIN,
  ASINH,
  ATAN,
  ATAN2,
  ATANH,
  BASE,
  CEILING,
  CEILING_MATH,
  CEILING_PRECISE,
  ECMA_CEILING,
  ISO_CEILING,
  COMBIN,
  COMBINA,
  COS,
  COSH,
  COT,
  COTH,
  CSC,
  CSCH,
  DECIMAL,
  DEGREES,
  EXP,
  EVEN,
  FACT,
  FACTDOUBLE,
  FLOOR,
  FLOOR_MATH,
  FLOOR_PRECISE,
  INT,
  LN,
  LOG,
  LOG10,
  MDETERM,
  MINVERSE,
  MUNIT,
  MROUND,
  MOD,
  ODD,
  PI,
  POWER,
  PRODUCT,
  QUOTIENT,
  RADIANS,
  RAND,
  RANDBETWEEN,
  ROMAN,
  ROUND,
  ROUNDDOWN,
  ROUNDUP,
  SEC,
  SECH,
  SERIESSUM,
  SEQUENCE,
  SIGN,
  SIN,
  SINH,
  SQRT,
  SQRTPI,
  SUBTOTAL,
  SUM,
  SUMSQ,
  SUMX2MY2,
  SUMX2PY2,
  SUMXMY2,
  SUMPRODUCT,
  TAN,
  TANH,
  TRUNC,
  GCD,
  LCM,
} from './math.js';
import { MMULT } from './matrix-math.js';
import { AVERAGEIF, AVERAGEIFS, COUNTIF, COUNTIFS, MAXIFS, MINIFS, SUMIF, SUMIFS } from './ifs.js';
import {
  AVERAGE,
  AVERAGEA,
  BETA_DIST,
  BETA_INV,
  BETADIST,
  BETAINV,
  BINOMDIST,
  BINOM_DIST,
  BINOM_DIST_RANGE,
  BINOM_INV,
  CHIDIST,
  CHIINV,
  CRITBINOM,
  CHISQ_DIST,
  CHISQ_DIST_RT,
  CHISQ_INV,
  CHISQ_INV_RT,
  CONFIDENCE,
  CONFIDENCE_NORM,
  CONFIDENCE_T,
  CORREL,
  COUNT,
  COUNTA,
  COUNTBLANK,
  COVAR,
  COVARIANCE_P,
  COVARIANCE_S,
  ERF,
  ERF_PRECISE,
  ERFC,
  ERFC_PRECISE,
  EXPONDIST,
  EXPON_DIST,
  FDIST,
  FINV,
  FISHER,
  FISHERINV,
  FORECAST,
  FORECAST_LINEAR,
  F_DIST,
  F_DIST_RT,
  F_INV,
  F_INV_RT,
  GAMMA,
  GAMMALN,
  GAMMALN_PRECISE,
  GAMMA_DIST,
  GAMMADIST,
  GAMMAINV,
  GEOMEAN,
  HYPGEOMDIST,
  HYPGEOM_DIST,
  GAMMA_INV,
  GAUSS,
  INTERCEPT,
  KURT,
  LARGE,
  LINEST,
  LOGNORM_DIST,
  LOGNORMDIST,
  LOGNORM_INV,
  LOGINV,
  MAX,
  MAXA,
  MEDIAN,
  MIN,
  MINA,
  NEGBINOM_DIST,
  NEGBINOMDIST,
  NORMINV,
  NORMDIST,
  NORMSDIST,
  NORMSINV,
  NORM_DIST,
  NORM_INV,
  NORM_S_DIST,
  NORM_S_INV,
  PERCENTILE,
  PERCENTILE_INC,
  PERCENTILE_EXC,
  PERCENTRANK,
  PERCENTRANK_INC,
  PERCENTRANK_EXC,
  PERMUT,
  PERMUTATIONA,
  PHI,
  POISSON_DIST,
  POISSON,
  QUARTILE,
  QUARTILE_INC,
  QUARTILE_EXC,
  RANK,
  RANK_AVG,
  RANK_EQ,
  SKEW,
  SKEW_P,
  SLOPE,
  SMALL,
  STANDARDIZE,
  STDEV,
  STDEVA,
  STDEVP,
  STDEVPA,
  STDEV_P,
  STDEV_S,
  T_DIST,
  T_DIST_2T,
  T_DIST_RT,
  T_INV,
  TDIST,
  TINV,
  T_INV_2T,
  TREND,
  VAR,
  VARA,
  VARPA,
  VAR_P,
  VAR_S,
  WEIBULL_DIST,
  WEIBULL,
  FREQUENCY,
} from './statistical';
import {
  ADDRESS,
  CHOOSE,
  COLUMN,
  COLUMNS,
  FORMULATEXT,
  HLOOKUP,
  LOOKUP,
  XLOOKUP,
  XMATCH,
  HYPERLINK,
  INDEX,
  INDIRECT,
  MATCH,
  OFFSET,
  ROW,
  ROWS,
  TRANSPOSE,
  VLOOKUP,
} from './lookup.js';
import {
  EFFECT,
  NOMINAL,
  RATE,
  COUPDAYS,
  COUPPCD,
  COUPNCD,
  COUPNUM,
  COUPDAYBS,
  COUPDAYSNC,
  CUMIPMT,
  CUMPRINC,
  DOLLARDE,
  DOLLARFR,
  DURATION,
  DISC,
  FV,
  FVSCHEDULE,
  IRR,
  NPER,
  NPV,
  MDURATION,
  MIRR,
  PV,
  PMT,
  PPMT,
  IPMT,
  ISPMT,
  XIRR,
  XNPV,
  RRI,
  PDURATION,
  SLN,
  SYD,
  DB,
  DDB,
  INTRATE,
  RECEIVED,
  TBILLEQ,
  TBILLPRICE,
  TBILLYIELD,
  PRICE,
  PRICEDISC,
  PRICEMAT,
  YIELD,
  YIELDDISC,
  YIELDMAT,
} from './financial';
import {
  COMPLEX,
  CONVERT,
  IMAGINARY,
  IMREAL,
  IMSUM,
  IMSUB,
  IMPRODUCT,
  IMDIV,
  IMABS,
  IMARGUMENT,
  IMPOWER,
  IMSQRT,
  IMEXP,
  IMLN,
  IMLOG2,
  IMLOG10,
  IMLOG,
  IMSIN,
  IMSINH,
  IMSEC,
  IMSECH,
  IMCOS,
  IMCOSH,
  IMCOT,
  IMCOTH,
  IMCSC,
  IMCSCH,
  IMTAN,
  IMTANH,
  IMCONJUGATE,
  BITAND,
  BITOR,
  BITXOR,
  BITLSHIFT,
  BITRSHIFT,
  BIN2OCT,
  BIN2DEC,
  BIN2HEX,
  OCT2BIN,
  OCT2DEC,
  OCT2HEX,
  DEC2BIN,
  DEC2OCT,
  DEC2HEX,
  HEX2BIN,
  HEX2OCT,
  HEX2DEC,
  GESTEP,
  DELTA,
  BESSELJ,
  BESSELY,
  BESSELI,
  BESSELK,
} from './engineering';
import { GRID_ERROR, GRID_ISPRINT, GRID_ISMOBILE, GRID_USERNAME, GRID_SCENARIO } from './grid';
import {
  LT,
  GT,
  LTE,
  GTE,
  EQ,
  NE,
  UMINUS,
  UPLUS,
  UNARY_PERCENT,
  ADD,
  MINUS,
  MULTIPLY,
  DIVIDE,
  POW,
} from './operators.js';
import {
  ISBETWEEN,
  ISURL,
  ISEMAIL,
  ISDATE,
  TO_DATE,
  TO_DOLLARS,
  TO_PERCENT,
  TO_PURE_NUMBER,
  TO_TEXT,
  REGEXMATCH,
  REGEXEXTRACT,
  REGEXREPLACE,
  ARRAYFORMULA,
  EPOCHTODATE,
} from './gsheets.js';
import { lazyArgumentFunctions } from './lazy.js';
import { NEVER_SUPPORTED, funcSigs } from '../signatures.js';
import { MODE_ALL } from '../../mode.js';
import { isErr } from './utils.js';

/**
 * @typedef {'ARRAY'
 * | 'ARRAYROW'
 * | 'SORT'
 * | 'FILTER'
 * | 'EXPAND'
 * | 'COMPLEX'
 * | 'IMSUM'
 * | 'IMSUB'
 * | 'IMPRODUCT'
 * | 'IMDIV'
 * | 'IMPOWER'
 * | 'IMSQRT'
 * | 'IMEXP'
 * | 'IMLN'
 * | 'IMLOG2'
 * | 'IMLOG10'
 * | 'IMSIN'
 * | 'IMSINH'
 * | 'IMSEC'
 * | 'IMSECH'
 * | 'IMCOS'
 * | 'IMCOSH'
 * | 'IMCOT'
 * | 'IMCSC'
 * | 'IMCSCH'
 * | 'IMTAN'
 * | 'PRODUCT'
 * | 'IMCONJUGATE'
 * | 'BIN2OCT'
 * | 'BIN2DEC'
 * | 'BIN2HEX'
 * | 'OCT2BIN'
 * | 'OCT2DEC'
 * | 'OCT2HEX'
 * | 'DEC2BIN'
 * | 'DEC2OCT'
 * | 'DEC2HEX'
 * | 'HEX2BIN'
 * | 'HEX2OCT'
 * | 'HEX2DEC'
 * | 'MIDB'
 * | 'TEXTBEFORE'
 * | 'TEXTAFTER'
 * | 'TEXTSPLIT'
 * | 'SCAN'
 * | 'SPLIT'
 * | 'SUMIF'
 * | 'SUMIFS'
 * | 'ISEVEN'
 * | 'ISODD'
 * | 'AVERAGEIF'
 * | 'AVERAGEIFS'
 * | 'COUNTIF'
 * | 'MAXIFS'
 * | 'MINIFS'
 * | 'CHOOSE'
 * | 'LOOKUP'
 * | 'XLOOKUP'
 * | 'XMATCH'
 * | 'HLOOKUP'
 * | 'INDEX'
 * | 'VLOOKUP'
 * | 'TO_TEXT'
 * | 'ISBETWEEN'
 * | 'ISURL'
 * | 'ISEMAIL'
 * | 'ISDATE'
 * | 'REDUCE'
 * | 'REGEXEXTRACT'
 * | 'REGEXREPLACE'
 * | 'ARRAYFORMULA'
 * | 'BINOM.DIST.RANGE'
 * | 'SORT.GOOGLE'
 * | 'FILTER.GOOGLE'
 * | 'UPLUS'
 * | 'SWITCH'
 * | 'ERF.PRECISE'
 * } ExcludedFromTypeChecking
 */

/**
 * @typedef {{
 *  FILTER_GOOGLE: typeof FILTER_GOOGLE,
 *  ERROR_TYPE: typeof ERROR_TYPE,
 *  PERCENTILE_INC: typeof PERCENTILE_INC,
 *  PERCENTILE_EXC: typeof PERCENTILE_EXC,
 *  QUARTILE_INC: typeof QUARTILE_INC,
 *  QUARTILE_EXC: typeof QUARTILE_EXC,
 *  RANK_AVG: typeof RANK_AVG,
 *  RANK_EQ: typeof RANK_EQ,
 *  'EXCEL.SORT': typeof EXCEL_SORT,
 *  'SORT.EXCEL': typeof EXCEL_SORT,
 *  'GSHEETS.SORT': typeof GSHEETS_SORT,
 *  'SORT.GSHEETS': typeof GSHEETS_SORT,
 *  'GRID.HBIND': typeof HSTACK,
 *  'GRID.VBIND': typeof VSTACK,
 * }} AliasedFunctions
 */

/**
 * @typedef {Partial<{
 *  [K in import('../signature-types').SpreadsheetFunctionName]:
 *    K extends ExcludedFromTypeChecking
 *      ? (...args: any[]) => MaybeBoxedFormulaValue
 *      : import('../signature-types').SpreadsheetFunction<K>
 * }> & AliasedFunctions} Handlers
 */

/** @type {Handlers} */
const handlers = {
  ...logical,
  // Google Sheets-style operator functions
  LT,
  GT,
  LTE,
  GTE,
  EQ,
  NE,
  UMINUS,
  UPLUS,
  UNARY_PERCENT,
  ADD,
  MINUS,
  MULTIPLY,
  DIVIDE,
  POW,
  // datetime
  ISOWEEKNUM,
  DATE,
  DATEDIF,
  DATEVALUE,
  DAY,
  DAYS,
  DAYS360,
  EDATE,
  EOMONTH,
  EPOCHTODATE,
  HOUR,
  MINUTE,
  MONTH,
  NOW,
  SECOND,
  TIME,
  TIMEVALUE,
  TODAY,
  WEEKNUM,
  WEEKDAY,
  YEAR,
  YEARFRAC,
  NETWORKDAYS,
  WORKDAY,
  // array
  ARRAY,
  ARRAYROW,
  CHOOSEROWS,
  CHOOSECOLS,
  SORT,
  SORTBY,
  UNIQUE,
  FILTER,
  FILTER_GOOGLE,
  RANDARRAY,
  SINGLE,
  HSTACK,
  VSTACK,
  ANCHORARRAY,
  TOROW,
  TOCOL,
  WRAPCOLS,
  WRAPROWS,
  TAKE,
  DROP,
  EXPAND,
  // database
  DAVERAGE,
  DCOUNT,
  DCOUNTA,
  DGET,
  DMAX,
  DMIN,
  DPRODUCT,
  DSTDEV,
  DSTDEVP,
  DSUM,
  DVAR,
  DVARP,
  // lambda
  MAKEARRAY,
  MAP,
  BYROW,
  BYCOL,
  // @ts-expect-error `ExcludedFromTypeChecking` is not working here
  SCAN,
  // @ts-expect-error `ExcludedFromTypeChecking` is not working here
  REDUCE,
  // engineering
  COMPLEX,
  CONVERT,
  IMAGINARY,
  IMREAL,
  IMSUM,
  IMSUB,
  IMPRODUCT,
  IMDIV,
  IMABS,
  IMARGUMENT,
  IMPOWER,
  IMSQRT,
  IMEXP,
  IMLN,
  IMLOG2,
  IMLOG10,
  IMLOG,
  IMSIN,
  IMSINH,
  IMSEC,
  IMSECH,
  IMCOS,
  IMCOSH,
  IMCOT,
  IMCOTH,
  IMCSC,
  IMCSCH,
  IMTAN,
  IMTANH,
  IMCONJUGATE,
  BITAND,
  BITOR,
  BITXOR,
  BITLSHIFT,
  BITRSHIFT,
  BIN2OCT,
  BIN2DEC,
  BIN2HEX,
  OCT2BIN,
  OCT2DEC,
  OCT2HEX,
  DEC2BIN,
  DEC2OCT,
  DEC2HEX,
  HEX2BIN,
  HEX2OCT,
  HEX2DEC,
  GESTEP,
  DELTA,
  BESSELJ,
  BESSELY,
  BESSELI,
  BESSELK,
  // text
  BAHTTEXT,
  CHAR,
  CONCAT,
  CONCATENATE,
  DOLLAR,
  'USDOLLAR': DOLLAR,
  ENCODEURL,
  EXACT,
  FIND,
  FINDB,
  FIXED,
  LEFT,
  LEFTB,
  LEN,
  LENB,
  LOWER,
  MID,
  MIDB,
  REPLACE,
  REPLACEB,
  REPT,
  RIGHT,
  RIGHTB,
  SUBSTITUTE,
  T,
  TEXT,
  TEXTBEFORE,
  TEXTAFTER,
  TEXTJOIN,
  TEXTSPLIT,
  JOIN,
  TRIM,
  UPPER,
  VALUE,
  SEARCH,
  SEARCHB,
  SPLIT,
  UNICHAR,
  UNICODE,
  CODE,
  CLEAN,
  PROPER,
  // math
  ABS,
  ACOS,
  ACOSH,
  ACOT,
  ACOTH,
  ARABIC,
  ASIN,
  ASINH,
  ATAN,
  ATAN2,
  ATANH,
  BASE,
  CEILING,
  COMBIN,
  COMBINA,
  COS,
  COSH,
  COT,
  COTH,
  CSC,
  CSCH,
  DECIMAL,
  DEGREES,
  EVEN,
  EXP,
  FACT,
  FACTDOUBLE,
  FLOOR,
  INT,
  LN,
  LOG,
  LOG10,
  MDETERM,
  MINVERSE,
  MUNIT,
  MOD,
  MMULT,
  MROUND,
  NUMBERVALUE,
  ODD,
  PI,
  POWER,
  PRODUCT,
  QUOTIENT,
  RADIANS,
  RAND,
  RANDBETWEEN,
  ROMAN,
  ROUND,
  ROUNDDOWN,
  ROUNDUP,
  SEC,
  SECH,
  SERIESSUM,
  SEQUENCE,
  SIGN,
  SIN,
  SINH,
  SQRT,
  SQRTPI,
  SUBTOTAL,
  SUM,
  SUMIF,
  SUMIFS,
  SUMPRODUCT,
  SUMSQ,
  SUMX2MY2,
  SUMX2PY2,
  SUMXMY2,
  TAN,
  TANH,
  TRUNC,
  GCD,
  LCM,
  // info
  CELL,
  ERROR_TYPE,
  INFO,
  ISBLANK,
  ISOMITTED,
  ISERR,
  ISERROR,
  ISEVEN,
  ISFORMULA,
  ISLOGICAL,
  ISNA,
  ISNONTEXT,
  ISNUMBER,
  ISODD,
  ISREF,
  ISTEXT,
  N,
  NA,
  TYPE,
  // statistical
  AVERAGE,
  AVERAGEA,
  AVERAGEIF,
  AVERAGEIFS,
  BETADIST,
  BETAINV,
  BINOMDIST,
  CHIDIST,
  CHIINV,
  CRITBINOM,
  CONFIDENCE,
  CORREL,
  COUNT,
  COUNTA,
  COUNTBLANK,
  COUNTIF,
  COUNTIFS,
  COVAR,
  ERF,
  ERFC,
  EXPONDIST,
  FORECAST,
  GEOMEAN,
  INTERCEPT,
  KURT,
  LARGE,
  LINEST,
  MAX,
  MAXA,
  MAXIFS,
  MEDIAN,
  MIN,
  MINA,
  MINIFS,
  NORMINV,
  NORMDIST,
  NORMSDIST,
  NORMSINV,
  NEGBINOMDIST,
  PERCENTILE,
  PERCENTILE_INC,
  PERCENTILE_EXC,
  PERCENTRANK,
  QUARTILE,
  QUARTILE_INC,
  QUARTILE_EXC,
  RANK,
  RANK_AVG,
  RANK_EQ,
  SKEW,
  SLOPE,
  SMALL,
  STANDARDIZE,
  STDEV,
  STDEVA,
  STDEVP,
  STDEVPA,
  TREND,
  VAR,
  VARA,
  VARPA,
  FDIST,
  FINV,
  FISHER,
  FISHERINV,
  GAMMA,
  GAMMALN,
  GAMMADIST,
  GAMMAINV,
  GAUSS,
  HYPGEOMDIST,
  LOGINV,
  LOGNORMDIST,
  PERMUT,
  PERMUTATIONA,
  PHI,
  POISSON,
  TDIST,
  TINV,
  WEIBULL,
  FREQUENCY,
  // lookup
  ADDRESS,
  CHOOSE,
  COLUMN,
  COLUMNS,
  FORMULATEXT,
  LOOKUP,
  XLOOKUP,
  XMATCH,
  HLOOKUP,
  INDEX,
  INDIRECT,
  MATCH,
  OFFSET,
  ROW,
  ROWS,
  TRANSPOSE,
  VLOOKUP,
  HYPERLINK,
  // financial
  EFFECT,
  NOMINAL,
  RATE,
  COUPDAYS,
  COUPPCD,
  COUPNCD,
  COUPNUM,
  COUPDAYBS,
  COUPDAYSNC,
  CUMIPMT,
  CUMPRINC,
  DOLLARDE,
  DOLLARFR,
  DURATION,
  DISC,
  FV,
  FVSCHEDULE,
  IPMT,
  IRR,
  ISPMT,
  NPER,
  NPV,
  MDURATION,
  MIRR,
  PMT,
  PPMT,
  PV,
  XIRR,
  XNPV,
  RRI,
  PDURATION,
  SLN,
  SYD,
  DB,
  DDB,
  INTRATE,
  RECEIVED,
  TBILLEQ,
  TBILLPRICE,
  TBILLYIELD,
  PRICE,
  PRICEDISC,
  PRICEMAT,
  YIELD,
  YIELDDISC,
  YIELDMAT,
  // Google sheets
  TO_DATE,
  TO_DOLLARS,
  TO_PERCENT,
  TO_PURE_NUMBER,
  TO_TEXT,
  ISBETWEEN,
  ISURL,
  ISEMAIL,
  ISDATE,
  REGEXMATCH,
  REGEXEXTRACT,
  REGEXREPLACE,
  ARRAYFORMULA,
  // Functions with period in name
  'CEILING.MATH': CEILING_MATH,
  'CEILING.PRECISE': CEILING_PRECISE,
  'ECMA.CEILING': ECMA_CEILING,
  'ERF.PRECISE': ERF_PRECISE,
  'ERFC.PRECISE': ERFC_PRECISE,
  'ISO.CEILING': ISO_CEILING,
  'FLOOR.MATH': FLOOR_MATH,
  'FLOOR.PRECISE': FLOOR_PRECISE,
  'ERROR.TYPE': ERROR_TYPE,
  'RANK.AVG': RANK_AVG,
  'RANK.EQ': RANK_EQ,
  'STDEV.S': STDEV_S,
  'STDEV.P': STDEV_P,
  'VAR.S': VAR_S,
  'VAR.P': VAR_P,
  'GRID.ERROR': GRID_ERROR,
  'GRID.ISPRINT': GRID_ISPRINT,
  'GRID.ISMOBILE': GRID_ISMOBILE,
  'GRID.USERNAME': GRID_USERNAME,
  'GRID.SCENARIO': GRID_SCENARIO,
  'GRID.HBIND': HSTACK, // legacy function, now aliased to HSTACK for backwards compatibility
  'GRID.VBIND': VSTACK, // legacy function, now aliased to VSTACK for backwards compatibility
  'BETA.DIST': BETA_DIST,
  'BETA.INV': BETA_INV,
  'BINOM.DIST': BINOM_DIST,
  'BINOM.DIST.RANGE': BINOM_DIST_RANGE,
  'BINOM.INV': BINOM_INV,
  'LOGNORM.DIST': LOGNORM_DIST,
  'LOGNORM.INV': LOGNORM_INV,
  'NORM.DIST': NORM_DIST,
  'NORM.INV': NORM_INV,
  'NORM.S.DIST': NORM_S_DIST,
  'NORM.S.INV': NORM_S_INV,
  'SKEW.P': SKEW_P,
  'T.DIST': T_DIST,
  'T.DIST.2T': T_DIST_2T,
  'T.DIST.RT': T_DIST_RT,
  'T.INV': T_INV,
  'T.INV.2T': T_INV_2T,
  'WEIBULL.DIST': WEIBULL_DIST,
  'CHISQ.DIST': CHISQ_DIST,
  'CHISQ.DIST.RT': CHISQ_DIST_RT,
  'CHISQ.INV': CHISQ_INV,
  'CHISQ.INV.RT': CHISQ_INV_RT,
  'CONFIDENCE.NORM': CONFIDENCE_NORM,
  'CONFIDENCE.T': CONFIDENCE_T,
  'COVARIANCE.P': COVARIANCE_P,
  'COVARIANCE.S': COVARIANCE_S,
  'EXPON.DIST': EXPON_DIST,
  'FORECAST.LINEAR': FORECAST_LINEAR,
  'F.DIST': F_DIST,
  'F.DIST.RT': F_DIST_RT,
  'F.INV': F_INV,
  'F.INV.RT': F_INV_RT,
  'GAMMA.DIST': GAMMA_DIST,
  'GAMMA.INV': GAMMA_INV,
  'GAMMALN.PRECISE': GAMMALN_PRECISE,
  'HYPGEOM.DIST': HYPGEOM_DIST,
  'POISSON.DIST': POISSON_DIST,
  'NEGBINOM.DIST': NEGBINOM_DIST,
  'NETWORKDAYS.INTL': NETWORKDAYS_INTL,
  'WORKDAY.INTL': WORKDAY_INTL,
  'PERCENTILE.INC': PERCENTILE_INC,
  'PERCENTILE.EXC': PERCENTILE_EXC,
  'PERCENTRANK.INC': PERCENTRANK_INC,
  'PERCENTRANK.EXC': PERCENTRANK_EXC,
  'QUARTILE.INC': QUARTILE_INC,
  'QUARTILE.EXC': QUARTILE_EXC,
  'SORT.EXCEL': EXCEL_SORT,
  'EXCEL.SORT': EXCEL_SORT,
  'SORT.GOOGLE': GSHEETS_SORT,
  'GSHEETS.SORT': GSHEETS_SORT,
  'SORT.GSHEETS': GSHEETS_SORT,
  'FILTER.GOOGLE': FILTER_GOOGLE,
};

// experimental canvas functions
// eslint-disable-next-line
if (true) {
  // FIXME: to be replaced with a build flag in the future
  Object.assign(handlers, canvas);
}

/** @type {Record<string, string>} */
const lazyLoadFunctionToModulePath = {
  QUERY: 'query/index.js',
};
export const lazyLoadModulePathsNotYetImported = new Set(Object.values(lazyLoadFunctionToModulePath));

/** Functions which are really special syntax, not handled by the function call mechanism. */
const specialSyntaxFunctions = [ 'LAMBDA', 'LET' ];

/** @type {Set<string>} */
export const supportedFunctionNames = new Set(
  Object.keys(handlers)
    .concat(Object.keys(lazyArgumentFunctions))
    .concat(Object.keys(lazyLoadFunctionToModulePath))
    .concat(specialSyntaxFunctions),
);

/**
 * Trigger dynamic imports of any function modules of any lazy-load functions named in the given list, or all lazy-load
 * functions if no list is given.
 *
 * Special case: in Safari 12.5, the promise will reject as soon as _one_ of the module imports fails, and meanwhile
 * the others may not have completed. So when more than one module is imported, it is possible that some of them have
 * yet to make their functions available for formula evaluation after this promise settles. That's because Safari 12.5
 * does not support the `Promise.allSettled` function. If we're really unlucky, it may sometimes lead to evaluation
 * failures due to the resulting race between the function being called and the function becoming available. This
 * would be quite the corner case, but it _can_ happen when all of the below are true:
 *
 * * the browser is Safari 12.5
 * * the dynamic import of a lazy-loaded function module fails (e.g. because of a network problem during page init)
 * * another lazy-loaded function module whose import was issued during the same workbook init has not yet imported
 *
 * ... and in such a case, the document is already in trouble as one of the lazy-loaded function modules it requires
 * failed to load, and this just compounds the problem a tiny bit by adding the possibility that _another_ lazy-loaded
 * function will appear to be unsupported when a formula calling it is evaluated, because that's happening too soon.
 *
 * @param {string[] | null} [functionNames=null] a list of function names (case does not matter), or nullish to import all functions
 * @return {Promise<void[] | PromiseSettledResult<void>[]>} a promise that
 *   settles when each module import either (a) has completed and its functions
 *   have been made available for formula evaluation, or (b) has failed, so its
 *   functions are _not_ available for formula evaluation. (It is possible for
 *   some of the module imports to fail and others to succeed, in which case
 *   those that succeed do make their functions available for formula
 *   evaluation.)
 */
export function loadLazy (functionNames = null) {
  /** @type {Set<string>} */
  let modulesToImport;
  if (functionNames != null) {
    modulesToImport = new Set();
    for (const funcName of functionNames) {
      const modulePath = lazyLoadFunctionToModulePath[funcName.toUpperCase()];
      if (modulePath) {
        modulesToImport.add(modulePath);
      }
    }
  }
  else {
    modulesToImport = new Set(Object.values(lazyLoadFunctionToModulePath));
  }
  const promises = Array.from(modulesToImport).map(importLazyLoadFunctionModule);
  return typeof Promise.allSettled !== 'undefined' ? Promise.allSettled(promises) : Promise.all(promises);
}

/**
 * @param {string} modulePath
 */
async function importLazyLoadFunctionModule (modulePath) {
  try {
    const module = await import(`./lazy/${modulePath}`);
    for (const [ functionName, _modulePath ] of Object.entries(lazyLoadFunctionToModulePath)) {
      if (modulePath === _modulePath) {
        handlers[functionName] = module[functionName];
      }
      lazyLoadModulePathsNotYetImported.delete(modulePath);
    }
  }
  catch (err) {
    console.error(`Failed to load spreadsheet functions from ${modulePath}`, err);
  }
}

/**
 * Function to report an unsupported spreadsheet function or functionality.
 * @param {string} what the function name in upper case, or a concise name for the functionality
 * @param {boolean} willNotSupport true if the thing will _never_ be supported, false if not _yet_ supported
 * @param {'is' | 'are'} [isOrAre='is'] whether message should say 'is not supported' or 'are not supported'
 * @param {string} [type] specific error type code (such as 'tbl-unsup')
 * @typedef {(what: string, willNotSupport: boolean, isOrAre?: 'is' | 'are', type?: string) => void} FnReportUnsupported
 */

/**
 * Check whether the given spreadsheet function is unsupported or needs lazy-loading, and act accordingly.
 * @param {string} possiblyPrefixedNameUpperCase name of the function in upper case, possibly prefixed with _XLFN etc.
 * @param {FnReportUnsupported} reportUnsupported function to report unsupported function(ality)
 * @param {EvaluationContext} [ctx]
 * @param {Map<string, Promise<void>>} [lazyImports] map of import promises for lazy-load function modules
 */
export function validateFunctionCall (possiblyPrefixedNameUpperCase, reportUnsupported, ctx, lazyImports) {
  // clean "error" namespaces off function tokens
  const nameUpperCase = possiblyPrefixedNameUpperCase.replace(/^(?:_XLFN\.|_XLWS\.|__XLUDF\.)+/, '');

  // NOTE: don't get confused by lazy _argument_ functions vs lazy _load_ functions
  // ... these are unrelated, with two completely different meanings of “lazy”!
  if (!handlers[nameUpperCase] && !lazyArgumentFunctions[nameUpperCase]) {
    const lazyImportModuleName = lazyLoadFunctionToModulePath[nameUpperCase];
    if (lazyImportModuleName != null) {
      if (lazyImports && !lazyImports.has(lazyImportModuleName)) {
        const importPromise = importLazyLoadFunctionModule(lazyImportModuleName);
        lazyImports.set(lazyImportModuleName, importPromise);
      }
    }
    else {
      // Only report unsupported functions if no defined name exists by that
      // name; if one does, we assume it defines a lambda, so is callable.
      const definedName = ctx ? ctx.resolveName(nameUpperCase) : null;
      if (!definedName || isErr(definedName)) {
        reportUnsupported(nameUpperCase, NEVER_SUPPORTED.includes(nameUpperCase));
      }
    }
  }
}

/**
 * Return details on supported worksheet functions.
 *
 * This converts the terse internal data stored in `funcSigs` in `lib/excel/signatures.js` into a
 * data structure for public (external) consumption. The function signatures are filtered so they
 * only include functions supported by Apiary.
 *
 * @param {ModeType} [mode=MODE_ALL] Filter returned functions to only include those available in
 *   the given mode(s). Default is no filter.
 * @returns {PublicFunctionSignatures}
 */
export function functionSignatures (mode = MODE_ALL) {
  /** @type {PublicFunctionSignatures} */
  const data = {};
  for (const [ funcName, sigs ] of Object.entries(funcSigs)) {
    if (!supportedFunctionNames.has(funcName)) {
      continue;
    }
    for (const [ sigMode ] of sigs) {
      if ((sigMode & mode) !== 0) {
        if (!(funcName in data)) {
          data[funcName] = [];
        }
        data[funcName].push({ mode: sigMode });
      }
    }
  }
  return data;
}

export default handlers;
