import { ERROR_NA, ERROR_VALUE } from '../constants.js';
import { toBool, isErr, resolve, isMatrix, isRef } from './utils.js';
import { EQ } from './operators.js';
import { isBool } from '../../utils.js';
import { invariant } from '../../validation';
import { isLambda } from '../lambda';

/**
 * AND(logical_expression1, [logical_expression2, ...])
 * @param {(Matrix | Reference | boolean)[]} args
 * @returns {boolean | FormulaError}
 */
export function AND (...args) {
  for (let i = 0, l = args.length; i < l; i++) {
    const value = args[i];
    if (isMatrix(value) || isRef(value)) {
      let nonBlanksFound = false;
      const elements = value.resolveRange({ skipBlanks: 'none' });
      if (isErr(elements)) {
        return elements;
      }
      for (const element of elements) {
        nonBlanksFound = nonBlanksFound || element != null;
        const elBool = toBool(element);
        if (nonBlanksFound && (!isBool(elBool) || !elBool)) {
          return elBool;
        }
      }
      if (!nonBlanksFound) {
        return ERROR_VALUE;
      }
    }
    else {
      const b = toBool(value);
      if (!isBool(b) || !b) {
        return b;
      }
    }
  }
  return true;
}

// FALSE()
export function FALSE () {
  return false;
}

/**
 * @param {boolean} expr
 * @returns {boolean | FormulaError}
 */
export function NOT (expr) {
  const b = toBool(expr);
  return !isBool(b) ? b : !b;
}

/**
 * OR(logical_expression1, [logical_expression2, ...])
 * @param {(Matrix | Reference | boolean)[]} args
 * @returns {boolean | FormulaError}
 */
export function OR (...args) {
  for (let i = 0, l = args.length; i < l; i++) {
    const value = args[i];
    if (isMatrix(value) || isRef(value)) {
      let nonBlanksFound = false;
      const elements = value.resolveRange({ skipBlanks: 'none' });
      if (isErr(elements)) {
        return elements;
      }
      for (const element of elements) {
        nonBlanksFound = nonBlanksFound || element != null;
        const elBool = toBool(element);
        if (!isBool(elBool) || elBool) {
          return elBool;
        }
      }
      if (!nonBlanksFound) {
        return ERROR_VALUE;
      }
    }
    else {
      const b = toBool(value);
      if (!isBool(b) || b) {
        return b;
      }
    }
  }
  return false;
}

/**
 * SWITCH(expression, case1, value1, [default or case2, value2, ...])
 * @param {FormulaValue[]} args
 * @return {FormulaValue}
 */
export function SWITCH (...args) {
  invariant(args.length >= 3);
  const value = resolve(args[0]);
  if (isErr(value)) {
    return value;
  }
  const caseDefault = !(args.length % 2);
  const l = caseDefault ? args.length - 1 : args.length;
  for (let i = 1; i < l; i += 2) {
    const comparand = resolve(args[i]);
    if (isErr(comparand)) {
      return comparand;
    }
    if (!isLambda(value) && !isLambda(comparand) && EQ(value, comparand)) {
      return args[i + 1];
    }
  }
  return caseDefault ? args[args.length - 1] : ERROR_NA;
}

// TRUE()
export function TRUE () {
  return true;
}

/**
 * XOR(logical_expression1, [logical_expression2, ...])
 * @param {FormulaValue[]} args
 * @returns {boolean | FormulaError}
 */
export function XOR (...args) {
  let v = 0;
  for (let i = 0, l = args.length; i < l; i++) {
    const value = args[i];
    if (isMatrix(value) || isRef(value)) {
      let nonBlanksFound = false;
      const elements = value.resolveRange({ skipBlanks: 'none' });
      if (isErr(elements)) {
        return elements;
      }
      for (const element of elements) {
        nonBlanksFound = nonBlanksFound || element != null;
        const elBool = toBool(element);
        if (!isBool(elBool)) {
          return elBool;
        }
        if (elBool) {
          v++;
        }
      }
      if (!nonBlanksFound) {
        return ERROR_VALUE;
      }
    }
    else {
      const b = toBool(value);
      if (!isBool(b)) {
        return b;
      }
      if (b) {
        v++;
      }
    }
  }
  return !!(Math.floor(v) & 1);
}
