const enum UType {
  MASS,
  DIST,
  TIME,
  PRES,
  FORC,
  ENER,
  POWR,
  MAGN,
  TEMP,
  VOLU,
  AREA,
  INFO,
  SPED,
}

type UnitInfo = {
  // c: the category/type of the unit (MASS, AREA, ...)
  c: keyof typeof UType,
  // multiplier for normalization
  x: number,
  // can take an SI prefix, truthy if yes
  p?: number,
  // offset adjustment for normalization
  a?: number,
  // can take a binary prefix, truthy if yes
  b?: number,
};

const units = {
  // all multipliers
  'g': { c: UType.MASS, p: 1, x: 1 },
  'sg': { c: UType.MASS, x: 0.00006852176585679177 },
  'lbm': { c: UType.MASS, x: 0.002204622621848776 },
  'u': { c: UType.MASS, p: 1, x: 6.022141794216764e23 },
  'ozm': { c: UType.MASS, x: 0.035273961949580414 },
  'grain': { c: UType.MASS, x: 15.43235835294143 },
  'cwt': { c: UType.MASS, x: 0.000022046226218487758 },
  'lcwt': { c: UType.MASS, x: 0.000019684130552221215 },
  'stone': { c: UType.MASS, x: 0.00015747304441776972 },
  'ton': { c: UType.MASS, x: 0.000001102311310924388 },
  'LTON': { c: UType.MASS, x: 9.842065276110607e-7 },

  'm': { c: UType.DIST, p: 1, x: 1 },
  'mi': { c: UType.DIST, x: 0.0006213711922373339 },
  'Nmi': { c: UType.DIST, x: 0.0005399568034557236 },
  'in': { c: UType.DIST, x: 39.37007874015748 },
  'ft': { c: UType.DIST, x: 3.2808398950131235 },
  'yd': { c: UType.DIST, x: 1.0936132983377078 },
  'ang': { c: UType.DIST, p: 1, x: 1e10 },
  'ell': { c: UType.DIST, x: 0.8748906386701663 },
  'ly': { c: UType.DIST, p: 1, x: 1.0570008340246154e-16 },
  'parsec': { c: UType.DIST, p: 1, x: 3.240779289664725e-17 },
  'Pica': { c: UType.DIST, x: 2834.6456692913384 },
  'pica': { c: UType.DIST, x: 236.22047244094486 },
  'survey_mi': { c: UType.DIST, x: 0.0006213699494949495 },

  'yr': { c: UType.TIME, x: 1 / 31557600 },
  'day': { c: UType.TIME, x: 1 / 86400 },
  'hr': { c: UType.TIME, x: 1 / 3600 },
  'mn': { c: UType.TIME, x: 1 / 60 },
  's': { c: UType.TIME, p: 1, x: 1 },

  'p': { c: UType.PRES, p: 1, x: 1 },
  'at': { c: UType.PRES, p: 1, x: 1 / 101325 },
  'mmHg': { c: UType.PRES, p: 1, x: 1 / 133.322 },
  'psi': { c: UType.PRES, x: 0.0001450377377302092 },
  'Torr': { c: UType.PRES, x: 0.007500616827041697 },

  'N': { c: UType.FORC, p: 1, x: 1 },
  'dyn': { c: UType.FORC, p: 1, x: 1e5 },
  'lbf': { c: UType.FORC, x: 0.22480894309971047 },
  'pond': { c: UType.FORC, p: 1, x: 101.97162129779282 },

  'J': { c: UType.ENER, p: 1, x: 1 },
  'e': { c: UType.ENER, p: 1, x: 1e7 },
  'c': { c: UType.ENER, p: 1, x: 1 / 4.184 },
  'cal': { c: UType.ENER, p: 1, x: 1 / 4.1868 },
  'eV': { c: UType.ENER, p: 1, x: 6241509647120418000 },
  'hh': { c: UType.ENER, x: 3.725061359986188e-7 },
  'wh': { c: UType.ENER, p: 1, x: 1 / 3600 },
  'flb': { c: UType.ENER, x: 0.7375621492772654 },
  'btu': { c: UType.ENER, x: 0.0009478171203133171 },

  'h': { c: UType.POWR, x: 0.0013410220895950279 },
  'PS': { c: UType.POWR, x: 0.0013596216173039043 },
  'w': { c: UType.POWR, p: 1, x: 1 },

  'T': { c: UType.MAGN, p: 1, x: 1 },
  'ga': { c: UType.MAGN, p: 1, x: 1e4 },

  'cel': { c: UType.TEMP, x: 1 },
  'fah': { c: UType.TEMP, x: 1.8, a: 32 },
  'kel': { c: UType.TEMP, p: 1, x: 1, a: 273.15 },
  'Rank': { c: UType.TEMP, x: 1.8, a: 491.67 },
  'Reau': { c: UType.TEMP, x: 0.8 },

  'tsp': { c: UType.VOLU, x: 202.88413621105798 },
  'tspm': { c: UType.VOLU, x: 200 },
  'tbs': { c: UType.VOLU, x: 67.628045403686 },
  'oz': { c: UType.VOLU, x: 33.814022701843 },
  'cup': { c: UType.VOLU, x: 4.226752837730375 },
  'pt': { c: UType.VOLU, x: 2.1133764188651876 },
  'uk_pt': { c: UType.VOLU, p: 1, x: 1.759753986392702 },
  'qt': { c: UType.VOLU, x: 1.0566882094325938 },
  'uk_qt': { c: UType.VOLU, x: 0.879876993196351 },
  'gal': { c: UType.VOLU, x: 0.26417205235814845 },
  'uk_gal': { c: UType.VOLU, x: 1 / 4.54609 },
  'l': { c: UType.VOLU, p: 1, x: 1 },
  'ang3': { c: UType.VOLU, p: 1, x: 1e27 },
  'barrel': { c: UType.VOLU, x: 0.006289810770432105 },
  'bushel': { c: UType.VOLU, x: 0.028377593258401747 },
  'ft3': { c: UType.VOLU, x: 0.035314666721488586 },
  'in3': { c: UType.VOLU, x: 61.02374409473229 },
  'ly3': { c: UType.VOLU, x: 1.1809349884417084e-51 },
  'm3': { c: UType.VOLU, p: 1, x: 0.001 },
  'mi3': { c: UType.VOLU, x: 2.3991275857892773e-13 },
  'yd3': { c: UType.VOLU, x: 0.001307950619314392 },
  'Nmi3': { c: UType.VOLU, x: 1.574262146858115e-13 },
  'Pica3': { c: UType.VOLU, x: 22776990.4358706 },
  'GRT': { c: UType.VOLU, x: 0.000353146667214886 },
  'MTON': { c: UType.VOLU, x: 0.000882866668037215 },

  'uk_acre': { c: UType.AREA, x: 0.0002471053814671653 },
  'us_acre': { c: UType.AREA, x: 0.0002471043930466279 },
  'ang2': { c: UType.AREA, p: 1, x: 1e20 },
  'ar': { c: UType.AREA, p: 1, x: 0.01 },
  'ft2': { c: UType.AREA, x: 10.763910416709722 },
  'ha': { c: UType.AREA, x: 0.0001 },
  'in2': { c: UType.AREA, x: 1550.0031000062 },
  'ly2': { c: UType.AREA, x: 1.117250763128733e-32 },
  'm2': { c: UType.AREA, p: 1, x: 1 },
  'Morgen': { c: UType.AREA, x: 0.0004 },
  'mi2': { c: UType.AREA, x: 3.861021585424458e-7 },
  'Nmi2': { c: UType.AREA, x: 1 / 3429904 },
  'Pica2': { c: UType.AREA, x: 8035216.070432141 },
  'yd2': { c: UType.AREA, x: 1.1959900463010802 },

  'bit': { c: UType.INFO, p: 1, x: 8, b: 1 },
  'byte': { c: UType.INFO, p: 1, x: 1, b: 1 },

  'admkn': { c: UType.SPED, x: 1.9426025694156652 },
  'kn': { c: UType.SPED, x: 1.9438444924406046 },
  'm/h': { c: UType.SPED, p: 1, x: 3600 },
  'm/s': { c: UType.SPED, p: 1, x: 1 },
  'mph': { c: UType.SPED, p: 1, x: 2.2369362920544025 },
};

const unitAliases: Record<string, keyof typeof units> = {
  'shweight': 'cwt',
  'uk_cwt': 'lcwt',
  'brton': 'LTON',
  'uk_ton': 'LTON',
  'pc': 'parsec',
  'Picapt': 'Pica',
  'd': 'day',
  'min': 'mn',
  'sec': 's',
  'Pa': 'p',
  'atm': 'at',
  'dy': 'dyn',
  'ev': 'eV',
  'HPh': 'hh',
  'Wh': 'wh',
  'BTU': 'btu',
  'HP': 'h',
  'W': 'w',
  'C': 'cel',
  'F': 'fah',
  'K': 'kel',
  'us_pt': 'pt',
  'lt': 'l',
  'L': 'l',
  'ang^3': 'ang3',
  'ft^3': 'ft3',
  'in^3': 'in3',
  'ly^3': 'ly3',
  'm^3': 'm3',
  'mi^3': 'mi3',
  'yd^3': 'yd3',
  'Nmi^3': 'Nmi3',
  'Picapt3': 'Pica3',
  'Picapt^3': 'Pica3',
  'Pica^3': 'Pica3',
  'regton': 'GRT',
  'ang^2': 'ang2',
  'ft^2': 'ft2',
  'in^2': 'in2',
  'ly^2': 'ly2',
  'm^2': 'm2',
  'mi^2': 'mi2',
  'Nmi^2': 'Nmi2',
  'Picapt2': 'Pica2',
  'Picapt^2': 'Pica2',
  'Pica^2': 'Pica2',
  'yd^2': 'yd2',
  'm/hr': 'm/h',
  'm/sec': 'm/s',
};

const prefix = {
  Y: 1e24,
  Z: 1e21,
  E: 1e18,
  P: 1e15,
  T: 1e12,
  G: 1e9,
  M: 1e6,
  k: 1e3,
  h: 1e2,
  e: 1e1,
  da: 1e1,
  d: 1e-1,
  c: 1e-2,
  m: 1e-3,
  u: 1e-6,
  n: 1e-9,
  p: 1e-12,
  f: 1e-15,
  a: 1e-18,
  z: 1e-21,
  y: 1e-24,
};

const binPrefix = {
  Yi: 2 ** 80,
  Zi: 2 ** 70,
  Ei: 2 ** 60,
  Pi: 2 ** 50,
  Ti: 2 ** 40,
  Gi: 2 ** 30,
  Mi: 2 ** 20,
  ki: 2 ** 10,
};

function getUnit (key: string): UnitInfo | undefined {
  // unroll aliases
  if (key in unitAliases) {
    key = unitAliases[key];
  }
  return units[key];
}

type ParsedUnit = [UnitInfo, number];

export function parseConvertUnit (str: string): ParsedUnit | undefined {
  let p: number;
  // is str a unit?
  let u = getUnit(str);
  if (u) {
    return [ u, 1 ];
  }
  // is it prefixed?
  else if (str.length > 1) {
    // 1 char prefix + unit match?
    p = prefix[str[0]];
    u = getUnit(str.slice(1));
    if (p && u && u.p) {
      return [ u, p ];
    }
    // 2 char prefix/binary-prefix + unit match?
    const px = str.slice(0, 2);
    u = getUnit(str.slice(2));
    p = prefix[px] ?? (u && u.b ? binPrefix[px] : null);
    if (p && u && u.p) {
      return [ u, p ];
    }
  }
}
