type Options = { returnCells: boolean, returnBoxed: boolean };

export class ResolveAreaOptions<O extends Options = Options> {
  public returnCells: O['returnCells'];
  public returnBoxed: O['returnBoxed'];
  public cropTo: 'any-cell-information' | 'cells-with-non-blank-values';

  constructor (options: O & { cropTo?: 'any-cell-information' | 'cells-with-non-blank-values' }) {
    this.returnCells = options.returnCells;
    this.returnBoxed = options.returnBoxed;
    this.cropTo = options.cropTo || 'any-cell-information';
  }
}
