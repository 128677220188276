import type Model from './Model';
import Reference from './excel/Reference';
import { isErr } from './typeguards';
import { isRef } from './utils';
import { invariant } from './validation';

export type ModelEntity =
  | {
    type: 'workbook',
    name: string,
    workbookName: string,
  }
  | {
    type: 'sheet',
    name: string,
    workbookName: string,
    sheetName: string,
  }
  | {
    type: 'name',
    name: string,
    workbookName: string,
    resolvesTo: string,
  }
  | {
    type: 'formula',
    name: string,
    workbookName: string,
  }
  | {
    type: 'table',
    name: string,
    workbookName: string,
    sheetName: string,
    resolvesTo: string,
    totals: number,
    headers: number,
  };

/**
 * Returns a list of defined names and tables in the Model.
 *
 * No guarantees are made about the order of the entities.
 */
export function getModelEntities (this: Model): ModelEntity[] {
  const out: ModelEntity[] = [];

  for (const workbook of this._workbooks) {
    const workbookName = workbook.name;

    out.push({ type: 'workbook', name: workbookName, workbookName });

    for (const { name } of workbook._sheets) {
      out.push({ type: 'sheet', name, workbookName, sheetName: name });
    }

    for (const { name } of workbook.names) {
      const global = workbook.getGlobal(name);
      invariant(!isErr(global));
      const globalValue = global.v;
      if (isRef(globalValue) && globalValue.isAddress) {
        const ref = globalValue.withPrefix({
          workbookName: globalValue.workbookName || workbookName,
          sheetName: globalValue.sheetName || workbook._sheets[0].name,
        });
        out.push({ type: 'name', name, workbookName, resolvesTo: String(ref) });
      }
      else {
        out.push({ type: 'formula', name, workbookName });
      }
    }

    for (const { name, ref, sheetName, totalsRowCount, headerRowCount } of workbook._tables.values()) {
      const resolvesTo = String(new Reference(ref, { sheetName: sheetName, workbookName: workbook.name }));
      out.push({
        type: 'table',
        name,
        resolvesTo,
        sheetName,
        workbookName,
        totals: totalsRowCount,
        headers: headerRowCount,
      });
    }
  }

  return out;
}
