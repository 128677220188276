import EventEmitter from 'component-emitter';
import Reference from './excel/Reference.js';
import Range, { type SlimRange } from './excel/referenceParser/Range.js';
import { assertParserLoaded, parseFormula } from './excel/formulaParser/index.js';
import {
  hasFunctionCallNode,
  isFunctionCallNode,
  isNonLiteralNode,
  isAggrCallWithArrayOpsInside,
  isContextDependentCall,
} from './excel/ast';
import { isLiteralNode, visitAllNonLiteralNodes } from './excel/ast-common';
import { ASTRootNode } from './excel/ast-types';
import { validateFunctionCall } from './excel/functions/index.js';
import ModelError from './ModelError.js';
import Cells, { createKey, type RTreeMatrixNode } from './Cells';
import { invariant } from './validation';
import Matrix from './excel/Matrix.js';
import { cellToVertexId } from './dep-graph-helpers.js';
import { ResolveAreaOptions } from './ResolveAreaOptions';
import { type CellCSF, type SheetCSF, ColumnInfo, TableCSF, TableColumnCSF, WorkbookType, DrawingCSF } from './csf';
import Cell from './excel/Cell';
import { CellVertexId, RangeVertexId } from './DependencyGraph';
import type { ResolveAreaResult } from './Cells.js';
import { colFromOffs } from './excel/referenceParser/a1.js';
import { CallbackWithStop } from './Signal';
import { EvaluationContext } from './excel/EvaluationContext.js';

/**
 * An object describing a sheet of A1-addressable spreadsheet cells.
 */
export default class WorkSheet extends EventEmitter {
  private _tableStructure: {
    name: string,
    columns: Array<{
      name: string,
      dataType: NonNullable<TableColumnCSF['data_type']>,
      columnId: string,
    }>,
  } | null = null;

  private _workbookType: WorkbookType;

  name: string;
  workbookKey: number;
  index: number;
  hidden: boolean;
  _cells: Cells;
  merges: Record<string, [width: number, height: number]>;
  columns: ColumnInfo[];
  col_widths: Record<string, number>;
  row_heights: Record<number, number>;
  defaults: {
    col_width?: number,
    row_height?: number,
  };

  merged_cells: string[];
  show_grid_lines: boolean = true;
  drawings: DrawingCSF[];
  locallyScopedNames: Record<string, Cell> = {};

  constructor (
    name: string,
    workbookKey: number,
    index: number,
    hidden: boolean = false,
    workbookType: WorkbookType = 'excel',
  ) {
    super();
    this.name = name;
    this.workbookKey = workbookKey;
    this.index = index;
    this.hidden = hidden;
    this._cells = new Cells(this.workbookKey, this.index);
    this.merges = {};
    this.columns = [];
    this.col_widths = {};
    this.row_heights = {};
    this.defaults = {};
    this.merged_cells = [];
    this._workbookType = workbookType;
    this.drawings = [];
  }

  moveCells (
    otherSheet: WorkSheet,
    from: Range,
    to: Range,
  ): { changedRefsInFrom: Reference[], changedRefsInTo: Reference[] } {
    const { changedRangesInFrom, changedRangesInTo } = this._cells.moveCells(otherSheet._cells, from, to);
    return {
      // We can assign sheet names to the ranges, but we don't know the workbook name.
      changedRefsInFrom: changedRangesInFrom.map(range => new Reference(range, { sheetName: this.name })),
      changedRefsInTo: changedRangesInTo.map(range => new Reference(range, { sheetName: otherSheet.name })),
    };
  }

  get cellCount () {
    return this._cells.cellCount;
  }

  isStructuredSheet (): boolean {
    return !!this._tableStructure;
  }

  getCell (cellID: string) {
    return this.getCellByID(cellID);
  }

  /**
   * @param cellID cell address in A1 form without prefix
   */
  getCellByID (cellID: string) {
    return this._cells.getCellByID(cellID);
  }

  getCellByRange (range: { top: number, left: number }) {
    return this.getCellByCoords(range.top, range.left);
  }

  getCellByCoords (row: number, col: number, willEdit: boolean = false, formulaCell: boolean = false) {
    return this._cells.getCellByCoords(row, col, willEdit, formulaCell);
  }

  getDataCellByCoords (row: number, col: number) {
    if (this.isStructuredSheet()) {
      row++;
    }
    return this.getCellByCoords(row, col);
  }

  setIndex (index: number) {
    this.index = index;
    this._cells.setSheetIndex(index);
  }

  resolveArea<O extends ResolveAreaOptions = ResolveAreaOptions> (
    range: SlimRange,
    options: O = new ResolveAreaOptions({ returnBoxed: false, returnCells: false }) as O,
  ): ResolveAreaResult<O> {
    return this._cells.resolveArea(range, options);
  }

  /**
   * Get the area which a spilled range covers, given a reference to the
   * spilled range's anchor cell.
   */
  getSpillAnchoredAtRange (range: Range | Reference): Range | null {
    return this._cells.getSpillAnchoredAtCoords(range.top, range.left);
  }

  /**
   * Returns an iterator of all cells contained in this sheet, even individual
   * cells within spilled ranges.
   * @param [includeStyleOnly=false] include cells that have never had a value but only style info
   */
  getCells (includeStyleOnly = false): IterableIterator<Cell> {
    return this._cells.getCells(includeStyleOnly);
  }

  /**
   * Yield all formula cells.
   */
  * iterFormulaCells (): IterableIterator<Cell> {
    yield * this._cells.iterFormulaCells();
  }

  /**
   * Visits all formula cells within the given range. Also includes formula cells
   * that spill into the range.
   */
  visitFormulaCellsIntersecting (range: SlimRange, callback: CallbackWithStop<Cell>): void {
    this._cells.visitFormulaCellsIntersecting(range, callback);
  }

  /**
   * Iterate all cells in range, except spilled cells
   */
  * iterAnchorCellsInRange (range: SlimRange): IterableIterator<Cell> {
    yield * this._cells.iterAnchorCellsInRange(range);
  }

  getSize (): [width: number, height: number] {
    return this._cells.getSize();
  }

  getBounds (): SlimRange {
    const [ width, height ] = this.getSize();
    return {
      left: 0,
      top: 0,
      right: width - 1,
      bottom: height - 1,
    };
  }

  reset () {
    this._cells.reset();
  }

  /**
   * Look for range write nodes in the R-Tree that overlap with the given range,
   * and if there are any, split them up such that the remaining set of range
   * write nodes cover any cells previously covered, but do not overlap the
   * given range. If the given range is a single-cell address, and there exists
   * a formula cell at that address (which will have been neutralized by one of
   * the overlapping range writes), then reinstate the cell R-tree node for that
   * cell (else it would not be covered by _any_ R-tree cell).
   * @returns the cell whose neutralized formula was reinstated, if any
   */
  _splitUpOverlappingRangeWrites (ref: SlimRange): Cell | undefined {
    // XXX this should be moved into Cells as it accesses internals of that
    // class and does not access anything else in this class.
    const overlappingRangeWrites = this._cells._rtree
      .search(new Range(ref).toBoundingBox())
      .filter(node => 'matrix' in node && node.isRangeWrite) as RTreeMatrixNode[];
    const { left, right, top, bottom } = ref;
    const insertFragmentNode = (overlappingNode: RTreeMatrixNode, overrides: Partial<RTreeMatrixNode>) => {
      const fragmentNode = {
        ...overlappingNode,
        ...overrides,
      };
      const width = fragmentNode.maxX - fragmentNode.minX + 1;
      const height = fragmentNode.maxY - fragmentNode.minY + 1;
      const matrix = overlappingNode.matrix;
      invariant(
        matrix.populatedWidth === 0 &&
          matrix.populatedHeight === 0 &&
          matrix._defaultColumn.length === 0 &&
          matrix._defaultRow.length === 0,
        'range write matrices should have only a _defaultValue, no data or _defaultColumn or _defaultRow',
      );
      fragmentNode.matrix = new Matrix(width, height, matrix._defaultValue);
      this._cells._rtree.insert(fragmentNode);
    };
    for (const overlappingNode of overlappingRangeWrites) {
      if (overlappingNode.minY < top) {
        insertFragmentNode(overlappingNode, { maxY: top - 1 });
      }
      if (overlappingNode.maxY > bottom) {
        insertFragmentNode(overlappingNode, { minY: bottom + 1 });
      }
      if (overlappingNode.minX < left) {
        insertFragmentNode(overlappingNode, {
          minY: Math.max(overlappingNode.minY, top),
          maxY: Math.min(overlappingNode.maxY, bottom),
          maxX: left - 1,
        });
      }
      if (overlappingNode.maxX > right) {
        insertFragmentNode(overlappingNode, {
          minY: Math.max(overlappingNode.minY, top),
          maxY: Math.min(overlappingNode.maxY, bottom),
          minX: right + 1,
        });
      }
      this._cells._rtree.remove(overlappingNode);
      overlappingNode.valid = false;
    }
    if (right === left && top === bottom && overlappingRangeWrites.length) {
      // Targeting a single cell address, which was overlapped by range writes.
      // There may have been a cell there, in which case:
      // 1. The cell may not be covered by any R-tree node; if so, create one.
      // 2. The cell may have a neutralized formula; if so, reinstate it.
      const cell = this._cells._cells.get(createKey(top, left));
      if (cell) {
        const cellNode = { cell, minX: left, maxX: left, minY: top, maxY: top };
        if (this._cells._rtree.search(cellNode).length === 0) {
          this._cells._rtree.insert(cellNode);
        }
        if (cell.neutralizedFormula) {
          cell.f = cell.neutralizedFormula;
          cell.neutralizedFormula = null;
          return cell;
        }
      }
    }
  }

  makeCell (cellLocation: { top: number, left: number }): Cell {
    // XXX _writeCellData is an edit operation but makeCell is used in transient
    // writes (though it sounds ambiguous as to whether it might have persisted
    // effects or not). We are writing no actual properties to the cell, just
    // using _writeCellData for the side effect of making the cell instance
    // exist in the cell storage layer, and maybe this has no unwanted effects
    // currently. But this should be done more explicitly and not using an edit
    // operation. We should firm up the distinction between edits (which get
    // persisted) and transient state (which does not).
    return this._writeCellData(cellLocation, {}).cell;
  }

  /**
   * Write the given cell data attributes (`.v`, `.f`, etc.) to an existing or new cell at the given ID.
   * NOTE: clients should update cells using `Workbook.writeCellData`; this should be called only from `Workbook`.
   * If cell value is an error value, normalize it to the corresponding the singleton error value in `errorTable`.
   * If the cell has a formula, it is parsed and (if parsing succeeds) the AST is stored in the cell object.
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * @param cellLocation zero-based sheet coordinates of cell to write
   * @param cellData object with attributes to write to the cell
   * @param ctx evaluation context (for looking up lambdas in formula, to not misreport calls to unsupported functions)
   * @returns the existing or new cell, and any other ranges which may have been
   *   affected by masking/unmasking
   * @throws {Error} if precondition is not satisfied (the formula parser has not finished importing)
   */
  _writeCellData (
    cellLocation: { top: number, left: number },
    cellData: CellCSF,
    ctx?: EvaluationContext,
  ): { cell: Cell, changedRanges: Range[] } {
    assertParserLoaded();
    const { cell, changedRanges } = this._cells.writeCellDataByRange(cellLocation, cellData);
    // Update and validate formula regardless of whether it actually changed
    // (because this simplifies update/cleanup of existing `Workbook.errors`)
    if (cellData.f) {
      // We have no `lazyImports` map to pass, in this context, but that is OK
      // because this call only happens in edit mode, where all lazy-loaded
      // function modules are imported proactively on initialization.
      this._updateParsedFormula(cell, ctx);
    }

    return { cell, changedRanges };
  }

  /**
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * NOTE: this function does not assert this precondition; callers must do so.
   */
  _updateParsedFormula (
    cell: Cell,
    ctx?: EvaluationContext,
    lazyImports?: Map<string, Promise<void>>,
    validateNow = true,
  ) {
    const handleError = (err: unknown) => this.emit('error', err);
    updateParsedFormula(cell, handleError, this._workbookType);
    if (validateNow) {
      validateFormula(cell, handleError, ctx, lazyImports);
    }
  }

  /**
   * @returns The places that got changed. This can include cells outside of
   *   `range`, which may have been changed in ways other than clearing them,
   *   because of spill ranges getting unblocked by the clearing of `range`.
   */
  clearCellsByRange (range: Range): (RangeVertexId | CellVertexId)[] {
    return this._cells.clear(range);
  }

  /**
   * @param column 0-based column index
   * @param width non-negative
   * @throws {Error} if column or width is negative, or column is not an integer
   */
  setColumnWidth (column: number, width: number) {
    if (!Number.isInteger(column) || column < 0) {
      throw new Error(`Column ${column} is invalid`);
    }
    if (width == null || width < 0) {
      throw new Error(`Cannot set width ${width} for column ${column}. Width must be a positive number`);
    }
    this.columns = changeColumnWidth(this.columns, column + 1, width);
  }

  /**
   * Adjust the columns attribute after insertion/deletion of one or more columns
   * @param column 0-based column index of the first inserted/deleted column
   * @param wasInserted true if inserted, false if deleted
   * @param count number of affected columns
   * @throws {Error} if column is negative or not an integer, or if count is less than 1 or not an integer
   */
  _updateColumnMetadata (column: number, wasInserted: boolean, count: number = 1) {
    if (!Number.isInteger(column) || column < 0) {
      throw new Error(`Column ${column} is invalid`);
    }
    if (!Number.isInteger(count) || count < 1) {
      throw new Error(`Count ${count} is invalid`);
    }
    if (wasInserted) {
      this.columns = insertColumnInfos(this.columns, column + 1, count);
    }
    else {
      this.columns = deleteColumnInfos(this.columns, column + 1, count);
    }
  }

  /**
   * @param rowNum 0-based row index
   * @param height non-negative
   * @returns true if visibility changed (the row height was previously 0 and now isn't, or vice versa)
   * @throws {Error} if rowNum is not present in the sheet, or if height is a negative number
   */
  _setRowHeight (rowNum: number, height: number): boolean {
    if (rowNum == null || rowNum < 0) {
      throw new Error(`Row ${rowNum} is invalid`);
    }
    if (height == null || height < 0) {
      throw new Error(`Cannot set height ${height} for row ${rowNum}. Height must be a positive number`);
    }
    const prev = this.row_heights[rowNum + 1];
    this.row_heights[rowNum + 1] = height;
    return (prev === 0) !== (height === 0);
  }

  /**
   * Populate this sheet with data from a CSF object.
   *
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * @param sheetData the CSF object representing the sheet
   * @param [ctx] context for looking up names (to not record calls to named lambdas as calls to unsupported functions)
   * @param [lazyImports] map to pass to
   *   validateFormula to keep track of dynamic-import promises for lazy-loaded
   *   function modules.
   * @param [willRecalc=true] if set to false, then formulas will not be parsed to save computation time, and work that
   *   cannot be done perfectly without recalc will be done imperfectly, as there will be no recalc
   * @throws {Error} if precondition is not satisfied (the formula parser has not finished importing)
   */
  setData (
    sheetData: SheetCSF,
    ctx?: EvaluationContext,
    lazyImports?: Map<string, Promise<void>>,
    willRecalc = true,
  ): Reference[] {
    if (!sheetData) {
      throw new Error('No data for Sheet.setData');
    }
    assertParserLoaded();
    if (sheetData.name) {
      this.name = sheetData.name;
    }
    this.hidden = !!sheetData.hidden;

    this.col_widths = { ...sheetData.col_widths };
    this.row_heights = { ...sheetData.row_heights };
    this.defaults = { ...sheetData.defaults };
    this.columns = sheetData.columns?.map(d => ({ ...d })) ?? [];
    this.show_grid_lines = sheetData.show_grid_lines !== false; // default is true

    // if will not recalc, then accept possibly incomplete spill ranges, as we can't complete them
    const missingOK = !willRecalc;

    const [ refsNeedingRecalc, haveBadValues ] = this._cells.setData(sheetData.cells, sheetData.gsdv ?? [], missingOK);
    if (haveBadValues.length) {
      this.emit(
        'error',
        ModelError.fromInvalidCellValueAt(
          haveBadValues.map(r => new CellVertexId(this.workbookKey, this.index, r.range.top, r.range.left)),
        ),
      );
    }
    if (lazyImports == null) {
      // just collect lazy-import promises into a throwaway map. This allows callers to not care about import promises
      // (simplifying tests), while still collecting them into a map internally to make each import() happen only once.
      lazyImports = new Map();
    }
    if (willRecalc) {
      for (const cell of this.iterFormulaCells()) {
        this._updateParsedFormula(cell, ctx, lazyImports, false);

        // Excel does not include 'href' in the CellCSF for cells with a
        // HYPERLINK formula, so we need to recalculate such cells so that
        // the 'href' attribute is set.
        //
        // Just checking for the presence of "HYPERLINK" somewhere in the
        // formula allows us to skip searching the AST. However, this can
        // return false positives (e.g. for a cell with a formula value
        // of `="HYPERLINK"`), but that should be very rare.
        if (cell.f && /\bHYPERLINK\b/i.test(cell.f)) {
          refsNeedingRecalc.push(new Reference(cell.id, { sheetName: this.name }));
        }
      }
    }

    this.drawings = sheetData.drawings || [];
    this.merged_cells = sheetData.merged_cells || [];
    this.prepareCellMerges();

    // @ArniDagur: Perhaps this should be an event that we emit using `this.emit`,
    // similar to errors. Could be useful in case cells need to be recalculated
    // under other circumstances. Also, "refsNeedingRecalc" seems kind of ad hoc
    // as a return value from a function called "setData".
    return refsNeedingRecalc;
  }

  prepareCellMerges () {
    this.merges = {};
    if (this.merged_cells?.length) {
      this.merged_cells.forEach(range => {
        const ref = new Reference(range, { sheetName: this.name });
        const anchor = ref.getCellId();
        const w = ref.width;
        const h = ref.height;
        this.merges[anchor] = [ w, h ];
        for (let r = 0; r < h; r++) {
          for (let c = 0; c < w; c++) {
            const row = ref.top + r;
            const col = ref.left + c;
            let cell = this.getCellByCoords(row, col, true);
            if (!cell) {
              // create a blank cell if cell does not exist
              // this allows clients to trace merge anchors
              cell = this._writeCellData(new Range({ top: row, left: col }), {}).cell;
            }
            cell.M = anchor;
          }
        }
      });
    }
  }

  /**
   * Find the next non-blank cell in the given direction relative to the given
   * cell. The search ignores the cell at `cellAddr`.
   * @param cellAddr Cell which search is relative to
   * @param direction Direction relative to `cellAddr` to search in; can be 'left', 'right', 'up', 'down',
   *   or case variations of those.
   * @returns Non-blank cell or null
   */
  nextValueCellByID (cellAddr: string, direction: string): Cell | null {
    direction = direction.toLowerCase();
    invariant(direction === 'up' || direction === 'down' || direction === 'left' || direction === 'right');
    return this._cells.nextValueCellByID(cellAddr, direction);
  }

  /**
   * Find the next non-blank cell in the given direction relative to (row, col).
   * The search ignores the cell at exacly (row, col).
   * @param row Row index
   * @param col Column index
   * @param direction Direction relative to (row, col) to search in; can be 'left', 'right', 'up', 'down',
   *   or case variations of those.
   * @returns Non-blank cell or null
   */
  nextValueCellByCoords (row: number, col: number, direction: string): Cell | null {
    direction = direction.toLowerCase();
    invariant(direction === 'up' || direction === 'down' || direction === 'left' || direction === 'right');
    return this._cells.nextValueCellByCoords(row, col, direction);
  }

  /**
   * Find the next boundary between an empty and non empty cell in the given
   * direction relative to the given cell.
   * @param cellAddr Cell which search is relative to
   * @param direction Direction relative to `cellAddr` to search in; can be 'left', 'right', 'up', 'down',
   *   or case variations of those.
   */
  nextBoundaryByID (cellAddr: string, direction: string): ReturnType<Cells['nextBoundaryByID']> {
    direction = direction.toLowerCase();
    invariant(direction === 'up' || direction === 'down' || direction === 'left' || direction === 'right');
    return this._cells.nextBoundaryByID(cellAddr, direction);
  }

  /**
   * Find the next boundary between an empty and non empty cell in the given
   * direction relative to (row, col).
   * @param direction Direction relative to (row, col) to search in; can be 'left', 'right', 'up', 'down',
   *   or case variations of those.
   */
  nextBoundaryByCoords (row: number, col: number, direction: string): ReturnType<Cells['nextBoundaryByCoords']> {
    // @ts-expect-error
    return this._cells.nextBoundaryByCoords(row, col, direction.toLowerCase());
  }

  * iterValueCellsInColumn (columnIndex: number, maxRow = Infinity) {
    let cell = this.getCellByCoords(0, columnIndex) ?? this.nextValueCellByCoords(0, columnIndex, 'down');
    while (cell) {
      const row = new Reference(cell.id).top;
      if (row > maxRow) {
        return;
      }
      if (cell.v != null) {
        yield cell;
      }
      // TODO: Only perform a single R-Tree search
      cell = this.nextValueCellByCoords(row, columnIndex, 'down');
    }
  }

  _convertToStructuredSheet (table: TableCSF): void {
    this._tableStructure = {
      name: table.name,
      columns: table.columns.map((column, i) => {
        const columnId = colFromOffs(i);
        return { columnId, name: column.name, dataType: column.data_type || 'unknown' };
      }),
    };
  }

  get tableName (): string | null {
    return this._tableStructure?.name ?? null;
  }

  getColumns () {
    return this._tableStructure?.columns || [];
  }
}

/**
 * @see https://github.com/GRID-is/Apiary/pull/849#discussion_r1006937268
 * @returns true if sheetName is valid, else false
 */
export function validateSheetName (sheetName: string): boolean {
  return typeof sheetName === 'string' && sheetName.length > 0 && !/['\r\n\t[\]*\\:/?]/.test(sheetName);
}

/**
 * Check whether the given cell's formula calls a spreadsheet function that is
 * unsupported or needs lazy-loading, and act accordingly.
 * @param cell a formula cell
 * @param reportUnsupported function to report unsupported function
 * @param [lazyImports] map to pass to validateFunctionCall to keep track of
 *   import promises for lazy-loaded function modules
 * @param [inDefinedName=false] false (the default) if this is a sheet cell formula, true if defined name
 */
export function validateFormula (
  cell: Cell,
  reportError: (error: ModelError) => void,
  ctx: EvaluationContext | undefined,
  lazyImports: Map<string, Promise<void>> | undefined,
) {
  const ast = cell._ast;
  const reportUnsupported = (
    what: string,
    willNotSupport: boolean,
    isOrAre: 'is' | 'are' | undefined,
    type: string | undefined,
  ): void => {
    const vertexId = cellToVertexId(cell);
    reportError(ModelError.fromUnsupported(what, willNotSupport, vertexId, isOrAre, type));
    invariant(isNonLiteralNode(ast));
    ast.callsUnsupportedFunction = true;
  };
  visitAllNonLiteralNodes(ast, node => {
    if ('call' in node) {
      if (typeof node.call !== 'string') {
        // Lambda call; nothing to report, the visit function will recurse into
        // its expression and args subnodes and report anything there.
        return;
      }
      const funcName = node.call.toUpperCase();
      // Report if the function is unsupported, and trigger lazy-load if the import requires lazy-loading
      validateFunctionCall(funcName, reportUnsupported, ctx, lazyImports);
      // Check for context dependence (ROW()/COLUMN() or INDIRECT calls) in
      // defined-name formula and mark if that's found. Such a formula in a
      // defined name needs to be evaluated afresh each time a reference to the
      // defined name is evaluated. (Strictly speaking, a simple unprefixed cell
      // reference would cause this context dependence as well, but both Excel
      // and Google Sheets prevent you from creating those, they always inject
      // the sheet prefix when you create the name. So let's not do the work of
      // hunting for those.)
      if (isContextDependentCall(funcName, node.args)) {
        // Mark it on the AST rather than the cell object, as (a) it _is_ an attribute of the formula rather than
        // of the cell, and (b) the more uniform we can make Cell objects, the better we can help the Javascript
        // engine optimize memory usage and accesses (hoping to use Object.preventExtensions or Object.seal
        // sometime soon, to that end.)

        // Root must be a non-literal since getAllNonLiteralNodes yielded a node
        invariant(isNonLiteralNode(ast));
        ast.isContextDependent = true;
      }
    }
  });
}

/**
 * Helper function for adjusting an array of run-length encoded column information when inserting one or more
 * columns. The new columns should inherit the current width (if set) of the column at the target index before insertion.
 * @param columns sorted by the begin attribute of ColumnInfo
 * @param column 1-based integer offset
 * @param count non-negative, how many columns should be inserted
 */
export function insertColumnInfos (columns: ColumnInfo[], column: number, count: number = 1): ColumnInfo[] {
  let shiftIndices = false;
  const ret: ColumnInfo[] = [];
  for (let i = 0; i < columns.length; i++) {
    const { begin, end, width, si } = columns[i];
    ret[i] = { begin, end, width, si };
    if (begin > column && !shiftIndices) {
      // we have gone past it, but the target column has no width. We only need to shift the rest of the indices by 1
      shiftIndices = true;
    }
    if (begin <= column && column <= end) {
      shiftIndices = true;
      ret[i].end += count;
    }
    else if (shiftIndices) {
      ret[i] = { begin: begin + count, end: end + count, width, si };
    }
  }
  return ret;
}

/**
 * @param columns original column metadata
 * @param from 0-based index of first column being moved
 * @param to 0-based index where that first column should end up
 * @param count the number of columns being moved
 * @returns updated column metadata
 */
export function updateColumnInfosOnMoveColumns (
  columns: ColumnInfo[],
  from: number,
  to: number,
  count: number,
): ColumnInfo[] {
  const transformSpan = createSpanTransformerForMoveColumnInfos(from, to, count);
  const out: ColumnInfo[] = [];
  for (const col of columns) {
    // Convert 1-based span to 0-based for the sake of our collective sanity
    const spans = transformSpan(col.begin - 1, col.end - 1);
    for (const [ begin, end ] of spans) {
      out.push({ ...col, begin: begin + 1, end: end + 1 });
    }
  }
  return out.sort((a, b) => a.begin - b.begin);
}

/**
 * @param rowHeights original row heights
 * @param from 0-based index of first row being moved
 * @param to 0-based index where that first row should end up
 * @param count the number of rows being moved
 * @returns updated row heights
 */
export function updateRowHeightsOnMoveRows (
  rowHeights: Record<number, number>,
  from: number,
  to: number,
  count: number,
): Record<number, number> {
  const transformSpan = createSpanTransformerForMoveColumnInfos(from, to, count);
  const out: Record<number, number> = {};
  for (const row of Object.keys(rowHeights).map(Number)) {
    const spans = transformSpan(row - 1, row - 1); // Keys are 1-based
    invariant(spans.length === 1);
    const [ begin, end ] = spans[0];
    invariant(begin === end);
    out[begin + 1] = rowHeights[row];
  }
  return out;
}

function createSpanTransformerForMoveColumnInfos (
  from: number,
  to: number,
  count: number,
): (begin: number, end: number) => Array<[number, number]> {
  // All column moves can be described in terms of two spans:
  //
  //    - A "shift left" span
  //    - A "shift right" span
  //
  // For example, moving C:D to F:G can be described like so:
  //
  //             >>>>  <<<<<<<
  //     [ 1, 2, 3, 4, 5, 6, 7, 8, 9 ];
  //     [ 1, 2, 5, 6, 7, 3, 4, 8, 9 ];
  //
  // This holds when moving left AND when moving right, but the calculations
  // are a bit different (hence the ternaries).

  if (from > to) {
    // Since leftward moves are symmetrical to rightward moves, we
    // only implement `from < to` and translate the `from > to` case
    // into the `from < to` case.
    [ from, to, count ] = [ to, from - (from - to - count), from - to ];
  }

  const shiftRightStart = from;
  const shiftRightEnd = from + count - 1;
  const shiftLeftStart = shiftRightEnd + 1;
  const shiftLeftEnd = to + count - 1;
  const shiftLeft = -count;
  const shiftRight = to - from;

  return (begin, end) => {
    function shift ([ begin, end ]: [number, number]): [result: [number, number], next: null | [number, number]] {
      if (begin <= shiftRightStart && end >= shiftLeftEnd) {
        // Span contains both shift spans.
        return [ [ begin, end ], null ];
      }

      for (const [ shiftStart, shiftEnd, shift ] of [
        [ shiftLeftStart, shiftLeftEnd, shiftLeft ],
        [ shiftRightStart, shiftRightEnd, shiftRight ],
      ]) {
        if (begin >= shiftStart && begin <= shiftEnd && end > shiftEnd) {
          //          [ current span ]
          // [ shift span ]
          const w = shiftEnd - begin;
          const nextBegin = begin + shift;
          return [
            [ nextBegin, nextBegin + w ],
            [ shiftEnd + 1, end ],
          ];
        }
        if (end >= shiftStart && end <= shiftEnd && begin < shiftStart) {
          // [ current span ]
          //           [ shift span ]
          const w = end - shiftStart;
          const nextBegin = shiftStart + shift;
          return [
            [ nextBegin, nextBegin + w ],
            [ begin, shiftStart - 1 ],
          ];
        }
      }

      if (end < shiftRightStart || begin > shiftLeftEnd) {
        return [ [ begin, end ], null ];
      }

      if (end <= shiftRightEnd) {
        // Inside of shift right span
        invariant(begin >= shiftRightStart);
        return [ [ begin + shiftRight, end + shiftRight ], null ];
      }

      // Inside of shift left span
      invariant(begin >= shiftLeftStart && end <= shiftLeftEnd);
      return [ [ begin + shiftLeft, end + shiftLeft ], null ];
    }

    const out: Array<[number, number]> = [];
    let curr: [number, number] | null = [ begin, end ];

    while (curr) {
      const [ result, next ] = shift(curr);
      out.push(result);
      curr = next;
    }

    return out;
  };
}

/**
 * Helper function for adjusting an array of run-length encoded column information when deleting one or more columns.
 * @param columns sorted by the begin attribute of ColumnInfo
 * @param column 1-based integer offset
 * @param count non-negative, how many columns should be deleted
 */
export function deleteColumnInfos (columns: ColumnInfo[], column: number, count: number = 1): ColumnInfo[] {
  let shiftIndices = false;
  const ret: ColumnInfo[] = [];
  let j = 0;
  for (const { begin, end, width, si } of columns) {
    if (begin > column && !shiftIndices) {
      // we have gone past it, but the target column has no width. We only need to shift the rest of the indices by 1
      shiftIndices = true;
    }
    if (column <= begin && end < column + count) {
      shiftIndices = true;
      // this item should be completely removed
      continue;
    }
    if (begin <= column && column <= end) {
      shiftIndices = true;
      const distanceFromEnd = end - column + 1;
      const newEnd = distanceFromEnd < count ? end - distanceFromEnd : end - count;
      ret[j] = { begin, end: Math.max(newEnd, 1), width, si };
    }
    else if (shiftIndices) {
      ret[j] = { begin: Math.max(begin - count, 1), end: Math.max(end - count, 1), width, si };
    }
    else {
      ret[j] = { begin, end, width, si };
    }
    j++;
  }
  return ret;
}

/**
 * Helper function for setting the column width for an array of run-length encoded column information.
 * @param columns sorted by the begin attribute of ColumnInfo
 * @param column 1-based integer offset
 * @param width non-negative
 */
export function changeColumnWidth (columns: ColumnInfo[], column: number, width: number): ColumnInfo[] {
  for (let i = 0; i < columns.length; i++) {
    const { begin, end, width: currentWidth, si } = columns[i];
    if (begin > column) {
      // we have gone past it
      if (begin === column + 1 && currentWidth === width) {
        // merge, as it is adjacent
        return [
          ...columns.slice(0, i),
          {
            begin: column,
            end,
            width,
            si,
          },
          ...columns.slice(i + 1),
        ];
      }
      else {
        // insert
        return [
          ...columns.slice(0, i),
          {
            begin: column,
            end: column,
            width,
          },
          ...columns.slice(i),
        ];
      }
    }
    else if (begin <= column && column <= end) {
      if (width === currentWidth) {
        // nothing needs to be done, the column has the same width already
        return columns;
      }
      else if (begin === column) {
        const colBefore = columns[i - 1];
        if (colBefore && colBefore.end === column - 1 && colBefore.width === width) {
          // merge with the adjacent column before
          return [
            ...columns.slice(0, i - 1),
            {
              begin: colBefore.begin,
              end,
              width,
              si: colBefore.si,
            },
            ...columns.slice(i + 1),
          ];
        }
        if (column < end) {
          return [
            ...columns.slice(0, i),
            {
              begin: column,
              end: column,
              width,
              si,
            },
            {
              begin: column + 1,
              end,
              width: currentWidth,
              si,
            },
            ...columns.slice(i + 1),
          ];
        }
        else {
          return [
            ...columns.slice(0, i),
            {
              begin: column,
              end: column,
              width,
              si,
            },
            ...columns.slice(i + 1),
          ];
        }
      }
      else if (end === column) {
        const nextColumn = columns[i + 1];
        if (nextColumn && nextColumn.begin === column + 1 && nextColumn.width === width) {
          // if the next column in the array is adjacent and with the same width, we merge these
          return [
            ...columns.slice(0, i),
            {
              begin,
              end: column - 1,
              width: currentWidth,
              si,
            },
            {
              begin: column,
              end: nextColumn.end,
              width,
              si: nextColumn.si,
            },
            ...columns.slice(i + 2),
          ];
        }
        return [
          ...columns.slice(0, i),
          {
            begin,
            end: column - 1,
            width: currentWidth,
            si,
          },
          {
            begin: column,
            end: column,
            width,
            si,
          },
          ...columns.slice(i + 1),
        ];
      }
      else {
        // break this column into three new ones, with a new item in between
        return [
          ...columns.slice(0, i),
          {
            begin,
            end: column - 1,
            width: currentWidth,
            si,
          },
          {
            begin: column,
            end: column,
            width,
            si,
          },
          {
            begin: column + 1,
            end,
            width: currentWidth,
            si,
          },
          ...columns.slice(i + 1),
        ];
      }
    }
  }
  // We reached the end without finding a place for the column.
  // So add a span to the end (or merge into last span if adjacent).
  const lastCol = columns[columns.length - 1];
  if (lastCol && lastCol.end + 1 === column && lastCol.width === width) {
    // we can merge it with the last column
    return [ ...columns.slice(0, columns.length - 1), { begin: lastCol.begin, end: column, width, si: lastCol.si } ];
  }
  return [ ...columns, { begin: column, end: column, width } ];
}

/**
 * Precondition: formula parser has finished importing (`formulaParserReady` has
 * resolved).
 * @param cell a cell object representing a global defined name (that
 *   restriction is relevant so that `cell.id` is its complete cell address,
 *   because that assumption is made here.)
 * @param [lazyImports] map to pass to `validateFormula` for tracking imports of
 *   lazy-loaded function modules
 */
export function updateParsedFormula (cell: Cell, reportError: (error: ModelError) => void, workbookType: WorkbookType) {
  try {
    // @ts-expect-error parseFormula is ensured non-null by the documented precondition
    cell._ast = parseFormula(cell.f);
  }
  catch (err) {
    cell._ast = null;
    const vertexId = cellToVertexId(cell);
    invariant(err instanceof Error);
    const errorType = 'type' in err ? String(err.type) : 'formula-parse';
    reportError(new ModelError(err.message, ModelError.WARNING, vertexId, errorType));
  }
  if (workbookType === 'google-sheets' && cell.ft !== 'a' && googleFormulaIsProbablyArrayType(cell._ast)) {
    cell.ft = 'a';
  }
}

/**
 * Functions which, when wrapped in a top-level IFERROR call in a Google Sheets
 * formula, typically indicate that the formula was of array type.
 */
const GOOGLE_IFERROR_WRAPPED_ARRAYFORMULA_FUNCTIONS = [ 'VLOOKUP', 'HLOOKUP' ];

function googleFormulaIsProbablyArrayType (ast: ASTRootNode): boolean {
  if (isLiteralNode(ast)) {
    return false;
  }
  if (
    isFunctionCallNode(ast) &&
    (ast.call.toUpperCase() === 'ARRAYFORMULA' ||
      (ast.call.toUpperCase() === 'IFERROR' &&
        hasFunctionCallNode(ast.args[0], n =>
          GOOGLE_IFERROR_WRAPPED_ARRAYFORMULA_FUNCTIONS.includes(n.call.toUpperCase()),
        )))
  ) {
    return true;
  }
  if (hasFunctionCallNode(ast, isAggrCallWithArrayOpsInside)) {
    return true;
  }
  return false;
}
