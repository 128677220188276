// For use with `Array.sort`
export function ascending (a: number, b: number) {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return a >= b ? 0 : NaN;
}
