/**
 * Formula parser function, if formula parser has finished (asynchronously) importing, else null.
 * This can be called from synchronous code which itself is guaranteed to be executed only after the formula parser
 * has finished importing. The caller is then responsible for enforcing that guarantee, and/or documenting that _its_
 * callers must enforce it, and/or guarding against this being `null` with appropriate error handling.
 */
/**
 * @typedef {function(string): ASTNode} FnParseFormula
 */

/**
 * @type {FnParseFormula | null} parseFormula
 */
export let parseFormula = null;

/**
 * @type {ReplaceRefsOnMoveFn | null}
 */
export let replaceRefsOnMove = null;

/**
 * @type {ReplaceWorkbookReferencesFn | null}
 */
export let replaceWorkbookReferences = null;

/**
 * @type {ReplaceSheetReferencesFn | null}
 */
export let replaceSheetReferences = null;

/**
 * @type {ReplaceTableReferencesFn | null}
 */
export let replaceTableReferences = null;

/**
 * Promise which can be awaited in asynchronous code to ensure that the formula parser has finished importing,
 * and obtain the resolved `formulaParser/parser.js` module object.
 * @type {Promise<{
 *   parse: FnParseFormula,
 *   replaceRefsOnMove: ReplaceRefsOnMoveFn,
 *   replaceSheetReferences: ReplaceSheetReferencesFn,
 *   replaceWorkbookReferences: ReplaceWorkbookReferencesFn,
 * }>}
 */
export const ready = import('./parser.js').then(parser => {
  parseFormula = parser.parse;
  replaceRefsOnMove = parser.replaceRefsOnMove;
  replaceSheetReferences = parser.replaceSheetReferences;
  replaceTableReferences = parser.replaceTableReferences;
  replaceWorkbookReferences = parser.replaceWorkbookReferences;
  return parser;
});

/**
 * Throw an Error if formula parser import has not been completed.
 * @throws {Error} if the `parseFormula` export is still null.
 */
export function assertParserLoaded () {
  if (parseFormula == null) {
    throw new Error('Precondition failed: formula parser must have finished importing before this is called');
  }
}
