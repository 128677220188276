class FormulaError extends Error {
  detail: string | null = null;
  // FIXME: This causes a memory leak. This memoization needs to be Model or Workbook scoped.
  static detailedErrors: Map<string, FormulaError> = new Map();
  code: number;

  /**
   * @param message the error message
   * @param code integer error code
   */
  constructor (message: string, code: number) {
    super(message);
    this.code = code;
  }

  valueOf () {
    return this.message;
  }

  toString () {
    return this.message;
  }

  /**
   * Get an instance of this error type with the given detail, available as the `.detail` property.
   *
   * Intended usage:
   * * Instead of using ERROR_FOO, use ERROR_FOO.detailed(detailText) to include detail specific to this error
   * * When displaying an error, expose `.detail` if and as appropriate (e.g. only in edit mode?)
   * * The detail text should be short and oriented towards helping authors understand how their model goes wrong,
   *   e.g. for ERROR_NAME the detail text might be the function name or defined name that wasn't recognized.
   * * Apiary internals should not be exposed (e.g. exception messages, except where known to be end-user-friendly)
   *
   * Error instances created this way are memoized, so that only one error instance with a given code, message and
   * detail will be created.
   *
   * @param detail some textual detail about the error, e.g. the unrecognized function name for #NAME?
   */
  detailed (detail: string): FormulaError {
    const key = `${this.code},${this.message},${detail}`;
    const existing = FormulaError.detailedErrors.get(key);
    if (existing) {
      return existing;
    }
    const ret = new FormulaError(this.message, this.code);
    ret.detail = detail;
    FormulaError.detailedErrors.set(key, ret);
    return ret;
  }

  get detailedMessage () {
    return this.detail == null ? this.message : `${this.message} (${this.detail})`;
  }
}

export default FormulaError;
