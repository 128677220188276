import Range, { UNBOUNDED_BOTTOM, UNBOUNDED_RIGHT } from './Range.js';
import { MAX_COL, MAX_ROW } from '../constants.js';
import { offsFromCol } from './a1.js';
import pkg from 'lru_map';
const { LRUMap } = pkg;

export const QUOT = "'";
export const BANG = '!';
const BRACKET_OPEN = '[';
const BRACKET_CLOSE = ']';
const DOLLAR = '$';
const SPACE = ' ';

/**
 * Determine if a prefix part should cause quotes to be added around the prefix when serialized
 * @param {string} r
 * @return {boolean}
 */
export function needsQuoting (r) {
  return !!r && /[^0-9A-Za-z._¡¤§¨ª\u00ad¯-\uffff]/.test(r);
}

/**
 * Check whether the given string contains any control characters as defined by the Unicode Cc property
 * @param {string} s the string to check
 * @returns {boolean} true if `s` contains a character with the Unicode Cc (control character) property
 */
function containsAnyUnicodeControlCharacter (s) {
  // eslint-disable-next-line no-control-regex
  return /[\u0000-\u001f\u007f-\u009f]/u.exec(s) !== null;
}

/**
 * Check whether the given string is a valid R1C1 address
 * @param {string} upperCaseString the string to check; must be upper-cased
 * @returns {boolean} true if `upperCaseString` is a valid R1C1 address
 */
function isR1C1Address (upperCaseString) {
  return /^(?:R\d+(?:C\d+)|C\d+(?:R\d+))$/.test(upperCaseString);
}

export function splitRef (refStr) {
  refStr = String(refStr || '');
  let inStr = false;
  let inBracket = false;
  let hasDollar = false;
  for (let pos = 0; pos < refStr.length; pos++) {
    const char = refStr[pos];
    if (char === BANG) {
      if (!pos) {
        return null;
      }
      // once we have a ! that is not in a string, it is presumed to be the divider
      if (!inStr) {
        let prefix = refStr.slice(0, pos);
        // was the prefix quoted?
        if (refStr[0] === QUOT) {
          if (refStr[pos - 1] === QUOT) {
            prefix = prefix.slice(1, -1).replace(/''/g, QUOT);
          }
          else {
            return null;
          }
        }
        if (hasDollar) {
          return null;
        }
        return [ prefix, refStr.slice(pos + 1) ];
      }
    }
    else if (char === QUOT) {
      if (inStr && refStr[pos + 1] === QUOT) {
        // escaped quote
        pos++;
      }
      else if (inStr) {
        inStr = false;
      }
      // this should not really not be happening unless at index 0
      else if (pos === 0) {
        inStr = true;
      }
      else {
        return null;
      }
    }
    else if (char === BRACKET_OPEN) {
      inBracket = true;
    }
    else if (char === BRACKET_CLOSE) {
      inBracket = false;
    }
    else if (char < SPACE) {
      // all characters below ' ' are disallowed (\n, \r, \t, etc.)
      return null;
    }
    else if (char === DOLLAR) {
      // $ is allowed in A1 syntax, but not in unquoted prefixes
      hasDollar = true;
    }
    else if (char === ' ' || char === '"') {
      // " and SPACE are only allowed in brackets or quoted
      if (!inBracket && !inStr) {
        return null;
      }
    }
    // $ and : are allowed in A1 part
    else if (/^[\t!`'#%&(){}<>,;^@|~=+-]$/.test(char) && !inStr) {
      return null;
    }
  }

  // string has been scanned and no ! found
  return [ '', refStr ];
}

function splitPrefixA1 (prefix, ref) {
  // split by pathPrefix[filename]sheetname
  const pathBits = prefix.split(/\[([^[\]*]*)\]/);
  if (pathBits.length === 3) {
    if (pathBits[0] && !/[/\\]$/.test(pathBits[0])) {
      // dir prefix does not end in a path separator, Excel's dir prefixes do (or at least Excel shows them that way)
      return false;
    }
    if (!pathBits[1] || !pathBits[2]) {
      // workbook name or sheet name empty, that's invalid
      return false;
    }
    ref.dir = pathBits[0] || '';
    ref.workbookName = pathBits[1] || '';
    ref.sheetName = pathBits[2] || '';
  }
  else if (pathBits.length === 1) {
    ref.sheetName = prefix;
  }
  else {
    return false;
  }

  if (ref.sheetName.length > 31 || /[:\\/?*[\]]/.test(ref.sheetName)) {
    // sheet name cannot contain any of: :\/?*[]
    // sheet name cannot exceed 31 characters.
    return false;
  }

  return true;
}

// See https://docs.microsoft.com/en-us/openspecs/office_standards/ms-xlsx/3d025add-118d-4413-9856-ab65712ec1b0
// in particular:
// A1-reference = (A1-column ":" A1-column) / (A1-row ":" A1-row) / A1-cell / A1-area
// A1-cell = A1-column A1-row
// A1-area = A1-cell ":" A1-cell
// A1-column = A1-relative-column / A1-absolute-column
// A1-relative-column = 1*2letter / A-to-W 2letter / "X" A-to-E letter / "XF" A-to-D
// A-to-D = %x41-44 / %x61-64
// A-to-E = A-to-D / "E"
// A-to-W = %x41-57 / %x61-77
// letter = %x41-5A / %x61-7A
// A1-absolute-column = "$" A1-relative-column
// A1-row = A1-relative-row / A1-absolute-row
// A1-relative-row = row-digit-sequence
// row-digit-sequence = nonzero-decimal-digit *5decimal-digit /  "10" %x30-33 4decimal-digit / "104" %x30-37 3decimal-digit / "1048" %x30-34 2decimal-digit / "10485" %x30-36 decimal-digit / "104857" %x30-36
// A1-absolute-row = "$" A1-relative-row
//
// Their repetition syntax is not great (probably a lossy ascii-ification of something clearer):
// * by `1*2letter` they mean “either one or two instances of letter”
// * by `2letter` they mean “exactly two instances of letter”
// * by `*5decimal-digit` they mean “0 to five instances of decimal-digit”
const COL_CHARS = '[A-Z]{1,2}|[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]';
const ROW_CHARS = '[1-9][0-9]{0,5}|10[0-3][0-9]{4}|104[0-7][0-9]{3}|1048[0-4][0-9]{2}|10485[0-6][0-9]|104857[0-6]';
const COL = `(\\$?)(${COL_CHARS})`;
const ROW = `(\\$?)(${ROW_CHARS})`;
const COL_AND_ROW = `${COL}${ROW}`;
const CELL_RANGE_REGEX = new RegExp(`^${COL_AND_ROW}(?::${COL_AND_ROW})?$`);
const COLUMN_RANGE_REGEX = new RegExp(`^${COL}:${COL}$`);
const ROW_RANGE_REGEX = new RegExp(`^${ROW}:${ROW}$`);
const PARTIAL_COLUMN_RANGE_REGEX = new RegExp(`^${COL}(${ROW})?:${COL}(${ROW})?$`);
const PARTIAL_ROW_RANGE_REGEX = new RegExp(`^(${COL})?${ROW}:(${COL})?${ROW}$`);

const PARSE_CACHE = new LRUMap(20000);

export class ParsedReference {
  dir = '';
  workbookName = '';
  sheetName = '';
  /** @type {string | null} */
  name = null;
  /** @type {Range | null} */
  range = null;
  isA1 = false;
  isNamed = false;
  isRC = false;
  inverted = false;

  /** @param {Partial<ParsedReference>} data */
  constructor (data) {
    Object.assign(this, data);
  }
}

/**
 * This returns an object with a breakdown of the reference or a null if the reference wasn't valid.
 * @param {string} refStr The reference to parse
 * @returns {ParsedReference | null}
 */
export function parse (refStr = '') {
  // The LRU cache is meant as a temporary crutch while we reduce the number of calls to this method
  // during recalc. Once we've replaced the use of canonical cell Ids with something more compact/efficient,
  // we should consider removing the cache.
  let ref = PARSE_CACHE.get(refStr);
  if (typeof ref === 'undefined') {
    // Freeze the object to ensure parse results are not mutated and then reused
    ref = Object.freeze(parseUncached(refStr));
    PARSE_CACHE.set(refStr, ref);
  }
  return ref;
}

/**
 * @param {string} refStr
 * @returns {ParsedReference | null}
 */
function parseUncached (refStr) {
  const chunks = splitRef(String(refStr));
  if (!chunks) {
    return null;
  }

  const [ prefix, rest ] = chunks;

  const restUpper = rest.toUpperCase();

  /** @type {RegExpExecArray | null} */
  let bits;

  // $A$1 | $A$1:$A$1
  if ((bits = CELL_RANGE_REGEX.exec(restUpper))) {
    const top = parseInt(bits[4], 10) - 1;
    const left = offsFromCol(bits[2]);
    const $top = !!bits[3];
    const $left = !!bits[1];
    let bottom = top;
    let right = left;
    let $bottom = false;
    let $right = false;
    if (bits[6]) {
      bottom = parseInt(bits[8], 10) - 1;
      right = offsFromCol(bits[6]);
      $bottom = !!bits[7];
      $right = !!bits[5];
    }
    const range = new Range({ top, left, bottom, right, $top, $left, $bottom, $right });
    const ref = new ParsedReference({ range, isA1: true });
    const prefixOk = splitPrefixA1(prefix, ref);
    if (!prefixOk) {
      return null;
    }
    return ref;
  }

  const rowOrColumnRangeRef = parseRowOrColumnRange(prefix, restUpper);
  if (rowOrColumnRangeRef) {
    const prefixOk = splitPrefixA1(prefix, rowOrColumnRangeRef);
    if (!prefixOk) {
      return null;
    }
    return rowOrColumnRangeRef;
  }

  // named ranges, specified thusly in the MS formula grammar:
  // ```
  // name = name-start-character [ name-characters ]
  // name-start-character = underscore  /  backslash  /  letter / name-base-character
  // underscore = "_"
  // backslash = "\"
  // name-base-character = (any code points which are characters as defined by the Unicode character properties,
  //                       [UNICODE5.1] chapter 4 ; MUST NOT be 0x0-0x7F)
  // name-characters= 1*name-character
  // name-character = name-start-character / decimal-digit / full-stop  / questionmark
  // questionmark = "?"
  // letter = %x41-5A / %x61-7A
  // ;A name MUST NOT have any of the following forms:
  // ;TRUE or FALSE
  // ;cell-reference
  // ;function-list
  // ;command-list
  // ;future-function-list
  // ;R1C1-cell-reference
  // ```
  if (/^(?![RC]$|TRUE$|FALSE$)[A-Z_\\\u0080-\uFFFF][A-Z0-9._\\?\u0080-\uFFFF]{0,255}$/.test(restUpper)) {
    // Regex excludes TRUE and FALSE and lone R and C, and previous if-clauses caught any cell-reference (A1 address).
    // Follow-up checks that can't be neatly expressed within the above regular expression):
    if (containsAnyUnicodeControlCharacter(rest) || isR1C1Address(restUpper)) {
      return null;
    }
    // FIXME: we _should_ reject if rest matches function-list or command-list or future-function-list
    const ref = new ParsedReference({ name: rest });
    // handle prefix
    if (prefix) {
      const m = /^(.*[/\\])?(?:\[([^/\\[\]]+)\])?([^/\\[\]:?*"]*)$/.exec(prefix);
      if (m) {
        if (m[1] && m[2]) {
          // Both a path and square brackets, nope, apparently that is invalid 🤷‍♂️
          return null;
        }
        ref.dir = m[1] || '';
        ref.workbookName = m[2] || m[3];
        ref.sheetName = m[2] ? m[3] : '';
      }
      else {
        return null;
      }
    }
    return ref;
  }
  return null;
}

/**
 * @param {string} prefix
 * @param {string} restUpper
 * @returns {ParsedReference | null}
 */
function parseRowOrColumnRange (prefix, restUpper) {
  /** @type {RegExpExecArray | null} */
  let bits = null;
  /** @type {ParsedReference | null} */
  let ref = null;

  // $A:$A
  if ((bits = COLUMN_RANGE_REGEX.exec(restUpper))) {
    const top = 0;
    const bottom = MAX_ROW;
    const left = offsFromCol(bits[2]);
    const right = offsFromCol(bits[4]);
    const $left = !!bits[1];
    const $right = !!bits[3];
    const range = new Range({ top, left, bottom, right, $top: false, $left, $bottom: false, $right });
    ref = new ParsedReference({ range, isA1: true });
  }

  // $1:$1
  else if ((bits = ROW_RANGE_REGEX.exec(restUpper))) {
    const top = parseInt(bits[2], 10) - 1;
    const bottom = parseInt(bits[4], 10) - 1;
    const left = 0;
    const right = MAX_COL;
    const $top = !!bits[1];
    const $bottom = !!bits[3];
    const range = new Range({ top, left, bottom, right, $top, $left: false, $bottom, $right: false });
    ref = new ParsedReference({ range, isA1: true });
  }
  // $A$1:$A or $A:$A$1
  else if ((bits = PARTIAL_COLUMN_RANGE_REGEX.exec(restUpper))) {
    // Examples of `bits`:
    //    [ "B:B5",    "",  "B", null, null, null, "",  "B", "5",  "",   "5"  ]
    //    [ "B5:B",    "",  "B", "5",  "",   "5",  "",  "B", null, null, null ]
    //    [ "$B:$B$5", "$", "B", null, null, null, "$", "B", "$5", "$",  "5" ]
    //    [ "$B$5:$B", "$", "B", "$5", "$",  "5",  "$", "B", null, null, null ]
    //      0          1    2    3     4     5     6    7    8     9     10

    const $top = !!bits[4];
    const $bottom = !!bits[9];
    const $left = !!bits[1];
    const $right = !!bits[6];

    const left = offsFromCol(bits[2]);
    const right = offsFromCol(bits[7]);

    // Google considers A:A2 equivalent to A2:A, and so do we.
    const top = typeof bits[5] !== 'undefined' ? parseInt(bits[5], 10) - 1 : MAX_ROW;
    const bottom = typeof bits[10] !== 'undefined' ? parseInt(bits[10], 10) - 1 : MAX_ROW;
    const unbounded = UNBOUNDED_BOTTOM;

    const range = new Range({ top, left, bottom, right, $top, $left, $bottom, $right, unbounded });
    ref = new ParsedReference({ range, isA1: true });
  }
  // $A$1:$1 or $1:$A$1
  else if ((bits = PARTIAL_ROW_RANGE_REGEX.exec(restUpper))) {
    // Examples of `bits`:
    //    [ "B5:5",    "B",  "",   "B",  "",  "5", null, null, null, "",  "5" ]
    //    [ "5:B5",    null, null, null, "",  "5", "B",  "",   "B",  "",  "5" ]
    //    [ "$B$5:$5", "$B", "$",  "B",  "$", "5", null, null, null, "$", "5" ]
    //    [ "$5:$B$5", null, null, null, "$", "5", "$B", "$",  "B",  "$", "5" ]
    //      0          1     2     3     4    5    6     7     8     9    10

    const $top = !!bits[4];
    const $bottom = !!bits[9];
    const $left = !!bits[2];
    const $right = !!bits[7];

    const top = parseInt(bits[5], 10) - 1;
    const bottom = parseInt(bits[10], 10) - 1;

    // Google considers 1:B1 equivalent to B1:1, and so do we.
    const left = typeof bits[3] !== 'undefined' ? offsFromCol(bits[3]) : MAX_COL;
    const right = typeof bits[8] !== 'undefined' ? offsFromCol(bits[8]) : MAX_COL;
    const unbounded = UNBOUNDED_RIGHT;

    const range = new Range({ top, left, bottom, right, $top, $left, $bottom, $right, unbounded });
    ref = new ParsedReference({ range, isA1: true });
  }

  if (ref) {
    const prefixOk = splitPrefixA1(prefix, ref);
    if (!prefixOk) {
      return null;
    }
  }
  return ref;
}
