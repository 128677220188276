import {
  normal,
  gamma,
  beta,
  binomial,
  chisquare,
  centralF,
  studentt,
  exponential,
  tci,
  sum,
  normalci,
  gammafn,
  gammaln,
  lognormal,
  negbin,
  hypgeom,
  det,
  inv,
  corrcoeff,
  covariance,
  models,
  skewness,
  erf,
  erfc,
} from '@grid-is/jstat-esm';

export default {
  normal,
  gamma,
  beta,
  binomial,
  chisquare,
  centralF,
  studentt,
  exponential,
  tci,
  sum,
  normalci,
  gammafn,
  gammaln,
  lognormal,
  negbin,
  hypgeom,
  corrcoeff,
  covariance,
  models,
  skewness,
  erf,
  erfc,
  det: /** @type {(mx: number[][]) => number} */ (det),
  inv: /** @type {(mx: number[][]) => number[][]} */ (inv),
};
