import { ERROR_VALUE, ERROR_NUM, MISSING, BLANK, MODE_GOOGLE, MODE_EXCEL } from '../constants.js';
import { isErr, isMatrix, isRef, toNum } from './utils.js';
import { toDateEngineering, toNumEngineering } from './analysis-toolpak';
import { toYMD, serialDate, serialTime, parseDateTime } from './utils-number';
import { isNum, isStr } from '../../utils.js';
import { invariant } from '../../validation';
import { unbox, isBoxed } from '../ValueBox.js';
import { isDateFormat } from 'numfmt';

const weekTypes = {
  1: [ 0, 1 ],
  2: [ 6, 1 ],
  3: [ 6, 0 ],
  11: [ 6, 1 ],
  12: [ 5, 1 ],
  13: [ 4, 1 ],
  14: [ 3, 1 ],
  15: [ 2, 1 ],
  16: [ 1, 1 ],
  17: [ 0, 1 ],
};

/**
 * @param {string | number | FormulaError | Reference} time
 * @returns {number | FormulaError}
 */
function timeInput (time) {
  let ord = toNum(time, ERROR_NUM);
  if (ord === ERROR_NUM) {
    // @ts-expect-error (time cannot be a number since `toNum` returned `ERROR_NUM` for it)
    ord = parseDateTime(time);
  }
  return ord;
}

/**
 * @param {number} time
 * @returns {number}
 */
export function ISOWEEKNUM (time) {
  const ep = Math.floor(time) + 2415019;
  const a = (((ep + 31741 - (ep % 7)) % 146097) % 36524) % 1461;
  const b = a === 1460 ? 1 : 0;
  return Math.floor((((a - b) % 365) + b) / 7) + 1;
}

/**
 * @param {number} year
 * @param {number} month
 * @param {number} day
 * @returns {number | FormulaError}
 */
export function DATE (year, month, day) {
  return serialDate(year, month, day);
}

/**
 * @param {number} year
 * @returns {number}
 */
export function isLeapYear (year) {
  // https://stackoverflow.com/q/9852837
  return +!(year & 3 || (year & 15 && !(year % 25)));
}

/**
 * @param {number} year The year.
 * @param {number} month The number of the month where 1=January. Must be a positive number.
 *  Can be larger than 12, in which case the year will be adjusted.
 * @returns {number}
 */
function daysInMonth (year, month) {
  invariant(month > 0, 'Month must be a positive number');
  year += Math.floor((month - 1) / 12);
  const lookup = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ];
  if (month % 12 === 2 && isLeapYear(year)) {
    return 29;
  }
  const index = (month - 1) % 12;
  return lookup[index];
}

/**
 * Syntax:
 *     =DATEDIF (start_date, end_date, unit)
 * Arguments:
 *     start_date (Number):
 *       Start date in Excel date serial number format.
 *     end_date (Number):
 *       End date in Excel date serial number format.
 *     unit (String):
 *       The time unit to use (years, months, or days).
 * Return value (Number):
 *   A number representing time between two dates
 * @param {number} start
 * @param {number} end
 * @param {string} unit
 * @returns {number | FormulaError}
 */
export function DATEDIF (start, end, unit) {
  unit = unit.toUpperCase();
  start = Math.floor(start);
  end = Math.floor(end);
  if (start > end || start < 0 || end < 0) {
    return ERROR_NUM;
  }
  const [ sY, sM, sD ] = toYMD(start);
  let [ eY, eM, eD ] = toYMD(end);
  // Normalize such that eY >= sY, eM >= sM, eD >= sD. That makes our
  // calculations easier later on.
  if (sD > eD) {
    eM -= 1;
    if (eM === 0) {
      eY -= 1;
      eM = 12;
    }
    eD += daysInMonth(eY, eM);
  }
  if (sM > eM) {
    eY -= 1;
    eM += 12;
  }
  if (unit === 'Y') {
    return eY - sY;
  }
  else if (unit === 'M') {
    return (eY - sY) * 12 + eM - sM;
  }
  else if (unit === 'D') {
    return end - start;
  }
  else if (unit === 'MD') {
    return eD - sD;
  }
  else if (unit === 'YM') {
    return eM - sM;
  }
  else if (unit === 'YD') {
    let sum = 0;
    for (let month = sM; month < eM; month += 1) {
      sum += daysInMonth(sY, month);
    }
    sum += eD - sD;
    return sum;
  }
  return ERROR_NUM;
}

/**
 * @this {EvaluationContext | void}
 * @param {MaybeBoxed<string | number>} dateValue
 * @return {number | FormulaError}
 */
export function DATEVALUE (dateValue) {
  if (
    this?.mode !== MODE_EXCEL &&
    isBoxed(dateValue) &&
    isNum(dateValue) &&
    typeof dateValue.metadata.numberFormat === 'string' &&
    isDateFormat(dateValue.metadata.numberFormat)
  ) {
    return Math.floor(unbox(dateValue));
  }
  if (!isStr(dateValue)) {
    return ERROR_VALUE;
  }
  const d = parseDateTime(unbox(dateValue));
  return isNum(d) ? Math.floor(d) : d;
}

/**
 * Note: This function (in Excel) is not consistent about error handling:
 * - A1:B5 generates #VALUE!
 * - empty cell generates 0
 * - 0/1 generates 0
 * @param {number} time
 */
export function DAY (time) {
  const ord = Math.floor(time);
  if (!ord) {
    // Jan, 0. 1900
    return 0;
  }
  if (ord <= 60) {
    // <= Feb 29. 1900
    return ((ord - 1) % 31) + 1;
  }
  const ts = (ord - 25569) * 864e5;
  return new Date(ts).getUTCDate();
}

/**
 * @param {string | number} end_date
 * @param {string | number} start_date
 * @return {number | FormulaError}
 */
export function DAYS (end_date, start_date) {
  const min = timeInput(start_date);
  if (isErr(min)) {
    return min;
  }
  const max = timeInput(end_date);
  if (isErr(max)) {
    return max;
  }
  return max - min;
}

/**
 * Syntax:
 *     =DAYS360 (start_date, end_date, [method])
 * Arguments:
 *     start_date (Number):
 *       The start date.
 *     end_date (Number):
 *       The end date.
 *     method (Optional) (Boolean):
 *       Day count method. FALSE (Default) = US method, TRUE = European method.
 * Return value (Number):
 *   A number representing days.
 * @param {number} startDate
 * @param {number} endDate
 * @param {boolean | null} [isEurope]
 */
export function DAYS360 (startDate, endDate, isEurope) {
  if (isEurope == null) {
    isEurope = false;
  }
  if (startDate < 0 || endDate < 0) {
    return ERROR_NUM;
  }
  const sYMD = toYMD(startDate);
  const eYMD = toYMD(endDate);
  const [ sY, sM ] = sYMD;
  let sD = sYMD[2];
  const [ eY, eM ] = eYMD;
  let eD = eYMD[2];
  if (sD === 31 || (!isEurope && sM === 2 && sD === 28 + isLeapYear(sY))) {
    sD = 30;
  }
  if (eD === 31 && (isEurope || sD === 30)) {
    eD = 30;
  }
  return (eY - sY) * 360 + (eM - sM) * 30 + eD - sD;
}

/**
 * Same as EDATE except returns the date in `[y, m, d]` form rather than as a number.
 * @param {string | number | boolean | FormulaError | Reference | undefined} startDateRaw
 * @param {string | number | boolean | FormulaError | Reference | undefined} monthsRaw
 * @param {ModeBit} mode
 * @returns {[number, number, number] | FormulaError}
 */
function edateYMD (startDateRaw, monthsRaw, mode) {
  const startDate = toDateEngineering(startDateRaw, mode);
  if (isErr(startDate)) {
    return startDate;
  }
  const monthsOffFrac = toNumEngineering(monthsRaw, mode) ?? 0;
  if (isErr(monthsOffFrac)) {
    return monthsOffFrac;
  }
  let [ y, m, d ] = toYMD(startDate, mode !== MODE_GOOGLE);

  const monthsOff = Math.trunc(monthsOffFrac);
  if (monthsOff > 0) {
    y += Math.floor(monthsOff / 12);
    m += monthsOff % 12;
    if (m > 12) {
      y += 1;
      m -= 12;
    }
  }
  else {
    y -= Math.floor(-monthsOff / 12);
    const monthsOffMod12 = -monthsOff % 12;
    if (monthsOffMod12 >= m) {
      y -= 1;
      m += 12;
    }
    m -= monthsOffMod12;
  }

  if (y < 1900 && mode !== MODE_GOOGLE) {
    return ERROR_NUM;
  }

  d = Math.min(d, daysInMonth(y, m));
  return [ y, m, d ];
}

/**
 * Date a number of months before/after another date
 * =EDATE(start_date, months)
 * @this {EvaluationContext}
 * @param {string | number | boolean | FormulaError | Reference | undefined} startDateRaw
 * @param {string | number | boolean | FormulaError | Reference | undefined} monthsRaw
 * @returns {number | FormulaError}
 */
export function EDATE (startDateRaw, monthsRaw) {
  const ymd = edateYMD(startDateRaw, monthsRaw, this.mode);
  if (isErr(ymd)) {
    return ymd;
  }
  const [ y, m, d ] = ymd;
  return serialDate(y, m, d, this.mode);
}

/**
 * @this {EvaluationContext}
 * @param {string | number | boolean | FormulaError | Reference | undefined} start_date
 * @param {string | number | boolean | FormulaError | Reference | undefined} months
 * @returns {number | FormulaError}
 */
export function EOMONTH (start_date, months) {
  // We can use the result from EDATE to calculate EOMONTH. The month will
  // always be correct, we just need to fix the day of month.
  const ymd = edateYMD(start_date, months, this.mode);
  if (isErr(ymd)) {
    return ymd;
  }
  return serialDate(ymd[0], ymd[1], daysInMonth(ymd[0], ymd[1]), this.mode);
}

/**
 * @param {number} time
 * @return {number|FormulaError}
 */
export function HOUR (time) {
  if (time < 0) {
    return ERROR_NUM;
  }
  return Math.floor(time * 24 + 0.00001) % 24;
}

/**
 * @param {number} time
 * @return {number|FormulaError}
 */
export function MINUTE (time) {
  if (time < 0) {
    return ERROR_NUM;
  }
  return Math.floor(time * 24 * 60 + 0.00001) % 60;
}

/**
 * @param {number} date
 * @return {number|FormulaError}
 */
export function MONTH (date) {
  if (date < 0) {
    return ERROR_NUM;
  }
  if (date < 32) {
    return 1;
  }
  if (date < 61) {
    return 2;
  }
  return toYMD(date)[1];
}

/**
 * @returns {number}
 */
export function NOW () {
  return Date.now() / 864e5 + 25569;
}

/**
 * @param {number} time
 * @returns {number|FormulaError}
 */
export function SECOND (time) {
  if (time < 0) {
    return ERROR_NUM;
  }
  return Math.floor(time * 24 * 60 * 60 + 0.5) % 60;
}

/**
 * @param {number} hour
 * @param {number} minute
 * @param {number} second
 * @returns {number|FormulaError}
 */
export function TIME (hour, minute, second) {
  const d = serialTime(hour, minute, second);
  return isNum(d) ? d % 1 : d;
}

/**
 * @param {string | number} timetext
 * @returns {number | FormulaError}
 */
export function TIMEVALUE (timetext) {
  if (isNum(timetext)) {
    return ERROR_VALUE;
  }
  const d = parseDateTime(timetext);
  return isNum(d) ? d % 1 : d;
}

/**
 * @returns {number}
 */
export function TODAY () {
  return Math.floor(Date.now() / 864e5) + 25569;
}

const weekStart = {
  1: 0, // (default) Sunday
  2: 1, // Monday
  11: 1, // Monday
  12: 2, // Tuesday
  13: 3, // Wednesday
  14: 4, // Thursday
  15: 5, // Friday
  16: 6, // Saturday
  17: 0, // Sunday
  21: 1, // Monday -- ISO week
};

/**
 * @param {string | number | boolean | FormulaError | Reference | undefined} time
 * @param {string | number | boolean | FormulaError | Reference | undefined} return_type
 * @returns {number | FormulaError}
 */
export function WEEKNUM (time, return_type) {
  if (
    time === '' ||
    typeof time === 'boolean' ||
    time == null ||
    return_type === '' ||
    typeof return_type === 'boolean'
  ) {
    return ERROR_VALUE;
  }

  let ord = timeInput(time);
  if (isErr(ord)) {
    return ord;
  }
  ord = Math.floor(ord);
  const r = return_type == null ? 1 : toNum(return_type);
  if (isErr(r)) {
    return r;
  }
  if (ord >= 0 && r === 21) {
    return ISOWEEKNUM(ord);
  }
  else if (ord >= 0 && r in weekStart) {
    const [ year ] = toYMD(ord);
    const dow = weekStart[r];
    const jan1 = serialDate(year, 1, 1);
    invariant(typeof jan1 === 'number');
    return Math.floor((ord - jan1 + ((7 - dow + jan1 - 1) % 7)) / 7) + 1;
  }
  return ERROR_NUM;
}

/**
 * @param {number} time
 * @param {number} return_type
 * @returns {number | FormulaError}
 */
export function WEEKDAY (time, return_type) {
  time = Math.floor(time);
  const r = return_type == null ? 1 : toNum(return_type);
  if (isErr(r)) {
    return r;
  }
  if (!(r in weekTypes)) {
    return ERROR_NUM;
  }
  const shift = weekTypes[r][0];
  const base = weekTypes[r][1];
  return ((shift + 6 + time) % 7) + base;
}

/**
 * @param {number} time
 * @returns {number}
 */
export function YEAR (time) {
  if (time <= 60) {
    return 1900;
  }
  return toYMD(time)[0];
}

/**
 * YEARFRAC with stricter input and output types
 * @param {number} startDate
 * @param {number} endDate
 * @param {number} basis
 * @returns {number}
 */
export function yearfrac (startDate, endDate, basis) {
  if (startDate > endDate) {
    // Swap
    const temp = startDate;
    startDate = endDate;
    endDate = temp;
  }

  const sYMD = toYMD(startDate);
  const [ sY, sM ] = sYMD;
  let sD = sYMD[2];
  const eYMD = toYMD(endDate);
  const [ eY, eM ] = eYMD;
  let eD = eYMD[2];

  if ((basis & 3) === 0) {
    // basis in [0, 4]
    if (basis === 0) {
      // US convention
      if (sD >= 30 && eD === 31) {
        eD = 30;
      }
      if (sD === 31 || (sM === 2 && sD === 28 + isLeapYear(sY))) {
        sD = 30;
      }
    }
    else {
      // European convention (basis === 4)
      sD = Math.min(sD, 30);
      eD = Math.min(eD, 30);
    }
    return eY - sY + (eM - sM) / 12 + (eD - sD) / 360;
  }
  else if (basis === 1) {
    // https://github.com/PHPOffice/PhpSpreadsheet/blob/9b34f8746bc931d0a2e6785625050841171a23a5/src/PhpSpreadsheet/Calculation/DateTimeExcel/YearFrac.php#L100-L118
    let numDays;
    const sMonthDay = sM * 100 + sD;
    const eMonthDay = eM * 100 + eD;
    if (sY === eY) {
      numDays = 365 + isLeapYear(eY);
    }
    // Different years, but exactly one whole year or less inbetween.
    else if (sY + 1 === eY && sMonthDay >= eMonthDay) {
      if (isLeapYear(sY)) {
        numDays = 365 + +(sMonthDay <= 229);
      }
      else if (isLeapYear(eY)) {
        numDays = 365 + +(eMonthDay >= 229);
      }
      else {
        numDays = 365;
      }
    }
    else {
      numDays = 0;
      for (let year = sY; year <= eY; year += 1) {
        numDays += 365 + isLeapYear(year);
      }
      numDays /= eY - sY + 1;
    }
    return (endDate - startDate) / numDays;
  }
  else {
    // basis in [2, 3]
    return (endDate - startDate) / (basis === 2 ? 360 : 365);
  }
}

/**
 * @param {NonMatrixFormulaArgument} startDate The start date
 * @param {NonMatrixFormulaArgument} endDate The end date
 * @param {NonMatrixFormulaArgument} [basis] The type of day count
 *   basis to use (see below).
 *         | Basis       | Calculation   | Notes
 *         +-------------+---------------+--------------------
 *         | 0 (default) | 30/360        | US convention
 *         | 1           | actual/actual |
 *         | 2           | actual/360    |
 *         | 3           | actual/365    |
 *         | 4           | 30/360        | European convention
 * @returns {number | FormulaError} a decimal number
 */
export function YEARFRAC (startDate, endDate, basis) {
  // The order of the error conditionals is important to get the right error
  // value.
  let basisNum;
  if (basis === MISSING) {
    basisNum = 0;
  }
  else {
    basisNum = toNumEngineering(basis);
    if (isErr(basisNum)) {
      return basisNum;
    }
    basisNum = Math.floor(basisNum ?? 0);
    if (basisNum < 0 || basisNum > 4) {
      return ERROR_NUM;
    }
  }
  startDate = toDateEngineering(startDate);
  if (isErr(startDate)) {
    return startDate;
  }
  endDate = toDateEngineering(endDate);
  if (isErr(endDate)) {
    return endDate;
  }
  return yearfrac(startDate, endDate, basisNum);
}

/**
 * @param {string} maskString
 * @returns {Set<number> | FormulaError}
 */
function parseWeekendMask (maskString) {
  if (maskString.length !== 7) {
    return ERROR_VALUE;
  }
  const mask = new Set();
  for (let i = 0; i < 7; i += 1) {
    if (maskString[i] === '1') {
      mask.add((i + 2) % 7);
    }
    else if (maskString[i] !== '0') {
      return ERROR_VALUE;
    }
  }
  return mask;
}

// These are the weekdays you get by calculating `date % 7`.
const SATURDAY = 0;
const SUNDAY = 1;
const MONDAY = 2;
const TUESDAY = 3;
const WEDNESDAY = 4;
const THURSDAY = 5;
const FRIDAY = 6;

const NETWORKDAYS_WEEKENDS = {
  1: [ SATURDAY, SUNDAY ],
  2: [ SUNDAY, MONDAY ],
  3: [ MONDAY, TUESDAY ],
  4: [ TUESDAY, WEDNESDAY ],
  5: [ WEDNESDAY, THURSDAY ],
  6: [ THURSDAY, FRIDAY ],
  7: [ FRIDAY, SATURDAY ],
  11: [ SUNDAY ],
  12: [ MONDAY ],
  13: [ TUESDAY ],
  14: [ WEDNESDAY ],
  15: [ THURSDAY ],
  16: [ FRIDAY ],
  17: [ SATURDAY ],
};

/**
 * @param {NonMatrixFormulaArgument} value
 * @param {FormulaError} errorValue
 * @returns {Set<number> | FormulaError}
 */
function toWeekendMask (value, errorValue) {
  if (isStr(value)) {
    return parseWeekendMask(value);
  }
  value = toNumEngineering(value);
  if (isErr(value)) {
    // In case the weekend argument receives an error, we return #VALUE! or
    // #NUM! instead of propagating the error we got as input.
    return errorValue;
  }
  const lookupResult = NETWORKDAYS_WEEKENDS[value];
  if (lookupResult == null) {
    return ERROR_NUM;
  }
  return new Set(lookupResult);
}

/**
 * @param {NonMatrixFormulaArgument} startDate The start date
 * @param {NonMatrixFormulaArgument} endDate The end date
 * @param {string | number | FormulaError | undefined} [weekend] Setting for which days of the week should be considered
 *   weekends. This can be configured in one of two ways. Either by entering a number from the following table:
 *         | Weekend number | Weekend days
 *         +----------------+------------------------
 *         | 1 (default)    | Saturday, Sunday
 *         | 2              | Sunday, Monday
 *         | 3              | Monday, Tuesday
 *         | 4              | Tuesday, Wednesday
 *         | 5              | Wednesday, Thursday
 *         | 6              | Thursday, Friday
 *         | 7              | Friday, Saturday
 *         | 11             | Sunday only
 *         | 12             | Monday only
 *         | 13             | Tuesday only
 *         | 14             | Wednesday only
 *         | 15             | Thursday only
 *         | 16             | Friday only
 *         | 17             | Saturday only
 * @param {FormulaArgument} [holidays] A reference to dates that should be considered non-work day
 * @returns {number | FormulaError} The number of working days between the two dates
 */
export function NETWORKDAYS_INTL (startDate, endDate, weekend, holidays) {
  if (weekend === MISSING) {
    weekend = 1;
  }
  const weekendMask = toWeekendMask(weekend, ERROR_VALUE);
  if (isErr(weekendMask)) {
    return weekendMask;
  }
  startDate = toDateEngineering(startDate);
  if (isErr(startDate)) {
    return startDate;
  }
  endDate = toDateEngineering(endDate);
  if (isErr(endDate)) {
    return endDate;
  }
  let sign = 1;
  if (startDate > endDate) {
    [ startDate, endDate ] = [ endDate, startDate ];
    sign = -1;
  }
  // A naive implementation of this function would iterate over each day and
  // check if it is a weekend or holiday. This would take O(n) time for n =
  // |endDate - startDate|. Thankfully, we are working in a modular problem
  // domain (over modulus 7), which we can take advantage of to maka the
  // algorithm constant time.
  //   We first need to align the start date such that the difference between
  // the start and end dates is a multiple of 7.
  const originalStart = startDate;
  let numDays = 0;
  const desiredStartWeekday = (endDate + 1) % 7;
  let startWeekday = startDate % 7;
  while (startWeekday !== desiredStartWeekday && startDate < endDate) {
    numDays += +!weekendMask.has(startWeekday);
    startDate += 1;
    startWeekday = startDate % 7;
  }
  if (startDate === endDate) {
    numDays += +!weekendMask.has(startWeekday);
  }
  else {
    // Once the difference between the start and end dates is a multiple of 7,
    // we divide by 7 to get the number of whole weeks between the two dates,
    // and multiply that quantity with the number of workdays in each week.
    const numWholeWeeks = (endDate - (startDate - 1)) / 7;
    numDays += (7 - weekendMask.size) * numWholeWeeks;
  }
  startDate = originalStart;
  if (isErr(holidays)) {
    return holidays;
  }
  // An array containing a single string/number is treated differently from a
  // string/number argument:
  //   =NETWORKDAYS.INTL("2021-09-03", "2021-09-07", 3, {"2021-09-06"}) is 3.
  //   =NETWORKDAYS.INTL("2021-09-03", "2021-09-07", 3, "2021-09-06") is 2.
  // The difference is that a saturday/sunday weekend is always assumed,
  // instead of the users weekend mask being used. Why Microsoft?
  if (isStr(holidays) || isNum(holidays)) {
    holidays = toDateEngineering(holidays);
    if (isErr(holidays)) {
      return holidays;
    }
    numDays -= +(holidays >= startDate && holidays <= endDate && holidays % 7 > 1);
  }
  else if (isRef(holidays) || isMatrix(holidays)) {
    const holidayArray = holidays.toMatrix(false);
    if (isErr(holidayArray)) {
      return holidayArray;
    }
    for (let holiday of holidayArray.uniqueValues()) {
      if (isErr(holiday)) {
        return holiday;
      }
      if (isStr(holiday) || isNum(holiday)) {
        holiday = toDateEngineering(holiday);
        if (isErr(holiday)) {
          return holiday;
        }
        // Here we actually use the user's weekend mask, unlike in the non-array branch.
        numDays -= +(holiday >= startDate && holiday <= endDate && !weekendMask.has(holiday % 7));
      }
      else if (holiday != null) {
        return ERROR_VALUE;
      }
    }
  }
  else if (holidays != null) {
    return ERROR_VALUE;
  }
  return sign * numDays;
}

/**
 * @param {NonMatrixFormulaArgument} startDate The start date
 * @param {NonMatrixFormulaArgument} endDate The end date
 * @param {FormulaArgument} [holidays] A list of non-work days as dates
 * @returns {number | FormulaError} The number of working days between the two dates
 */
export function NETWORKDAYS (startDate, endDate, holidays) {
  return NETWORKDAYS_INTL(startDate, endDate, MISSING, holidays);
}

// Syntax:
//     =WORKDAY.INTL (start_date, days, [weekend], [holidays])
// Arguments:
//     start_date:
//       The start date.
//     days:
//       The end date.
//     weekend (Optional):
//       Setting for which days of the week should be considered weekends.
//       This argument works the same way as the weekend argument in
//       NETWORKDAYS.INTL.
//     holidays:
//       A list of one or more dates that should be considered non-work days.
// Return value:
//   Next or previous working date based on inputs
/**
 * @param {NonMatrixFormulaArgument} startDate The start date
 * @param {NonMatrixFormulaArgument} days The end date
 * @param {string | number | FormulaError | undefined} [weekend] Setting for which days of the week should be considered
 *   weekends. This argument works the same way as the weekend argument in NETWORKDAYS.INTL.
 * @param {FormulaArgument} [holidays] list of one or more dates that should be considered non-work days.
 * @returns {number | FormulaError} The number of working days between the two dates
 */
export function WORKDAY_INTL (startDate, days, weekend, holidays) {
  startDate = toDateEngineering(startDate);
  if (isErr(startDate)) {
    return startDate;
  }
  let daysNum = toNumEngineering(days);
  if (isErr(daysNum)) {
    return daysNum;
  }
  daysNum = Math.floor(daysNum ?? 0);
  if (startDate + daysNum < 0) {
    return ERROR_NUM;
  }
  if (weekend === MISSING) {
    weekend = 1;
  }
  const weekendMask = toWeekendMask(weekend, ERROR_NUM);
  if (isErr(weekendMask)) {
    return weekendMask;
  }
  if (weekendMask.size === 7) {
    return ERROR_VALUE;
  }

  /** @type {Set<ArrayValue>|ArrayValue[]} */
  let holidaysArray;
  if (isRef(holidays) || isMatrix(holidays)) {
    const holidaysMatrix = holidays.toMatrix(false);
    if (isErr(holidaysMatrix)) {
      return holidaysMatrix;
    }
    holidaysArray = Array.from(holidaysMatrix.uniqueValues());
  }
  else if (isStr(holidays) || isNum(holidays) || holidays === BLANK) {
    holidaysArray = [ holidays ];
  }
  else if (isErr(holidays)) {
    return holidays;
  }
  else if (typeof holidays === 'undefined') {
    holidaysArray = [];
  }
  else {
    return ERROR_VALUE;
  }
  /** @type {Set<number>} */
  const holidaysSet = new Set();
  for (const holiday of holidaysArray) {
    if (isErr(holiday)) {
      return holiday;
    }
    if (isStr(holiday) || isNum(holiday) || holiday == null) {
      const holidayDate = toDateEngineering(holiday);
      if (isErr(holidayDate)) {
        return holidayDate;
      }
      holidaysSet.add(holidayDate);
    }
    else {
      return ERROR_VALUE;
    }
  }

  if (daysNum === 0) {
    return startDate;
  }

  const sign = daysNum >= 0 ? 1 : -1;
  // We first try to skip by whole weeks, without any consideration for holidays.
  const workdaysPerWeek = 7 - weekendMask.size;
  const weeksToSkip = Math.trunc((daysNum - sign) / workdaysPerWeek);
  const workdaysToSkip = weeksToSkip * workdaysPerWeek;
  let adjustmentNeeded = Math.abs(daysNum - workdaysToSkip);
  let resultDate = startDate + weeksToSkip * 7;
  const smallerDate = Math.min(startDate, resultDate);
  const largerDate = Math.max(startDate, resultDate);
  for (const holiday of holidaysSet) {
    if (!weekendMask.has(holiday % 7) && smallerDate <= holiday && holiday <= largerDate) {
      adjustmentNeeded += 1;
    }
  }
  while (adjustmentNeeded > 0) {
    resultDate += sign;
    if (!weekendMask.has(resultDate % 7) && !holidaysSet.has(resultDate)) {
      adjustmentNeeded -= 1;
    }
  }
  if (resultDate < 0) {
    return ERROR_NUM;
  }
  return resultDate;
}

/**
 * @param {NonMatrixFormulaArgument} startDate
 * @param {NonMatrixFormulaArgument} days
 * @param {FormulaArgument} [holidays]
 */
export function WORKDAY (startDate, days, holidays) {
  return WORKDAY_INTL(startDate, days, MISSING, holidays);
}
