import { ERROR_DIV0, ERROR_NULL, ERROR_NUM, MODE_GOOGLE } from '../constants.js';
import { nearlyEqual } from './utils-number';
import { isRef } from './utils.js';
import { TYPE_BLANK, TYPE_NUM, TYPE_BOOL, TYPE_STRING, TYPE_LAMBDA, TYPE_ERROR } from '../types';
import { valueToType } from '../coerce';
import Reference from '../Reference.js';
import Range from '../referenceParser/Range.js';
import { add, div, sub, pow, mul } from '../operations.js';
import { box, isBoxed, unbox } from '../ValueBox.js';

/**
 * @template {CellValue} T
 * @typedef { import("../ValueBox").MaybeBoxed<T> } MaybeBoxed<T>
 */
/**
 * @template {CellValue} T
 * @typedef { import("../ValueBox").default<T> } ValueBox<T>
 */

// Higher number means higher priority
const TYPE_PRIORITY = {
  [TYPE_NUM]: 1,
  [TYPE_STRING]: 2,
  [TYPE_LAMBDA]: 3,
  [TYPE_BOOL]: 4,
  [TYPE_ERROR]: 5,
};

/**
 * @type {{ compare: (a: string, b: string) => number }}
 */
const collator = (() => {
  // we prefer Intl.Collator
  if (typeof Intl !== 'undefined') {
    return new Intl.Collator('en', { sensitivity: 'accent' });
  }
  else {
    // but will fallback to localeCompare
    return {
      compare: (a, b) => {
        if (a === b) {
          return 0;
        }
        return a.toLocaleUpperCase().localeCompare(b.toLocaleUpperCase());
      },
    };
  }
})();

/**
 * @param {string} a
 * @param {string} b
 * @returns {number}
 */
export function strCompare (a, b) {
  return collator.compare(a, b);
}

/**
 * - Returns zero when `a` and `b` are equal (or nearly so, per Excel).
 * - Returns a negative number when `a > b`.
 * - Returns a positive number when `a < b`.
 * @param {number | string | boolean | null | FormulaError | Lambda} a
 * @param {number | string | boolean | null | FormulaError | Lambda} b
 * @returns {number}
 */
export function operatorCollate (a, b) {
  const aType = valueToType(a);
  const bType = valueToType(b);
  if ((aType | bType) & ~(TYPE_NUM | TYPE_STRING | TYPE_BOOL | TYPE_BLANK | TYPE_LAMBDA | TYPE_ERROR)) {
    throw new Error(`internal error: can't collate types ${aType} and ${bType}`);
  }
  if (aType === bType) {
    if (aType & (TYPE_NUM | TYPE_BOOL)) {
      // We use `nearlyEqual` to make expressions like 0.1 + 0.2 = 0.3 match
      // Excel's behaviour rather than JavaScript's.
      if (nearlyEqual(/** @type {number | boolean} */ (a), /** @type {number | boolean} */ (b))) {
        return 0;
      }
      // @ts-expect-error `a` and `b` are numbers or booleans
      return a - b;
    }
    if (aType === TYPE_STRING) {
      // @ts-expect-error `a` and `b` are strings
      return strCompare(a, b);
    }
    // OBSERVATION: aType === bType, and that type is either BLANK or LAMBDA,
    // each of which only has a single value.
    return 0;
  }
  if ((aType | bType) & TYPE_BLANK) {
    // Exactly one of the arguments is blank
    if (bType === TYPE_BLANK) {
      if (aType & (TYPE_ERROR | TYPE_LAMBDA)) {
        return -1;
      }
      if (aType & (TYPE_NUM | TYPE_BOOL)) {
        // @ts-expect-error `a` is a number or boolean
        return +a;
      }
      // @ts-expect-error OBSERVATION: aType === TYPE_STRING
      return strCompare(a, '');
    }
    if (bType & (TYPE_ERROR | TYPE_LAMBDA)) {
      return 1;
    }
    if (bType & (TYPE_NUM | TYPE_BOOL)) {
      // @ts-expect-error `b` is a number or boolean
      return -b;
    }
    // @ts-expect-error OBSERVATION: bType === TYPE_STRING
    return strCompare('', b);
  }
  return TYPE_PRIORITY[aType] - TYPE_PRIORITY[bType];
}

/**
 * @param {string | number | boolean | null} a
 * @param {string | number | boolean | null} b
 */
export function LT (a, b) {
  const cmp = operatorCollate(a, b);
  return cmp < 0;
}

/**
 * @param {string | number | boolean | null} a
 * @param {string | number | boolean | null} b
 */
export function GT (a, b) {
  const cmp = operatorCollate(a, b);
  return cmp > 0;
}

/**
 * @param {string | number | boolean | null} a
 * @param {string | number | boolean | null} b
 */
export function LTE (a, b) {
  const cmp = operatorCollate(a, b);
  return cmp <= 0;
}

/**
 * @param {string | number | boolean | null} a
 * @param {string | number | boolean | null} b
 */
export function GTE (a, b) {
  const cmp = operatorCollate(a, b);
  return cmp >= 0;
}

/**
 * @param {string | number | boolean | null} a
 * @param {string | number | boolean | null} b
 */
export function EQ (a, b) {
  const cmp = operatorCollate(a, b);
  return cmp === 0;
}

/**
 * @param {string | number | boolean | null} a
 * @param {string | number | boolean | null} b
 */
export function NE (a, b) {
  const cmp = operatorCollate(a, b);
  return cmp !== 0;
}

/**
 * @param {MaybeBoxed<number>} a
 * @returns {MaybeBoxed<number>}
 */
export function UPLUS (a) {
  if (isBoxed(a)) {
    return a.map(v => v);
  }
  return a;
}

/**
 * @param {MaybeBoxed<number>} a
 * @returns {MaybeBoxed<number>}
 */
export function UMINUS (a) {
  if (isBoxed(a)) {
    return a.map(v => -v);
  }
  return -a;
}

/**
 * Returns the value interpreted as a percentage.
 *
 * @param {MaybeBoxed<number>} a
 * @example
 * // Returns 1
 * UNARY_PERCENT(100);
 * @returns {ValueBox<number>}
 */
export function UNARY_PERCENT (a) {
  if (isBoxed(a)) {
    return box(unbox(a) * 0.01, { ...a.metadata });
  }
  return box(a * 0.01);
}

/**
 * @param {MaybeBoxed<number>} a
 * @param {MaybeBoxed<number>} b
 * @returns {MaybeBoxed<number>}
 */
export function ADD (a, b) {
  return add(a, b);
}

/**
 * @param {MaybeBoxed<number>} a
 * @param {MaybeBoxed<number>} b
 * @returns {MaybeBoxed<number>}
 */
export function MINUS (a, b) {
  return sub(a, b);
}

/**
 * @param {MaybeBoxed<number>} a
 * @param {MaybeBoxed<number>} b
 * @returns {MaybeBoxed<number>}
 */
export function MULTIPLY (a, b) {
  return mul(a, b);
}

/**
 * @param {string} a
 * @param {string} b
 * @returns {string}
 */
export function CONCAT (a, b) {
  return a + b;
}

/**
 * @param {MaybeBoxed<number>} a
 * @param {MaybeBoxed<number>} b
 * @returns {MaybeBoxed<number> | FormulaError}
 */
export function DIVIDE (a, b) {
  if (unbox(b) === 0) {
    return ERROR_DIV0;
  }
  return div(a, b);
}

/**
 * @this {EvaluationContext}
 * @param {MaybeBoxed<number>} a
 * @param {MaybeBoxed<number>} b
 * @returns {MaybeBoxed<number> | FormulaError}
 */
export function POW (a, b) {
  if (unbox(a) === 0) {
    const ub = unbox(b);
    if (ub === 0) {
      return ERROR_NUM;
    }
    if (ub < 0) {
      return this.mode === MODE_GOOGLE ? ERROR_NUM : ERROR_DIV0;
    }
  }
  return pow(a, b);
}

/**
 * @param {Reference} a
 * @param {Reference} b
 * @returns {Reference | FormulaError}
 */
export function INTERSECT (a, b) {
  if (!isRef(a) || !isRef(b)) {
    // intersect only works on ranges everything else should be blocked by the parser (syntax error)
    return ERROR_NULL;
  }
  const ar = a.range;
  const br = b.range;
  if (
    a.sheetName !== b.sheetName ||
    ar.top > br.bottom ||
    br.top > ar.bottom ||
    ar.left > br.right ||
    br.left > ar.right
  ) {
    // ranges don't overlap
    return ERROR_NULL;
  }
  // ranges overlap, return a new range with the minimum common area
  const c = new Reference(
    new Range({
      top: Math.max(ar.top, br.top),
      left: Math.max(ar.left, br.left),
      bottom: Math.min(ar.bottom, br.bottom),
      right: Math.min(ar.right, br.right),
    }),
    a,
  );
  return c;
}

const infixHandlers = {
  '<': LT,
  '>': GT,
  '<=': LTE,
  '>=': GTE,
  '=': EQ,
  '<>': NE,
  '+': ADD,
  '-': MINUS,
  '*': MULTIPLY,
  '/': DIVIDE,
  '^': POW,
  '&': CONCAT,
  ' ': INTERSECT,
};

const infixNames = {
  '<': 'LT',
  '>': 'GT',
  '<=': 'LTE',
  '>=': 'GTE',
  '=': 'EQ',
  '<>': 'NE',
  '+': 'ADD',
  '-': 'MINUS',
  '*': 'MULTIPLY',
  '/': 'DIVIDE',
  '^': 'POW',
  '&': 'CONCAT',
  ' ': 'INTERSECT',
};

/**
 * @template {string} T
 * @param {T} symbol
 * @returns {T extends keyof typeof infixHandlers
 *  ? typeof infixHandlers[T]
 *  : import('../EvaluationContext').ContextFreeSpreadsheetFunction | undefined}
 */
export function getInfixHandler (symbol) {
  return infixHandlers[/** @type {string} */ (symbol)];
}

/**
 * @param {string} symbol
 * @returns {string | undefined}
 */
export function getInfixName (symbol) {
  return infixNames[symbol];
}
