export class SheetNotFoundError extends Error {
  sheetName: string;
  workbookName: string | null;

  constructor (sheetName: string, workbookName: string | null) {
    const workbookSpec = workbookName ? ` in workbook ${workbookName}` : '';
    super(`Could not find sheet named ${sheetName}${workbookSpec}`);
    this.sheetName = sheetName;
    this.workbookName = workbookName;
  }
}

export class NameNotFoundError extends Error {
  workbookName: string | null;

  constructor (name: string, workbookName: string | null) {
    const workbookSpec = workbookName ? ` in workbook ${workbookName}` : '';
    super(`Could not find defined name ${name}${workbookSpec}`);
    this.name = name;
    this.workbookName = workbookName;
  }
}

export class WorkbookNotFoundError extends Error {
  workbookName: string;

  constructor (workbookName: string) {
    super(workbookName ? `Could not find workbook named ${workbookName}` : 'Could not find workbook');
    this.workbookName = workbookName;
  }
}
