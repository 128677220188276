import { ChartCSF, WorkbookCSF } from './csf';

export function chartsFromData (csf: WorkbookCSF): ChartCSF[] {
  return (csf.charts || []).map(chart => ({
    ...chart,
    title: pickTitleForChart(chart),
  }));
}

function pickTitleForChart (chart: ChartCSF): string {
  const useAutoTitle = !chart.title && chart.auto_title_deleted === false && chart.series.length === 1;
  if (useAutoTitle) {
    return chart.series[0].name;
  }
  return chart.title || '';
}
