import Reference, { isRef } from '../Reference.js';
import { isErr, isStr, isNum } from './utils';
import Matrix from '../Matrix.js';
import FormulaError from '../FormulaError.js';
import { CellValueAtom, ArrayValue } from '../types.js';
import { ERROR_DIV0, ERROR_NUM, ERROR_VALUE, MISSING } from '../constants';
import { isLambda } from '../lambda';
import { compileFilterExpr } from './utils-funcif';
import { AVERAGE, COUNT, COUNTA, MAX, MIN, STDEV, STDEVP, VAR, VAR_P } from './statistical';
import { uniqueHashable } from './array.js';
import { PRODUCT, SUM } from './math.js';

function toArrayCheckingHeight (array: Reference | Matrix): Matrix | FormulaError {
  if (array.height < 2) {
    return ERROR_VALUE;
  }
  if (isRef(array)) {
    return array.toMatrix();
  }
  return array;
}

function checkOredConditions (
  database: Matrix,
  row: number,
  oredConditions: [number, ((x: ArrayValue) => boolean)[]][][],
): boolean {
  return oredConditions.some(andedConditions => {
    for (const [ column, testFuncs ] of andedConditions) {
      const value = database.get(column, row);
      if (!testFuncs.every(testFunc => testFunc(value))) {
        return false;
      }
    }
    return true;
  });
}

function createOredConditions (
  criteria: Matrix,
  colNameToIdx: Map<string, number>,
): [number, ((x: ArrayValue) => boolean)[]][][] | null {
  const oredConditions: [number, ((x: ArrayValue) => boolean)[]][][] = [];
  for (let r = 1; r < criteria.height; r++) {
    const andedConditionMap: Map<number, ((x: ArrayValue) => boolean)[]> = new Map();
    for (let c = 0; c < criteria.width; c++) {
      const condition = criteria.get(c, r);
      if (condition == null) {
        // Blank cells are considered always true conditionals.
        continue;
      }
      const name = criteria.get(c, 0);
      if (name == null || name === '') {
        // Regardless of what the other conditionals evaluate to, we should
        // not return any rows.
        return null;
      }
      const idx = colNameToIdx.get(uniqueHashable(name));
      if (idx == null) {
        continue;
      }
      let andedConditions = andedConditionMap.get(idx);
      if (!andedConditions) {
        andedConditions = [];
        andedConditionMap.set(idx, andedConditions);
      }
      andedConditions.push(compileFilterExpr(condition, true));
    }
    oredConditions.push([ ...andedConditionMap.entries() ]);
  }
  return oredConditions;
}

/**
 * Shared implementation of all database functions.
 *
 * If `allowMissingFilter` is set to true, and `filterArg` is missing, a zero
 * is returned for each row that passes all criteria (instead of the value
 * in the column given by `filterArg`). This is used by `DCOUNT` and `DCOUNTA`.
 */
function getDatabaseRows (
  databaseArg: Reference | Matrix,
  filterArg: string | number | undefined,
  criteriaArg: Reference | Matrix | string,
  allowMissingFilter: boolean,
): ArrayValue[] | FormulaError {
  const database = toArrayCheckingHeight(databaseArg);
  if (isErr(database)) {
    return database;
  }
  if (isStr(criteriaArg)) {
    // It is a complete mystery to me why the Excel signatures allow a string
    // criteria. I haven't found any examples or resources online showing this
    // in action. Neither ChatGPT nor the OpenDocument standard[1] knows
    // anything either.
    // [1]: https://docs.oasis-open.org/office/OpenDocument/v1.3/os/part4-formula/OpenDocument-v1.3-os-part4-formula.html#criteria
    return ERROR_VALUE;
  }
  const criteria = toArrayCheckingHeight(criteriaArg);
  if (isErr(criteria)) {
    return criteria;
  }

  const colNameToIdx: Map<string, number> = new Map();
  for (let c = database.width - 1; c >= 0; c--) {
    const nameHashable = uniqueHashable(database.get(c, 0));
    // If a column name is duplicated, the one which appears first wins. That's why we
    // iterate in reverse order.
    colNameToIdx.set(nameHashable, c);
  }

  let filterIdx: number | null;
  if (isNum(filterArg)) {
    filterIdx = Math.floor(filterArg) - 1;
    if (filterIdx < 0 || filterIdx >= database.width) {
      return ERROR_VALUE;
    }
  }
  else if (isStr(filterArg)) {
    const idx = colNameToIdx.get(uniqueHashable(filterArg));
    if (idx == null) {
      return ERROR_VALUE;
    }
    filterIdx = idx;
  }
  else if (filterArg === MISSING && allowMissingFilter) {
    filterIdx = null;
  }
  else {
    return ERROR_VALUE;
  }

  const oredConditions = createOredConditions(criteria, colNameToIdx);
  if (oredConditions == null) {
    return [];
  }
  const values: ArrayValue[] = [];
  for (let row = 1; row < database.height; row++) {
    if (checkOredConditions(database, row, oredConditions)) {
      values.push(filterIdx != null ? database.get(filterIdx, row) : 0);
    }
  }
  return values;
}

function createGenericDatabaseFunc (
  impl: (values: Matrix) => number | FormulaError,
  resultWhenZero: number | FormulaError,
  allowMissingFilter: boolean,
): (
    databaseArg: Reference | Matrix,
    filterArg: string | number | undefined,
    criteriaArg: Reference | Matrix | string
  ) => number | FormulaError {
  return (databaseArg, filterArg, criteriaArg) => {
    const values = getDatabaseRows(databaseArg, filterArg, criteriaArg, allowMissingFilter);
    if (isErr(values)) {
      return values;
    }
    if (values.length === 0) {
      return resultWhenZero;
    }
    return impl(Matrix.createColumn(values));
  };
}

export const DAVERAGE = createGenericDatabaseFunc(AVERAGE, ERROR_DIV0, false);

export const DCOUNT = createGenericDatabaseFunc(COUNT, 0, true);

export const DCOUNTA = createGenericDatabaseFunc(COUNTA, 0, true);

export function DGET (
  databaseArg: Reference | Matrix,
  filterArg: string | number | undefined,
  criteriaArg: Reference | Matrix | string,
): FormulaError | CellValueAtom {
  const values = getDatabaseRows(databaseArg, filterArg, criteriaArg, false);
  if (isErr(values)) {
    return values;
  }
  if (values.length !== 1) {
    return values.length === 0 ? ERROR_VALUE : ERROR_NUM;
  }
  const value = values[0];
  if (isLambda(value) || value == null) {
    return ERROR_VALUE;
  }
  return value;
}

export const DMAX = createGenericDatabaseFunc(MAX, 0, false);

export const DMIN = createGenericDatabaseFunc(MIN, 0, false);

export const DPRODUCT = createGenericDatabaseFunc(PRODUCT, 0, false);

export const DSTDEV = createGenericDatabaseFunc(STDEV, ERROR_DIV0, false);

export const DSTDEVP = createGenericDatabaseFunc(STDEVP, ERROR_DIV0, false);

export const DSUM = createGenericDatabaseFunc(SUM, 0, false);

export const DVAR = createGenericDatabaseFunc(VAR, ERROR_DIV0, false);

export const DVARP = createGenericDatabaseFunc(VAR_P, ERROR_DIV0, false);
