import { type RTreeMatrixNode, determineMatrixDataBounds } from './Cells';
import { CellVertexId, RangeVertexId, cellVertexIdKey, DependencyGraph, NameVertexId } from './DependencyGraph';
import type Workbook from './Workbook';
import Cell from './excel/Cell';
import Range, { type SlimRange } from './excel/referenceParser/Range';
import type { WorkbookRootsAndDependents } from './types';
import { constructCellID } from './utils';
import { invariant } from './validation';

// XXX when a vertex has inDegree 0, there may still be dependencies on cells
// spilled from it (or formulas within it). We are missing those dependencies.

/**
 * Returns an object containing two lists of cells from this workbook:
 *
 *  - `roots` are cells with incoming references but no outgoing references.
 *  - `dependents` are all cells that reference any other cells (even if also
 *    referenced by other cells further in)
 *
 * The roots contain a `cumulativeSumInDegrees` property, and `dependents`
 * contain `cumulativeSumOutDegrees`.
 *
 *  - `cumulativeSumInDegrees` roughly counts how many cells depend on this root.
 *  - `cumulativeSumOutDegrees` roughly counts how many cells this cell depends on.
 *
 * These sums are not exact. In the case of a "diamond-like" dependency graph,
 * double counts occur. Take this example graph:
 *
 *    A1 -> B1, A1 -> C1, B1 -> D1, C1 -> D1
 *
 * In this graph:
 *
 *  - D1 is a root, containing a `cumulativeSumInDegrees` of 4.
 *  - A1 is a dependent, containing a `cumulativeSumOutDegrees` of 4.
 *  - B1 and C1 are dependents, containing a `cumulativeSumOutDegrees` of 1.
 *
 * The cumulative sums can be thought of as an upper bound. The exact number of
 * cells might be lower, but never higher.
 *
 * The lists are ordered by the sums. These ordered lists can be used to
 * determine the most "impactful" inputs and outputs for a given workbook.
 */
export function getRootsAndDependents (workbook: Workbook): WorkbookRootsAndDependents {
  const roots: WorkbookRootsAndDependents['roots'] = [];
  const dependents: WorkbookRootsAndDependents['dependents'] = [];

  const depGraph = workbook._model._graph;
  const vertices = depGraph._getVertices(workbook.keyInDepGraph);
  if (!vertices) {
    return { roots, dependents };
  }

  // Cache used to avoid computing the degrees of vertices multiple times.
  const inDegreeByVertexKey = new Map<string, number>();
  const outDegreeByVertexKey = new Map<string, number>();

  for (const vertex of vertices) {
    if (vertex.id instanceof CellVertexId) {
      if (vertex.outDegree > 0) {
        // Dependent
        const cumulativeSumOutDegrees = getCumulativeSumOutDegrees(vertex.id, depGraph, workbook, outDegreeByVertexKey);
        const { cellId, sheetName } = cellVertexToSheetNameAndCellId(workbook, vertex.id);
        dependents.push({ cellId, sheetName, cumulativeSumOutDegrees });
      }
      else if (vertex.inDegree > 0) {
        invariant(vertex.id instanceof CellVertexId);
        // Root
        const cumulativeSumInDegrees = getCumulativeSumInDegrees(vertex.id, depGraph, inDegreeByVertexKey);
        const { cellId, sheetName } = cellVertexToSheetNameAndCellId(workbook, vertex.id);
        roots.push({ cellId, sheetName, cumulativeSumInDegrees });
      }
    }
  }

  roots.sort((a, b) => b.cumulativeSumInDegrees - a.cumulativeSumInDegrees);
  dependents.sort((a, b) => b.cumulativeSumOutDegrees - a.cumulativeSumOutDegrees);

  return { roots, dependents };
}

function getCumulativeSumInDegrees<T> (
  vertexId: CellVertexId | NameVertexId,
  depGraph: DependencyGraph<T>,
  inDegreeByVertexKey: Map<string, number>,
) {
  const memoizedInDegree = inDegreeByVertexKey.get(vertexId.key);
  if (memoizedInDegree != null) {
    return memoizedInDegree;
  }

  inDegreeByVertexKey.set(vertexId.key, 0); // Prevent infinite recursion on circular references

  let cumulativeSumInDegrees = 0;
  depGraph.visitIncomingEdges(vertexId, edge => {
    const fromVertex = edge.from;
    const fromVertexId = fromVertex.id;

    if (fromVertexId instanceof CellVertexId || fromVertexId instanceof NameVertexId) {
      cumulativeSumInDegrees++;
      cumulativeSumInDegrees += getCumulativeSumInDegrees(fromVertex.id, depGraph, inDegreeByVertexKey);
    }
  });

  inDegreeByVertexKey.set(vertexId.key, cumulativeSumInDegrees);
  return cumulativeSumInDegrees;
}

function getCumulativeSumOutDegrees<T> (
  vertexId: CellVertexId | NameVertexId,
  depGraph: DependencyGraph<T>,
  workbook: Workbook,
  outDegreeByVertexKey: Map<string, number>,
) {
  const memoizedOutDegree = outDegreeByVertexKey.get(vertexId.key);
  if (memoizedOutDegree != null) {
    return memoizedOutDegree;
  }

  outDegreeByVertexKey.set(vertexId.key, 0); // Prevent infinite recursion on circular references

  let cumulativeSumOutDegrees = 0;
  depGraph.visitOutgoingEdges(vertexId, edge => {
    const toVertex = edge.to;
    const toVertexId = toVertex.id;

    if (toVertexId instanceof RangeVertexId) {
      const result = getRangeVertexOutDegreeAndReferencedVertexIDs(workbook, depGraph, toVertexId);
      cumulativeSumOutDegrees += result.outDegree;
      for (const cellVertexId of result.referencedCellVertexIDs) {
        cumulativeSumOutDegrees += getCumulativeSumOutDegrees(cellVertexId, depGraph, workbook, outDegreeByVertexKey);
      }
    }
    else {
      // Outgoing reference to an individual cell adds +1 to out degree.
      cumulativeSumOutDegrees++;
      // Also add the out degree for the referenced cell.
      cumulativeSumOutDegrees += getCumulativeSumOutDegrees(toVertexId, depGraph, workbook, outDegreeByVertexKey);
    }
  });

  outDegreeByVertexKey.set(vertexId.key, cumulativeSumOutDegrees);
  return cumulativeSumOutDegrees;
}

function getSizeOfIntersection (node: RTreeMatrixNode, vertexId: SlimRange) {
  const considerDefaultedDataPopulated = true;
  const { mxMaxDataX, mxMaxDataY } = determineMatrixDataBounds(
    node,
    vertexId.right,
    vertexId.bottom,
    considerDefaultedDataPopulated,
  );
  if (mxMaxDataX < node.minX || mxMaxDataY < node.minY) {
    return 0;
  }
  const populatedRange = new Range({
    top: node.minY,
    left: node.minX,
    bottom: mxMaxDataY,
    right: mxMaxDataX,
  });
  const intersection = populatedRange.getIntersection(vertexId);
  return intersection != null ? intersection.size : 0;
}

function getRTreeNodesIntersectingRangeVertexId (workbook: Workbook, vertexId: RangeVertexId) {
  const toWorkbook =
    vertexId.workbookKey === workbook.keyInDepGraph ? workbook : workbook._model.getWorkbookByKey(vertexId.workbookKey);
  const toSheet = toWorkbook?.getSheetByIndex(vertexId.sheetIndex);
  const toRange = vertexId.toRange();
  invariant(toSheet);
  return toSheet._cells._rtree.search(toRange.toBoundingBox());
}

function getRangeVertexOutDegreeAndReferencedVertexIDs<T> (
  workbook: Workbook,
  depGraph: DependencyGraph<T>,
  toVertexId: RangeVertexId,
) {
  const toRange = toVertexId.toRange();

  let outDegree = 0;
  const referencedCellVertexIDs: CellVertexId[] = [];

  const nodes = getRTreeNodesIntersectingRangeVertexId(workbook, toVertexId);
  for (const node of nodes) {
    if ('matrix' in node) {
      // Range reference intersects a spill.
      //
      // We don't want to use the reference size as the out degree because whole-column
      // references (such as A:A) would dominate.
      //
      // Instead, find the intersection of the non-blank spill area and the range
      // reference, and add the size of that area to the out degree.
      if (node.masked) {
        if (toRange.contains({ left: node.minX, top: node.minY })) {
          outDegree += 1;
        }
      }
      else {
        outDegree += getSizeOfIntersection(node, toVertexId);
      }
    }
    else {
      // Outgoing reference to an individual cell adds +1 to out degree.
      outDegree++;

      if (node.cell.f) {
        // The cell has a formula, which may contain outgoing references.
        const { minX, minY } = node;
        const { workbookKey, sheetIndex } = node.cell;
        invariant(sheetIndex != null);
        const vertices = depGraph._getVertices(workbookKey);
        const vertex = vertices?._vertices.get(cellVertexIdKey(workbookKey, sheetIndex, minY, minX));
        if (vertex) {
          // Also add the out degree for the referenced cell.
          referencedCellVertexIDs.push(vertex.id as CellVertexId);
        }
      }
    }
  }

  return { outDegree, referencedCellVertexIDs };
}

function cellVertexToSheetNameAndCellId (workbook: Workbook, vertexId: CellVertexId) {
  return {
    sheetName: workbook.getSheetByIndex(vertexId.sheetIndex)?.name || '',
    cellId: constructCellID(vertexId.rowIndex, vertexId.colIndex),
  };
}

/**
 * Returns whether or not a cell root exists in this workbook. A cell root is
 * defined as a cell that
 *
 *  - references no other cells, and
 *  - is referenced by a cell in this workbook.
 */
export function hasCellRoot (workbook: Workbook, predicate: (cell: Cell | null) => boolean = () => true) {
  const vertices = workbook._model._graph._getVertices(workbook.keyInDepGraph) || [];
  for (const vertex of vertices) {
    if (vertex.id instanceof CellVertexId && vertex.outDegree === 0) {
      const hasIncomingEdgeFromThisWorkbook = vertex.incomingEdges.some(
        edge => edge.from.id.workbookKey === vertex.id.workbookKey,
      );
      if (hasIncomingEdgeFromThisWorkbook) {
        const cell = vertex.data;
        if (cell && predicate(cell)) {
          return true;
        }
      }
    }
  }
  return false;
}
