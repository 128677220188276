/* global process */

/** Wrapper for the performance.now() function, available only in browsers, falling back to Date.now()
 */
// eslint-disable-next-line no-undef
export const now = typeof performance !== 'undefined' ? performance.now.bind(performance) : Date.now;

export const DEV_LOGGING = typeof process !== 'undefined' && JSON.parse(process.env.DEV_LOGGING || 'false');

const MILLISECOND_UNITS = [
  { min: 1e3, suffix: ' sec' },
  { min: 1, suffix: ' ms' },
  { min: 1e-3, suffix: ' µs' },
];

/**
 * @param {number} value
 * @param {{ min: number, suffix: string}[]} units
 * @return {[ number, string]}
 */
function chooseScale (value, units) {
  const absValue = Math.abs(value);
  for (const { min, suffix } of units) {
    if (absValue >= min) {
      return [ value / min, suffix ];
    }
  }
  return [ 0, '' ];
}

/**
 * @param {number} durationInMilliseconds
 * @param {boolean} [showPlus] true to prefix positive numbers with `+`
 * @returns {string}
 */
export function formatDuration (durationInMilliseconds, showPlus = false) {
  if (durationInMilliseconds < 1e-3) {
    return '0';
  }
  const [ duration, unitSuffix ] = chooseScale(durationInMilliseconds, MILLISECOND_UNITS);
  const decimalPlaces = Math.max(0, 3 - Math.ceil(Math.log10(Math.abs(duration) + 1e-12)));
  return `${showPlus && duration > 0 ? '+' : ''}${duration.toFixed(decimalPlaces)}${unitSuffix}`;
}
