import { CellVertexId, NameVertexId } from './DependencyGraph';
import { invariant, AssertionError } from './validation';
import Cell from './excel/Cell';
import { cellToVertexId } from './dep-graph-helpers';
import VertexIdSet from './VertexIdSet';
import Reference from './excel/Reference';

/** @typedef {Cell | CellVertexId| NameVertexId | Iterable<Cell | CellVertexId | NameVertexId> | null | undefined} ConvertibleToVertexIDs */

/**
 * Converts various types to vertex IDs. Allows for a user friendly interface.
 * @param {ConvertibleToVertexIDs} value
 * @returns {(CellVertexId | NameVertexId)[]}
 */
function toVertexIdArray (value) {
  if (!value) {
    return [];
  }
  if (!Array.isArray(value) && Symbol.iterator in value) {
    value = [ ...value ];
  }
  if (Array.isArray(value)) {
    return value.map(v => (v instanceof Cell ? cellToVertexId(v) : v));
  }
  if (value instanceof Cell) {
    return [ cellToVertexId(value) ];
  }
  invariant(value instanceof CellVertexId || value instanceof NameVertexId, 'wrong cell type given to toVertexIdArray');
  return [ value ];
}

class ModelError extends Error {
  /** @type {Workbook | null} */
  workbook = null;
  /** @type {Error & { formula?: string, ast?: ASTNode } | null} */
  origException = null;

  /**
   * @param {string} message the error message
   * @param {ModelError.ERROR | ModelError.WARNING | ModelError.NOTICE | ModelError.NONE} level
   * @param {ConvertibleToVertexIDs} [cells] indications of cell(s) or defined
   *    name(s) associated with the error
   * @param {string} [type] error type identifier
   * @param {Error|null} [origException] original exception, included for troubleshooting errors whose reason is unknown
   */
  constructor (message, level = 2, cells = null, type = 'unknown', origException = null) {
    super(message);
    this.type = type;
    this.level = Math.min(3, Math.max(level, -1));

    const vertexIds = toVertexIdArray(cells);
    /** @type {VertexIdSet<CellVertexId | NameVertexId>} */
    this.vertexIds = new VertexIdSet(vertexIds);

    Object.defineProperty(this, 'origException', {
      enumerable: false,
      writable: false,
      value: origException,
    });
    Object.defineProperty(this, 'workbook', {
      enumerable: false,
      writable: true,
      value: null,
    });
  }

  /**
   * @returns {Set<string>} set of cell IDs (e.g. `Sheet1!A1`), defined names, and column names for structured sheets
   */
  get references () {
    const set = new Set();

    for (const vertex of this.vertexIds.values()) {
      if (vertex instanceof NameVertexId) {
        set.add(vertex.name);
      }
      else if (vertex instanceof CellVertexId) {
        /** @type {string | undefined} */
        let sheetName;
        let column;
        // XXX: This is really brittle. Whether a workbook is present depends on how/where the error is created.
        if (this.workbook) {
          // XXX: why store the workbook if we're just going to use it to get the model...and then get the workbook again?!?
          const model = this.workbook._model;
          const workbook = model.getWorkbookByKey(vertex.workbookKey);
          const sheet = typeof vertex.sheetIndex === 'number' ? workbook?.getSheetByIndex(vertex.sheetIndex) : null;
          sheetName = sheet?.name;
          column = sheet?.getColumns()[vertex.colIndex];
        }

        if (column) {
          set.add(column.name);
        }
        else {
          set.add(String(new Reference(vertex.toRange(), { sheetName })));
        }
      }
      else {
        throw new AssertionError('vertex ID should either be a cell or name vertex ID');
      }
    }

    return set;
  }

  /**
   * Create a circular dependency error involving the given cells.
   * @param {ConvertibleToVertexIDs} vertexIds vertex IDs of cells
   *     (defined names or sheet cells) known to be involved in a dependency cycle.
   * @returns {ModelError}
   */
  static fromCircularDependencyWith (vertexIds) {
    return new ModelError('Dependency Cycle Found', ModelError.NOTICE, vertexIds, 'circdep');
  }

  /**
   * Create an error representing something unsupported (a spreadsheet function or other functionality) in a formula
   * @param {string} what the function name or a concise description of the functionality
   * @param {boolean} neverSupport true if we intend never to support the thing, false if we think we maybe will
   * @param {Cell | NameVertexId | CellVertexId} cell the sheet-prefixed cell address or defined name whose formula contains the unsupported thing
   * @param {'is' | 'are'} [isOrAre='is'] whichever verb form makes sense after `what`
   * @param {string | null} [type=null] optional override of the `ModelError` type; the default type is `fn-unsup` if
   *   `neverSupport` is true, else `fn`
   */
  static fromUnsupported (what, neverSupport, cell, isOrAre = 'is', type = null) {
    if (type == null) {
      type = neverSupport ? 'fn-unsup' : 'fn';
    }
    return new ModelError(
      `${what} ${isOrAre} not ${neverSupport ? '' : 'yet '}supported`,
      neverSupport ? ModelError.NOTICE : ModelError.WARNING,
      cell,
      type,
    );
  }

  /**
   * Create an error about invalid cell values in workbook input in the given cells.
   * @param {ConvertibleToVertexIDs} vertexIds vertex IDs of cells (defined names or sheet cells) in which invalid cell
   *   values were found and replaced with #VALUE!
   * @returns {ModelError}
   */
  static fromInvalidCellValueAt (vertexIds) {
    return new ModelError('Invalid cell value', ModelError.WARNING, vertexIds, 'invalid-cell-value');
  }

  valueOf () {
    return this.message;
  }

  toString () {
    return this.message;
  }

  /**
   * Produce a representation of this error for JSON serialization.
   * Adds the non-enumerable properties `message` and `origException` (the latter only if set),
   * and makes `cells` an array.
   * Despite the name, this does not return a JSON string, it returns an object to stringify instead of this one, see
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#tojson_behavior
   * @returns an object that will JSON.stringify more usefully than this instance.
   */
  toJSON () {
    /** @type {{
     *   message: string,
     *   type: string,
     *   level: number,
     *   vertexIds: string[],
     *   origException?: {
     *     message: string,
     *     stack: string | undefined,
     *   }
     * }}
     */
    const repr = {
      message: this.message,
      type: this.type,
      level: this.level,
      vertexIds: Array.from(this.vertexIds.values()).map(v => v.key),
    };
    const origException = this.origException;
    if (origException) {
      repr.origException = {
        message: origException.message,
        stack: origException.stack,
      };
    }
    return repr;
  }
}

ModelError.ERROR = 3;
ModelError.WARNING = 2;
ModelError.NOTICE = 1;
ModelError.NONE = 0;

export default ModelError;
