class AssertionError extends Error {}

function invariant (condition: unknown, message: string = ''): asserts condition {
  if (condition) {
    return;
  }
  if (message) {
    message = 'Invariant violation: ' + message;
  }
  else {
    message = 'Invariant violation';
  }
  throw new AssertionError(message);
}

export { invariant, AssertionError };
