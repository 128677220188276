import { CallbackWithStop, Signal } from '../Signal';
import {
  ASTNode,
  ASTNonLiteralNode,
  ASTStaticReferenceNode,
  ASTReferenceOperationNode,
  ASTLiteralNode,
  ASTRootNode,
} from './ast-types';
import { isStr, isNum, isBool } from '../typeguards';

export function visitAllTopLevelReferenceNodes (
  ast: ASTNode,
  callback: CallbackWithStop<ASTStaticReferenceNode | ASTReferenceOperationNode>,
): void {
  /**
   * @param {ASTNonLiteralNode} node
   * @returns {node is ASTReferenceOperationNode | ASTStaticReferenceNode}
   */
  const isReferenceNode = (node: ASTNonLiteralNode): node is ASTReferenceOperationNode | ASTStaticReferenceNode =>
    isReferenceOperationNode(node) || isStaticReferenceNode(node);
  const stopSignal = new Signal();
  recurseAndVisitNonLiteralNodes(
    ast,
    node => {
      // Do not recurse through the children of references nodes. The range node
      // that represents A1:A10 looks like so:
      //
      //    { range: [ { cell: 'A1' }, { cell: 'A10' } ] }
      //
      // If we were to continue recursing this node, it would yield all of these:
      //
      //    [ 'A1:A10', 'A1', 'A10' ]
      //
      if (!isLiteralNode(node) && isReferenceNode(node)) {
        return false;
      }
      return true;
    },
    (node, stop) => {
      if (isReferenceNode(node)) {
        callback(node, stop);
      }
    },
    stopSignal,
  );
}

export function isReferenceOperationNode (ast: ASTNonLiteralNode): ast is ASTReferenceOperationNode {
  return 'range' in ast || 'intersection' in ast || 'union' in ast;
}

export function isStaticReferenceNode (ast: ASTNonLiteralNode): ast is ASTStaticReferenceNode {
  return (
    'cell' in ast ||
    'name' in ast ||
    'colrange' in ast ||
    'rowrange' in ast ||
    ('prefixed' in ast && containsOnlyStaticReferenceNodes(ast.prefixed)) ||
    'structured' in ast
  );
}

/**
 * Common implementation for getAllNonLiteralNodes and getAllTopLevelReferenceNodes.
 * @param ast the AST node whose subtree is to be searched
 * @param shouldRecurse predicate which returns true if we should recurse the
 *   child nodes of this AST node, false if we should not
 */
export function recurseAndVisitNonLiteralNodes (
  ast: ASTNode,
  shouldRecurse: (node: ASTNode) => boolean,
  callback: CallbackWithStop<ASTNonLiteralNode>,
  stopSignal = new Signal(),
): void {
  // must check for Array first, because arrays _also_ satisfy the second if-cond
  if (Array.isArray(ast)) {
    for (const elem of ast) {
      recurseAndVisitNonLiteralNodes(elem, shouldRecurse, callback, stopSignal);
      if (stopSignal.isSet) {
        return;
      }
    }
  }
  else if (ast && typeof ast === 'object' && !('error' in ast)) {
    callback(ast, stopSignal);
    if (stopSignal.isSet) {
      return;
    }
    const recurse = shouldRecurse(ast);
    if (recurse) {
      if ('let' in ast) {
        for (const letExpression of Object.values(ast.let)) {
          recurseAndVisitNonLiteralNodes(letExpression, shouldRecurse, callback, stopSignal);
          if (stopSignal.isSet) {
            return;
          }
        }
        recurseAndVisitNonLiteralNodes(ast.expr, shouldRecurse, callback, stopSignal);
      }
      else {
        for (const attr of Object.values(ast)) {
          recurseAndVisitNonLiteralNodes(attr, shouldRecurse, callback, stopSignal);
          if (stopSignal.isSet) {
            return;
          }
        }
      }
    }
  }
}

export function isLiteralNode (ast: ASTNode): ast is ASTLiteralNode {
  return isStr(ast) || isNum(ast) || isBool(ast) || (typeof ast === 'object' && (ast == null || 'error' in ast));
}

export function containsOnlyStaticReferenceNodes (ast: ASTNode) {
  let result = true;
  visitAllNonLiteralNodes(ast, (node, stop) => {
    if (!isStaticReferenceNode(node)) {
      result = false;
      stop.set();
    }
  });
  return result;
}

/**
 * Traverse the given AST, yielding each non-literal node (any node of type `object`, except error nodes),
 * followed by the non-literal nodes of its subtree.
 * Traverses through arrays to yield non-literal nodes within them, but not the arrays themselves.
 * A yielded node can be responded to with `false` in order to skip that node's subtree.
 * @param ast the AST node whose subtree is to be searched
 */
export function visitAllNonLiteralNodes (ast: ASTNode, callback: CallbackWithStop<ASTNonLiteralNode>): void {
  recurseAndVisitNonLiteralNodes(ast, () => true, callback);
}

export function isContextDependent (ast: ASTRootNode) {
  return typeof ast === 'object' && ast != null && !('error' in ast) && ast.isContextDependent;
}
