export class Signal {
  private _set = false;

  set () {
    this._set = true;
  }

  get isSet () {
    return this._set;
  }
}

export type CallbackWithStop<T> = (itm: T, stopSignal: Signal) => void;
