import { TableCSF } from './csf';
import FormulaError from './excel/FormulaError';
import Reference from './excel/Reference';
import { ERROR_REF, ERROR_VALUE } from './excel/constants';
import Range from './excel/referenceParser/Range';
import { isErr } from './typeguards';
import { invariant } from './validation';

type WhichRows = {
  data: boolean,
  headers: boolean,
  totals: boolean,
  /**
   * Row index in sheet (not relative to table), determined using
   * the [#This row] keyword. Ignore if null.
   */
  rowIndex: number | null,
};

export class Table {
  csf: TableCSF;
  _ref: Reference;
  private columnNameLowerToIndex: Map<string, number>;

  constructor (csf: TableCSF, ref: Reference) {
    this.csf = csf;
    this._ref = ref;
    this.columnNameLowerToIndex = new Map(csf.columns.map((c, index) => [ c.name.toLowerCase(), index ]));
  }

  get name () {
    return this.csf.name;
  }

  get ref () {
    return this.csf.ref;
  }

  get totalsRowCount () {
    return this.csf.totals_row_count || 0;
  }

  get headerRowCount () {
    return this.csf.header_row_count ?? 1;
  }

  get sheetName (): string {
    return this._ref.sheetName;
  }

  containsCoords (row: number, column: number): boolean {
    const range = this._ref.range as Range;
    invariant(range instanceof Range);
    return range.contains({ left: column, top: row });
  }

  wholeTable (whichRows: WhichRows) {
    return this.makeRef(whichRows, 0, this._ref.width);
  }

  column (name: string, include: WhichRows) {
    const index = this.columnNameLowerToIndex.get(name.toLowerCase());
    if (index == null) {
      return ERROR_REF.detailed('No such column: ' + name);
    }
    return this.makeRef(include, index, 1);
  }

  columnRange (from: string, to: string, include: WhichRows): Reference | FormulaError {
    let fromIndex = this.columnNameLowerToIndex.get(from.toLowerCase());
    let toIndex = this.columnNameLowerToIndex.get(to.toLowerCase());
    if (fromIndex == null || toIndex == null) {
      const missingName = fromIndex == null ? from : to;
      return ERROR_REF.detailed('No such column: ' + missingName);
    }
    if (fromIndex > toIndex) {
      [ fromIndex, toIndex ] = [ toIndex, fromIndex ];
    }
    return this.makeRef(include, fromIndex, toIndex - fromIndex + 1);
  }

  private makeRef (include: WhichRows, columnIndex: number, numColumns: number): Reference | FormulaError {
    const ref = new Reference(this._ref);
    const rowsToInclude = this.getRowsToInclude(include);
    if (isErr(rowsToInclude)) {
      return rowsToInclude;
    }
    const { rowIndex, numRows } = rowsToInclude;
    return ref.offset(rowIndex, columnIndex, numRows, numColumns);
  }

  /**
   * @returns the range of rows to return from the table.
   *
   *  - `rowIndex` specifies the first row to include, relative to the table.
   *  - `numRows` specifies the number of rows to return.
   */
  private getRowsToInclude (include: WhichRows): FormulaError | { rowIndex: number, numRows: number } {
    if (include.rowIndex != null) {
      const range = this._ref.range;
      invariant(range instanceof Range);

      // Return #VALUE! if the row is not in bounds, or if it is referencing
      // the header or totals rows.
      const dataTop = range.top + this.headerRowCount;
      const dataBottom = range.bottom - this.totalsRowCount;
      if (include.rowIndex < dataTop || include.rowIndex > dataBottom) {
        return ERROR_VALUE;
      }

      return { rowIndex: include.rowIndex - range.top, numRows: 1 };
    }

    if (include.totals && this.totalsRowCount === 0 && !include.data) {
      return ERROR_REF.detailed('Table has no totals row');
    }

    if (include.headers && include.totals && !include.data) {
      return ERROR_REF.detailed('Cannot reference headers row and totals row and not the data between');
    }

    let rowIndex = 0;
    let numRows = 0;
    if (include.data) {
      rowIndex = include.headers ? 0 : this.headerRowCount;
      numRows = this._ref.height - rowIndex;
      if (!include.totals) {
        numRows -= this.totalsRowCount;
      }
    }
    else if (include.totals) {
      // Only includes totals row
      rowIndex = this._ref.height - this.totalsRowCount;
      numRows = 1;
    }
    else if (include.headers) {
      rowIndex = 0;
      numRows = this.headerRowCount;
    }
    if (numRows <= 0) {
      return ERROR_REF.detailed('No table rows referenced');
    }
    return { rowIndex, numRows };
  }
}
