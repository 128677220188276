/** @module */
import { ERROR_VALUE, ERROR_NUM, ERROR_NA, ERROR_REF, MISSING, BLANK, MODE_GOOGLE } from '../constants.js';
import { isRef } from '../Reference.js';
export { isRef } from '../Reference.js';
import { isMatrix } from '../Matrix.js';
export { isMatrix } from '../Matrix.js';
import { toNum, round15 } from './utils-number';
export { add, toNum } from './utils-number';
import { isErr, isNum, isStr, isBool } from '../../utils.js';
import { invariant } from '../../validation';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, isLambda } from '../lambda';
export { isErr, isNum, isStr, isBool } from '../../utils.js';

/**
 * @param {NonMatrixFormulaArgument} value
 * @returns {string | FormulaError}
 */
export function toStr (value) {
  if (isRef(value)) {
    value = value.resolveSingle();
  }
  if (isErr(value)) {
    return value;
  }
  if (value == null) {
    return '';
  }
  if (typeof value === 'number') {
    if (!isFinite(value) || isNaN(value)) {
      return ERROR_VALUE;
    }
    return String(round15(value));
  }
  const t = typeof value;
  if (t === 'object') {
    return ERROR_VALUE;
  }
  if (t === 'boolean') {
    return String(value).toUpperCase();
  }
  return String(value);
}

/**
 * @param {NonMatrixFormulaArgument} value
 * @returns {Date | FormulaError}
 */
export function toDate (value) {
  if (isRef(value)) {
    value = value.resolveSingle();
  }
  if (isErr(value)) {
    return value;
  }
  if (!isNum(value) || isNaN(value) || !isFinite(value)) {
    return ERROR_NUM;
  }
  const ts =
    value <= 60 // <= Feb 29. 1900
      ? (value - 25568) * 864e5
      : (value - 25569) * 864e5;
  return new Date(ts);
}

/**
 * @param {NonMatrixFormulaArgument} value
 * @returns {boolean | FormulaError}
 */
export function toBool (value) {
  if (isRef(value)) {
    value = value.resolveSingle();
  }
  if (isErr(value)) {
    return value;
  }
  if (value == null) {
    return false;
  }
  if (isStr(value)) {
    // Comparison is case insensitive; both "tRuE" and "TRUE" should be convertible
    // to booleans.
    const uppercase = value.toUpperCase();
    if (uppercase === 'TRUE') {
      return true;
    }
    if (uppercase === 'FALSE') {
      return false;
    }
  }
  if (isNum(value) || isBool(value)) {
    return Boolean(value);
  }
  return ERROR_VALUE;
}

/**
 * Why does this function exist? Because the "engineering" set of functions,
 * including FACTDOUBLE, SERIESSUM, DELTA, GESTEP, all the complex number
 * functions, etc, handle their arguments a bit differently than the rest of
 * Excel. I don't have a history lesson for you, but that's the truth. That's
 * why those functions allow every input type in their respective signatures;
 * they need to be handled differently, _inside_ the functions.
 *
 * @param {NonMatrixFormulaArgument} value
 * @param {ModeBit} [mode]
 * @returns {number | FormulaError | null}
 */
export function toNumEngineering (value, mode) {
  if (isRef(value) && value.size === 1) {
    value = value.resolveSingle();
  }
  if (isErr(value)) {
    return value;
  }
  if (isNum(value)) {
    return value;
  }
  if (value === MISSING) {
    return ERROR_NA;
  }
  if (value === BLANK) {
    return value;
  }
  if (!isStr(value)) {
    return ERROR_VALUE;
  }
  if (value === '' && mode === MODE_GOOGLE) {
    return 0;
  }
  return toNum(value);
}

/** Filter an argument list down to numbers or error.
 * This is used by multiple aggregate functions and conforms to the behaviour of SUM/MIN/MAX.
 * @param {FormulaArgument[]} args zero or more arguments
 * @param {boolean} refStringToZero true if strings in reference/matrix arguments should be collected as zeroes
 * @param {boolean} skipHidden true if hidden cells should be omitted when resolving range arguments
 * @returns {number[] | FormulaError}
 */
export const toNumList = (args, refStringToZero = false, skipHidden = false) => {
  const res = [];
  let r = 0;
  for (const arg of args) {
    if (isNum(arg)) {
      if (isFinite(arg)) {
        res[r++] = arg;
      }
    }
    else if (!!arg === arg) {
      res[r++] = +arg;
    }
    else if (isStr(arg)) {
      const n = arg ? toNum(arg) : ERROR_VALUE;
      if (isErr(n)) {
        return n;
      }
      if (isFinite(n)) {
        res[r++] = n;
      }
    }
    else if (isRef(arg) || isMatrix(arg)) {
      const matrix = arg.toMatrix(false);
      if (isErr(matrix)) {
        return matrix;
      }
      /** @type {(y: number) => boolean} */
      let skipRow;
      if (skipHidden && isRef(arg)) {
        const notErr = arg.resolveWorkbookAndSheet();
        invariant(!isErr(notErr), 'arg is a resolvable reference because toMatrix did not return an error');
        const { workbook, sheet } = notErr;
        invariant(sheet != null, 'reference resolves to a sheet because name refs are not passed to functions');
        skipRow = matrixY => !workbook.rowHeight(arg.top + matrixY + 1, sheet.name);
      }
      else {
        skipRow = () => false;
      }
      // Typecast because we know (but type checker doesn't) that iterAll
      // yields unboxed CellValues because we don't pass `leaveBoxed: true`
      // (could do some type gymnastics to make type checker know that, but nah)
      for (const { y, value } of /** @type {IterableIterator<{x: number, y: number, value: CellValue}>} */ (
        matrix.iterAll({ skipBlanks: 'all' })
      )) {
        if (skipRow(y)) {
          continue;
        }
        if (isErr(value)) {
          return value;
        }
        if (isNum(value) && isFinite(value)) {
          res[r++] = value;
        }
        else if (refStringToZero && isStr(value)) {
          res[r++] = 0;
        }
      }
    }
    else if (isErr(arg)) {
      return arg;
    }
  }
  return res;
};

/**
 * @param {FormulaValue} value
 * @returns {ArrayValue | null}
 */
export function resolve (value) {
  if (isRef(value) || isMatrix(value)) {
    return value.resolveSingle();
  }
  if (isLambda(value)) {
    return ERROR_CALC_LAMBDA_NOT_ALLOWED;
  }
  return value ?? null;
}

const re_chars = /[-/\\^$*+?.()|[\]{}]/g;
export function reEscape (s) {
  return String(s || '').replace(re_chars, '\\$&');
}

/**
 * @param {number[]} arr
 * @param {boolean} sample
 * @returns {{ sum: number, mean: number, stdDev: number | null, var: number | null}}
 */
export function stdDev (arr, sample = false) {
  if (!arr || arr.length < 1 || (sample && arr.length < 2)) {
    return { sum: 0, mean: 0, stdDev: null, var: null };
  }
  let sum = arr[0] || 0;
  let m = sum;
  let q = 0;
  const size = arr.length;
  for (let idx = 1; idx < size; idx++) {
    const x = arr[idx];
    sum += x;
    q = q + (idx * (x - m) ** 2) / (idx + 1);
    m = m + (x - m) / (idx + 1);
  }
  const v = sample ? q / (size - 1) : q / size;
  return {
    sum: sum,
    mean: sum / size,
    stdDev: Math.sqrt(v),
    var: v,
  };
}

/**
 * @param {number} value
 * @param {Reference} data
 * @param {string | number | boolean | Reference | FormulaError | Matrix | null | undefined} [order]
 * @returns {{ rank: number, valueCount: number } | FormulaError}
 */
export function rank (value, data, order) {
  if (isStr(order)) {
    return ERROR_VALUE;
  }

  order = toNum(order);
  if (isErr(order)) {
    return order;
  }

  if (!isRef(data)) {
    return ERROR_REF;
  }

  const values = data.resolveRange();
  if (isErr(values)) {
    return values;
  }
  const is_ascending = order && order !== 0;
  let rank = 1;
  let valueCount = 0;

  for (const v of values) {
    if (isNum(v)) {
      if (is_ascending) {
        if (v < value) {
          rank++;
        }
      }
      else if (v > value) {
        rank++;
      }
      if (v === value) {
        valueCount++;
      }
    }
  }

  if (valueCount === 0) {
    return ERROR_NA;
  }

  return { rank: rank, valueCount: valueCount };
}

export function bisectLeft (arr, x) {
  let lo = 0;
  let hi = arr.length;
  while (lo < hi) {
    const mid = (lo + hi) >> 1;
    if (x > arr[mid]) {
      lo = mid + 1;
    }
    else {
      hi = mid;
    }
  }
  return lo;
}

/**
 * @template T
 * @param {number[]} permutation
 * @param {T[]} array
 * @returns {T[]}
 */
export function applyPermutation (permutation, array) {
  return permutation.map(d => array[d]);
}

/**
 * @param {number[]} permutation
 * @returns {number[]}
 */
export function invertPermutation (permutation) {
  const result = permutation.slice(0);
  for (let i = 0; i < result.length; i++) {
    result[permutation[i]] = i;
  }
  return result;
}

/**
 * Returns a permutation which once applied sorts the array.
 * @param {number[]} array
 * @returns {number[]}
 */
export function sortingPermutation (array) {
  return Array.from(Array(array.length).keys()).sort((a, b) => {
    if (array[a] < array[b]) {
      return -1;
    }
    if (array[a] > array[b]) {
      return 1;
    }
    return 0;
  });
}

/**
 * Format a template literal as a string, representing cell values as they would
 * be represented in a spreadsheet formula.
 *
 * @example
 * ```tsx
 * fmtDetail`Condition is ${true}`;
 * //=> 'Condition is TRUE'
 *
 * fmtDetail`Length is ${42}`;
 * //=> 'Length is 42'
 *
 * fmtDetail`Name is ${'John'}`;
 * //=> 'Name is "John"'
 * ```
 *
 * @param {TemplateStringsArray} strings
 * @param {...CellValue | Lambda} values
 * @returns {string} formatted detail message
 */
export function fmtWithCellValues (strings, ...values) {
  let out = '';

  for (let i = 0; i < strings.length; i++) {
    out += strings[i];
    if (i < values.length) {
      out += formatCellValueForFormula(values[i]);
    }
  }

  return out;
}

/**
 * Format a cell value such that formula parsing would parse it as that value
 * (except not accounting for detail message if `value` is a `FormulaError`).
 * @param {CellValue | Lambda} value
 * @param {string} [blankAs='<blank>']
 * @returns {string}
 */
export function formatCellValueForFormula (value, blankAs = '<blank>') {
  if (value === null) {
    return blankAs;
  }
  if (isLambda(value)) {
    return value.toString();
  }
  switch (typeof value) {
    case 'string':
      // Escape quotes '"' using double quotes '""' (Excel string literal syntax)
      return '"' + value.replace(/"/g, '""') + '"';
    case 'boolean':
      return String(value).toUpperCase();
    case 'number':
    default:
      return String(value);
  }
}
