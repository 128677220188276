import { max } from '@grid-is/iterators';
import { isCellValueBlank } from '../utils-value';

/**
 * @template T
 * @param {T[]} arr
 * @param {(this: T[], elem: T, index: number, arr: T[]) => boolean} callbackfn
 */
function findLastIndex (arr, callbackfn) {
  let index = arr.length;
  while (index-- > 0) {
    if (callbackfn.call(arr, arr[index], index, arr)) {
      return index;
    }
  }
  return -1;
}

/**
 * @template {MaybeBoxed<ArrayValue> | Cell} T
 * @param {T[][] & Partial<AreaArrayAttributes>} array
 * @returns {T[][] & AreaArrayAttributes}
 */
export function cropRange (array) {
  // this method should always return a nested array
  if (!Array.isArray(array[0]) || (array.length === 1 && array[0].length === 0)) {
    return Object.assign([ [] ], { top: 0, left: 0, bottom: 0, right: 0, dataBottom: 0, dataRight: 0 });
  }
  // trim empty rows/cols off the range
  const emptyBottom = array.emptyBottom || 0;
  const emptyRight = array.emptyRight || 0;
  if (emptyBottom) {
    array.length -= emptyBottom;
    array.bottom = (array.bottom || (array.length || 1) - 1) - emptyBottom;
  }
  if (emptyRight) {
    array.right = (array.right || (array[0].length || 1) - 1) - emptyRight;
    array.forEach(row => {
      row.length -= emptyRight;
    });
  }

  // find last data point in remaining rows
  /** @type {number[]} */
  const lasts = array.map(row =>
    findLastIndex(
      row,
      // this asks the question: does the cell have blocking content?
      d => {
        // is it blank?
        // @ts-expect-error
        if (isCellValueBlank(d?.v)) {
          // we don't crop if the cell is part of a merge
          // @ts-expect-error
          return d && d.M;
        }
        // no cell value and no cell merge...
        return true;
      },
    ),
  );

  const maxIndexWithData = max(lasts) || 0;
  /** @type {typeof array[number][number][][]} */
  const finalArray = array.reduceRight((acc, row, i) => {
    const lastDataCell = lasts[i];
    if (lastDataCell === -1 && !acc.length) {
      // If there is no data in this row and we've not added anything to the
      // table yet, we skip this row. This will trim empty rows of the bottom.
    }
    else {
      row.length = maxIndexWithData + 1;
      acc.unshift(row);
    }
    return acc;
  }, /** @type {(typeof array)[number][number][][]} */ ([]));

  if (finalArray.length === 0) {
    finalArray.push([]);
  }

  // preserve origin meta values if we got any
  const top = array.top || 0;
  const left = array.left || 0;
  const dataBottom = top + finalArray.length - 1;
  const dataRight = left + finalArray[0].length - 1;
  /** @type {AreaArrayAttributes} */
  const meta = {
    top: top,
    left: left,
    bottom: dataBottom,
    right: dataRight,
    dataBottom,
    dataRight,
  };
  if (array.sheetName != null) {
    meta.sheetName = array.sheetName;
  }
  if (array.workbookName != null) {
    meta.workbookName = array.workbookName;
  }
  if (array.emptyBottom != null) {
    meta.emptyBottom = 0;
  }
  if (array.emptyRight != null) {
    meta.emptyRight = 0;
  }
  return Object.assign(finalArray, meta);
}
