export { default as Model, modelFromData } from './Model';
export { findVertexIdsNeedingInitRecalc } from './initRecalc';
export { default as Workbook } from './Workbook.js';
export { default as WorkSheet } from './WorkSheet';
export { default as Reference } from './excel/Reference.js';
export type Sheet = import('./WorkSheet').default;

import { ready as formulaParserReady } from './excel/formulaParser/index.js';
export { parseFormula, ready as formulaParserReady } from './excel/formulaParser/index.js';

export { default as Range } from './excel/referenceParser/Range.js';
export { parse as parseReference } from './excel/referenceParser/index.js';
export { offsFromCol, colFromOffs, a1ToRowColumn } from './excel/referenceParser/a1.js';

export { default as ModelError } from './ModelError.js';
export { default as FormulaError } from './excel/FormulaError';
export { default as Matrix } from './excel/Matrix.js';
export { default as Cell } from './excel/Cell';
export { Lambda } from './excel/lambda';
export { default as ValueBox } from './excel/ValueBox';

export { default as functions, loadLazy } from './excel/functions/index.js';

export { default as wbNameToKey } from './wbNameToKey.js';
export { isBoxed } from './excel/ValueBox.js';
import evaluateFormula from './excel/evaluate.js';
export { hasCellRoot, getRootsAndDependents } from './workbookRootsAndDependents';
export { type WorkbookRootsAndDependents } from './types';
export { constructCellID } from './utils.js';
export { functionSignatures } from './excel/functions/index.js';
export { toNum } from './excel/functions/utils.js';
export { parseDateTime } from './excel/functions/utils-number';

export { describeWorkbook } from './describeWorkbook';

export { flags } from './Flags';

export {
  ERROR_NAME,
  ERROR_VALUE,
  ERROR_REF,
  ERROR_DIV0,
  ERROR_NULL,
  ERROR_NUM,
  ERROR_NA,
  ERROR_GETDATA,
  ERROR_SPILL,
  ERROR_UNKNOWN,
  ERROR_FIELD,
  ERROR_CALC,
  ERROR_SYNTAX,
  MAX_COL,
  MAX_ROW,
} from './excel/constants.js';

export { MODE_GRID_SHEET, MODE_GOOGLE, MODE_EXCEL, MODE_CANVAS, MODE_ALL } from './mode.js';

export type { ModelEntity } from './modelEntities';

/**
 * @param {string} fx
 * @param {boolean} rawOutput
 * @param {EvaluationContext} resolver
 */
export function run (fx, rawOutput, resolver) {
  return evaluateFormula(fx, { ...resolver, rawOutput });
}

/**
 * @typedef {(
 *   formula: string,
 *   fromWorkbookName: string,
 *   toWorkbookName: string,
 * ) => string} ReplaceWorkbookReferencesFn
 */

/**
 * Make a function that replaces workbook names in formulas in a syntactically
 * sound way. That means:
 * (a) it will only affect occurrences of the previous name in reference
 *     prefixes (not e.g. in string literals)
 * (b) it takes quoting into account, including changing references to/from
 *     quoted form as appropriate.
 * (c) it matches workbook names case-insensitively.
 *
 * This is an async factory for such a function because we need to dynamically
 * import the waspiary module which is the actual implementation. The returned
 * function is synchronous.
 *
 * The returned function throws `FormulaSyntaxError` if the given `formula` is
 * not parseable to begin with.
 * @returns {Promise<ReplaceWorkbookReferencesFn>}
 */
export const getReplaceWorkbookFn = async () => {
  const formulaParser = await formulaParserReady;
  return formulaParser.replaceWorkbookReferences;
};

/**
 * @typedef {(
 *   formula: string,
 *   fromName: string,
 *   toName: string,
 *   workbookName: string,
 *   includeNoWb?: boolean
 * ) => string} ReplaceSheetReferencesFn
 */

/**
 * Make a function that replaces sheet names in formulas in a syntactically
 * sound way. That means:
 * (a) it will only affect occurrences of the previous name in reference
 *     prefixes (not e.g. in string literals), and only in those reference
 *     prefixes that have the given workbook name, or (if `includeNoWb` is true)
 *     have no workbook name.
 * (b) it takes quoting into account, including changing references to/from
 *     quoted form as appropriate.
 * (c) it matches sheet names and workbook names case-insensitively.
 *
 * This is an async factory for such a function because we need to dynamically
 * import the waspiary module which is the actual implementation. The returned
 * function is synchronous.
 *
 * The returned function throws `FormulaSyntaxError` if the given `formula` is
 * not parseable to begin with.
 * @returns {Promise<ReplaceSheetReferencesFn>}
 */
export const getReplaceSheetReferencesFn = async () => {
  const formulaParser = await import('./excel/formulaParser/parser.js');
  return formulaParser.replaceSheetReferences;
};

/**
 * Make a function that replaces table names in structured references in
 * formulas in a syntactically sound way. That means:
 * (a) it will only affect occurrences of the previous table name in structured
 *     references, and only in those structured references which have the given
 *     workbook name, or (if `includeNoWb` is true) have no workbook name.
 * (c) it matches workbook names and table names case-insensitively.
 *
 * This is an async factory for such a function because we need to dynamically
 * import the waspiary module which is the actual implementation. The returned
 * function is synchronous.
 *
 * The returned function throws `FormulaSyntaxError` if the given `formula` is
 * not parseable to begin with.
 * @returns {Promise<ReplaceTableReferencesFn>}
 */
export const getReplaceTableReferencesFn = async () => {
  const formulaParser = await import('./excel/formulaParser/parser.js');
  return formulaParser.replaceTableReferences;
};
