/* eslint-disable no-console */
import { formatDuration, now } from './devutil';
import type Cell from './excel/Cell';
import { type EvaluationContext } from './excel/EvaluationContext';
import Reference from './excel/Reference';
import { invariant } from './validation';

interface ProfileEntry {
  count: number,
  ms: number,
  formula?: string,
}

type ProfileStatsEntry = {
  count: number,
  ms: number,
  avgMs: number,
};

type RecalcSummary = {
  cellsEvaluated: Set<string>,
  formulaCellsUpdated: Map<Cell, unknown>,
};

class Category {
  public entries: Record<string, ProfileEntry> = {};

  /**
   * Start profiling with the given key. The caller must call the returned
   * callable to complete the measurement, else it will not be counted. Usage:
   * ```ts
   * const end = profile?.category('foo')?.start(makeKey());
   * try {
   *   // perform the work to be measured
   * }
   * finally {
   *   end?.();
   * }
   * ```
   */
  start (key: string, formula?: string): () => void {
    let entry = this.entries[key];
    if (!entry) {
      entry = { count: 0, ms: 0 };
      if (formula) {
        entry.formula = formula;
      }
      this.entries[key] = entry;
    }
    const startTime = now();
    return () => {
      entry.ms += now() - startTime;
      entry.count += 1;
    };
  }
}

const DEFAULT_CATEGORIES = [ 'isDirtyRef', 'calcCell', 'reorder', 'propagate' ] as const;
export type CategoryName = (typeof DEFAULT_CATEGORIES)[number];

export class Profile {
  public name: string;
  public defaultWorkbookName: string;
  private categories: Record<string, Category> = {};
  private milestoneTimes: { stepName: string, doneTime: number }[] = [];
  public static console = console;
  public queueSteps: number = 0;
  public queueStepsNotReached: number = 0;
  public queueStepsUpToDate: number = 0;

  private static manuallyEnabled = false;
  private static cpuProfilingManuallyEnabled: boolean = false;

  static get enabled () {
    if (this.manuallyEnabled) {
      return true;
    }
    try {
      return typeof localStorage !== 'undefined' && [ 'cpu', '1', 'true' ].includes(localStorage.profileApiary || '');
    }
    catch {
      return false;
    }
  }

  static set enabled (value: boolean) {
    this.manuallyEnabled = value;
  }

  static get cpuProfilingEnabled () {
    if (this.cpuProfilingManuallyEnabled) {
      return true;
    }
    try {
      return typeof localStorage !== 'undefined' && localStorage.profileApiary === 'cpu';
    }
    catch {
      return false;
    }
  }

  static set cpuProfilingEnabled (value: boolean) {
    this.cpuProfilingManuallyEnabled = value;
  }

  constructor (name: string, defaultWorkbookName: string, which = DEFAULT_CATEGORIES) {
    for (const categoryName of which) {
      this.categories[categoryName] = new Category();
    }
    if (Profile.cpuProfilingEnabled) {
      Profile.console.profile(`recalc ${name}`);
    }
    this.name = name;
    this.defaultWorkbookName = defaultWorkbookName;
  }

  category (which: CategoryName): Category | undefined {
    return this.categories[which];
  }

  milestone (stepName: string) {
    this.milestoneTimes.push({ stepName, doneTime: now() });
  }

  done () {
    if (Profile.cpuProfilingEnabled) {
      Profile.console.profileEnd(`recalc ${this.name}`);
    }
  }

  summarize (ts: RecalcSummary, ctx: EvaluationContext) {
    invariant(this.milestoneTimes.length > 0, 'No milestones recorded');
    const details: Record<string, string> = {};
    const startTime = this.milestoneTimes[0].doneTime;
    let previousDoneTime = startTime;
    for (const { stepName, doneTime } of this.milestoneTimes.slice(1)) {
      details[stepName] = formatDuration(doneTime - previousDoneTime);
      previousDoneTime = doneTime;
    }
    const endTime = previousDoneTime;
    Profile.console.log(`Recalculation ${this.name} done in ${formatDuration(endTime - startTime)}; details:`, details);
    Profile.console.log('Cells evaluated:', ts.cellsEvaluated.size, 'and updated:', ts.formulaCellsUpdated.size);
    Profile.console.log(
      'Main recalc loops:',
      this.queueSteps,
      'of which',
      this.queueStepsNotReached,
      'not reached, and',
      this.queueStepsUpToDate,
      'already up-to-date',
    );
    const calcCellCategory = this.categories.calcCell;
    if (ts.formulaCellsUpdated.size < ts.cellsEvaluated.size && calcCellCategory) {
      const ineffectualCalcCells = new Category();
      this.categories['calcCell with no effect'] = ineffectualCalcCells;
      for (const [ key, profileEntry ] of Object.entries(calcCellCategory.entries)) {
        const cell = new Reference(key, { ctx }).resolveCell();
        if (cell && !ts.formulaCellsUpdated.has(cell)) {
          ineffectualCalcCells.entries[key] = profileEntry;
        }
      }
    }
    for (const [ name, category ] of Object.entries(this.categories)) {
      const perfEntries: [string, ProfileEntry & { avgMs: number }][] = Object.entries(category.entries).map(
        ([ args, statsForTheseArgs ]) => [
          args,
          { ...statsForTheseArgs, avgMs: statsForTheseArgs.ms / statsForTheseArgs.count },
        ],
      );

      const numEntries = perfEntries.length;
      const total: ProfileStatsEntry = perfEntries.reduce(
        (accum, [ , stats ]) => ({
          count: accum.count + stats.count,
          ms: accum.ms + stats.ms,
          avgMs: accum.avgMs + stats.avgMs,
        }),
        { count: 0, ms: 0, avgMs: 0 },
      );
      Profile.console.groupCollapsed(
        `Profile ${name} (count=${total.count}, time=${formatDuration(total.ms)}, avg time=${formatDuration(
          total.avgMs,
        )}, distinct=${numEntries}, repeats=${total.count - numEntries}, max potential savings=${formatDuration(
          total.ms - total.avgMs,
        )})`,
      );
      const summary = perfEntries.sort(([ , aStats ], [ , bStats ]) => bStats.ms - aStats.ms).slice(0, 10);
      const restArgs = name === 'isDirtyRef' ? [ [ 'count', 'ms', 'avgMs' ] ] : [];
      Profile.console.table({ ...Object.fromEntries(summary), distinct: { count: numEntries }, total }, ...restArgs);
      Profile.console.groupEnd();
    }
  }

  /**
   * Make a string representation of the given reference, including the explicit or contextual workbook name if and only
   * if it is not the default workbook name
   */
  canonicalize (ref: Reference, contextWorkbookName?: string | null) {
    const effectiveWorkbookName: string = ref.workbookName || ref.ctx?.workbookName || contextWorkbookName || '';
    const effectiveSheetName: string = ref.sheetName || ref.ctx?.sheetName || '';
    if (ref.workbookName && effectiveWorkbookName.toLowerCase() === this.defaultWorkbookName.toLowerCase()) {
      ref = ref.withPrefix({ workbookName: '' });
    }
    else if (!ref.workbookName && effectiveWorkbookName.toLowerCase() !== this.defaultWorkbookName.toLowerCase()) {
      ref = ref.withPrefix({ workbookName: effectiveWorkbookName, sheetName: effectiveSheetName });
    }
    return String(ref);
  }
}
