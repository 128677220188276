import { ERROR_NA, MISSING, ERROR_DIV0, ERROR_VALUE } from '../constants.js';
import { isNum, isErr, isMatrix, isRef, isBool, isStr } from '../../utils.js';
import { isLambda } from '../lambda';

// There are sets of functions in Excel which implement essentially the same
// algorithm, with only minor differences in the way input data is coerced
// between data types. For example:
// * {SUM,MIN,MAX,AVERAGE} are the same as {SUM,MIN,MAX,AVERAGE}IFS,
//   after the if statements are evaluated.
// * Same applies for these functions and their variants which end with an A
//   (MINA, MAXA, AVERAGEA, etc), except the A functions coerce booleans into
//   numbers, and strings into zeros.
//
// This motivates the definition of different _FilterMap_ functions, inspired
// by the [filter-map concept][1] from functional programming. FilterMap
// functions have two return values. The first value implements the _filter_
// part of filter-map, and is a boolean which tells you if the element should
// be included or not in the iteration. The second value implements the _map_
// part, and is the value which should be returned to the iterator.
// A FilterMap function takes two arguments:
// * the value to be filtered and mapped
// * an optional boolean, true iff the value being passed comes from an array.
//
// [1]: https://doc.rust-lang.org/std/iter/struct.FilterMap.html
//

/**
 * @typedef {(function(ArrayValue | undefined, boolean):[boolean, ArrayValue | undefined])
 *   & { iterationOptions?: IterationOptions }
 * } FnFilterMap
 * @typedef {function(ArrayValue | undefined): boolean | void} FnVisit
 * @typedef {function(ArrayValue | undefined, number): boolean | void} FnVisitWithCount
 * @typedef {function(ArrayValue | undefined, boolean | undefined):
 *           ([true, number | FormulaError | Lambda] | [false, null])
 *   & { iterationOptions?: IterationOptions }
 * } FnNumberFilterMap
 * @typedef {function(number): boolean | void} FnVisitNumber
 */

/**
 * We call this the _standard_ filter-map, since it is so commonly seen. It
 * for example describes the behaviour of:
 * - MIN, MAX, AVERAGE, FREQUENCY
 * - MINA, MAXA (for values inside arrays)
 * It includes only numbers and error values (no blanks), and does not change them.
 * @param {ArrayValue | undefined} value
 * @returns {[ true, number | FormulaError | Lambda ] | [ false, null ]}
 */
export function standardFilterMap (value) {
  if (isNum(value) || isErr(value) || isLambda(value)) {
    return [ true, value ];
  }
  return [ false, null ];
}
standardFilterMap.iterationOptions = { skipBlanks: 'all' };

/**
 * Filter-map that accepts only numbers.
 * Like standardFilterMap, except error values are skipped.
 * @param {ArrayValue | undefined} value
 * @returns {[ true, number ] | [ false, null ]}
 */
export function numberFilterMap (value) {
  if (isNum(value)) {
    return [ true, value ];
  }
  return [ false, null ];
}

/**
 * This filter-map describes the behaviour of MINA, MAXA, AVERAGEA for values
 * except those inside arrays.
 * @param {ArrayValue | undefined} value
 * @returns {[true, number | FormulaError | Lambda ] | [ false, null ]}
 */
export function aggregationAFilterMap (value) {
  if (isNum(value) || isErr(value) || isLambda(value)) {
    return [ true, value ];
  }
  if (isBool(value)) {
    return [ true, value ? 1 : 0 ];
  }
  if (isStr(value)) {
    return [ true, 0 ];
  }
  return [ false, null ];
}

/**
 * Some functions exhibit different coercion behaviour
 * for values inside arrays, compared to those outside. This function
 * fuses together two filter-maps to create a new filter-map which delegates to
 * one or the other filter-map depending on if the values comes from an array
 * or not.
 * @param {FnNumberFilterMap} filterMap
 * @param {FnNumberFilterMap} arrayFilterMap
 * @returns {FnNumberFilterMap}
 */
export function arraySpecializeFilterMap (filterMap, arrayFilterMap) {
  return (x, isArray) => (isArray ? arrayFilterMap(x, true) : filterMap(x, false));
}

// We can now use this concept of a FilterMap to help us iterate over collections
// of values in the standard Excel left-to-right top-to-bottom manner. For each
// value the `visit` function iterates over, the user-supplied function `visitFunc`
// is called. If the `visitFunc` function return a truthy value, the iteration
// is short-circuited. This is useful when the user of this function knows that
// the result of the computation won't change in later iterations, for example
// when an error is encountered or a function like `AND` encounters a `FALSE`
// value. For the caller's convenience, this behaviour of short-circuiting when
// an error is encountered is built into `visitReturningError`.

/**
 * @param {FormulaArgument | FormulaArgument[]} item
 * @param {FnVisit} visitFunc
 * @param {FnFilterMap | null} [filterMap]
 */
export function visit (item, visitFunc, filterMap = null) {
  if (filterMap == null) {
    filterMap = x => [ true, x ];
  }
  visitRecursive(item, visitFunc, filterMap, visitMatrix);
}

/**
 * @param {FormulaArgument | FormulaArgument[]} item
 * @param {FnVisitWithCount} visitFunc
 * @param {FnFilterMap | null} [filterMap]
 */
export function visitWithCount (item, visitFunc, filterMap = null) {
  if (filterMap == null) {
    filterMap = x => [ true, x ];
  }
  visitRecursive(item, visitFunc, filterMap, visitMatrixWithCount);
}

/**
 * @param {FormulaArgument | FormulaArgument[]} args
 * @param {FnVisitNumber} visitFunc
 * @param {FnNumberFilterMap} filterMap
 * @returns {FormulaError | null}
 */
export function visitReturningError (args, visitFunc, filterMap) {
  let err = null;
  visitRecursive(
    args,
    x => {
      if (isErr(x)) {
        err = x;
        return true;
      }
      if (isLambda(x)) {
        err = ERROR_VALUE;
        return true;
      }
      return visitFunc(x);
    },
    filterMap,
    visitMatrix,
  );
  return err;
}

/**
 * @typedef {visitMatrix | visitMatrixWithCount} FnVisitMatrix
 */

/**
 * @template {'yes' | 'no'} WithCount
 * @param {FormulaArgument | FormulaArgument[]} item
 * @param {WithCount extends 'yes' ? FnVisitWithCount : FnVisit} visitFunc
 * @param {FnFilterMap} filterMap
 * @param {WithCount extends 'yes' ? visitMatrixWithCount : visitMatrix} visitMatrixFunc
 */
function visitRecursive (item, visitFunc, filterMap, visitMatrixFunc) {
  if (Array.isArray(item)) {
    // Accepting JavaScript arrays allows us to accept function arguments directly.
    for (const v of item) {
      if (visitRecursive(v, visitFunc, filterMap, visitMatrixFunc)) {
        return true;
      }
    }
  }
  else if (isMatrix(item)) {
    if (
      visitMatrixFunc(
        item,
        // @ts-expect-error tsc fails to infer that visitFunc is FnVisitWithCount when visitMatrixFunc is visitMatrixWithCount
        visitFunc,
        filterMap,
      )
    ) {
      return true;
    }
  }
  else if (isRef(item)) {
    const matrix = item.toMatrix(false);
    if (isErr(matrix)) {
      return visitFunc(matrix, 1);
    }
    const modFilterMap = arg => filterMap(arg, false);
    modFilterMap.iterationOptions = filterMap.iterationOptions;
    if (
      visitMatrixFunc(
        matrix,
        // @ts-expect-error tsc fails to infer that visitFunc is FnVisitWithCount when visitMatrixFunc is visitMatrixWithCount
        visitFunc,
        modFilterMap,
      )
    ) {
      return true;
    }
  }
  else if (isLambda(item)) {
    return true;
  }
  else {
    const [ include, mapped ] = filterMap(item, false);
    if (!include) {
      return false;
    }
    if (visitFunc(mapped, 1)) {
      return true;
    }
  }
  return false;
}

/**
 * @param {Matrix} matrix
 * @param {FnVisit} visitFunc
 * @param {FnFilterMap} filterMap
 */
function visitMatrix (matrix, visitFunc, filterMap) {
  const iterOptions = filterMap.iterationOptions || {};
  for (const { value } of matrix.iterAll(iterOptions)) {
    // @ts-expect-error (value is an ArrayValue because we passed leaveBoxed: false)
    const [ include, mapped ] = filterMap(value, true);
    if (!include) {
      continue;
    }
    if (visitFunc(mapped)) {
      return true;
    }
  }
  return false;
}

/**
 * @param {Matrix} matrix
 * @param {FnVisitWithCount} visitFunc
 * @param {FnFilterMap} filterMap
 */
function visitMatrixWithCount (matrix, visitFunc, filterMap) {
  for (const { value, count } of matrix.iterAllWithCount()) {
    const [ include, mapped ] = filterMap(value, true);
    if (!include) {
      continue;
    }
    if (visitFunc(mapped, count)) {
      return true;
    }
  }
  return false;
}

/**
 * This is a variation of the `visit` function which describes the behaviour
 * seen in GCD, LCM, IMSUB, and IMSUM. These are all functions which belong to
 * the "engineering" category, hence the function's name. What is different
 * between this function and vanilla `visit` is that we return `ERROR_NA` if the
 * missing value precedes any non-missing value.
 *
 * Árni suspects there are more functions which exhibit this same behaviour,
 * but we don't know for sure.
 * @param {FormulaArgument[]} items
 * @param {FnVisit} visitFunc
 * @param {FnFilterMap | null} [filterMap]
 * @returns {FormulaError | null}
 */
export function visitEngineering (items, visitFunc, filterMap = null) {
  if (filterMap == null) {
    filterMap = x => [ true, x ];
  }
  let encounteredNonMissing = false;
  let err = null;
  visitRecursive(
    items,
    val => {
      if (isErr(val)) {
        err = val;
        return true;
      }
      if (val === MISSING) {
        if (!encounteredNonMissing) {
          err = ERROR_NA;
          return true;
        }
        return false;
      }
      encounteredNonMissing = true;
      return visitFunc(val);
    },
    filterMap,
    visitMatrix,
  );
  return err;
}

/**
 * A convenient wrapper around `visit` which _collects_ all of the visited values into a JavaScript array.
 * Specific to numbers (just because those are the only current usages and the specificity aids type checking).
 * If any error value is found, that is returned instead.
 * @param {FormulaArgument} item
 * @param {FnNumberFilterMap} filterMap
 * @returns {number[] | FormulaError}
 */
export function collectNumbers (item, filterMap) {
  /** @type {number[]} */
  const result = [];
  const error = visitReturningError(
    item,
    x => {
      result.push(x);
    },
    filterMap,
  );
  return error ?? result;
}

/**
 * The algorithm for AVERAGE, AVERAGEA, AVERAGEIFS, independent of value coercion behaviour.
 * @param {FormulaArgument | FormulaArgument[]} args
 * @param {FnNumberFilterMap} filterMap
 * @returns {number | FormulaError}
 */
export function average (args, filterMap) {
  let sum = 0;
  let count = 0;
  const err = visitReturningError(
    args,
    x => {
      sum += x;
      count += 1;
    },
    filterMap,
  );
  if (isErr(err)) {
    return err;
  }
  const result = sum / count;
  if (!isFinite(result)) {
    return ERROR_DIV0;
  }
  return result;
}

/**
 * The algorithm for MIN, MINA, MINIFS, independent of value coercion behaviour.
 * @param {FormulaArgument | FormulaArgument[]} args
 * @param {FnNumberFilterMap} filterMap
 * @returns {number | FormulaError}
 */
export function min (args, filterMap) {
  let min = Infinity;
  const err = visitReturningError(
    args,
    x => {
      if (x < min) {
        min = x;
      }
    },
    filterMap,
  );
  if (isErr(err)) {
    return err;
  }
  return isFinite(min) ? min : 0;
}

/**
 * The algorithm for MAX, MAXA, MAXIFS, independent of value coercion behaviour.
 * @param {FormulaArgument | FormulaArgument[]} args
 * @param {FnNumberFilterMap} filterMap
 * @returns {number | FormulaError}
 */
export function max (args, filterMap) {
  let max = -Infinity;
  const err = visitReturningError(
    args,
    x => {
      if (x > max) {
        max = x;
      }
    },
    filterMap,
  );
  if (isErr(err)) {
    return err;
  }
  return isFinite(max) ? max : 0;
}

/**
 * The algorithm for SUM, SUMIFS, independent of value coercion behaviour.
 * @param {FormulaArgument | FormulaArgument[]} args
 * @param {FnNumberFilterMap} filterMap
 * @returns {number | FormulaError}
 */
export function sum (args, filterMap) {
  let sum = 0;
  const err = visitReturningError(
    args,
    x => {
      sum += x;
    },
    filterMap,
  );
  if (isErr(err)) {
    return err;
  }
  return sum;
}

/**
 * The algorithm for COUNT, COUNTA, COUNTIFS, COUNTBLANK, independent of value coercion behaviour.
 * @param {FormulaArgument | FormulaArgument[]} args
 * @param {FnFilterMap | null} [filterMap]
 * @returns {number}
 */
export function count (args, filterMap) {
  let sum = 0;
  visitWithCount(
    args,
    (_value, count) => {
      sum += count;
    },
    filterMap,
  );
  return sum;
}
