import { box, unbox, isBoxed } from './ValueBox.js';
import { zeroCancel } from './functions/utils-number';
import { invariant } from '../validation';

function valueboxBinop (a, b, transform) {
  if (isBoxed(a) || isBoxed(b)) {
    return box(transform(unbox(a), unbox(b)), { ...b.metadata, ...a.metadata });
  }
  return transform(a, b);
}

export function add (a, b) {
  return valueboxBinop(a, b, (a, b) => zeroCancel(a + b, a));
}

export function sub (a, b) {
  return valueboxBinop(a, b, (a, b) => zeroCancel(a - b, a));
}

export function mul (a, b) {
  return valueboxBinop(a, b, (a, b) => a * b);
}

export function div (a, b) {
  return valueboxBinop(a, b, (a, b) => {
    invariant(b !== 0, 'Division by zero');
    return a / b;
  });
}

export function pow (a, b) {
  return valueboxBinop(a, b, (a, b) => a ** b);
}
