import { match } from './utils-glob';
import { operatorCollate, strCompare } from './operators.js';
import { isStr } from '../../utils.js';
import { TYPE_NUM, TYPE_STRING, TYPE_BOOL, TYPE_BLANK, ArrayValue, CellValueAtom, TYPE_LAMBDA } from '../types';
import { valueToType } from '../coerce';
import { type MaybeBoxed, unbox } from '../ValueBox.js';
import { Lambda, isLambda } from '../lambda';

/**
 * This is a JavaScript `.sort()` compatible collator used by SORT and SORTBY.
 * - Returns zero when `a` and `b` are equal (or nearly so, per Excel).
 * - Returns a negative number when `a > b`.
 * - Returns a positive number when `a < b`.
 */
export function sortCollate (a: MaybeBoxed<ArrayValue>, b: MaybeBoxed<ArrayValue>, isAsc: boolean = true): number {
  a = unbox(a);
  b = unbox(b);
  const aIsBlank = a == null;
  const bIsBlank = b == null;
  if (aIsBlank || bIsBlank) {
    // Blanks are always on the top, no matter the sort order!
    return +aIsBlank - +bIsBlank;
  }
  let result = operatorCollate(a, b);
  if (!isAsc) {
    result = -result;
  }
  return result;
}

export const EQUAL = 0;
export const INDETERMINATE = NaN;

function isIndeterminate (x: number): boolean {
  return isNaN(x);
}

// Instead of there being three collator states (<, >, ==), we additionally
// have indeterminate (NaN). That's because in the case where the two types being
// compared are different, there is no obvious ordering between the two, so we
// treat it differently in binary search.
export function makeCollator (
  needle: MaybeBoxed<boolean | number | string | null | Lambda>,
  fuzzyString: boolean = false,
): (x: MaybeBoxed<ArrayValue>) => number {
  needle = unbox(needle);

  const needleType = valueToType(needle);
  if (needleType & (TYPE_BOOL | TYPE_NUM)) {
    const typeofNeedle = typeof needle;
    return x => {
      x = unbox(x);
      if (typeof x !== typeofNeedle) {
        return INDETERMINATE;
      }
      // @ts-expect-error Both `needle` and `x` are either numbers or booleans.
      return x - needle;
    };
  }
  else if (needleType === TYPE_STRING) {
    if (fuzzyString) {
      return x => {
        x = unbox(x);
        if (!isStr(x)) {
          return INDETERMINATE;
        }
        // Returns either 0 (equal) or NaN (indeterminate)
        return Math.floor(Math.abs(match(needle as string, x))) && INDETERMINATE;
      };
    }
    else {
      return x => {
        x = unbox(x);
        if (!isStr(x)) {
          return INDETERMINATE;
        }
        // @ts-expect-error `needle` is string
        return strCompare(x, needle);
      };
    }
  }
  else if (needleType === TYPE_BLANK) {
    return x => {
      x = unbox(x);
      if (x != null) {
        return INDETERMINATE;
      }
      return 0;
    };
  }
  else if (needleType === TYPE_LAMBDA) {
    return x => (isLambda(x) ? 0 : INDETERMINATE);
  }
  throw new Error('unreachable');
}

/**
 * Corresponds to MATCH's -1 setting
 */
export function searchExactDesc (
  needle: CellValueAtom | Lambda,
  length: number,
  getCellValue: (i: number) => MaybeBoxed<ArrayValue>,
  repeatStart: number = length - 1,
) {
  // Last index containing a value greater than or equal to needle
  let lastIdxGreaterThan = -1;
  const collator = makeCollator(needle);
  const needleType = valueToType(needle);
  for (let i = 0; i <= repeatStart; i += 1) {
    const x = getCellValue(i);
    const cmp = collator(x);
    if (valueToType(x) !== needleType) {
      continue;
    }
    if (cmp === EQUAL) {
      return i;
    }
    if (cmp < 0) {
      return lastIdxGreaterThan;
    }
    lastIdxGreaterThan = i;
  }
  return lastIdxGreaterThan === repeatStart ? length - 1 : lastIdxGreaterThan;
}

/**
 * Corresponds to MATCH's 0 setting
 */
export function searchExact (
  needle: string | number | boolean | Lambda,
  repeatStart: number,
  getCellValue: (n: number) => ArrayValue,
) {
  const collator = makeCollator(needle, true);
  const needleType = valueToType(needle);
  for (let i = 0; i <= repeatStart; i += 1) {
    const x = getCellValue(i);
    if (valueToType(x) === needleType && collator(x) === EQUAL) {
      return i;
    }
  }
  return -1;
}

/**
 * Corresponds to MATCH's 1 setting
 */
export function searchBinary (
  needle: string | number | boolean | Lambda,
  length: number,
  getCellValue: (n: number) => MaybeBoxed<ArrayValue>,
  repeatStart: number = Infinity,
): number {
  let ptr = -1;
  let cmp = INDETERMINATE;
  let lo = 0;
  let hi = length - 1;
  const collator = makeCollator(needle, false);
  while (lo <= hi) {
    ptr = Math.floor((lo + hi) / 2);
    cmp = collator(getCellValue(ptr));

    if (isIndeterminate(cmp)) {
      for (let i = ptr + 1; i <= hi; i += 1) {
        cmp = collator(getCellValue(i));
        if (!isIndeterminate(cmp)) {
          ptr = i;
          break;
        }
      }
    }

    // Less than
    if (cmp < 0) {
      lo = ptr + 1;
    }
    // Greater than or indeterminate
    else if (cmp !== 0) {
      hi = ptr - 1;
    }
    else {
      // If there are multiple values side-by-side which are equal to the
      // needle, we pick the rightmost one.
      for (;;) {
        if (ptr >= repeatStart) {
          return length - 1;
        }
        ptr += 1;
        cmp = collator(getCellValue(ptr));
        if (cmp !== EQUAL || ptr >= length) {
          return ptr - 1;
        }
      }
    }
  }
  // Greater than or indeterminate (the latter is expressed by `NaN` so `cmp > 0` would _not_ be equivalent)
  while (!(cmp <= 0)) {
    ptr -= 1;
    if (ptr < 0) {
      break;
    }
    cmp = collator(getCellValue(ptr));
  }
  return ptr;
}

/**
 */
export function searchWildcardXlookup (
  needle: CellValueAtom,
  length: number,
  getCellValue: (i: number) => ArrayValue,
  ascending: boolean,
  repeatStart: number,
) {
  let start, step, cond;
  if (ascending) {
    start = 0;
    step = 1;
    cond = (i: number) => i <= repeatStart;
  }
  else {
    start = repeatStart;
    step = -1;
    cond = (i: number) => i >= 0;
  }
  // It's fine to use the LOOKUP, VLOOKUP, HLOOKUP, MATCH collator instead of
  // the XLOOKUP, SORT, SORTBY collator, because it should be the same when
  // checking for equality.
  const collator = makeCollator(needle, true);
  for (let i = start; cond(i); i += step) {
    const x = getCellValue(i);
    const cmp = collator(x);
    if (cmp === 0) {
      // found value x at position i
      if (!ascending && i >= repeatStart) {
        // Found x is in repeated quadrant of matrix. Return index of last element.
        return length - 1;
      }
      return i;
    }
  }
  return -1;
}

export function searchExactXlookup (
  needle: MaybeBoxed<ArrayValue>,
  length: number,
  getCellValue: (i: number) => ArrayValue,
  ascending: boolean,
  matchMode: number,
  repeatStart: number,
) {
  const collator = (x: ArrayValue) => sortCollate(x, needle, true);
  let bestIdx = -1;
  let bestValue: ArrayValue = null;
  let start, step, cond;
  if (ascending) {
    start = 0;
    step = 1;
    cond = (i: number) => i <= repeatStart;
  }
  else {
    start = repeatStart;
    step = -1;
    cond = (i: number) => i >= 0;
  }
  let exactFound = false;
  for (let i = start; cond(i); i += step) {
    const x = getCellValue(i);
    const cmp = collator(x);
    if (cmp === EQUAL) {
      exactFound = true;
      bestIdx = i;
      break;
    }
    if (
      (cmp < 0 && matchMode === -1 && (bestIdx === -1 || sortCollate(x, bestValue) > 0)) ||
      (cmp > 0 && matchMode === 1 && (bestIdx === -1 || sortCollate(x, bestValue) < 0))
    ) {
      bestIdx = i;
      bestValue = x;
    }
  }
  if (matchMode === 0 && !exactFound) {
    return -1;
  }
  if (!ascending && bestIdx >= repeatStart) {
    // Found x is in repeated quadrant of matrix. Return index of last element.
    return length - 1;
  }
  return bestIdx;
}

export function searchBinaryXlookup (
  needle: ArrayValue,
  length: number,
  getCellValue: (arg0: number) => ArrayValue,
  ascending: boolean,
  matchMode: number,
) {
  let enterDownAlgoCond: (mm: number) => boolean,
    goDownCond: (cmp: number) => boolean,
    goUpCond: (cmp: number) => boolean,
    hiSplitCond: (cmp: number) => boolean;
  let ptr = -1;
  let cmp = INDETERMINATE;
  // Do you notice how the conditionals which act on `cmp` are _almost_ opposite
  // to each other? It suggests there may be some other cleverer way of
  // implementing ascending/descending mode. Two approaches I've tried are:
  // * Negate the collator's result, either by using `-cmp` or by setting
  //   `isAscending` to false in the arguments to `sortCollate`. These two
  //   approaches are the same except w.r.t. how they handle the BLANK value.
  // * Reverse the `getCellValue` argument.
  // I briefly tried all three approaches and none of them worked without
  // further tricks.
  if (ascending) {
    enterDownAlgoCond = mm => mm === -1;
    goDownCond = cmp => cmp > 0;
    goUpCond = cmp => cmp < 0;
    hiSplitCond = cmp => cmp >= 0;
  }
  else {
    enterDownAlgoCond = mm => mm === 1;
    goDownCond = cmp => cmp < 0;
    goUpCond = cmp => cmp > 0;
    hiSplitCond = cmp => cmp < 0;
  }
  let lo = 0;
  let hi = length - 1;
  const collator = (x: ArrayValue) => sortCollate(x, needle, true);
  let equalIdx: number | null = null;
  while (lo <= hi) {
    ptr = Math.floor((lo + hi) / 2);
    cmp = collator(getCellValue(ptr));
    if (hiSplitCond(cmp)) {
      hi = ptr - 1;
    }
    else {
      lo = ptr + 1;
    }
    if (cmp === 0) {
      equalIdx = ptr;
    }
  }
  if (equalIdx != null) {
    return equalIdx;
  }
  if (matchMode === 0) {
    return -1;
  }
  if (enterDownAlgoCond(matchMode)) {
    // "Down algorithm"
    while (goDownCond(cmp)) {
      ptr -= 1;
      if (ptr < 0) {
        break;
      }
      cmp = collator(getCellValue(ptr));
    }
  }
  else {
    // "Up algorithm"
    while (goUpCond(cmp)) {
      ptr += 1;
      if (ptr >= length) {
        break;
      }
      cmp = collator(getCellValue(ptr));
    }
  }
  return ptr;
}
