import { MAX_COL, MAX_ROW } from '../constants';

const code_A = 65;
const code_Z = 90;
const code_a = 97;
const code_z = 122;
const code_0 = 48;
const code_9 = 57;
const code_DOLLAR = 36;

export const LOCK = '$';

/**
 * Convert a numeric column index (zero-based) to an upper-case column name. 0 to A, 1 to B, 26 to AA, and so on.
 * @param {Number} col a zero-based column index
 * @returns {string} the corresponding column name in upper case.
 */
export function colFromOffs (col) {
  let n = col;
  let c = '';
  while (n >= 0) {
    c = String.fromCharCode((n % 26) + code_A) + c;
    n = Math.floor(n / 26) - 1;
  }
  return c;
}

export function offsFromCol (colstr) {
  let d = 0;
  let i = 0;
  for (; i !== colstr.length; ++i) {
    const chr = colstr.charCodeAt(i);
    if (chr >= code_A && chr <= code_Z) {
      // omits any non A-Z character
      d = 26 * d + chr - 64;
    }
    else if (chr >= code_a && chr <= code_z) {
      d = 26 * d + chr - 96;
    }
  }
  return d - 1;
}

/**
 * Convert an A1 cell address into zero-based row and column indices
 * @param {string} a1Address A cell address in A1 format
 * @returns {[ row: number, column: number ]} An array with zero-based cell coordinates: [row, column]
 * @throws {Error} if a1Address is not a cell address in A1 format
 */
export function a1ToRowColumn (a1Address) {
  let colStart = -1;
  let colEnd = -1;
  let rowStart = -1;

  // Scan the string to find out where the column letter(s) are located and where the row number starts
  for (let i = 0; i !== a1Address.length; ++i) {
    const chr = a1Address.charCodeAt(i);
    const isLetter = (chr >= code_A && chr <= code_Z) || (chr >= code_a && chr <= code_z);
    if (colStart === -1 && isLetter) {
      colStart = i;
    }
    else if (!isLetter) {
      if (colStart > -1 && colEnd === -1) {
        colEnd = i;
      }
      const isDigit = chr >= code_0 && chr <= code_9;
      if (isDigit && rowStart === -1 && chr > code_0) {
        rowStart = i;
      }
      else if (!(isDigit || (chr === code_DOLLAR && rowStart === -1))) {
        throw new Error('Invalid character: ' + a1Address.charAt(i));
      }
    }
    else if (rowStart !== -1) {
      throw new Error('Letter after row: ' + a1Address.charAt(i));
    }
  }
  const validSequence = colStart < colEnd && colEnd <= rowStart;
  if (!validSequence) {
    throw new Error('Invalid A1 address: ' + a1Address);
  }
  const col = offsFromCol(a1Address.slice(colStart, colEnd));
  const row = parseInt(a1Address.slice(rowStart), 10) - 1;
  // Row bound check disabled because we have native workbooks with out-of-bound cells which can no longer be loaded.
  // See GRID-3525
  if (col > MAX_COL || row > MAX_ROW) {
    throw new Error('A1 address out of bounds: ' + a1Address);
  }
  return [ row, col ];
}

/**
 * Convert an A1 cell address into zero-based row and column indices, if possible.
 * Like `a1ToRowColumn` but returns `null` instead of throwing an error, if `a1Address` is not an A1 cell address.
 * @param {string} a1Address
 * @returns {[ row: number, column: number ] | null} zero-based row and column indices, or `null` if `a1Address` is not
 *   a cell address in A1 format.
 */
export function a1ToRowColumnOrNull (a1Address) {
  try {
    return a1ToRowColumn(a1Address);
  }
  catch (err) {
    return null;
  }
}
