import { format as formatNumber } from 'numfmt';
import { DEV_LOGGING } from '../devutil.js';

class ValueBox {
  constructor () {
    this._value = null;
    this._metadata = null;
  }

  map (transformFunc) {
    return ValueBox.fromValue(transformFunc(this.value), this.metadata);
  }

  get value () {
    return this._value;
  }

  get metadata () {
    return this._metadata ?? {};
  }

  toString () {
    const formatPattern = this._metadata?.numberFormat;
    if (typeof this._value === 'number' && formatPattern) {
      try {
        return formatNumber(formatPattern, this._value);
      }
      catch (err) {
        // Ignore error and format normally
        DEV_LOGGING && console.error('numfmt error in ValueBox, falling back to `_value.toString()`', err);
      }
    }
    else if (this._value == null) {
      return '';
    }
    return this._value.toString();
  }

  static fromValue (value, metadata) {
    if (value instanceof ValueBox) {
      return value;
    }

    const vb = new ValueBox();
    vb._value = value;
    vb._metadata = metadata;
    return vb;
  }
}

export function box (value, metadata) {
  return ValueBox.fromValue(value, metadata);
}

export function unbox (value) {
  if (value instanceof ValueBox) {
    return value._value;
  }
  return value;
}

/**
 * @param {any} value
 * @returns {value is ValueBox}
 */
export function isBoxed (value) {
  return value instanceof ValueBox;
}

/**
 * @param {MaybeBoxed<CellValue>} a
 * @param {MaybeBoxed<CellValue>} b
 */
export function maybeBoxedValuesEqual (a, b) {
  if (!isBoxed(a) || !isBoxed(b)) {
    return a === b;
  }
  if (a._value !== b._value) {
    return false;
  }
  const aKeys = Object.keys(a.metadata);
  const bKeys = Object.keys(b.metadata);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  const bKeySet = new Set(bKeys);
  for (const key of aKeys) {
    if (!bKeySet.has(key)) {
      return false;
    }
    if (a.metadata[key] !== b.metadata[key]) {
      return false;
    }
  }
  return true;
}

export function mapMaybeBox (value, transformFunc) {
  if (isBoxed(value)) {
    return value.map(transformFunc);
  }
  return transformFunc(value);
}

export default ValueBox;
