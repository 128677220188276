import FormulaError from './excel/FormulaError';
import { isBoxed, unbox } from './excel/ValueBox.js';

/**
 * @param {any} d
 * @return {d is MaybeBoxed<FormulaError>}
 */
export function isErr (d) {
  return unbox(d) instanceof FormulaError;
}

/**
 * @param {any} d
 * @returns {d is MaybeBoxed<boolean>}
 */
export function isBool (d) {
  return typeof unbox(d) === 'boolean';
}

/**
 * @param {any} d
 * @returns {d is MaybeBoxed<number>}
 */
export function isNum (d) {
  return typeof unbox(d) === 'number';
}

/**
 * @param {any} d
 * @returns {d is MaybeBoxed<string>}
 */
export function isStr (d) {
  return typeof unbox(d) === 'string';
}

/**
 * @param {any} d
 * @returns {d is MaybeBoxed<CellValue>} d
 */
export function isCellValue (d) {
  return d === null || isBoxed(d) || isStr(d) || isNum(d) || isBool(d) || isErr(d);
}
