import {
  ERROR_VALUE,
  ERROR_NUM,
  ERROR_NAME,
  ERROR_NA,
  MISSING,
  MAX_STRING_CHARS,
  MODE_GOOGLE,
  MODE_EXCEL,
} from '../constants.js';
import { toNum, toStr, isErr, reEscape, isRef, isMatrix } from './utils.js';
import { parseDateTime } from './utils-number';
import { visit } from './utils-visit.js';
import { format as formatNumber } from 'numfmt';
import { match } from './utils-glob';
import { ROUND } from './math.js';
import Matrix from '../Matrix.js';
import { vstackAll } from './array.js';
import { isStr, isBool, isNum } from '../../utils.js';
import { type CellValue, TYPE_BOOL, TYPE_NUM } from '../types';
import { coerceValue } from '../coerce';
import type { EvaluationContext } from '../EvaluationContext';
import { invariant } from '../../validation';
import type FormulaError from '../FormulaError.js';
import type Reference from '../Reference.js';
import { ModeBit } from './sigtypes.js';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, Lambda, isLambda } from '../lambda';

function intoCodepoints (str: string): string[] {
  return [ ...str ];
}

// ASC(text)
// Converts full-width ASCII and katakana characters to their half-width counterparts.
// All standard-width characters will remain unchanged.
export function ASC () {
  return ERROR_NAME;
}

/**
 * BAHTTEXT(num)
 * The number converted to a Thai text string and appends 'Baht'.
 */
export function BAHTTEXT (num: number): string {
  // The following was used as reference:
  // (MIT) https://github.com/yeeraf/number-to-bahttext/blob/master/src/Thaibaht.php
  const BAHT = 'บาท'; // The currency of Thailand is called a baht.
  const SATANG = 'สตางค์'; // A satang is one hundredth of a baht.
  const THAWN = 'ถ้วน'; // If there are no satangs, then this is the suffix.
  const MILLION = 'ล้าน';
  const ELEVEN = 'เอ็ด';
  const TWENTY = 'ยี่';
  const MINUS = 'ลบ';
  const ZERO = 'ศูนย์';
  const THAI_DIGIT = [ '', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า', 'สิบ' ];
  const THAI_POWER = [ '', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน' ];

  const toThai = (num: number) => {
    let result = '';
    if (num >= 1000000) {
      result += toThai(Math.floor(num / 1000000)) + MILLION;
      num = Math.round(num % 1000000);
    }
    let log = 5;
    let divisor = 100000;
    while (num > 0) {
      const n = Math.floor(num / divisor);
      if (n === 1 && divisor === 1) {
        result += result !== '' ? ELEVEN : THAI_DIGIT[n];
      }
      else if (n === 2 && divisor === 10) {
        result += TWENTY;
      }
      else if (n !== 1 || divisor !== 10) {
        result += THAI_DIGIT[n];
      }
      result += n >= 1 ? THAI_POWER[log] : '';
      num %= divisor;
      divisor /= 10;
      log -= 1;
    }
    return result;
  };

  let result = '';

  if (num < 0) {
    result += MINUS;
  }

  num = Math.abs(num);
  const bahts = Math.floor(num);
  const satangs = Math.round((num - bahts) * 100);

  if (bahts < 1 && satangs < 1) {
    result += ZERO;
  }
  else if (bahts >= 1) {
    result += toThai(bahts);
  }
  if (result) {
    result += BAHT;
  }
  if (satangs >= 1) {
    result += toThai(satangs) + SATANG;
  }
  else {
    result += THAWN;
  }
  return result;
}

/**
 * Convert a number into a character according to the current Unicode table.
 */
export function CHAR (this: EvaluationContext, code: number): string | FormulaError {
  if (this.mode === MODE_EXCEL && code > 255) {
    return ERROR_VALUE;
  }
  // CHAR uses the system character mapping to determine which character to
  // return. For example iso-8859-1, iso-8859-2, Windows-1252, Windows-1250.
  // Since GRID runs in the browser, it's only natural to use Unicode as the
  // character encoding. That is also what Google Sheets does.
  return UNICHAR(code);
}

/**
 * Remove the first 32 nonprinting characters in the 7-bit ASCII code (values
 * 0 through 31) from text.
 */
export function CLEAN (text: string): string {
  return Array.from(text)
    .filter(c => c.charCodeAt(0) >= 32)
    .join('');
}

/**
 * Returns the numeric Unicode map value of the first character in the string.
 */
export function CODE (this: EvaluationContext, text: string): number | FormulaError {
  const result = UNICODE(text);
  if (isErr(result)) {
    return result;
  }
  if (this.mode !== MODE_GOOGLE && result > 255) {
    // Question mark ("?") in ASCII. Same return value as Excel Online.
    return 63;
  }
  return result;
}

/**
 * CONCAT(value1, value2)
 * Returns the concatenation of two values. Equivalent to the `&` operator.
 */
export function CONCAT (...args: (string | Matrix | Reference | undefined)[]): string | FormulaError {
  return TEXTJOIN([], true, ...args);
}

/**
 * CONCATENATE -- deprecated
 */
export function CONCATENATE (...args: (string | Matrix | Reference | undefined)[]): string | FormulaError {
  return CONCAT(...args);
}

// DBCS(???)
// The text string converted from single byte to double byte characters.
export function DBCS () {
  return ERROR_NAME;
}

/**
 * DOLLAR(number, [number_of_places])
 * Formats a number into the currency specific to your spreadsheet locale.
 */
export function DOLLAR (this: EvaluationContext, number: number, decimals: number = 2): string | FormulaError {
  let decimalString = '';
  if (decimals > 0) {
    decimalString = '.' + '0'.repeat(decimals);
  }
  else if (decimals < 0) {
    number = ROUND(number, decimals);
  }
  const formatString = `$#,##0${decimalString};($#,##0${decimalString})`;
  return TEXT.call(this, number, formatString);
}

/**
 * ENCODEURL(text)
 * Returns a URL-encoded string.
 */
export function ENCODEURL (text: string) {
  // eslint-disable-next-line newline-per-chained-call
  const toHex = (d: string) => '%' + d.charCodeAt(0).toString(16).toUpperCase();
  try {
    // encodeURIComponent will throw if it encounters unpaired surrogates.
    // we've not reproduced this in Excel or Sheets, so the type of error
    // returned is still unknown, #VALUE! seems most likely.
    return encodeURIComponent(text).replace(/[!~*'()]/g, toHex);
  }
  catch (err) {
    return ERROR_VALUE;
  }
}

/**
 * EXACT(string1, string2)
 * Tests whether two strings are identical.
 */
export function EXACT (string1: string | Lambda, string2: string | Lambda): boolean | FormulaError {
  if (isLambda(string1) || isLambda(string2)) {
    return ERROR_CALC_LAMBDA_NOT_ALLOWED;
  }
  return string1 === string2;
}

/**
 * FIND(search_for, text_to_search, [starting_at])
 * Returns the position at which a string is first found within text where the capitalization
 * of letters matters. Returns `#VALUE!` if the string is not found.
 */
export function FIND (needle: string, haystack: string, offs: number = 1): number | FormulaError {
  offs = Math.floor(offs);

  if (!needle) {
    return offs <= haystack.length + 1 ? offs : ERROR_VALUE;
  }

  if (offs < 1 || offs > haystack.length) {
    return ERROR_VALUE;
  }
  const r = haystack.indexOf(needle, offs - 1);
  if (r < 0) {
    return ERROR_VALUE;
  }
  return r + 1;
}

/**
 * FINDB(search_for, text_to_search, [starting_at])
 * Returns the position at which a string is first found within text counting each
 * double-character as 2.
 */
export function FINDB (needle: string, haystack: string, offs?: number): number | FormulaError {
  // Because we don't support locales other than en_US, FIND and FINDB are
  // the same. If we start supporting any CJK locale, this changes.
  return FIND(needle, haystack, offs);
}

/**
 * FIXED(number, [number_of_places], [suppress_separator])
 * Formats a number with a fixed number of decimal places.
 */
export function FIXED (this: EvaluationContext, number: number, decimals: number = 2): string | FormulaError {
  let decimalString = '';
  if (decimals > 0) {
    decimalString = '.' + '0'.repeat(decimals);
  }
  else if (decimals < 0) {
    number = ROUND(number, decimals);
  }
  return TEXT.call(this, number, `#,##0${decimalString}`);
}

// JIS(???)
// undefined
export function JIS () {
  return ERROR_NAME;
}

/**
 * LEFT(string, [number_of_characters])
 * Returns a substring from the beginning of a specified string.
 */
export function LEFT (str: string, offs?: number | FormulaError): string | FormulaError {
  if (offs == null) {
    offs = 1;
  }
  else {
    offs = toNum(offs);
    if (offs === ERROR_NUM || (typeof offs === 'number' && offs < 0)) {
      return ERROR_VALUE;
    }
    if (isErr(offs)) {
      return offs;
    }
    offs = Math.floor(offs);
  }
  const codePoints = intoCodepoints(str);
  let cpIdx = 0;
  while (offs > 0 && cpIdx < codePoints.length) {
    offs -= codePoints[cpIdx].length;
    cpIdx += 1;
  }
  return codePoints.slice(0, cpIdx).join('');
}

/**
 * LEFTB(str, offs)
 * The first or left most characters in a text string.
 */
export function LEFTB (str: string, offs?: number | FormulaError): string | FormulaError {
  // Because we don't support locales other than en_US, LEFT and LEFTB are
  // the same. If we start supporting any CJK locale, this changes.
  return LEFT(str, offs);
}

/**
 * LEN(text)
 * Returns the length of a string.
 */
export function LEN (str: string): number | FormulaError {
  return str.length;
}

/**
 * LENB(str)
 * The number of characters in a text string.
 */
export function LENB (str: string): number | FormulaError {
  // Because we don't support locales other than en_US, LEN and LENB are
  // the same. If we start supporting any CJK locale, this changes.
  return LEN(str);
}

/**
 * LOWER(text)
 * Converts a specified string to lowercase.
 */
export function LOWER (str: string): string | FormulaError {
  return str.toLowerCase();
}

/**
 * MID(string, starting_at, extract_length)
 * Returns a segment of a string.
 */
export function MID (str: string, offs: number, len: number): string | FormulaError {
  if (offs < 1 || len < 0) {
    return ERROR_VALUE;
  }
  return str.slice(Math.floor(offs) - 1, Math.floor(offs) + Math.floor(len) - 1);
}

/**
 * MIDB(str, offs, len)
 * The text string which is a substring of a given string.
 */
export function MIDB (str: string, offs: number, len: number): string | FormulaError {
  // Because we don't support locales other than en_US, MID and MIDB are
  // the same. If we start supporting any CJK locale, this changes.
  return MID(str, offs, len);
}

/**
 * NUMBERVALUE(text, [decimal_separator], [group_separator])
 * The text to number in a locale independent manner.
 */
export function NUMBERVALUE (str: string, decSep: string | undefined, grpSep: string | undefined) {
  const decimal = (decSep || '.').charAt(0);
  const group = (grpSep || ',').charAt(0);
  if (decSep === '' || grpSep === '' || decimal === group) {
    return ERROR_VALUE;
  }
  if (!str) {
    return 0;
  }
  let cleanedNumber = '';
  let isDateTime = false;
  let isCurrency = false;
  let seenDigits = false;
  let noMoreDigitsAllowed = false;
  let scale = 1;
  for (const char of str) {
    // "1.1" always works, except when group is '.'
    if (char === decimal || (char === '.' && group !== '.')) {
      cleanedNumber += '.';
      continue;
    }
    else if (char === group || char === ' ' || char === '\n' || char === '\r' || char === '\t') {
      // ignore whitespace
      continue;
    }
    else if (char === '$' || char === '€') {
      // this is not an incomplete list, all other currency symbols cause errors
      noMoreDigitsAllowed = seenDigits;
      isCurrency = true;
      continue;
    }
    else if (char === '%') {
      noMoreDigitsAllowed = seenDigits;
      scale /= 100;
      continue;
    }
    else if (char === '/' || char === ':') {
      isDateTime = true;
    }
    else if (char === '-') {
      const lastUsedChar = cleanedNumber.at(-1);
      if (seenDigits && lastUsedChar !== 'e' && lastUsedChar !== 'E') {
        isDateTime = true;
      }
    }
    else if (char >= '0' && char <= '9') {
      if (noMoreDigitsAllowed) {
        return ERROR_VALUE;
      }
      seenDigits = true;
    }
    cleanedNumber += char;
  }

  if (isCurrency && isDateTime) {
    return ERROR_VALUE;
  }
  if (isDateTime) {
    return parseDateTime(cleanedNumber);
  }
  const num = +cleanedNumber * scale;
  return isFinite(num) ? num : ERROR_VALUE;
}

// PHONETIC(???)
// The phonetic characters from a text string.
export function PHONETIC () {
  return ERROR_NAME;
}

function isLetter (char: string): boolean {
  // https://stackoverflow.com/a/32567789/7864343
  return char.toLowerCase() !== char.toUpperCase();
}

/**
 * Capitalizes each word in a specified string.
 */
export function PROPER (text: string): string {
  let result = '';
  let lastWasLetter = false;
  for (const char of text) {
    if (!lastWasLetter) {
      result += char.toUpperCase();
    }
    else {
      result += char.toLowerCase();
    }
    lastWasLetter = isLetter(char);
  }
  return result;
}

/**
 * REPLACE(text, position, length, new_text)
 * Replaces part of a text string with a different text string.
 */
export function REPLACE (str: string, offs: number, len: number, newStr: string): string | FormulaError {
  if (offs < 1 || len < 0) {
    return ERROR_VALUE;
  }
  return str.slice(0, Math.floor(offs) - 1) + newStr + str.slice(Math.floor(offs) + Math.floor(len) - 1);
}

/**
 * REPLACEB(str, offs, len, newStr)
 * The text string with a number of characters replaced.
 */
export function REPLACEB (str: string, offs: number, len: number, newStr: string): string | FormulaError {
  // Because we don't support locales other than en_US, REPLACE and REPLACEB are
  // the same. If we start supporting any CJK locale, this changes.
  return REPLACE(str, offs, len, newStr);
}

/**
 * REPT(text_to_repeat, number_of_repetitions)
 * Returns specified text repeated a number of times.
 * @param str String to repeat
 * @param num Number of times to repeat the given string
 */
export function REPT (str: string, num: number): string | FormulaError {
  if (num < 0) {
    return ERROR_VALUE;
  }
  num = Math.floor(num);
  if (str.length * num > MAX_STRING_CHARS) {
    return ERROR_VALUE;
  }
  return str.repeat(num);
}

/**
 * RIGHT(string, [number_of_characters])
 * Returns a substring from the end of a specified string.
 */
export function RIGHT (str: string, offs?: number | FormulaError): string | FormulaError {
  if (offs == null) {
    offs = 1;
  }
  else {
    offs = toNum(offs);
    if (isErr(offs)) {
      return offs;
    }
    if (offs < 0) {
      return ERROR_VALUE;
    }
    offs = Math.floor(offs);
  }
  const codePoints = intoCodepoints(str);
  let cpIdx = codePoints.length - 1;
  while (offs > 0 && cpIdx >= 0) {
    offs -= codePoints[cpIdx].length;
    cpIdx -= 1;
  }
  return codePoints.slice(cpIdx + 1).join('');
}

/**
 * RIGHTB(str, offs)
 * The last or right most characters in a text string.
 */
export function RIGHTB (str: string, offs?: number | FormulaError): string | FormulaError {
  // Because we don't support locales other than en_US, RIGHT and RIGHTB are
  // the same. If we start supporting any CJK locale, this changes.
  return RIGHT(str, offs);
}

/**
 * SEARCH(search_for, text_to_search, [starting_at])
 * Returns the position at which a string is first found within text and ignores
 * capitalization of letters. Returns `#VALUE!` if the string is not found.
 */
export function SEARCH (needle: string, haystack: string, startingAt?: number): number | FormulaError {
  needle += '*';
  if (startingAt == null) {
    startingAt = 1;
  }
  else if (startingAt <= 0) {
    return ERROR_VALUE;
  }
  const codepoints = intoCodepoints(haystack);
  let strIdx = 0;
  for (const codepoint of codepoints) {
    if (strIdx >= startingAt - 1 && Math.trunc(match(needle, haystack.slice(strIdx))) === 0) {
      return strIdx + 1;
    }
    strIdx += codepoint.length;
  }
  return ERROR_VALUE;
}

/**
 * SEARCHB(search_for, text_to_search, [starting_at])
 * Returns the position at which a string is first found within text, counting
 * each double-character as 2.
 */
export function SEARCHB (needle: string, haystack: string, startingAt?: number): number | FormulaError {
  // Because we don't support locales other than en_US, SEARCH and SEARCHB are
  // the same. If we start supporting any CJK locale, this changes.
  return SEARCH(needle, haystack, startingAt);
}

/**
 * SUBSTITUTE(text_to_search, search_for, replace_with, [occurrence_number])
 * Replaces existing text with new text in a string.
 * Note that the fourth parameter appears to be treated as string type in Excel,
 * and coerced accordingly, contrary to the Excel documentation and the clearly
 * numeric nature of the parameter. We do likewise, to handle the boolean case
 * like Excel does. If we did change this parameter type to `number`, here and
 * in the signature, then TRUE would get coerced to 1, but Excel returns #VALUE!
 * when the fourth argument is TRUE, and we would not.
 */
export function SUBSTITUTE (str: string, needle: string, replace: string, nthMatch?: string): string | FormulaError {
  let nth: number | FormulaError;
  if (nthMatch == null) {
    nth = 1;
  }
  else {
    nth = toNum(nthMatch);
    if (isErr(nth)) {
      return nth;
    }
    if (nth < 1) {
      return ERROR_VALUE;
    }
  }
  const reNeedle = new RegExp(reEscape(needle), 'g');
  let n = 0;
  return str.replace(reNeedle, () => {
    n++;
    return nthMatch == null || n === nth ? replace : needle;
  });
}

/**
 * T(value)
 * Returns the value provided or referenced, if it a string or error, else ''.
 * @param val
 * @returns `val` if it is a string or error (or a 1x1 reference to one), else ''
 */
export function T (val: string | number | boolean | Reference | FormulaError | null): string | FormulaError {
  const derefedVal = isRef(val) ? val.collapse().resolveSingle() : val;
  if (isStr(derefedVal) || isErr(derefedVal)) {
    return derefedVal;
  }
  return '';
}

const numfmtOptionsByMode: Map<ModeBit, unknown> = new Map([
  [ MODE_EXCEL, { dateSpanLarge: false, dateErrorThrows: true } ],
  [ MODE_GOOGLE, { leap1900: false } ],
]);

/**
 * TEXT(number, format)
 * Converts a number into text according to a specified format.
 */
export function TEXT (
  this: EvaluationContext,
  number: string | number | boolean | Lambda,
  format: string,
): string | FormulaError {
  if (isLambda(number)) {
    return ERROR_VALUE;
  }
  // if not a bool, try to cast to number > if we fail use the input as-is
  const n = number !== !!number ? toNum(number) : number;
  if (isNum(n)) {
    number = n;
  }
  try {
    return formatNumber(format, number, numfmtOptionsByMode.get(this.mode) || {});
  }
  catch (err) {
    return ERROR_VALUE;
  }
}

/**
 * Join bits of text together.
 * Syntax:
 *     =TEXTJOIN (delimiter, ignore_empty, text1, [text2], ...)
 * @param rawDelimiters Separators between each text item.
 *   If the delimiters argument is an array or range, the delimiters are rotated in a round-robin manner. For example,
 *   TEXTJOIN({"-", "_"},, 1, 2, 3, 4) yields "1-2_3-4".
 *   This accepts more types than the signature requires, to support internal use by other spreadsheet functions.
 * @param ignoreEmpty Whether to ignore empty cells or not.
 * @param texts Texts to join.
 * @returns Concatenated text
 */
export function TEXTJOIN (
  rawDelimiters: undefined | Matrix | Reference | string | string[],
  ignoreEmpty: boolean | undefined,
  ...texts: (undefined | Matrix | Reference | string)[]
): string | FormulaError {
  if (ignoreEmpty == null) {
    ignoreEmpty = true;
  }
  const delimiters: string[] = [];
  let error: FormulaError | null = null;
  visit(rawDelimiters, x => {
    x = toStr(x);
    if (isErr(x)) {
      error = x;
      return true;
    }
    delimiters.push(x);
  });
  if (error) {
    return error;
  }

  // Last element status:
  // 0: First iteration
  // 1: Last element was empty
  // 2: Last element was non-empty
  let lastElemStatus = 0;
  let concatenated = '';
  let delimiterIndex = 0;
  let delimiter = '';
  visit(texts, elem => {
    elem = toStr(elem);
    if (isErr(elem)) {
      error = elem;
      return true;
    }
    if (lastElemStatus > 0 && delimiters.length > 0 && (!ignoreEmpty || lastElemStatus === 2)) {
      delimiter = delimiters[delimiterIndex];
      concatenated += delimiter;
      delimiterIndex = (delimiterIndex + 1) % delimiters.length;
    }
    concatenated += elem;
    if (concatenated.length > 32767) {
      // Microsoft documentation: If the resulting string exceeds 32767
      // characters (cell limit), {TEXTJOIN,CONCAT} returns the #VALUE! error.
      error = ERROR_VALUE;
      return true;
    }
    lastElemStatus = 1 + +(elem !== '');
  });
  if (error) {
    return error;
  }
  // Remove trailing delimiter when `ignoreEmpty` is true and the final element is empty.
  if (ignoreEmpty && lastElemStatus === 1 && delimiters.length > 0 && concatenated !== '') {
    concatenated = concatenated.slice(0, concatenated.length - delimiter.length);
  }
  return concatenated;
}

export function JOIN (
  delimiterMaybeRef: null | Reference | Matrix,
  ...texts: (string | Reference | Matrix)[]
): string | FormulaError {
  const delimiter = isRef(delimiterMaybeRef) ? delimiterMaybeRef.toMatrix() : delimiterMaybeRef;
  if (isErr(delimiter)) {
    return delimiter;
  }

  for (const text of texts) {
    if ((isRef(text) || isMatrix(text)) && !text.is1D()) {
      return ERROR_VALUE.detailed('Two dimensional range or matrix given to JOIN');
    }
  }
  const delimStr = delimiter == null ? '' : toStr(delimiter.get(0, 0));
  if (isErr(delimStr)) {
    return delimStr;
  }
  return TEXTJOIN(delimStr, false, ...texts);
}

/**
 * TRIM(text)
 * Removes leading, trailing, and repeated spaces in text.
 */
export function TRIM (this: EvaluationContext, str: string): string | FormulaError {
  if (this.mode === MODE_GOOGLE) {
    str = str.trim();
  }
  return str.replace(/ {2,}/g, ' ').replace(/(?:^ )|(?: $)/g, '');
}

/**
 * The unicode character that is references by the given numeric value.
 */
export function UNICHAR (code: number): string | FormulaError {
  code = Math.floor(code);
  if (code < 1 || code > 0x10ffff) {
    return ERROR_VALUE;
  }
  return String.fromCodePoint(code);
}

/**
 * The number that corresponds to the first character of the text.
 */
export function UNICODE (text: string): number | FormulaError {
  if (text.length === 0) {
    return ERROR_VALUE;
  }
  const code = text.codePointAt(0);
  invariant(code != null);
  return code;
}

/**
 * UPPER(text)
 * Converts a specified string to uppercase.
 */
export function UPPER (str: string): string | FormulaError {
  return str.toUpperCase();
}

/**
 * VALUE(text)
 * Converts a string in any of the date, time or number formats into a number.
 */
export function VALUE (this: EvaluationContext, str: string | number | boolean): number | FormulaError {
  const n = toNum(str);
  if (str == null || (this.mode === MODE_GOOGLE && str === '')) {
    return 0;
  }
  if (isBool(str) || str === '') {
    return ERROR_VALUE;
  }
  return isErr(n) ? parseDateTime(String(str)) : n;
}

/**
 * Find the n-th occurrence of the given substring, within the given string.
 * Returns a number >=0 if an occurrence was found.
 * Returns -1 if no occurrence in the string is found or if `|n| > 1` and we skip past it.
 * Returns -2 if `matchMode=true` and the added delimiter at the end of the string was matched.
 * @param needle Substring to search for
 * @param haystack String to search in
 * @param n The occurrence number, can be negative (e.g 1 for first occurrence,
 *   -1 for last occurrence).
 * @param matchEnd If true, pretend that there is an extra delimiter at the end
 *   of the string (in the direction that we are searching). For
 *   `=TEXTBEFORE("1_2", "_", 2, , 1)` we pretend that the input string is
 *   "1_2_" and return the text before the second instance of "_" ("1_2").
 */
function seekSubstring (needle: string, haystack: string, n: number, matchEnd: boolean): number {
  if (n > 0) {
    return seekSubstringLeft(needle, haystack, n, matchEnd);
  }
  return seekSubstringRight(needle, haystack, n, matchEnd);
}

function seekSubstringLeft (needle: string, haystack: string, n: number, matchEnd: boolean): number {
  let idx = -needle.length;
  while (n > 0) {
    idx = haystack.indexOf(needle, idx + needle.length);
    const foundMatch = idx >= 0;
    if (foundMatch) {
      n -= 1;
      continue;
    }
    if (matchEnd && n === 1) {
      return -2;
    }
    return -1;
  }
  return idx;
}

function seekSubstringRight (needle: string, haystack: string, n: number, matchEnd: boolean): number {
  let idx = haystack.length - 1 + needle.length;
  while (n < 0) {
    const position = idx - needle.length;
    idx = haystack.lastIndexOf(needle, position);

    // If `position < 0`, idx may still equal -1 because `lastIndexOf`
    // applies `max(position, 0)`:
    //
    // > If position is less than 0, the behavior [of `lastIndexOf`] is the
    // > same as for 0 — that is, the method looks for the specified
    // > substring only at index 0.
    //
    // However, we do not want to match if we are searching out-of-bounds.
    //
    const foundMatch = position >= 0 && idx >= 0;
    if (foundMatch) {
      n += 1;
      continue;
    }
    if (matchEnd && n === -1) {
      return -2;
    }
    return -1;
  }
  return idx;
}

function textBeforeAfterIdx (
  haystack: string,
  needle: string,
  n: number,
  ignoreCase: boolean,
  matchEnd: boolean,
): number | FormulaError {
  if (n === MISSING) {
    n = 1;
  }
  if (matchEnd === MISSING) {
    matchEnd = false;
  }
  if (haystack.length <= Math.abs(n) && !matchEnd) {
    return ERROR_NA;
  }
  if (ignoreCase === MISSING) {
    ignoreCase = false;
  }
  if (needle === '') {
    return 0;
  }
  if (n === 0) {
    return ERROR_VALUE;
  }
  if (ignoreCase) {
    haystack = haystack.toLowerCase();
    needle = needle.toLowerCase();
  }
  return seekSubstring(needle, haystack, n, matchEnd);
}

export function TEXTBEFORE (
  input_text: string,
  text_before: string | Matrix,
  n: number,
  ignore_case: boolean,
  match_end: boolean,
  if_not_found: CellValue,
): string | CellValue | FormulaError {
  if (isMatrix(text_before)) {
    // TODO: Handle this case
    return ERROR_VALUE;
  }
  const idx = textBeforeAfterIdx(input_text, text_before, n, ignore_case, match_end);
  if (isErr(idx)) {
    return idx;
  }
  if (idx === -2) {
    return (n ?? 1) < 0 ? '' : input_text;
  }
  if (idx === -1) {
    return if_not_found ?? ERROR_NA;
  }
  return input_text.slice(0, idx);
}

export function TEXTAFTER (
  input_text: string,
  text_after: string | Matrix,
  n: number,
  ignore_case: boolean,
  match_end: boolean,
  if_not_found: CellValue,
): string | CellValue | FormulaError {
  if (isMatrix(text_after)) {
    // TODO: Handle this case
    return ERROR_VALUE;
  }
  const idx = textBeforeAfterIdx(input_text, text_after, n, ignore_case, match_end);
  if (isErr(idx)) {
    return idx;
  }
  if (idx === -2) {
    return (n ?? 1) < 0 ? input_text : '';
  }
  if (idx === -1) {
    return if_not_found ?? ERROR_NA;
  }
  return input_text.slice(idx + text_after.length);
}

function splitAll (
  string: string,
  delimiters: string | Matrix | undefined,
  ignoreEmpty: boolean | undefined,
  matchMode: number | undefined,
): string[] {
  if (delimiters === MISSING) {
    return [ string ];
  }
  if (ignoreEmpty === MISSING) {
    ignoreEmpty = false;
  }

  const isDelimCaseInsensitive = matchMode === 1;

  const rawDelimArray = isMatrix(delimiters) ? delimiters.resolveRange() : [ delimiters ];

  const delimArray = rawDelimArray.map(value => {
    let str = String(value);
    if (typeof value === 'boolean') {
      str = str.toUpperCase();
    }
    if (isDelimCaseInsensitive) {
      str = str.toLowerCase();
    }
    return str;
  });

  let parts: string[] = [];
  let start = 0;
  let i = 0;
  while (i < string.length) {
    let matchingDelim: string | null = null;
    for (const delim of delimArray) {
      let subString = string.slice(i, i + delim.length);
      if (isDelimCaseInsensitive) {
        subString = subString.toLowerCase();
      }
      if (subString === delim) {
        matchingDelim = delim;
        break;
      }
    }
    if (matchingDelim != null) {
      parts.push(string.slice(start, i));
      i += matchingDelim.length;
      start = i;
    }
    else {
      i += 1;
    }
  }
  parts.push(string.slice(start));

  if (ignoreEmpty) {
    parts = parts.filter(part => part !== '');
  }
  return parts;
}

export function TEXTSPLIT (
  text: string,
  colDelim: string | Matrix | undefined,
  rowDelim: string | Matrix,
  ignoreEmpty: boolean,
  matchMode: number,
  padWith: string | number | boolean | FormulaError | Matrix | null | Lambda,
): Matrix | FormulaError {
  if (isMatrix(padWith)) {
    // TODO: Handle this case
    return ERROR_VALUE;
  }
  if (matchMode && matchMode !== 1) {
    return ERROR_VALUE.detailed('Match mode must be 0 or 1');
  }

  const rowMatrices = splitAll(text, rowDelim, ignoreEmpty, matchMode).map(line =>
    Matrix.createRow(splitAll(line, colDelim, ignoreEmpty, matchMode)),
  );
  return vstackAll(rowMatrices, padWith ?? ERROR_NA);
}

/**
 * Google Sheet's alternative to Excel's `TEXTSPLIT`.
 */
export function SPLIT (text: string, delimiter: string, splitByEach: boolean, removeEmptyText: boolean) {
  if (text.length === 0) {
    return ERROR_VALUE.detailed('Text should be non-empty');
  }
  if (delimiter.length === 0) {
    return ERROR_VALUE.detailed('Delimiter should be non-empty');
  }

  if (splitByEach == null) {
    splitByEach = true;
  }
  if (removeEmptyText == null) {
    removeEmptyText = true;
  }

  const delimiters = splitByEach ? [ ...delimiter ] : [ delimiter ];
  let parts = [ text ];
  for (const delim of delimiters) {
    const newParts: string[] = [];
    for (const part of parts) {
      newParts.push(...part.split(delim));
    }
    parts = newParts;
  }

  if (removeEmptyText) {
    parts = parts.filter(part => part.length > 0);
  }

  // Google's SPLIT does some positively insane coercion logic to each of the
  // parts before returning. For example, SPLIT("1 2,3", ",") interprets the
  // part "1 2" as the date `1/2/2023` and thus returns the number `44928`.
  //
  // The closest thing we can do is run the parts through `coerceValue`,
  // which is what I do below.
  const coercedParts = parts.map(part => {
    const maybeNum = coerceValue(part, TYPE_NUM);
    if (isNum(maybeNum)) {
      return maybeNum;
    }
    const maybeBool = coerceValue(part, TYPE_BOOL);
    if (isBool(maybeBool)) {
      return maybeBool;
    }
    return part;
  });

  return Matrix.createRow(coercedParts);
}
