/** @module */
import { ERROR_VALUE, ERROR_NUM, ERROR_NA, MISSING, BLANK, MODE_GOOGLE } from '../constants.js';
import Reference, { isRef } from '../Reference.js';
import { toNum } from './utils-number';
import { isErr, isNum, isStr } from '../../utils.js';
import { NonMatrixFormulaArgument } from '../types.js';
import { ModeBit } from './sigtypes.js';
import FormulaError from '../FormulaError.js';
import { Lambda } from '../lambda.js';

export function toNumEngineering(value: number, mode?: ModeBit): number;
export function toNumEngineering(value: null, mode?: ModeBit): null;
export function toNumEngineering(value: FormulaError | undefined | Lambda | boolean, mode?: ModeBit): FormulaError;
export function toNumEngineering(value: string | Reference, mode?: ModeBit): number | FormulaError;
export function toNumEngineering(value: Exclude<NonMatrixFormulaArgument, null>, mode?: ModeBit): number | FormulaError;
export function toNumEngineering(value: NonMatrixFormulaArgument, mode?: ModeBit): number | FormulaError | null;
export function toNumEngineering (value: NonMatrixFormulaArgument, mode?: ModeBit): number | FormulaError | null {
  if (isRef(value) && value.size === 1) {
    value = value.resolveSingle();
  }
  if (isNum(value)) {
    return value;
  }
  if (isErr(value)) {
    return value;
  }
  if (value === MISSING) {
    return ERROR_NA;
  }
  if (value === BLANK) {
    return value;
  }
  if (!isStr(value)) {
    return ERROR_VALUE;
  }
  if (value === '' && mode === MODE_GOOGLE) {
    return 0;
  }
  return toNum(value);
}

export function toDateEngineering (date: NonMatrixFormulaArgument, mode?: ModeBit): number | FormulaError {
  date = toNumEngineering(date, mode) ?? 0;
  if (isErr(date)) {
    return date;
  }
  date = Math.floor(date);
  if (date < 0) {
    return ERROR_NUM;
  }
  return date;
}
