import {
  ERROR_VALUE,
  ERROR_NAME,
  ERROR_NUM,
  ERROR_DIV0,
  ERROR_CALC,
  MISSING,
  ERROR_NA,
  BLANK,
  MODE_GOOGLE,
} from '../constants.js';
import { toNum, toNumEngineering, toNumList, isNum, isErr, isRef, isMatrix, isBool } from './utils.js';
import { round15 } from './utils-number';
import { comb, combinations, EPSILON } from './utils-math';
import { sum, standardFilterMap, visitEngineering } from './utils-visit.js';
import { AVERAGE, COUNT, COUNTA, STDEV_S, STDEV_P, VAR_S, VAR_P, MIN, MAX } from './statistical';
import Matrix from '../Matrix.js';
import jstat from './jstat.js';
import { unbox } from '../ValueBox.js';
import { isCellValue } from '../../typeguards.js';
import { isLambda } from '../lambda';

const π = round15(Math.PI);

/**
 * ABS(value)
 * Returns the absolute value of a number.
 * @param {number} val
 * @returns {number}
 */
export function ABS (val) {
  return Math.abs(val);
}

/**
 * ACOS(value)
 * Returns the inverse cosine of a value, in radians.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function ACOS (val) {
  if (val < -1 || val > 1) {
    return ERROR_NUM;
  }
  return Math.acos(val);
}

/**
 * ACOSH(value)
 * Returns the inverse hyperbolic cosine of a number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function ACOSH (val) {
  if (val < 1) {
    return ERROR_NUM;
  }
  return Math.acosh(val);
}

/**
 * ACOT(value)
 * Returns the inverse cotangent of a value, in radians.
 * @param {number} val
 * @returns {number}
 */
export function ACOT (val) {
  return π / 2 - Math.atan(val);
}

/**
 * ACOTH(value)
 * Returns the inverse hyperbolic cotangent of a value, in radians.
 * The value must not be between `-1` and `1`, inclusive.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function ACOTH (val) {
  if (val >= -1 && val <= 1) {
    return ERROR_NUM;
  }
  const x = 1 / val;
  return Math.log((1 + x) / (1 - x)) / 2;
}

// function_num, options, ref1, ref2
// The aggregate of values in a list, table or cell range.
export function AGGREGATE () {
  return ERROR_NAME;
}

const roman = { i: 1, v: 5, x: 10, l: 50, c: 100, d: 500, m: 1000 };

/**
 * ARABIC(roman_numeral)
 * Computes the value of a Roman numeral.
 * @param {string} val
 * @returns {number | FormulaError}
 */
export function ARABIC (val) {
  val = val.trim().toLowerCase();
  let sum = 0;
  let last = 0;
  let hold = 0;
  let curr = 0;
  for (let i = 0; i < val.length; i++) {
    if (val[i] in roman) {
      last = curr;
      curr = roman[val[i]];
      if (i === 0) {
        hold = curr;
      }
      else if (curr > last) {
        // IX
        sum -= hold;
        hold = curr;
      }
      else if (curr < last) {
        // XI
        sum += hold;
        hold = curr;
      }
      else {
        // XX
        hold += curr;
      }
    }
    else {
      return ERROR_VALUE;
    }
  }
  return sum + hold;
}

/**
 * ASIN(value)
 * Returns the inverse sine of a value, in radians.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function ASIN (val) {
  const r = Math.asin(val);
  return isFinite(r) ? r : ERROR_NUM;
}

/**
 * ASINH(value)
 * Returns the inverse hyperbolic sine of a number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function ASINH (val) {
  const r = Math.asinh(val);
  return isFinite(r) ? r : ERROR_NUM;
}

/**
 * ATAN(value)
 * Returns the inverse tangent of a value, in radians.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function ATAN (val) {
  return Math.atan(val);
}

/**
 * ATAN2(x, y)
 * Returns the angle between the x-axis and a line segment from the origin (0,0) to specified coordinate pair (`x`,`y`),
 * in radians.
 * @param {number} x
 * @param {number} y
 * @returns {number | FormulaError}
 */
export function ATAN2 (x, y) {
  if (!x && !y) {
    return ERROR_DIV0;
  }
  return Math.atan2(y, x);
}

/**
 * ATANH(value)
 * Returns the inverse hyperbolic tangent of a number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function ATANH (val) {
  if (val >= 1 || val <= -1) {
    return ERROR_NUM;
  }
  return Math.log((1 + val) / (1 - val)) / 2;
}

/**
 * BASE(value, base, [min_length])
 * Converts a number into a text representation in another base, for example, base 2 for binary.
 * @param {number} val
 * @param {number} base
 * @param {number} [min_len]
 * @returns {string | FormulaError}
 */
export function BASE (val, base, min_len) {
  if (min_len == null) {
    min_len = 1;
  }
  if (val < 0 || min_len < 0 || base < 2 || base > 36) {
    return ERROR_NUM;
  }
  let r = val.toString(base).toUpperCase();
  if (min_len && min_len > r.length) {
    r = '0'.repeat(min_len - r.length) + r;
  }
  return r;
}

/**
 * CEILING(value, factor)
 * Rounds a number up to the nearest integer multiple of specified significance `factor`.
 * FIXME: This will emit numbers with floating point artifacts, see about fixing that:
 * `=CEILING(123.456,0.01) => 123.46000000000001`
 * @param {number} val
 * @param {number} [factor=1]
 * @returns {number | FormulaError}
 */
export function CEILING (val, factor = 1) {
  if (factor < 0 && val > 0) {
    return ERROR_NUM;
  }
  if (factor === 0) {
    return ERROR_DIV0;
  }
  return Math.ceil(val / factor) * factor;
}

export const ECMA_CEILING = CEILING;

/**
 * CEILING.MATH(number, [significance], [mode])
 * Rounds a number up to the nearest integer multiple of specified `significance`, with negative numbers rounding toward
 * or away from 0 depending on the mode.
 * FIXME: This will emit numbers with floating point artifacts: =CEILING.MATH(123.456,0.01) => 123.46000000000001
 * @param {number} val
 * @param {number} [factor]
 * @param {number} [mode]
 * @returns {number}
 */
export function CEILING_MATH (val, factor = 1, mode = 0) {
  if (!factor || !val) {
    return 0;
  }
  factor = Math.abs(factor);
  if (mode) {
    const s = -SIGN(val);
    return s * Math.ceil((s * val) / -factor) * -factor;
  }
  return Math.ceil(val / factor) * factor;
}

/**
 * CEILING.PRECISE(number, [significance])
 * Rounds a number up to the nearest integer multiple of specified `significance`.
 * If the number is positive or negative, it is rounded up.
 * @param {number} val
 * @param {number} [factor]
 * @returns {number}
 */
export function CEILING_PRECISE (val, factor) {
  return CEILING_MATH(val, factor, 0);
}

export const ISO_CEILING = CEILING_PRECISE;

/**
 * COMBIN(n, k)
 * Returns the number of ways to choose some number of objects from a pool of a given size of objects.
 * @param {number} n
 * @param {number} k
 * @returns {number | FormulaError}
 */
export function COMBIN (n, k) {
  return combinations(n, k);
}

/**
 * COMBINA(n, k)
 * Returns the number of ways to choose some number of objects from a pool of
 * a given size of objects, including ways that choose the same object multiple times.
 * @param {number} n
 * @param {number} k
 * @returns {number | FormulaError}
 */
export function COMBINA (n, k) {
  n = Math.trunc(n);
  k = Math.trunc(k);
  if (n === 0 && k === 0) {
    return 1;
  }
  const result = comb(n + k - 1, n - 1);
  if (isErr(result)) {
    return result;
  }
  return round15(result);
}

/**
 * COS(angle)
 * Returns the cosine of an angle provided in radians.
 * @param {number} val
 * @returns {number}
 */
export function COS (val) {
  return Math.cos(val);
}

/**
 * COSH(value)
 * Returns the hyperbolic cosine of any real number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function COSH (val) {
  const r = (Math.exp(val) + Math.exp(-val)) / 2;
  return isFinite(r) ? r : ERROR_NUM;
}

/**
 * COT(angle)
 * Returns the cotangent of an angle provided in radians.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function COT (val) {
  if (!val) {
    return ERROR_DIV0;
  }
  return 1 / Math.tan(val);
}

/**
 * COTH(value)
 * Returns the hyperbolic cotangent of any real number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function COTH (val) {
  if (!val) {
    return ERROR_DIV0;
  }
  if (val > 20) {
    return 1;
  }
  if (val < -20) {
    return -1;
  }
  const e2 = Math.exp(2 * val);
  if (!isFinite(e2)) {
    return ERROR_NUM;
  }
  return (e2 + 1) / (e2 - 1);
}

/**
 * CSC(angle)
 * Returns the cosecant of an angle provided in radians.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function CSC (val) {
  if (!val) {
    return ERROR_DIV0;
  }
  return 1 / Math.sin(val);
}

/**
 * CSCH(value)
 * Returns the hyperbolic cosecant of any real number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function CSCH (val) {
  if (!val) {
    return ERROR_DIV0;
  }
  return 2 / (Math.exp(val) - Math.exp(-val));
}

const _baseChars = '0123456789abcdefghijklmnopqrstuvwxyz';

/**
 * DECIMAL(value, base)
 * Converts the text representation of a number in another base, to base 10 (decimal).
 * @param {string} val
 * @param {number} radix
 * @returns {number | FormulaError}
 */
export function DECIMAL (val, radix) {
  if (val.length > 255) {
    return ERROR_NUM;
  }
  val = val.toLowerCase();
  radix = Math.floor(radix);
  if (radix < 2 || radix > 36) {
    return ERROR_NUM;
  }
  if (radix === 16) {
    val = val.replace(/^0x/, '');
  }
  const okChars = _baseChars.slice(0, ~~radix);
  let m = 0;
  for (let i = 0; i < val.length; i++) {
    const chr = val[val.length - i - 1];
    const idx = okChars.indexOf(chr);
    if (idx < 0) {
      return ERROR_NUM;
    }
    m += radix ** i * idx;
  }
  return isFinite(m) ? m : ERROR_NUM;
}

/**
 * DEGREES(angle)
 * Converts an angle value in radians to degrees.
 * @param {number} a
 * @returns {number | FormulaError}
 */
export function DEGREES (a) {
  return (a * 180) / π;
}

/**
 * EVEN(value)
 * Rounds a number up to the nearest even integer.
 * @param {number} val
 * @returns {number}
 */
export function EVEN (val) {
  return CEILING_MATH(val, 2, 1);
}

/**
 * EXP(exponent)
 * Returns Euler's number, e (~2.718) raised to a power, or #NUM! if the result is too large to represent as a
 * JavaScript number (happens when val > 709.782712893384029939625)
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function EXP (val) {
  const r = Math.exp(val);
  return isFinite(r) ? r : ERROR_NUM;
}

/**
 * FACT(value)
 * Returns the factorial of a number.
 * TODO: might want to cache the results of this, esp. for numbers > 40
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function FACT (val) {
  // Excel caps at 170, we short this so we don't do the work for no reason
  if (val < 0 || val > 170) {
    return ERROR_NUM;
  }
  val = Math.floor(val);
  let r = 1;
  while (val > 1) {
    r *= val--;
  }
  return r;
}

/**
 * FACTDOUBLE(value)
 * Returns the "double factorial" of a number.
 * TODO: might want to cache the results of this, esp. for numbers > 40
 * @param {NonMatrixFormulaArgument} val
 * @returns {number | FormulaError}
 */
export function FACTDOUBLE (val) {
  if (!!val === val) {
    return ERROR_VALUE;
  }
  val = toNum(val);
  if (isErr(val)) {
    return val;
  }
  // Excel caps at 300, we short this so we don't do the work for no reason
  if (val < 0) {
    return 1;
  }
  if (val > 300) {
    return ERROR_NUM;
  }
  val = Math.floor(val);
  let r = 1;
  while (val > 1) {
    r *= val;
    val -= 2;
  }
  return r;
}

/**
 * FLOOR(number, significance)
 * @param {number} val
 * @param {number} [factor=1]
 * @returns {number | FormulaError}
 */
export function FLOOR (val, factor = 1) {
  if (factor < 0 && val > 0) {
    return ERROR_NUM;
  }
  if (factor === 0) {
    return ERROR_DIV0;
  }
  return Math.floor(val / factor) * factor;
}

/**
 * FLOOR.MATH(number, [significance], [mode])
 * Rounds a number down to the nearest integer multiple of specified `significance`, with negative numbers rounding toward or away from 0 depending on the mode.
 * @param {number} val
 * @param {number} [factor]
 * @param {number} [mode]
 * @returns {number}
 */
export function FLOOR_MATH (val, factor = 1, mode = 0) {
  if (factor === 0 || val === 0) {
    return 0;
  }
  factor = Math.abs(factor);
  if (mode) {
    const s = -SIGN(val);
    return s * Math.floor((s * val) / -factor) * -factor;
  }
  return Math.floor(val / factor) * factor;
}

/**
 * FLOOR.PRECISE(number, [significance])
 * Rounds a number down to the nearest integer multiple of specified `significance`. If the number is positive or
 * negative, it is rounded down.
 * @param {number} number
 * @param {number} [factor]
 * @returns {number}
 */
export function FLOOR_PRECISE (number, factor) {
  return FLOOR_MATH(number, factor, 0);
}

/**
 * @param {ArrayValue | undefined} x
 * @returns {[boolean, number | FormulaError | undefined]}
 */
function lcmGcdFilterMap (x) {
  if (isErr(x) || x === MISSING) {
    return [ true, x ];
  }
  if (x == null) {
    return [ true, 0 ];
  }
  const n = toNumEngineering(x);
  if (isErr(n)) {
    return [ true, n ];
  }
  return [ true, typeof n === 'number' && n >= 0 ? Math.floor(n) : ERROR_NUM ];
}

/**
 * GCD(value1, value2, ...)
 * Returns the greatest common divisor of one or more integers.
 * @param {FormulaArgument[]} args
 * @returns {number | FormulaError}
 */
export function GCD (...args) {
  let gcd = 0;
  const err = visitEngineering(
    args,
    num_ => {
      /** @type {number} */
      // @ts-expect-error (lcmGcdFilterMap only emits numbers and errors, and visitEngineering short-circuits on errors)
      let num = num_;
      while (gcd && num) {
        if (gcd > num) {
          gcd %= num;
        }
        else {
          num %= gcd;
        }
      }
      gcd += num;
    },
    lcmGcdFilterMap,
  );
  if (isErr(err)) {
    return err;
  }
  return gcd;
}

/**
 * LCM(value1, value2, ...)
 * Returns the least common multiple of one or more integers.
 * @param {FormulaArgument[]} args
 * @returns {number | FormulaError}
 */
export function LCM (...args) {
  args = args.map(arg => {
    if (isRef(arg) && arg.size === 1) {
      return arg.resolveSingle();
    }
    return arg;
  });
  let lcm = 1;
  const err = visitEngineering(
    args.filter(a => a !== BLANK),
    num_ => {
      /** @type {number} */
      // @ts-expect-error (lcmGcdFilterMap only emits numbers and errors, and visitEngineering short-circuits on errors)
      let num = num_;
      const numerator = lcm * num;
      while (lcm && num) {
        if (lcm > num) {
          lcm %= num;
        }
        else {
          num %= lcm;
        }
      }
      lcm = numerator / (lcm + num);
    },
    lcmGcdFilterMap,
  );
  if (isErr(err)) {
    return err;
  }
  return lcm;
}

/**
 * INT(value)
 * Rounds a number down to the nearest integer that is less than or equal to it.
 * @param {number} val
 * @returns {number}
 */
export function INT (val) {
  return Math.floor(val);
}

/**
 * LN(value)
 * Returns the logarithm of a number, base e (Euler's number).
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function LN (val) {
  if (val <= 0) {
    return ERROR_NUM;
  }
  return Math.log(val);
}

/**
 * LOG(value, base)
 * Returns the logarithm of a number with respect to a base.
 * @param {number} val
 * @param {number} [base]
 * @returns {number | FormulaError}
 */
export function LOG (val, base = 10) {
  const baseLog = Math.log(base);
  if (val <= 0 || base <= 0) {
    return ERROR_NUM;
  }
  if (!baseLog) {
    return ERROR_DIV0;
  }
  return Math.log(val) / baseLog;
}

/**
 * LOG10(value)
 * Returns the logarithm of a number, base 10.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function LOG10 (val) {
  if (val <= 0) {
    return ERROR_NUM;
  }
  return Math.log10(val);
}

/**
 * MDETERM(square_matrix)
 * Returns the matrix determinant of a square matrix specified as an array or range.
 * @param {Matrix} mx
 * @return {number | FormulaError}
 */
export function MDETERM (mx) {
  const num2dArray = toNum2dArrayStrict(mx);
  if (isErr(num2dArray)) {
    return num2dArray;
  }
  if (num2dArray.length === 1) {
    return num2dArray[0][0];
  }
  const det = jstat.det(num2dArray);
  if (!Number.isFinite(det)) {
    return ERROR_NUM;
  }
  return det;
}

/**
 * MINVERSE(square_matrix)
 * Returns the multiplicative inverse of a square matrix specified as an array or range.
 * @param {Matrix} mx
 * @returns {Matrix | FormulaError}
 */
export function MINVERSE (mx) {
  const num2dArray = toNum2dArrayStrict(mx);
  if (isErr(num2dArray)) {
    return num2dArray;
  }
  if (num2dArray.length === 1) {
    const scalar = num2dArray[0][0];
    return scalar === 0 ? ERROR_NUM : Matrix.of(1 / scalar);
  }
  if (jstat.det(num2dArray) === 0) {
    return ERROR_NUM.detailed('Matrix is not invertible');
  }
  const inverted = jstat.inv(num2dArray);
  if (inverted.some(row => row.some(x => !Number.isFinite(x)))) {
    return ERROR_NUM;
  }
  return Matrix.of(inverted);
}

/**
 * Common input argument handling for MDETERM and MINVERSE: return error if any
 * element is NaN or Infinity or its `typeof` is not `'number'`, or if the
 * matrix is non-square. Else return a plain 2-D array of its numbers.
 * @param {Matrix} mx
 * @returns {number[][] | FormulaError}
 */
function toNum2dArrayStrict (mx) {
  if (mx.height !== mx.width) {
    return ERROR_VALUE;
  }
  const result = [];
  for (const { row } of mx.iterRowsBoxed()) {
    const resultRow = [];
    result.push(resultRow);
    for (const boxedValue of row) {
      const value = unbox(boxedValue);
      if (!isNum(value)) {
        return ERROR_VALUE;
      }
      if (!Number.isFinite(value)) {
        return ERROR_NUM;
      }
      resultRow.push(value);
    }
  }
  return result;
}

/**
 * MOD(numerator, denominator)
 * Returns the result of the modulo operator, the remainder after a division operation.
 * @param {number} num
 * @param {number} den
 * @returns {number | FormulaError}
 */
export function MOD (num, den) {
  if (!den) {
    return ERROR_DIV0;
  }
  return num - den * Math.floor(num / den);
}

/**
 * MROUND(value, factor)
 * Rounds one number to the nearest integer multiple of another.
 * @param {NonMatrixFormulaArgument} val
 * @param {NonMatrixFormulaArgument} factor
 * @returns {number | FormulaError}
 */
export function MROUND (val, factor) {
  val = toNum(val);
  if (isErr(val)) {
    return val;
  }
  factor = toNum(factor);
  if (isErr(factor)) {
    return factor;
  }
  if (!factor) {
    return 0;
  }
  // the number and multiple arguments must have the same sign.
  if (val * factor < 0) {
    return ERROR_NUM;
  }
  const r = Math.floor(val / factor + 0.5) * factor;
  return isFinite(r) ? r : ERROR_VALUE;
}

// MULTINOMIAL(value1, value2)
// Returns the factorial of the sum of values divided by the product of the values' factorials.
export function MULTINOMIAL () {
  return ERROR_NAME;
}

// MUNIT(dim)
// The unit matrix or the specified dimension.
export function MUNIT (dim) {
  if (dim < 1) {
    return ERROR_VALUE;
  }
  dim = Math.floor(dim);
  const result = new Matrix(dim, dim, 0);
  for (let i = 0; i < dim; i++) {
    result.set(i, i, 1);
  }
  return result;
}

/**
 * ODD(value)
 * Rounds a number up to the nearest odd integer.
 * @param {number} val
 * @returns {number}
 */
export function ODD (val) {
  const temp = Math.ceil(Math.abs(val));
  return val < 0 ? -temp - ((temp + 1) % 2) : temp + ((temp + 1) % 2);
}

/**
 * PI()
 * Returns the value of Pi to 14 decimal places.
 * @returns {number}
 */
export function PI () {
  return π;
}

/**
 * POWER(base, exponent)
 * Returns a number raised to a power.
 * @param {number} val
 * @param {number} exp
 * @returns {number | FormulaError}
 */
export function POWER (val, exp) {
  if (!val && !exp) {
    return ERROR_NUM;
  }
  return val ** exp;
}

/**
 * PRODUCT(factor1, [factor2, ...])
 * Returns the result of multiplying a series of numbers together.
 * @param {(number | Reference | Matrix)[]} args
 * @returns {number | FormulaError}
 */
export function PRODUCT (...args) {
  if (args.length < 1) {
    return ERROR_VALUE;
  }
  let prod = 1;
  let anyFound = false;
  const argsResolved = args.map(arg => {
    if (isMatrix(arg) || isRef(arg)) {
      return arg.resolveRange({ skipBlanks: 'none' });
    }
    return arg;
  });
  const err = argsResolved.find(arg => isErr(arg));
  if (isErr(err)) {
    return err;
  }
  for (const arg of argsResolved) {
    if (Array.isArray(arg)) {
      for (const elem of arg) {
        if (isNum(elem)) {
          if (elem === 0) {
            return 0;
          }
          anyFound = true;
          prod *= elem;
        }
      }
    }
    else {
      const val = toNum(arg);
      if (isErr(val)) {
        return val;
      }
      anyFound = true;
      prod *= val;
    }
  }
  if (!anyFound) {
    return 0;
  }
  else if (isFinite(prod)) {
    return prod;
  }
  else {
    return ERROR_VALUE;
  }
}

/**
 * QUOTIENT(numerator, denominator)
 * Returns the integer portion of a division
 * @param {NonMatrixFormulaArgument} num
 * @param {NonMatrixFormulaArgument} den
 * @returns {number | FormulaError}
 */
export function QUOTIENT (num, den) {
  if (!!num === num || !!den === den || num === '' || den === '') {
    return ERROR_VALUE;
  }
  num = toNum(num);
  if (isErr(num)) {
    return num;
  }
  den = toNum(den);
  if (isErr(den)) {
    return den;
  }
  if (!den) {
    return ERROR_DIV0;
  }
  const r = num / den;
  return r < 0 ? Math.ceil(r) : Math.floor(r);
}

/**
 * RADIANS(angle)
 * Converts an angle value in degrees to radians.
 * @param {number} val
 * @returns {number}
 */
export function RADIANS (val) {
  return (val * π) / 180;
}

/**
 * RAND()
 * Returns a random number between 0 inclusive and 1 exclusive.
 * @returns {number}
 */
export function RAND () {
  return Math.random();
}

/**
 * RANDBETWEEN(low, high)
 * Returns a uniformly random integer between two values, inclusive.
 * @param {NonMatrixFormulaArgument} min
 * @param {NonMatrixFormulaArgument} max
 * @returns {number | FormulaError}
 */
export function RANDBETWEEN (min, max) {
  min = toNum(min);
  if (isErr(min)) {
    return min;
  }
  max = toNum(max);
  if (isErr(max)) {
    return max;
  }
  if (min > max) {
    return ERROR_NUM;
  }
  if (min === max) {
    return Math.ceil(min);
  }
  min = Math.ceil(min);
  max = Math.floor(max);
  if (min > max) {
    return min;
  }
  return min + Math.floor(Math.random() * (max - min + 1));
}

/** @type {[number, string, 0 | 1 | 2 | 3 | 4][]} */
const romanData = [
  [ 1000, 'M', 0 ],
  [ 999, 'IM', 4 ],
  [ 995, 'VM', 3 ],
  [ 990, 'XM', 2 ],
  [ 950, 'LM', 1 ],
  [ 900, 'CM', 0 ],
  [ 500, 'D', 0 ],
  [ 499, 'ID', 4 ],
  [ 495, 'VD', 3 ],
  [ 490, 'XD', 2 ],
  [ 450, 'LD', 1 ],
  [ 400, 'CD', 0 ],
  [ 100, 'C', 0 ],
  [ 99, 'IC', 4 ],
  [ 95, 'VC', 3 ],
  [ 90, 'XC', 0 ],
  [ 50, 'L', 0 ],
  [ 49, 'IL', 4 ],
  [ 45, 'VL', 1 ],
  [ 40, 'XL', 0 ],
  [ 10, 'X', 0 ],
  [ 9, 'IX', 0 ],
  [ 5, 'V', 0 ],
  [ 4, 'IV', 0 ],
  [ 1, 'I', 0 ],
];

/**
 * ROMAN(number, [rule_relaxation])
 * Formats a number in Roman numerals.
 * @param {number} val
 * @param {number | boolean} [rule]
 * @returns {string | FormulaError}
 */
export function ROMAN (val, rule) {
  if (val < 0 || val > 3999) {
    return ERROR_VALUE;
  }
  if (rule === true) {
    rule = 0;
  }
  else if (rule === false) {
    rule = 4;
  }
  else if (rule == null) {
    rule = 0;
  }
  else {
    const ruleNum = toNum(rule);
    if (isErr(ruleNum)) {
      return ruleNum;
    }
    if (rule < 0 || rule > 4) {
      return ERROR_VALUE;
    }
    rule = ruleNum;
  }
  let ret = '';
  for (let i = 0; i < romanData.length && val > 0; i++) {
    if (romanData[i][2] <= rule) {
      const [ amt, chr ] = romanData[i];
      while (val >= amt) {
        ret += chr;
        val -= amt;
      }
    }
  }
  return ret;
}

/**
 * ROUND(value, [places])
 * Rounds a number to a certain number of decimal places according to standard rules.
 * @param {number} val
 * @param {number} [places]
 * @returns {number}
 */
export function ROUND (val, places = 0) {
  const p = 10 ** Math.floor(places) || 0 || 1;
  return ((val < 0 ? -1 : 1) * Math.floor(Math.abs(val) * p + (0.5 + EPSILON))) / p;
}

/**
 * ROUNDDOWN(value, [places])
 * Rounds a number to a certain number of decimal places, always rounding down to the next valid increment.
 * @param {number} val
 * @param {number} [places]
 * @returns {number | FormulaError}
 */
export function ROUNDDOWN (val, places = 0) {
  const p = 10 ** Math.floor(places) || 0 || 1;
  const r = FLOOR_MATH(val * p, 1, 1) / p;
  if (isErr(r)) {
    return r;
  }
  return isFinite(r) ? r : ERROR_NUM;
}

/**
 * ROUNDUP(value, [places])
 * Rounds a number to a certain number of decimal places, always rounding up to the next valid increment.
 * @param {number} val
 * @param {number} [places]
 * @returns {number | FormulaError}
 */
export function ROUNDUP (val, places = 0) {
  const p = 10 ** Math.floor(places) || 0 || 1;
  const r = CEILING_MATH(val * p, 1, 1) / p;
  if (isErr(r)) {
    return r;
  }
  return isFinite(r) ? r : ERROR_NUM;
}

/**
 * SEC(angle)
 * Returns the secant of an angle provided in radians.
 * @param {number} a
 * @returns {number}
 */
export function SEC (a) {
  return 1 / Math.cos(a);
}

/**
 * SECH(value)
 * Returns the hyperbolic secant of any real number.
 * @param {number} val
 * @returns {number}
 */
export function SECH (val) {
  return 2 / (Math.exp(val) + Math.exp(-val));
}

/**
 * SERIESSUM(x, n, m, a)
 * Given parameters `x`, `n`, `m`, and `a`, returns the power series sum
 * a_1 x^n + a_2 x^{n + m} + a_3 x^{n + 2m} + ... + a_i x^{n + (i-1)m}
 * @param {NonMatrixFormulaArgument} x
 * @param {NonMatrixFormulaArgument} n
 * @param {NonMatrixFormulaArgument} m
 * @param {Exclude<FormulaArgument, Lambda>} a
 * @returns {number | FormulaError}
 */
export function SERIESSUM (x, n, m, a) {
  const xNum = toNumEngineering(x) ?? 0;
  if (isErr(xNum)) {
    return xNum;
  }
  if (xNum === MISSING) {
    return ERROR_NA;
  }
  const nNum = toNumEngineering(n) ?? 0;
  if (isErr(nNum)) {
    return nNum;
  }
  if (nNum === MISSING) {
    return ERROR_NA;
  }
  const mNum = toNumEngineering(m) ?? 0;
  if (isErr(mNum)) {
    return mNum;
  }
  if (a === MISSING) {
    return ERROR_NA;
  }
  if (a === BLANK) {
    a = 0;
  }
  if (isCellValue(a)) {
    a = Matrix.of(a);
  }
  let sum = 0;
  let p = nNum;
  const coeff = a.resolveRange();
  if (isErr(coeff)) {
    return coeff;
  }
  for (let c of coeff) {
    if (!isFinite(sum)) {
      return ERROR_NUM;
    }
    c = toNumEngineering(c);
    if (isErr(c)) {
      return c;
    }
    if (c === BLANK) {
      continue;
    }
    sum += c * xNum ** p;
    if (!isFinite(sum)) {
      return ERROR_NUM;
    }
    p += mNum;
  }
  return sum;
}

/**
 * SEQUENCE(rows, [columns], [start], [step])
 * Generates a list of sequential numbers in an array
 * @this {EvaluationContext}
 * @param {number | undefined} rows
 * @param {number | undefined} columns
 * @param {number | undefined} start
 * @param {number | undefined} step
 * @returns {Matrix | FormulaError}
 */
export function SEQUENCE (rows, columns, start, step) {
  if (this.mode === MODE_GOOGLE) {
    if (rows === MISSING && columns === MISSING && start === MISSING && step === MISSING) {
      return Matrix.of(1);
    }
    if (!rows) {
      return ERROR_NUM.detailed('SEQUENCE rows parameter is 0, should be at least 1');
    }
    if (rows && columns === 0) {
      return ERROR_NUM.detailed('SEQUENCE columns parameter is 0, should be at least 1');
    }
    if (columns == null && (start != null || step != null)) {
      return ERROR_NUM; // Not sure why Google Sheets does this, so no detail!
    }
  }

  const h = rows ?? 1;
  if (h < 0) {
    return ERROR_VALUE;
  }

  const w = columns ?? 1;
  if (w < 0) {
    return ERROR_VALUE;
  }

  if (!h || !w) {
    return ERROR_CALC;
  }

  const i = start ?? 1;
  const k = step ?? 1;

  const r = new Matrix(w, h);
  for (let j = 0; j < h * w; j++) {
    r.set(j % w, Math.floor(j / w), i + j * k);
  }
  return r;
}

/**
 * SIGN(value)
 * Given an input number, returns `-1` if it is negative, `1` if positive, and `0` if it is zero.
 * @param {number} val
 * @returns {number}
 */
export function SIGN (val) {
  if (!val) {
    return 0;
  }
  return val < 0 ? -1 : 1;
}

/**
 * SIN(angle)
 * Returns the sine of an angle provided in radians.
 * @param {number} a
 * @returns {number}
 */
export function SIN (a) {
  return Math.sin(a);
}

/**
 * SINH(value)
 * Returns the hyperbolic sine of any real number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function SINH (val) {
  const r = Math.sinh(val);
  return isFinite(r) ? r : ERROR_NUM;
}

/**
 * SQRT(value)
 * Returns the positive square root of a positive number.
 * @param {number} val
 * @returns {number | FormulaError}
 */
export function SQRT (val) {
  if (val < 0) {
    return ERROR_NUM;
  }
  return Math.sqrt(val);
}

/**
 * SQRTPI(value)
 * Returns the positive square root of the product of Pi and the given positive number.
 * @param {NonMatrixFormulaArgument} val
 * @returns {number | FormulaError}
 */
export function SQRTPI (val) {
  if (isBool(val)) {
    return ERROR_VALUE;
  }
  val = toNum(val);
  if (isErr(val)) {
    return val;
  }
  if (val < 0) {
    return ERROR_NUM;
  }
  return Math.sqrt(val * π);
}

/**
 * SUM(value1, [value2, ...])
 * Returns the sum of a series of numbers.
 * @param {(number | Reference | Matrix | Lambda)[]} args
 * @returns {number | FormulaError}
 */
export function SUM (...args) {
  for (const arg of args) {
    if (isLambda(arg)) {
      return ERROR_VALUE;
    }
  }
  return sum(args, standardFilterMap);
}

/**
 * SUMPRODUCT(array1, [array2, ...])
 * Calculates the sum of the products of corresponding entries in two or more equal-sized arrays or ranges.
 * @param {Matrix[]} args
 * @returns {number | FormulaError}
 */
export function SUMPRODUCT (...args) {
  let sum = 0;
  const valueLists = args.map(arg => arg.resolveRange());
  const length = valueLists[0].length;

  for (let i = 0; i < length; i++) {
    let product = 1;
    for (let j = 0; j < valueLists.length; j++) {
      const values = valueLists[j];

      if (length !== values.length) {
        return ERROR_VALUE;
      }

      if (typeof values[i] === 'string') {
        product = 0;
        break;
      }
      else {
        const value = toNum(values[i]);
        if (isErr(value)) {
          return value;
        }
        if (value === 0) {
          product = 0;
          break;
        }
        product *= value;
      }
    }
    sum += product;
  }
  return sum;
}

/**
 * SUMSQ(value1, [value2, ...])
 * Returns the sum of the squares of a series of numbers and/or cells.
 * @param {(number | Matrix | Reference)[]} args
 * @returns {number | FormulaError}
 */
export function SUMSQ (...args) {
  const numbers = toNumList(args);
  if (isErr(numbers)) {
    return numbers;
  }
  return numbers.reduce((sum, num) => (sum += num * num), 0);
}

/**
 * @param {Matrix} arrayXArg
 * @param {Matrix} arrayYArg
 * @param {(x: number, y: number) => number} func
 * @returns {number | FormulaError}
 */
function sumxCommon (arrayXArg, arrayYArg, func) {
  if (arrayXArg.size !== arrayYArg.size) {
    return ERROR_NA;
  }
  const arrayX = arrayXArg.resolveRange();
  const arrayY = arrayYArg.resolveRange();
  let acc = 0;
  let numInX = false;
  let numInY = false;
  for (let i = 0; i < arrayX.length; i += 1) {
    const x = arrayX[i];
    const y = arrayY[i];
    if (isNum(x) && isNum(y)) {
      acc += func(x, y);
    }
    numInX = numInX || isNum(x);
    numInY = numInY || isNum(y);
  }
  if (!(numInX && numInY)) {
    return ERROR_DIV0;
  }
  return acc;
}

/**
 * SUMX2MY2(array_x, array_y)
 * Calculates the sum of the differences of the squares of values in two arrays.
 * @param {Matrix} arrayXArg
 * @param {Matrix} arrayYArg
 * @returns {number | FormulaError}
 */
export function SUMX2MY2 (arrayXArg, arrayYArg) {
  return sumxCommon(arrayXArg, arrayYArg, (x, y) => x * x - y * y);
}

/**
 * SUMX2PY2(array_x, array_y)
 * Calculates the sum of the sums of the squares of values in two arrays.
 * @param {Matrix} arrayXArg
 * @param {Matrix} arrayYArg
 * @returns {number | FormulaError}
 */
export function SUMX2PY2 (arrayXArg, arrayYArg) {
  return sumxCommon(arrayXArg, arrayYArg, (x, y) => x * x + y * y);
}

/**
 * SUMXMY2(array_x, array_y)
 * Calculates the sum of the squares of differences of values in two arrays.
 * @param {Matrix} arrayXArg
 * @param {Matrix} arrayYArg
 * @returns {number | FormulaError}
 */
export function SUMXMY2 (arrayXArg, arrayYArg) {
  return sumxCommon(arrayXArg, arrayYArg, (x, y) => (x - y) ** 2);
}

/**
 * TAN(angle)
 * Returns the tangent of an angle provided in radians.
 * @param {number} a
 * @returns {number}
 */
export function TAN (a) {
  return Math.tan(a);
}

/**
 * TANH(value)
 * Returns the hyperbolic tangent of any real number.
 * @param {number} val
 * @returns {number}
 */
export function TANH (val) {
  return Math.tanh(val);
}

/**
 * TRUNC(value, [places])
 * Truncates a number to a certain number of significant digits by omitting less significant digits.
 * @param {number} val
 * @param {number} [digits=0]
 * @returns {number}
 */
export function TRUNC (val, digits = 0) {
  const p = 10 ** digits;
  return ((val >= 0 ? 1 : -1) * Math.floor(Math.abs(val) * p)) / p;
}

const _subtotalFuncs = [ AVERAGE, COUNT, COUNTA, MAX, MIN, PRODUCT, STDEV_S, STDEV_P, SUM, VAR_S, VAR_P ];

/**
 * SUBTOTAL(function_num, ref1, [ref2, ...])
 * Returns a subtotal in a list or database.
 * https://support.office.com/en-us/article/subtotal-function-7b027003-f060-4ade-9040-e478765b9939
 * @param {number} function_num
 * @param {Reference[]} refs
 * @returns {number | FormulaError}
 */
export function SUBTOTAL (function_num, ...refs) {
  function_num = Math.trunc(function_num);
  if (function_num < 1 || (function_num > 11 && function_num < 101) || function_num > 111) {
    return ERROR_VALUE;
  }
  for (let i = 0; i < refs.length; i++) {
    if (!isRef(refs[i])) {
      return ERROR_VALUE;
    }
  }
  const skipHidden = function_num > 100;
  const numbers = toNumList(refs, false, skipHidden);
  if (isErr(numbers)) {
    return numbers;
  }
  const fun = _subtotalFuncs[(function_num % 100) - 1];
  return fun(...numbers);
}
