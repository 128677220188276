import {
  isNonLiteralNode,
  astIsGoogleArrayFunctionCall,
  visitAllCallNodes,
  isContextDependentCall,
  reconstructFormula,
} from './excel/ast';
import { visitAllNonLiteralNodes } from './excel/ast-common';
import { EXTRACTED_SUBEXPRESSION_CELL_ID_PREFIX, MODE_GOOGLE } from './excel/constants.js';
import { invariant } from './validation';
import { vertexIdToCell } from './dep-graph-helpers.js';
import { CellVertexId, NameVertexId, RangeVertexId, Edge, Vertex } from './DependencyGraph';
import Cell from './excel/Cell.js';
import { ASTNonLiteralNode, ASTPath, type ASTNonLiteralRootNode } from './excel/ast-types.js';
import { chain } from '@grid-is/iterators';
import { DefaultMap } from '@grid-is/collections';
import { SlimRange } from './excel/referenceParser/Range.js';
import updateGraphFor from './updateGraph';
import type Model from './Model';

type RangeVertex = Vertex<Cell, RangeVertexId>;
type FromVertexId = CellVertexId | NameVertexId;
type FromVertex = Vertex<Cell, FromVertexId>;

type ExpressionOccurrence = [node: ASTNonLiteralNode, formula: string, from: FromVertex, astPath: ASTPath];

const MIN_RANGE_SIZE_TIMES_REPEATS_TO_OPTIMIZE = 1000;

const RANDOM_FUNCTIONS = [ 'RAND', 'RANDARRAY', 'RANDBETWEEN' ];
const FUNCTIONS_TO_EXTRACT = [ 'TODAY', 'NOW' ];

/**
 * Optimizer to reduce redundant expression evaluations in a model.
 *
 * Outline of the optimization approach:
 *
 * 1. Walk formula ASTs upwards from range-reference leaves, reconstructing a
 *    formula string for each wider-and-wider subexpression, and associating
 *    those formula strings with the cells/defined-names and AST paths at which
 *    they occur. These formula strings will parse to an AST identical to the
 *    AST subtrees from which they were reconstructed, except with any bare
 *    references prefixed with the correct sheet name (and possibly with
 *    `ASTRootNode` properties added).
 *
 * 2. For each subexpression that occurs more than once:
 *
 *     2.1. Create a defined name for that expression, in the same workbook as
 *          the formulas referencing it (so possibly create a defined name in
 *          more than one workbook)
 *
 *     2.2. Replace all occurrences of these subexpressions with references to
 *          the corresponding name. Update the dependency graph accordingly.
 */
class ExpressionExtractor {
  private model: Model;

  constructor (model: Model) {
    this.model = model;
  }

  /**
   * Extract selected expressions to defined names to reduce repeated and/or
   * unnecessary work:
   *
   * 1. repeated expressions around range references (to compute them only once)
   *
   * 2. calls to `TODAY` and `NOW` (to avoid recalculating the rest of the
   *    formula, and any on-demand cells it may reference, if the volatile
   *    function call result is unchanged, as `TODAY()` is most of the time,
   *    and also to let `NOW()` have a consistent value across a recalculation),
   */
  extract () {
    this.model._graph.visitRangeVertices(vertex => {
      this.extractForRange(vertex);
    });
    // Create a copy of the list of volatiles before we begin, because that list
    // will change during the procedure
    const originalVolatiles = [ ...this.model.volatiles ];
    for (const vertexId of originalVolatiles) {
      this.extractVolatileCalls(vertexId);
    }
  }

  private extractVolatileCalls (vertexId: FromVertexId) {
    const cell = vertexIdToCell(this.model, vertexId);
    const sheetName = this.getSheetName(vertexId);
    if (!cell || !sheetName) {
      // Presumably cruft in the dependency graph; ignore.
      return;
    }
    visitAllCallNodes(cell._ast, node => {
      if (
        typeof node.call === 'string' &&
        FUNCTIONS_TO_EXTRACT.includes(node.call.toUpperCase()) &&
        !(node === cell._ast && cell.id.startsWith(EXTRACTED_SUBEXPRESSION_CELL_ID_PREFIX))
      ) {
        const formula = reconstructFormula(node, sheetName, new Map());
        const extracted = this.extractExpression(vertexId, formula);
        if (extracted) {
          changeNodeToNameReference(node, extracted.id);
          updateGraphFor(this.model._graph, this.model.getWorkbookByKey(vertexId.workbookKey)!, [ vertexId ]);
        }
      }
    });
  }

  private getSheetCroppedSize (vertexId: RangeVertexId) {
    const sheetBounds = this.wbToSheetToBounds.get(vertexId.workbookKey).get(vertexId.sheetIndex);
    return vertexId.toRange().getIntersection(sheetBounds)?.size || 0;
  }

  private wbToSheetToBounds = new DefaultMap<number, DefaultMap<number, SlimRange>>(
    workbookKey =>
      new DefaultMap(sheetIndex => {
        const workbook = this.model.getWorkbookByKey(workbookKey);
        const sheet = workbook?.getSheetByIndex(sheetIndex);
        return sheet?.getBounds() || { top: 0, left: 0, right: 0, bottom: 0 };
      }),
  );

  /**
   * Extract repeated expressions that reference the given range.
   */
  private extractForRange (vertex: RangeVertex) {
    // One call to find them
    const occurrences = this.findCommonExpressionsReferencing(vertex);
    // One call to bring them all and in defined names bind them
    const formulaToName = this.extractExpressions(occurrences);
    // Finally, replace each expression occurrence with a reference to the name
    this.transformOccurrences(occurrences, formulaToName);
  }

  private findCommonExpressionsReferencing (vertex: RangeVertex): ExpressionOccurrence[] {
    const edgesToPaths: ReadonlyMap<Edge<Cell>, ASTPath[]> = this.collectEdgesWithASTPathsEligibleForExtraction(vertex);
    // Step upwards from the AST path node parents, collecting information about
    // nodes that are candidates for common subexpression extraction.
    const { formulaToNodes, nodeToFormulaAndFromVertexAndAstPath } = this.collectSubexpressionCandidates(edgesToPaths);
    const formulaAndNodesOrderedLongestFirst = [ ...formulaToNodes.entries() ];
    formulaAndNodesOrderedLongestFirst.sort((a, b) => b[0].length - a[0].length);
    const sheetCroppedRangeSize = this.getSheetCroppedSize(vertex.id);
    for (const [ formula, nodes ] of formulaAndNodesOrderedLongestFirst) {
      if (nodes.size === 1 || nodes.size * sheetCroppedRangeSize < MIN_RANGE_SIZE_TIMES_REPEATS_TO_OPTIMIZE) {
        // only referenced once, or not repeated often enough for its size; skip!
        formulaToNodes.delete(formula);
        for (const node of nodes) {
          nodeToFormulaAndFromVertexAndAstPath.delete(node);
        }
      }
      else {
        // we will transform all nodes that are keys of nodeToVertexId into
        // name reference nodes that reference the extracted common subexpression.
        // This will discard their node subtrees, which may contain other nodes
        // that appear as keys in values of `formulaToNodes`.
        // We must consider those subsumed by the larger common subexpression,
        // so that we don't needlessly make defined names for them.
        for (const nodeToTransform of nodes) {
          for (const node of Object.values(nodeToTransform)) {
            visitAllNonLiteralNodes(node, n => {
              nodeToFormulaAndFromVertexAndAstPath.delete(n);
            });
          }
        }
      }
    }
    return Array.from(
      chain(nodeToFormulaAndFromVertexAndAstPath.entries()).map(([ node, [ formula, fromVertex, path ] ]) => [
        node,
        formula,
        fromVertex,
        path,
      ]),
    );
  }

  private collectEdgesWithASTPathsEligibleForExtraction (vertex: RangeVertex): ReadonlyMap<Edge<Cell>, ASTPath[]> {
    const edgesToPaths = new DefaultMap<Edge<Cell>, ASTPath[]>(() => []);
    const edgeToASTPaths = this.model._graph.edgeToASTPaths;
    let numPaths = 0;
    for (const edge of vertex.incomingEdges) {
      const astPaths = edgeToASTPaths.getIfPresent(edge);
      if (astPaths) {
        const eligibleAstPaths = this.astPathsEligibleForSubexpressionExtraction(astPaths, edge.from.id);
        numPaths += eligibleAstPaths.length;
        for (const astPath of eligibleAstPaths) {
          edgesToPaths.get(edge).push(astPath);
        }
      }
    }
    if (numPaths > 1) {
      const sheetCroppedRangeSize = this.getSheetCroppedSize(vertex.id);
      // Only return these AST paths if their count times the range size reaches
      // the threshold. Later we will compare the number of repetitions of any
      // extracted subexpression to this same threshold, and reject expressions
      // that don't meet the threshold. But here we can cancel the subexpression
      // work _earlier_ because the number of AST paths referencing a range is
      // an upper bound on the possible number of repetitions of any expression
      // that references that range ... so if the number of paths doesn't reach
      // this threshold, then no subexpression derived from them will, either,
      // so we would end up rejecting any such subexpressions, and thus can now
      // abort the rather expensive work of finding them.
      if (numPaths * sheetCroppedRangeSize >= MIN_RANGE_SIZE_TIMES_REPEATS_TO_OPTIMIZE) {
        return edgesToPaths;
      }
    }
    return new Map();
  }

  private astPathsEligibleForSubexpressionExtraction (astPaths: ASTPath[], fromId: FromVertexId) {
    if (!astPaths[0] || isExtractedSubexpression(fromId)) {
      return [];
    }
    const cell = this.getCell(fromId);
    if (!cell) {
      return [];
    }
    if (cell.ft === 'a' || !this.workbookDefaultsToNonArrayMode.get(fromId.workbookKey)) {
      return astPaths;
    }
    return astPaths.filter(path => path.some(astIsGoogleArrayFunctionCall));
  }

  private workbookDefaultsToNonArrayMode = new DefaultMap((workbookKey: number) => {
    // Non-null because we only look up workbook keys of from-IDs of edges in
    // the graph; when they are added, the workbook is necessarily present, and
    // when a workbook is removed, all edges from its vertices are removed.
    const workbook = this.model.getWorkbookByKey(workbookKey);
    return workbook?.mode === MODE_GOOGLE;
  });

  private getCell (vertexId: FromVertexId): Cell | null {
    return vertexIdToCell(this.model, vertexId);
  }

  /**
   * Find all subexpressions in the given AST paths which occur more than once.
   * Return a map that can be used to look up AST nodes in order to replace each
   * with a reference to a defined name that evaluates the common subexpression.
   */
  private collectSubexpressionCandidates (edgesToPaths: ReadonlyMap<Edge<Cell>, ASTPath[]>): {
    formulaToNodes: DefaultMap<string, Set<ASTNonLiteralNode>>,
    nodeToFormulaAndFromVertexAndAstPath: Map<ASTNonLiteralNode, [string, FromVertex, ASTPath]>,
  } {
    const formulaToNodes = new DefaultMap<string, Set<ASTNonLiteralNode>>(() => new Set());
    const nodeToFormulaAndFromVertexAndAstPath = new Map<ASTNonLiteralNode, [string, FromVertex, ASTPath]>();
    for (const [ edge, paths ] of edgesToPaths.entries()) {
      const sheetName = this.getSheetName(edge.from.id);
      if (!sheetName) {
        // Guard against corner cases where there's leftover junk in the graph
        continue;
      }
      const formulaMemo = new Map<ASTNonLiteralNode, string>();
      // Start at -2, the node _containing_ the range reference. We don't
      // extract range references themselves, because they would become a
      // defined name that returns a reference, which saves no work; the work of
      // dereferencing the range reference into a matrix to use in formula
      // evaluation would still be done repeatedly in each formula that uses the
      // extracted name.
      //
      // A follow-up might revisit this to create defined names which do resolve
      // the reference into a matrix; that might bring more performance benefits
      // by effectively memoizing the range resolution.
      for (const astPath of paths) {
        // Skip over any LAMBDA subtrees, not extracting anything there for now.
        if (astPath.some(node => 'lambda' in node)) {
          continue;
        }
        const hasLet = astPath.some(node => 'let' in node);
        for (let index = astPath.length - 2; index >= 0; index--) {
          const node = astPath[index];
          if (
            'name' in node ||
            this.hasIneligibleCall.get(node) ||
            (hasLet && referencesName(node, letParametersAlongPath(astPath.slice(0, index + 1))))
          ) {
            // Reached a node not eligible for extraction (the root node, or an
            // already extracted node, or a node with random calls, or a node
            // which gets evaluated in implicit-intersection mode
            break;
          }
          const isRootNode = index === 0;
          const formula = reconstructFormula(
            isRootNode ? stripRootNodeProperties(node as ASTNonLiteralRootNode) : node,
            sheetName,
            formulaMemo,
            false,
          );
          formulaToNodes.get(formula).add(node);
          nodeToFormulaAndFromVertexAndAstPath.set(node, [ formula, edge.from, astPath ]);
        }
      }
    }
    return {
      formulaToNodes,
      nodeToFormulaAndFromVertexAndAstPath,
    };
  }

  private getSheetName (vertexId: CellVertexId | NameVertexId) {
    const wb = this.model.getWorkbookByKey(vertexId.workbookKey);
    return wb?.getSheetByIndex(vertexId.sheetIndex)?.name || null;
  }

  private hasIneligibleCall = new DefaultMap<ASTNonLiteralNode, boolean>(ast => {
    if ('call' in ast) {
      if (typeof ast.call !== 'string') {
        // Lambda calls may or may not be eligible for extraction; safest to
        // consider them ineligible.
        return true;
      }
      const funcName = ast.call.toUpperCase();
      if (RANDOM_FUNCTIONS.includes(funcName) || isContextDependentCall(funcName, ast.args)) {
        // Random functions and context-dependent calls (ROW() and COLUMN()
        // and INDIRECT(...)) are ineligible
        return true;
      }
    }
    if ('args' in ast) {
      // Function call or binary operator; check each argument
      for (const subNode of ast.args) {
        if (isNonLiteralNode(subNode) && this.hasIneligibleCall.get(subNode)) {
          return true;
        }
      }
    }
    else if ('array' in ast) {
      for (const row of ast.array) {
        for (const subNode of row) {
          if (isNonLiteralNode(subNode) && this.hasIneligibleCall.get(subNode)) {
            return true;
          }
        }
      }
    }
    else if ('range' in ast) {
      for (const subNode of ast.range) {
        if (isNonLiteralNode(subNode) && this.hasIneligibleCall.get(subNode)) {
          return true;
        }
      }
    }
    else if ('intersection' in ast) {
      for (const subNode of ast.intersection) {
        if (isNonLiteralNode(subNode) && this.hasIneligibleCall.get(subNode)) {
          return true;
        }
      }
    }
    else if ('union' in ast) {
      for (const subNode of ast.union) {
        if (isNonLiteralNode(subNode) && this.hasIneligibleCall.get(subNode)) {
          return true;
        }
      }
    }
    else if ('structured' in ast && ast.structured?.keywords?.some(kw => kw.toLowerCase() === '[#this row]')) {
      return true;
    }
    // Can leave out the 'expr' case (for 'unop' nodes) because a unop node
    // only has one child, so we would already have checked that before
    // propagating through it up the AST.
    return false;
  });

  /**
   * Extract defined names for subexpressions that appear multiple times.
   * Return a mapping of subexpression (with all references sheet-qualified) to
   * name.
   */
  private extractExpressions (occurrences: ExpressionOccurrence[]) {
    const formulaToName = new Map<string, string>();
    for (const [ node, formula, from ] of occurrences) {
      if ('name' in node) {
        // already replaced
        continue;
      }
      const cell = this.extractExpression(from.id, formula);
      if (cell) {
        formulaToName.set(formula, cell.id);
      }
    }
    return formulaToName;
  }

  private extractExpression (fromId: FromVertexId, formula: string) {
    const workbook = this.model.getWorkbookByKey(fromId.workbookKey)!;
    return workbook.getCachedFormulaCell(formula, false, EXTRACTED_SUBEXPRESSION_CELL_ID_PREFIX);
  }

  /**
   * Change each AST node (root of a repeated subexpression), to be a name
   * reference to the already-computed subexpression result, found in
   * `formulaToName`. Then redo the formula in the dependency graph
   * via `updateGraphFor`, i.e. clear all its edges and re-analyze the formula
   * adding edges from scratch. This:
   *
   * * is necessary because the change may or may not have removed particular
   *   edges from the dependency graph, or changed their kinds, and these
   *   changes are hard to reason about in a more granular way
   *
   * * must happen for each individual expression even if multiple expressions
   *   will get extracted from the same formula, because the dependency graph
   *   information may be used again in processing other range vertices, so it
   *   must be up-to-date in order not to confuse the subexpression extraction
   *   procedure for those range vertices.
   */
  private transformOccurrences (occurrences: Iterable<ExpressionOccurrence>, formulaToName: Map<string, string>) {
    const graph = this.model._graph;
    for (const [ node, formula, from, astPath ] of occurrences) {
      if ('name' in node) {
        // already replaced
        continue;
      }
      invariant(astPath.includes(node));
      const name = formulaToName.get(formula);
      if (name) {
        if (from.id instanceof NameVertexId && from.id.name === name) {
          // the from-cell is itself a cached-formula cell and this would be a self-reference.
          continue;
        }
        changeNodeToNameReference(node, name);
        updateGraphFor(graph, this.model.getWorkbookByKey(from.id.workbookKey)!, [ from.id ]);
      }
    }
  }
}

export function extractRepeatedExpressions (model: Model) {
  new ExpressionExtractor(model).extract();
}

function stripRootNodeProperties (rootNode: ASTNonLiteralRootNode) {
  const node = { ...rootNode };
  delete node.isContextDependent;
  delete node.hasConditionalDependencies;
  delete node.callsUnsupportedFunction;
  return node;
}

function changeNodeToNameReference (node: ASTNonLiteralNode, name: string) {
  for (const key of Object.keys(node)) {
    delete node[key];
  }
  (node as { name: string }).name = name;
}

function isExtractedSubexpression (id: FromVertexId): any {
  return id instanceof NameVertexId && id.name.startsWith(EXTRACTED_SUBEXPRESSION_CELL_ID_PREFIX);
}

/** Collect any parameter names of LET parameters along the given AST path (i.e. visible in the path's subtree) */
function letParametersAlongPath (path: ASTNonLiteralNode[]) {
  const letParamNamesUpper: string[] = [];
  for (const node of path) {
    if ('let' in node) {
      for (const name of Object.keys(node.let)) {
        letParamNamesUpper.push(name.toUpperCase());
      }
    }
  }
  return letParamNamesUpper;
}

/**
 * Checks if the given AST includes a name reference to one of the given names.
 */
function referencesName (ast: ASTNonLiteralNode, namesUpperCase: string[]): boolean {
  let found = false;
  visitAllNonLiteralNodes(ast, (node, stop) => {
    if ('name' in node && namesUpperCase.includes(node.name.toUpperCase())) {
      found = true;
      stop.set();
    }
  });
  return found;
}
