import { ERROR_NUM, ERROR_VALUE, ERROR_NA, ERROR_DIV0, MISSING, BLANK, ERROR_NAME } from '../constants.js';
import { isErr, isRef, toNum, toNumEngineering, toStr } from './utils.js';
import { priestsAlgorithm, complexDivisionNaive } from './utils-math';
import { visitEngineering } from './utils-visit.js';
import { isNum, isStr } from '../../utils.js';
import type FormulaError from '../FormulaError.js';
import { FormulaArgument, NonMatrixFormulaArgument, CellValueAtom } from '../types.js';
import Reference from '../Reference.js';
import { invariant } from '../../validation';
import bessel from 'bessel';
import { parseConvertUnit } from './utils-convert';

// Bitfield for a Complex number's unit, which is either `i`, `j`, or missing.
// The bitfield form makes it easier to check if a set of complex numbers have
// compatible units or not. Incompatible complex numbers have (a.unit | b.unit) !== 3,
// or equivalently, (a.unit ^ b.unit) === 0.
const UNIT_NONE = 0;
const UNIT_I = 1;
const UNIT_J = 2;
const UNIT_BOTH = 3;

type ComplexNumberUnit = typeof UNIT_NONE | typeof UNIT_I | typeof UNIT_J | typeof UNIT_BOTH;

function withoutWhitespace (str: string): string {
  return str.replace(/\s+/g, '');
}

function realPartToNumber (str: string): number {
  return parseFloat(withoutWhitespace(str));
}

function imaginaryPartToNumber (str: string): [number, typeof UNIT_I | typeof UNIT_J] {
  str = withoutWhitespace(str);
  const unit = str.indexOf('i') >= 0 ? 'i' : 'j';
  let number = parseFloat(str);
  if (isNaN(number)) {
    // We're here because there is no constant in front of the imaginary part,
    // as is the case in "i", "+i", "-i", "1 + i", "1 - i" etc.
    number = parseFloat(str.replace(unit, '1'));
  }
  return [ number, unit === 'i' ? UNIT_I : UNIT_J ];
}

/**
 * Try to parse a string as a complex number. The return value is an array of
 * three numbers `[real, imaginary, unit]`. The first and second numbers are the
 * real and imaginary parts of the number, respectively. The third number is
 * one of `UNIT_NONE`, `UNIT_I` or `UNIT_J`, depending on which unit was used
 * in the complex number string given to the function.
 */
export function parseComplex (
  str: string,
): [number, number, typeof UNIT_NONE | typeof UNIT_I | typeof UNIT_J] | FormulaError {
  // The regexes were generated using the following Python script:
  /* ```python
  # Whitespace
  ws = "\s*"
  # 2, 2., 2.0
  float1 = "[0-9]+(?:[.][0-9]*)?"
  # .2
  float2 = "[.][0-9]+"
  # e1, e+2, e-5
  exp_part = "[eE][+-]?[0-9]+"
  # 2, 2., 2.0, .2, 2e1, 2.E-2, .2e100
  float_regex = f"(?:{float1}|{float2})(?:{exp_part})?"
  real_part = f"[+-]?{ws}{float_regex}"
  im_part_no_sign = f"(?:{ws}{float_regex}{ws})?[ij]"
  im_part_req_sign = f"[+-]{im_part_no_sign}"
  im_part_opt_sign = f"[+-]?{im_part_no_sign}"
  real_im_opt_regex = f"{ws}({real_part}){ws}({im_part_req_sign})?{ws}"
  im_regex = f"{ws}({im_part_opt_sign}){ws}"
  print("/^" + real_im_opt_regex + "$/")
  print("/^" + im_regex + "$/")
  ```*/
  str = str.trim();
  // This regex matches a real part, optionally followed by an imaginary part.
  const realReqImOptRegex =
    /^([+-]?\s*(?:[0-9]+(?:[.][0-9]*)?|[.][0-9]+)(?:[eE][+-]?[0-9]+)?)\s*([+-](?:\s*(?:[0-9]+(?:[.][0-9]*)?|[.][0-9]+)(?:[eE][+-]?[0-9]+)?\s*)?[ij])?$/;
  let matches = realReqImOptRegex.exec(str);
  if (matches !== null) {
    const realPart = matches[1];
    const imPart = matches[2];
    if (imPart == null) {
      return [ realPartToNumber(realPart), 0, 0 ];
    }
    else {
      return [ realPartToNumber(realPart), ...imaginaryPartToNumber(imPart) ];
    }
  }
  // This regex matches an imaginary part only, without a real part.
  const imRegex = /^([+-]?(?:\s*(?:[0-9]+(?:[.][0-9]*)?|[.][0-9]+)(?:[eE][+-]?[0-9]+)?\s*)?[ij])$/;
  matches = imRegex.exec(str);
  if (matches !== null) {
    return [ 0, ...imaginaryPartToNumber(matches[1]) ];
  }
  return ERROR_NUM;
}

function complexPartToString (num: number): string {
  let str;
  // It seems to only be in this range that JavaScript and Excel differ
  // in when they turn a floating point number into exponential notation.
  if (num <= 1e-7 && num > 1e-20) {
    const numDecimalPlaces = -Math.log10(num);
    str = num.toFixed(numDecimalPlaces);
  }
  else {
    str = num.toString();
  }
  return str.toUpperCase();
}

// Allow both strings and UNIT_* constants as arguments to the ComplexNumber
// constructor.
const UNIT_MAP: { [key: string | number]: ComplexNumberUnit } = {
  [UNIT_NONE]: UNIT_NONE,
  [UNIT_I]: UNIT_I,
  [UNIT_J]: UNIT_J,
  i: UNIT_I,
  j: UNIT_J,
};

export class ComplexNumber {
  unit: ComplexNumberUnit;
  real: number;
  im: number;

  constructor (real: number, im: number, unit: number | string | null) {
    if (im === 0) {
      unit = null;
    }
    const mappedUnit = unit == null ? UNIT_NONE : UNIT_MAP[unit];
    if (mappedUnit == null) {
      throw new Error(`invalid complex number unit: ${unit}`);
    }
    this.unit = mappedUnit;
    this.real = real;
    this.im = im;
  }

  /**
   * Same as the constructor, except the input arguments are actually validated.
   * Constructors can't have error return values, which is why we do it this way.
   */
  static newChecked (
    real: NonMatrixFormulaArgument,
    im: NonMatrixFormulaArgument,
    unit: NonMatrixFormulaArgument,
  ): ComplexNumber | FormulaError {
    real = toNum(real);
    if (isErr(real)) {
      return real;
    }
    im = toNum(im);
    if (isErr(im)) {
      return im;
    }
    const unitStr = toStr(unit);
    if (isErr(unitStr)) {
      return unitStr;
    }
    return new ComplexNumber(real, im, unitStr);
  }

  static newPolar (r: number, theta: number, unit: string | number | null): ComplexNumber {
    return new ComplexNumber(r * Math.cos(theta), r * Math.sin(theta), unit);
  }

  // Additive identity
  static zero () {
    return new ComplexNumber(0, 0, null);
  }

  // Multiplicative identity
  static one () {
    return new ComplexNumber(1, 0, null);
  }

  /**
   * Try to convert the given value into a ComplexNumber. Which error codes are
   * returned when is kind of bizarre, but this seems to be how Excel does it.
   */
  static from (value: NonMatrixFormulaArgument): ComplexNumber | FormulaError {
    if (isRef(value) && value.size === 1) {
      value = value.resolveSingle();
    }
    if (isErr(value)) {
      return value;
    }
    if (isNum(value)) {
      return new ComplexNumber(value, 0, UNIT_NONE);
    }
    if (value === MISSING) {
      return ERROR_NA;
    }
    if (value === BLANK) {
      return new ComplexNumber(0, 0, UNIT_NONE);
    }
    if (!isStr(value)) {
      return ERROR_VALUE;
    }
    const parsed = parseComplex(value);
    if (isErr(parsed)) {
      return parsed;
    }
    return new ComplexNumber(parsed[0], parsed[1], parsed[2]);
  }

  toString (): string | FormulaError {
    const real = this.real;
    if (!isFinite(real)) {
      return ERROR_NUM;
    }
    const im = this.im;
    if (!isFinite(im)) {
      return ERROR_NUM;
    }
    if (im === 0 && real === 0) {
      return '0';
    }
    let imPart = '';
    if (im !== 0) {
      if (im === -1) {
        imPart = '-';
      }
      else if (im !== 1) {
        imPart = complexPartToString(im);
      }
      if (im > 0 && real !== 0) {
        imPart = '+' + imPart;
      }
      // Unit should be non-empty if im !== 0.
      imPart += this.unit === UNIT_I ? 'i' : 'j';
    }
    let realPart = '';
    if (real !== 0) {
      realPart = complexPartToString(real);
    }
    return `${realPart}${imPart}`;
  }

  chooseUnit (other: ComplexNumber, error = ERROR_NUM): ComplexNumberUnit | FormulaError {
    if (this.unit === other.unit || other.unit === UNIT_NONE) {
      return this.unit;
    }
    if (this.unit === UNIT_NONE) {
      return other.unit;
    }
    return error;
  }

  magnitude (): number {
    // We go a round-about way of finding the magnitude, to prevent overflow. See
    // https://www.johndcook.com/blog/2010/06/02/whats-so-hard-about-finding-a-hypotenuse/
    const absReal = Math.abs(this.real);
    const absIm = Math.abs(this.im);
    const max = Math.max(absReal, absIm);
    if (max === 0) {
      return 0;
    }
    const min = Math.min(absReal, absIm);
    const r = min / max;
    return max * Math.sqrt(1 + r ** 2);
  }

  /**
   * The complex number's angle in radians
   */
  angle (): number {
    return Math.atan2(this.im, this.real);
  }

  conjugate (): ComplexNumber {
    return new ComplexNumber(this.real, -this.im, this.unit);
  }

  add (other: ComplexNumber, unitError: FormulaError = ERROR_NUM): ComplexNumber | FormulaError {
    const unit = this.chooseUnit(other, unitError);
    if (isErr(unit)) {
      return unit;
    }
    return new ComplexNumber(this.real + other.real, this.im + other.im, unit);
  }

  sub (other: ComplexNumber): ComplexNumber | FormulaError {
    const unit = this.chooseUnit(other);
    if (isErr(unit)) {
      return unit;
    }
    return new ComplexNumber(this.real - other.real, this.im - other.im, unit);
  }

  mul (other: ComplexNumber, unitError: FormulaError = ERROR_NUM): ComplexNumber | FormulaError {
    const unit = this.chooseUnit(other, unitError);
    if (isErr(unit)) {
      return unit;
    }
    // I've searched the literature, and there seems to be no better way to
    // multiply two complex numbers without special FMA (fused multiply add)
    // CPU instructions. We don't have access to those within JavaScript.
    return new ComplexNumber(
      this.real * other.real - this.im * other.im,
      this.real * other.im + this.im * other.real,
      unit,
    );
  }

  div (other: ComplexNumber): ComplexNumber | FormulaError {
    const unit = this.chooseUnit(other);
    if (isErr(unit)) {
      return unit;
    }
    if (other.im === 0) {
      return new ComplexNumber(this.real / other.real, this.im / other.real, unit);
    }
    if (typeof BigInt !== 'undefined') {
      // A special algorithm is neccesary to divide two complex numbers without
      // overflow or loss in accuracy. See Chapter 11 in the Handbook of Floating-Point
      // Arithmetic (2nd edition).
      const [ real, im ] = priestsAlgorithm(this.real, this.im, other.real, other.im);
      return new ComplexNumber(real, im, unit);
    }
    // ... however, on old browsers which don't support BigInt, it's ok to fall
    // back on a more naive algorithm.
    const [ real, im ] = complexDivisionNaive(this.real, this.im, other.real, other.im);
    return new ComplexNumber(real, im, unit);
  }

  pow (n: number): ComplexNumber {
    const r = this.magnitude();
    const theta = this.angle();
    return ComplexNumber.newPolar(r ** n, theta * n, this.unit || UNIT_I);
  }

  sqrt (): ComplexNumber {
    const r = this.magnitude();
    const theta = this.angle();
    return ComplexNumber.newPolar(Math.sqrt(r), theta / 2, this.unit || UNIT_I);
  }

  exp (): ComplexNumber {
    return ComplexNumber.newPolar(Math.exp(this.real), this.im, this.unit);
  }

  log10 (): ComplexNumber | FormulaError {
    const log_magnitude = Math.log10(this.magnitude());
    if (!isFinite(log_magnitude)) {
      return ERROR_NUM;
    }
    const log10_e = 0.4342944819032518;
    return new ComplexNumber(log_magnitude, log10_e * this.angle(), this.unit || UNIT_I);
  }

  log2 (): ComplexNumber | FormulaError {
    const log_magnitude = Math.log2(this.magnitude());
    if (!isFinite(log_magnitude)) {
      return ERROR_NUM;
    }
    const log2_e = 1.4426950408889634;
    return new ComplexNumber(log_magnitude, log2_e * this.angle(), this.unit || UNIT_I);
  }

  ln (): ComplexNumber | FormulaError {
    const log_magnitude = Math.log(this.magnitude());
    if (!isFinite(log_magnitude)) {
      return ERROR_NUM;
    }
    return new ComplexNumber(log_magnitude, this.angle(), this.unit || UNIT_I);
  }

  log (base: number): ComplexNumber | FormulaError {
    const log_base = Math.log(base);
    const log_magnitude = Math.log(this.magnitude()) / log_base;
    if (!isFinite(log_magnitude) || !isFinite(log_base)) {
      return ERROR_NUM;
    }
    return new ComplexNumber(log_magnitude, this.angle() / log_base, this.unit || UNIT_I);
  }

  sin (): ComplexNumber {
    return new ComplexNumber(
      Math.sin(this.real) * Math.cosh(this.im),
      Math.cos(this.real) * Math.sinh(this.im),
      this.unit || UNIT_I,
    );
  }

  /**
   * Hyperbolic sine
   * https://proofwiki.org/wiki/Hyperbolic_Sine_of_Complex_Number
   */
  sinh (): ComplexNumber {
    return new ComplexNumber(
      Math.sinh(this.real) * Math.cos(this.im),
      Math.cosh(this.real) * Math.sin(this.im),
      this.unit || UNIT_I,
    );
  }

  /**
   * Secant
   * https://proofwiki.org/wiki/Secant_of_Complex_Number
   */
  sec (): ComplexNumber {
    const denum = Math.cos(2 * this.real) + Math.cosh(2 * this.im);
    const real = (2 * Math.cos(this.real) * Math.cosh(this.im)) / denum;
    const im = (2 * Math.sin(this.real) * Math.sinh(this.im)) / denum;
    return new ComplexNumber(isFinite(real) ? real : 0, isFinite(im) ? im : 0, this.unit || UNIT_I);
  }

  /**
   * Hyperbolic secant
   * https://www.wolframalpha.com/input/?i2d=true&i=sech%28a+%2B+b+*+i%29+%3D+c+%2B+d+*+i
   */
  sech (): ComplexNumber {
    const denum = Math.cosh(2 * this.real) + Math.cos(2 * this.im);
    const real = 2 * ((Math.cosh(this.real) * Math.cos(this.im)) / denum);
    const im = -2 * ((Math.sinh(this.real) * Math.sin(this.im)) / denum);
    return new ComplexNumber(isFinite(real) ? real : 0, isFinite(im) ? im : 0, this.unit || UNIT_I);
  }

  cos (): ComplexNumber {
    return new ComplexNumber(
      Math.cos(this.real) * Math.cosh(this.im),
      -Math.sin(this.real) * Math.sinh(this.im),
      this.unit || UNIT_I,
    );
  }

  /**
   * Hyperbolic cosine
   * https://proofwiki.org/wiki/Hyperbolic_Cosine_of_Complex_Number
   */
  cosh (): ComplexNumber {
    return new ComplexNumber(
      Math.cosh(this.real) * Math.cos(this.im),
      Math.sinh(this.real) * Math.sin(this.im),
      this.unit || UNIT_I,
    );
  }

  /**
   * Cotangent
   * https://www.wolframalpha.com/input/?i2d=true&i=cot%28a+%2B+b+*+i%29+%3D+c+%2B+d+*+i
   */
  cot (): ComplexNumber | FormulaError {
    const denum = Math.cos(2 * this.real) - Math.cosh(2 * this.im);
    if (denum === 0) {
      return ERROR_NUM;
    }
    const im_num = Math.sinh(2 * this.im);
    if (!isFinite(denum) || !isFinite(im_num)) {
      // This is asymptotically correct (the best kind of correct), and the same
      // thing as Excel does.
      return new ComplexNumber(0, this.im < 0 ? 1 : -1, this.unit || UNIT_I);
    }
    const real_num = -Math.sin(2 * this.real);
    return new ComplexNumber(real_num / denum, im_num / denum, this.unit || UNIT_I);
  }

  /**
   * Hyperbolic cotangent
   * https://www.wolframalpha.com/input/?i2d=true&i=coth%28a+%2B+b+*+i%29+%3D+c+%2B+d+*+i
   */
  coth (): ComplexNumber | FormulaError {
    const denum = Math.cos(2 * this.im) - Math.cosh(2 * this.real);
    if (denum === 0) {
      return ERROR_NUM;
    }
    const real = -Math.sinh(2 * this.real) / denum;
    const im = Math.sin(2 * this.im) / denum;
    return new ComplexNumber(real, im, this.unit || UNIT_I);
  }

  /**
   * Cosecant
   * https://www.wolframalpha.com/input/?i2d=true&i=cot%28a+%2B+b+*+i%29+%3D+c+%2B+d+*+i
   */
  csc (): ComplexNumber | FormulaError {
    const sinh_im = Math.sinh(this.im);
    const cos_real = Math.cos(this.real);
    const cosh_im = Math.cosh(this.im);
    const sin_real = Math.sin(this.real);
    const denum = sin_real ** 2 * cosh_im ** 2 + cos_real ** 2 * sinh_im ** 2;
    if (denum === 0) {
      return ERROR_NUM;
    }
    const real = (sin_real * cosh_im) / denum;
    const im = -(cos_real * sinh_im) / denum;
    return new ComplexNumber(isFinite(real) ? real : 0, isFinite(im) ? im : 0, this.unit || UNIT_I);
  }

  /**
   * Hyperbolic cosecant
   * https://proofwiki.org/wiki/Hyperbolic_Cosecant_of_Complex_Number
   */
  csch (): ComplexNumber | FormulaError {
    const sinh_real = Math.sinh(this.real);
    const cos_im = Math.cos(this.im);
    const cosh_real = Math.cosh(this.real);
    const sin_im = Math.sin(this.im);
    const denum = sinh_real ** 2 * cos_im ** 2 + cosh_real ** 2 * sin_im ** 2;
    if (denum === 0) {
      return ERROR_NUM;
    }
    const real = (sinh_real * cos_im) / denum;
    const im = -(cosh_real * sin_im) / denum;
    return new ComplexNumber(isFinite(real) ? real : 0, isFinite(im) ? im : 0, this.unit || UNIT_I);
  }

  /**
   * Tangent
   * https://proofwiki.org/wiki/Tangent_of_Complex_Number - Formulation 4
   */
  tan (): ComplexNumber | FormulaError {
    const denum = Math.cos(2 * this.real) + Math.cosh(2 * this.im);
    if (denum === 0) {
      return ERROR_NUM;
    }
    const im_num = Math.sinh(2 * this.im);
    if (!isFinite(denum) || !isFinite(im_num)) {
      return new ComplexNumber(0, this.im < 0 ? -1 : 1, this.unit || UNIT_I);
    }
    const real_num = Math.sin(2 * this.real);
    return new ComplexNumber(real_num / denum, im_num / denum, this.unit || UNIT_I);
  }

  /**
   * Hyperbolic tangent
   * https://www.wolframalpha.com/input/?i2d=true&i=tanh%28a+%2B+i+*+b%29
   */
  tanh (): ComplexNumber | FormulaError {
    const denum = Math.cosh(2 * this.real) + Math.cos(2 * this.im);
    if (denum === 0) {
      return ERROR_NUM;
    }
    const real = Math.sinh(2 * this.real) / denum;
    const im = Math.sin(2 * this.im) / denum;
    return new ComplexNumber(real, im, this.unit || UNIT_I);
  }
}

function manyToComplex (args: NonMatrixFormulaArgument[]): ComplexNumber[] | FormulaError {
  const result: ComplexNumber[] = [];
  for (const arg of args) {
    const complex = ComplexNumber.from(arg);
    if (isErr(complex)) {
      return complex;
    }
    result.push(complex);
  }
  return result;
}

function toComplexResult (complex: ComplexNumber | FormulaError): string | FormulaError {
  if (isErr(complex)) {
    return complex;
  }
  return complex.toString();
}

export function COMPLEX (
  real: NonMatrixFormulaArgument,
  im: NonMatrixFormulaArgument,
  unit: NonMatrixFormulaArgument,
): string | FormulaError {
  if (unit == null) {
    unit = 'i';
  }
  const num = ComplexNumber.newChecked(real, im, unit);
  return toComplexResult(num);
}

export function IMAGINARY (val: NonMatrixFormulaArgument): number | FormulaError {
  const complex_num = ComplexNumber.from(val);
  if (isErr(complex_num)) {
    return complex_num;
  }
  return complex_num.im;
}

export function IMREAL (val: NonMatrixFormulaArgument): number | FormulaError {
  const complex_num = ComplexNumber.from(val);
  if (isErr(complex_num)) {
    return complex_num;
  }
  return complex_num.real;
}

export function IMSUM (...vals: FormulaArgument[]): string | FormulaError {
  let seenUnits = UNIT_NONE;
  let sum: ComplexNumber | FormulaError = ComplexNumber.zero();
  const err = visitEngineering(vals, val => {
    if (val === BLANK) {
      val = 0;
    }
    const summand = ComplexNumber.from(val);
    if (isErr(summand)) {
      sum = summand;
      return true;
    }
    seenUnits |= summand.unit;
    if (seenUnits === UNIT_BOTH) {
      sum = ERROR_VALUE;
      return true;
    }
    // We don't get here if sum has been changed to a `FormulaError`
    invariant(sum instanceof ComplexNumber);
    sum = sum.add(summand, ERROR_VALUE);
    return isErr(sum);
  });
  if (isErr(err)) {
    return err;
  }
  return toComplexResult(sum);
}

export function IMSUB (a: NonMatrixFormulaArgument, b: NonMatrixFormulaArgument): string | FormulaError {
  const converted = manyToComplex([ a, b ]);
  if (isErr(converted)) {
    return converted;
  }
  const [ ca, cb ] = converted;
  const subbed = ca.sub(cb);
  return toComplexResult(subbed);
}

export function IMPRODUCT (...vals: FormulaArgument[]): string | FormulaError {
  let seenUnits = UNIT_NONE;
  let multiple: ComplexNumber | FormulaError = ComplexNumber.one();
  const err = visitEngineering(vals, val => {
    if (val === BLANK) {
      val = 0;
    }

    const factor = ComplexNumber.from(val);
    if (isErr(factor)) {
      multiple = factor;
      return true;
    }
    seenUnits |= factor.unit;
    if (seenUnits === UNIT_BOTH) {
      multiple = ERROR_VALUE;
      return true;
    }

    // We don't get here if multiple has been changed to a `FormulaError`
    invariant(multiple instanceof ComplexNumber);
    multiple = multiple.mul(factor, ERROR_VALUE);
    return isErr(multiple);
  });
  if (isErr(err)) {
    return err;
  }
  return toComplexResult(multiple);
}

export function IMDIV (num: NonMatrixFormulaArgument, denom: NonMatrixFormulaArgument): string | FormulaError {
  const converted = manyToComplex([ num, denom ]);
  if (isErr(converted)) {
    return converted;
  }
  const [ cNum, cDenom ] = converted;
  const divided = cNum.div(cDenom);
  if (isErr(divided)) {
    return divided;
  }
  return divided.toString();
}

export function IMABS (num: NonMatrixFormulaArgument): number | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return cNum.magnitude();
}

export function IMARGUMENT (num: NonMatrixFormulaArgument): number | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  if (cNum.real === 0 && cNum.im === 0) {
    return ERROR_DIV0;
  }
  return cNum.angle();
}

export function IMPOWER (num: NonMatrixFormulaArgument, pow: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  // We don't use toNum(...) here because the logic is different.
  if (pow == null) {
    return ERROR_NA;
  }
  if (typeof pow !== 'number' && typeof pow !== 'string') {
    return ERROR_VALUE;
  }
  const powNum = typeof pow === 'number' ? pow : parseFloat(pow);
  if (isNaN(powNum)) {
    return ERROR_VALUE;
  }
  return toComplexResult(cNum.pow(powNum));
}

export function IMSQRT (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.sqrt());
}

export function IMEXP (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.exp());
}

export function IMLN (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.ln());
}

export function IMLOG2 (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.log2());
}

export function IMLOG10 (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.log10());
}

/**
 * Google Sheets specific function
 * https://support.google.com/docs/answer/9366486?hl=en
 */
export function IMLOG (num: NonMatrixFormulaArgument, base: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  const baseNum = toNum(base);
  if (isErr(baseNum)) {
    return baseNum;
  }
  return toComplexResult(cNum.log(baseNum));
}

export function IMSIN (num: NonMatrixFormulaArgument): string | FormulaError {
  const cnum = ComplexNumber.from(num);
  if (isErr(cnum)) {
    return cnum;
  }
  return toComplexResult(cnum.sin());
}

export function IMSINH (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.sinh());
}

export function IMSEC (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.sec());
}

export function IMSECH (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.sech());
}

export function IMCOS (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.cos());
}

export function IMCOSH (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.cosh());
}

export function IMCOT (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.cot());
}

/**
 * Google Sheets specific function
 * https://support.google.com/docs/answer/9366256?hl=en
 */
export function IMCOTH (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.coth());
}

export function IMCSC (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.csc());
}

export function IMCSCH (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.csch());
}

export function IMTAN (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.tan());
}

/**
 * Google Sheets specific function
 * https://support.google.com/docs/answer/9366655?hl=en
 */
export function IMTANH (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.tanh());
}

export function IMCONJUGATE (num: NonMatrixFormulaArgument): string | FormulaError {
  const cNum = ComplexNumber.from(num);
  if (isErr(cNum)) {
    return cNum;
  }
  return toComplexResult(cNum.conjugate());
}

function bitFunctionValidation (funcName: string, ...args: number[]): FormulaError | undefined {
  if (typeof BigInt === 'undefined') {
    return ERROR_NAME.detailed(`${funcName} is not supported in your browser`);
  }
  for (const arg of args) {
    if (!Number.isInteger(arg) || arg < 0 || arg >= 0x1000000000000) {
      return ERROR_NUM;
    }
  }
}

function createBitFunc (
  funcName: string,
  op: (a: bigint, b: bigint) => bigint,
): (a: number, b: number) => number | FormulaError {
  return function (a, b) {
    const err = bitFunctionValidation(funcName, a, b);
    if (isErr(err)) {
      return err;
    }
    // We don't need to check for overflow since the result of XOR, OR, or AND
    // are never larger than max(a, b), and we know that a and b are numbers.
    return Number(op(BigInt(a), BigInt(b)));
  };
}

export const BITAND = createBitFunc('BITAND', (a, b) => a & b);
export const BITOR = createBitFunc('BITOR', (a, b) => a | b);
export const BITXOR = createBitFunc('BITXOR', (a, b) => a ^ b);

function bitShiftBoth (a: number, b: number, isRight: boolean): number | FormulaError {
  b = Math.trunc(b);
  let doRight = isRight;
  if (b < 0) {
    b = -b;
    doRight = !isRight;
  }
  if (b > 53) {
    return ERROR_NUM;
  }
  const err = bitFunctionValidation(isRight ? 'BITRSHIFT' : 'BITLSHIFT', a, b);
  if (isErr(err)) {
    return err;
  }
  const biga = BigInt(a);
  const bigb = BigInt(b);
  let result;
  if (doRight) {
    result = biga >> bigb;
  }
  else {
    result = biga << bigb;
  }
  if (result >= BigInt(0x1000000000000)) {
    return ERROR_NUM;
  }
  return Number(result);
}

export function BITLSHIFT (a: number, b: number): number | FormulaError {
  return bitShiftBoth(a, b, false);
}

export function BITRSHIFT (a: number, b: number): number | FormulaError {
  return bitShiftBoth(a, b, true);
}

const BIN = 2;
const OCT = 8;
const DEC = 10;
const HEX = 16;

type NumberBase = typeof BIN | typeof OCT | typeof DEC | typeof HEX;

const BASE_CEILING = {
  [BIN]: 0x200,
  [OCT]: 0x20000000,
  [HEX]: 0x8000000000,
  [DEC]: Infinity,
};

const BASE_REGEX = {
  [BIN]: /^[01]+$/,
  [OCT]: /^[0-7]+$/,
  [HEX]: /^[0-9a-fA-F]+$/,
};

function baseToBase (
  src: NumberBase,
  target: NumberBase,
  number: NonMatrixFormulaArgument,
  places?: NonMatrixFormulaArgument,
) {
  // I ask the reader of this code forgiveness for Excel's behaviour
  if (places != null) {
    places = toNumEngineering(places) ?? 0;
    if (isErr(places)) {
      return places;
    }
    places = Math.trunc(places);
    if (places > 10 || places <= 0) {
      return ERROR_NUM;
    }
  }
  if (src !== DEC) {
    if (isRef(number)) {
      number = number.resolveSingle();
    }
    if (isErr(number)) {
      return number;
    }
    if (number === MISSING) {
      return ERROR_NA;
    }
    if (number === BLANK) {
      number = 0;
    }
    if (!isNum(number) && !isStr(number)) {
      return ERROR_VALUE;
    }
    if (!BASE_REGEX[src].test(String(number))) {
      return ERROR_NUM;
    }
  }
  else {
    number = toNumEngineering(number) ?? 0;
    if (isErr(number)) {
      return number;
    }
  }

  const srcCeiling = BASE_CEILING[src];
  const targetCeiling = BASE_CEILING[target];
  let value = parseInt(String(number), src);
  if (value >= srcCeiling * 2) {
    return ERROR_NUM;
  }
  else if (value >= srcCeiling) {
    // Overflow
    value -= srcCeiling * 2;
  }
  if (target === DEC) {
    return value;
  }
  if (value >= targetCeiling || value < -targetCeiling) {
    return ERROR_NUM;
  }
  if (value < 0) {
    return (targetCeiling * 2 + value).toString(target).toUpperCase();
  }
  const result = value.toString(target).toUpperCase();
  if (places == null) {
    return result;
  }
  if (places < result.length) {
    return ERROR_NUM;
  }
  let zeros = '';
  while (places > result.length) {
    zeros += '0';
    places -= 1;
  }
  return zeros + result;
}

export const BIN2OCT = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(BIN, OCT, num, places);
export const BIN2DEC = (num: NonMatrixFormulaArgument) => baseToBase(BIN, DEC, num);
export const BIN2HEX = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(BIN, HEX, num, places);

export const OCT2BIN = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(OCT, BIN, num, places);
export const OCT2DEC = (num: NonMatrixFormulaArgument) => baseToBase(OCT, DEC, num);
export const OCT2HEX = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(OCT, HEX, num, places);

export const DEC2BIN = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(DEC, BIN, num, places);
export const DEC2OCT = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(DEC, OCT, num, places);
export const DEC2HEX = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(DEC, HEX, num, places);

export const HEX2BIN = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(HEX, BIN, num, places);
export const HEX2OCT = (num: NonMatrixFormulaArgument, places?: number) => baseToBase(HEX, OCT, num, places);
export const HEX2DEC = (num: NonMatrixFormulaArgument) => baseToBase(HEX, DEC, num);

function gestepDelta (
  arg1: Reference | FormulaError | undefined | CellValueAtom,
  arg2: Reference | FormulaError | undefined | CellValueAtom,
  isGestep: boolean,
): number | FormulaError {
  const number1 = toNumEngineering(arg1) ?? 0;
  if (isErr(number1)) {
    return number1;
  }
  const number2 = arg2 === MISSING ? 0 : (toNumEngineering(arg2) ?? 0);
  if (isErr(number2)) {
    return number2;
  }
  return +(isGestep ? number1 >= number2 : number1 === number2);
}

export function GESTEP (
  number: Reference | FormulaError | undefined | CellValueAtom,
  step: Reference | FormulaError | undefined | CellValueAtom,
): number | FormulaError {
  return gestepDelta(number, step, true);
}

export function DELTA (
  number1Arg: Reference | FormulaError | undefined | CellValueAtom,
  number2Arg: Reference | FormulaError | undefined | CellValueAtom,
): number | FormulaError {
  return gestepDelta(number1Arg, number2Arg, false);
}

function besselCommon (
  xArg: Reference | FormulaError | undefined | CellValueAtom,
  nArg: Reference | FormulaError | undefined | CellValueAtom,
  implementation: (x: number, n: number) => number,
) {
  const x = toNumEngineering(xArg) ?? 0;
  if (isErr(x)) {
    return x;
  }
  const n = toNumEngineering(nArg) ?? 0;
  if (isErr(n)) {
    return n;
  }
  if (n < 0) {
    return ERROR_NUM;
  }
  const result = implementation(x, Math.floor(n));
  if (!isFinite(result)) {
    return ERROR_NUM;
  }
  return result;
}

export function BESSELJ (
  x: Reference | FormulaError | undefined | CellValueAtom,
  n: Reference | FormulaError | undefined | CellValueAtom,
) {
  return besselCommon(x, n, bessel.besselj);
}

export function BESSELY (
  x: Reference | FormulaError | undefined | CellValueAtom,
  n: Reference | FormulaError | undefined | CellValueAtom,
) {
  return besselCommon(x, n, bessel.bessely);
}

export function BESSELI (
  x: Reference | FormulaError | undefined | CellValueAtom,
  n: Reference | FormulaError | undefined | CellValueAtom,
) {
  return besselCommon(x, n, bessel.besseli);
}

export function BESSELK (
  x: Reference | FormulaError | undefined | CellValueAtom,
  n: Reference | FormulaError | undefined | CellValueAtom,
) {
  return besselCommon(x, n, bessel.besselk);
}

/**
 * CONVERT(value, start_unit, end_unit)
 * Converts a numeric value to a different unit of measure.
 */
export function CONVERT (
  value: NonMatrixFormulaArgument,
  start_unit: NonMatrixFormulaArgument,
  end_unit: NonMatrixFormulaArgument,
): number | FormulaError {
  if (!isNum(value)) {
    return ERROR_VALUE;
  }
  const str_start_unit = toStr(start_unit);
  const str_end_unit = toStr(end_unit);
  if (isErr(str_start_unit)) {
    return str_start_unit;
  }
  if (isErr(str_end_unit)) {
    return str_end_unit;
  }
  const u1 = parseConvertUnit(str_start_unit);
  const u2 = parseConvertUnit(str_end_unit);
  // both units must be valid and have same type
  if (!u1 || !u2 || u1[0].c !== u2[0].c) {
    return ERROR_NA;
  }
  const [ unit1, mul1 ] = u1;
  const [ unit2, mul2 ] = u2;
  const v = ((value - (unit1.a || 0)) / unit1.x) * mul1;
  return (v * unit2.x) / mul2 + (unit2.a || 0);
}
