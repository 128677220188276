import { invariant } from './validation';
import { KnownVertexId } from './DependencyGraph';

/**
 * A set specifically meant for vertex IDs. A normal set will not de-duplicate
 * multiple instances of vertex IDs pointing to the same location. This one does.
 */
export default class VertexIdSet<T extends KnownVertexId = KnownVertexId> {
  private readonly _countPerWorkbook: Map<number, number>;
  private readonly __map: Map<string, T>;

  constructor (iterable?: Iterable<T>) {
    this._countPerWorkbook = new Map();
    this.__map = new Map();

    for (const vid of iterable || []) {
      this.add(vid);
    }
  }

  get size (): number {
    return this.__map.size;
  }

  add (vertexId: T): this {
    if (!this.__map.has(vertexId.key)) {
      this.__map.set(vertexId.key, vertexId);
      const countBefore = this._countPerWorkbook.get(vertexId.workbookKey) || 0;
      this._countPerWorkbook.set(vertexId.workbookKey, countBefore + 1);
    }
    return this;
  }

  has (vertexId: T): boolean {
    return this.__map.has(vertexId.key);
  }

  hasWorkbook (workbookKey: number): boolean {
    return !!this._countPerWorkbook.get(workbookKey);
  }

  workbookKeys (): number[] {
    return [ ...this._countPerWorkbook.entries() ].filter(([ , count ]) => count > 0).map(([ key ]) => key);
  }

  delete (vertexId: T): boolean {
    const deleted = this.__map.delete(vertexId.key);
    if (deleted) {
      const countBefore = this._countPerWorkbook.get(vertexId.workbookKey);
      invariant(countBefore);
      this._countPerWorkbook.set(vertexId.workbookKey, countBefore - 1);
    }
    return deleted;
  }

  clear (): void {
    this.__map.clear();
    this._countPerWorkbook.clear();
  }

  values (): IterableIterator<T> {
    return this.__map.values();
  }

  [Symbol.iterator] (): IterableIterator<T> {
    return this.values();
  }
}
