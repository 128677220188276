import EventEmitter from 'component-emitter';
import {
  CACHED_FORMULA_CELL_ID_PREFIX,
  ERROR_NAME,
  ERROR_REF,
  ERROR_SPILL,
  IDENTITY,
  MAX_COL,
  MAX_ROW,
} from './excel/constants.js';
import { assertParserLoaded, replaceSheetReferences, replaceWorkbookReferences } from './excel/formulaParser/index.js';
import Reference, { toRef } from './excel/Reference.js';
import Cell, { NO_PROPAGATE_NUMBER_FORMAT_MODES } from './excel/Cell';
import {
  VertexId,
  CellVertexId,
  RangeVertexId,
  NameVertexId,
  vertexIdFromRange,
  nameVertexIdKey,
} from './DependencyGraph';
import { formatCellValueForFormula, isMatrix } from './excel/functions/utils.js';
import WorkSheet, {
  updateColumnInfosOnMoveColumns,
  updateParsedFormula,
  updateRowHeightsOnMoveRows,
  validateFormula,
  validateSheetName,
} from './WorkSheet';
import ModelError from './ModelError.js';
import EvaluationOrderException from './EvaluationOrderException';
import { evaluateAST, evaluateASTNodeUnbound, evaluateLiteralASTNode, EvaluationError } from './excel/evaluate.js';
import { DEV_LOGGING } from './devutil.js';
import { invariant } from './validation';
import moveCells, { rewriteFormulaAfterMove } from './moveCells.js';
import { isStr, isNum, isRef, excelEqual, isErr } from './utils.js';
import { MODE_GOOGLE, MODE_EXCEL, MODE_GRID_SHEET, modeCoercesNullToZero, COERCE_NONE } from './mode.js';
import { isBoxed, unbox } from './excel/ValueBox.js';
export { CHANGED_OR_VOLATILE, ALL_FORMULA_CELLS } from './recalculate.js';
import { wbWrite } from './write.js';
import Range from './excel/referenceParser/Range.js';
import wbNameToKey from './wbNameToKey.js';
import {
  cellToVertexId,
  referenceToVertexId,
  vertexIdToCell,
  vertexIdToReference,
  visitFormulaCellsForVertexId,
} from './dep-graph-helpers.js';
import VertexIdSet from './VertexIdSet';
import { isNonLiteralNode } from './excel/ast';
import { isLiteralNode } from './excel/ast-common';
import { Table } from './Table';
import { a1ToRowColumn } from './excel/referenceParser/a1.js';
import { CellEvaluator } from './CellEvaluator';
import updateGraphFor from './updateGraph';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, isLambda } from './excel/lambda';
import hash from '@emotion/hash';
import { chartsFromData } from './charts';
import { DefaultMap } from '@grid-is/collections';

/**
 * @typedef {(formula: string) => string} RewriteFormula
 */

let instanceCounter = 1;

const ERROR_MESSAGES_OK_FOR_USERS = {
  EVAL_FAILED_BECAUSE_UNPARSED_FORMULA: 'Cannot evaluate cell because we could not parse its formula',
};

export class ParseFailureEvaluationError extends EvaluationError {
  /**
   * @param {string} formula
   */
  constructor (formula) {
    super(
      ERROR_MESSAGES_OK_FOR_USERS.EVAL_FAILED_BECAUSE_UNPARSED_FORMULA,
      // undefined, not null, because null is a valid ASTNode
      // eslint-disable-next-line no-undefined
      undefined,
      formula,
    );
  }
}

/**
 * CSF version at which formula types were introduced. They _haven't_ been
 * introduced to CSF yet, so this is a dummy placeholder for now.
 * @see https://github.com/GRID-is/excel-processor/pull/750
 */
const FORMULA_TYPES_CSF_VERSION_PARTS = [ 4, 9 ];
export const MIN_CSF_VERSION_FOR_FORMULA_TYPES = FORMULA_TYPES_CSF_VERSION_PARTS.map(String).join('.');

// Translation table for server wb-type to mode.
// These type values are what affect engine behaviour but server is able to
// send pretty much anything across (the set of values will grow over time).
// None of those values have formulas so we fallback to MODE_GRID_SHEET when
// we get something like "csv".
/** @type {Partial<Record<WorkbookType, WorkbookMode>>} */
export const WORKBOOK_TYPE_TO_MODE = {
  'native': MODE_GRID_SHEET,
  'google-sheets': MODE_GOOGLE,
  'excel': MODE_EXCEL,
};

/** @type {Partial<Record<WorkbookMode, WorkbookType>>} */
export const MODE_TO_WORKBOOK_TYPE = Object.entries(WORKBOOK_TYPE_TO_MODE).reduce(
  /**
   * @param {Partial<Record<WorkbookMode, WorkbookType>>} obj
   */
  (obj, [ type, mode ]) => {
    invariant(typeof mode === 'number');
    obj[mode] = /** @type {WorkbookType} */ (type);
    return obj;
  },
  {},
);

/**
 * Error messages for workbook data defects
 * @type {Record<string, string>}
 */
const defectMessage = {
  'too_big': 'Workbook exceeds size limit',
  'unrecognized_format': 'Unrecognized workbook format',
  'cannot_fetch_from_remote': 'Unable to fetch workbook from remote source',
  'processing_timeout': 'Timeout while processing workbook',
  'conversion_error': 'Error while converting workbook',
  'notion-incomplete-rollup': 'Rollup issues in Notion database',
  'notion-incomplete-relation': 'Relation issues in Notion database',
  'notion-unresolved-relation': 'Relation issues in Notion database',
  'notion-unknown-user': 'Unknown users in Notion database',
  'notion-missing-data': 'Missing data in Notion database',
};

/**
 * Generate a unique sheet name given an array of sheets
 * @param {Sheet[]} sheets
 * @param {number} [index]: a default value is set internally
 * @returns {string}
 */
export function generateUniqueSheetName (sheets, index) {
  if (!index) {
    index = sheets.length + 1;
  }
  else if (typeof index !== 'number') {
    throw new Error('sheet index should be number but received' + index);
  }
  const suggestedName = `Sheet${index}`;
  if (sheets.some(sheet => sheet.name === suggestedName)) {
    return generateUniqueSheetName(sheets, index + 1);
  }
  return suggestedName;
}

/**
 * @typedef {Object} IterativeCalculationOptions
 * @property {boolean} iterate whether or not iterative calculation is enabled.
 * @property {number} maxIterations maximum number of iterations. Must be > 0.
 * @property {number} maxChange maximum change in a cell to continue iterating. Must be >= 0.
 */

/**
 * @typedef {Object} WorkbookOptions
 * @property {WorkbookCSF} csf
 * @property {Model} model the model that contains the workbook.
 * @property {IterativeCalculationOptions} [iterativeCalculation] settings for iterative calculations
 * (nullish to disable; that's the default)
 * @property {WorkbookType} [type] workbook type, a string denoting its origin
 *   ("excel", "google-sheets", "csv", ...). Overridden by csf.type if present.
 * @property {WorkbookMode} [mode]
 * @property {boolean} [readOnly=false] whether the workbook is read-only
 */

/**
 * @typedef {{change: number | boolean; changedRefs: Reference[]}} CellChangeRecord a record of cell changes resulting
 *   from cell formula evaluation. This can involve changes to multiple ranges, not all of which contain the cell that
 *   was evaluated, due to spilling and the associated masking and unmasking.
 */

/**
 * Individual workbook within a model. Contains Sheets which contain Cells.
 * Contains its own dependency graph, for now at least, so no dependencies between workbooks.
 * @implements {EvaluationContext}
 */
class Workbook extends EventEmitter {
  /**
   * Dummy declaration for type checker, overridden with defineProperty in constructor
   * @type {CoercionMode}
   */
  coerceNullToZero = COERCE_NONE;

  /**
   * True iff workbook is created from CSF of a schema version after formula
   * types were introduced (so cells with array formulas will have "t": "a")
   */
  hasFormulaTypes = false;

  /**
   * @param {Model} model
   * @param {WorkbookCSF} csf
   * @param {Omit<WorkbookOptions, 'csf' | 'model'>} [options]
   * @deprecated this method is currently used in only `doc-test`.
   */
  static newInModel (model, csf, options) {
    const wb = new Workbook({ csf, model, ...options });
    model._attachWorkbook(wb);
    return wb;
  }

  /** Dummy declaration for type checker, overridden with defineProperty in constructor */
  workbookName = '';

  /**
   * Create a Workbook instance.
   *
   * @param {WorkbookOptions} options
   */
  constructor ({ csf, model, iterativeCalculation, type = 'native', mode, readOnly = false }) {
    super();

    this.evaluateASTNode = function (/** @type {ASTNode} */ ast) {
      return evaluateASTNodeUnbound.call(this, ast);
    };

    if (!csf.filename) {
      throw new Error('Workbooks are required to have a filename');
    }

    /** @type {Model} */
    this._model = model;

    /**
     * Mapping of lower-cased names of sheets to their indexes in this workbook
     * @type {{[name: string]: number}}
     */
    this.sheetNameToIndex = {};
    /** @type {Record<string, Cell>} */
    this._globals = {};
    /** @type {ModelError[]} */
    this._errors = [];
    /**
     * @type {{[message: string]: ModelError}}
     */
    this._errorsByMsg = {};
    /**
     * Vertex ID keys of cells with errors.
     * @type {VertexIdSet<CellVertexId|import('./DependencyGraph').NameVertexId>}
     */
    this._cellsWithErrors = new VertexIdSet();
    this.ready = false;

    /**
     * @type {string | null}
     */
    this.replacedBy = null; // reserved for outside use (client loader)
    this.instanceId = instanceCounter++;

    this.id = csf.id || '';
    this.schema_version = csf.schema_version;
    this.hasFormulaTypes = csfVersionHasFormulaTypes(this.schema_version);
    /** @type {WorkbookType} */
    this.type = csf.type || type;
    /** @type {WorkbookMode} */
    // mode passed to constructor overrides default mode for the workbook type
    this.mode = mode || WORKBOOK_TYPE_TO_MODE[this.type] || MODE_GRID_SHEET;

    this.filename = csf.filename;
    this.name = csf.filename.replace(/\.gsheet$/i, '');

    // Getters to satisfy EvaluationContext interface
    Object.defineProperty(this, 'coerceNullToZero', { get: () => modeCoercesNullToZero(this.mode), enumerable: true });
    Object.defineProperty(this, 'workbookName', { get: () => this.name, enumerable: true });

    this.keyInDepGraph = wbNameToKey(this.name);
    this.metadata = { ...csf.metadata };
    /** @type {Sheet[]} */
    this._sheets = [];
    /** @type {NameCSF[]} */
    this.names = [];
    /** @type {Map<string, Table>} */
    this._tables = new Map();
    this.styles = csf.styles ? csf.styles.map(d => ({ ...d })) : [];
    /** @type {string | undefined} */
    this.update_time = csf.update_time;
    this.version = csf.version;
    /** @type {CloudConnection | null} */
    this.cloud_connection = csf.cloud_connection ? { ...csf.cloud_connection } : null;

    /** @type {ChartCSF[]} */
    this.charts = chartsFromData(csf);

    /** @type {string | null} */
    this.defect = csf.defect ?? null;
    /** @type {WorkbookCSF['state'] | 'replaced'} */
    this._state = csf.state ?? 'ready';

    this.addError = this.addError.bind(this); // bind needed because of use as sheet.on event handler
    // binds needed because of use as EvaluationContext via { ...this, ... }
    this.resolveName = resolveName.bind(this);
    this.resolveWorkbook = resolveWorkbook.bind(this);
    this.getWorkbookByKey = getWorkbookByKey.bind(this);
    this.resolveSheet = resolveSheet.bind(this);
    this.resolveTable = resolveTable.bind(this);
    this.writeState = writeState.bind(this);

    const self = this;

    /** @type {import('./Cells').CellContainer} */
    this._cellContainerForGlobals = {
      workbookKey: this.keyInDepGraph, // Set in `_init`
      sheetIndex: null, // Globals exist on the workbook level

      // As it currently stands, globals cannot contain non-value attributes
      getAttribute: () => undefined, // eslint-disable-line no-undefined
      setAttributes: () => {},

      get mode () {
        return self.mode;
      },
    };

    assertParserLoaded();

    /** @type {IterativeCalculationOptions | null} */
    this._iterativeCalculationSettings = iterativeCalculation ?? readIterativeCalculationSettings(csf);

    const cellsNeedingInitRecalc = populateSheetsFromCSF(this, csf.sheets, csf.tables, !readOnly);
    const definedNameCells = populateNamesFromCSF(this, csf.names);
    if (!readOnly) {
      validateFormulas(this, definedNameCells);
      scheduleForInitRecalc(this, cellsNeedingInitRecalc);
    }
    populateTablesFromCSF(this, csf.tables);
    populateMetadataDefectsFromCSF(this, csf.metadata?.defects);

    if (this._sheets.length === 0) {
      this.addSheet();
    }

    this.cellEvaluator = new CellEvaluator(this);
    /**
     * Map keyed by strings, where each string is the key of a Vertex ID.
     * @type {Map<string, CellValue>}
     */
    this._writes = new Map();

    /**
     * VertexIDs of range writes applied in this workbook. Redundant with
     * `this._writes`, maintained separately just for quick checking.
     * @type {RangeVertexId[]}
     */
    this._rangeWrites = [];

    /**
     * @type {Set<Cell>}
     */
    this.cellsWithNeutralizedFormulas = new Set();
  }

  /**
   * Evaluate a formula in the form of an AST, in a given evaluation context.
   * This wraps the `evaluateAST` function as a `Workbook` method, just to
   * enable calling it via evaluation context to dodge circular imports.
   */
  evaluateAST (ast, options) {
    return evaluateAST(ast, { ...this, ...options });
  }

  get env () {
    return this._model.env;
  }

  get editable () {
    return this.type === 'native';
  }

  /**
   * Precondition: Workbook must be initialized.
   * @returns {Promise<any>}
   */
  get lazyImportPromise () {
    invariant(this._model != null, 'workbook must be initialized');
    return this._model.lazyImportPromise;
  }

  /**
   * @param {import('./csf').WorkbookInfo['state'] | undefined} [newState]
   * @param {string | null | undefined} [defect]
   */
  state (newState, defect) {
    if (newState != null) {
      this._state = newState;
    }
    if (defect != null) {
      this.defect = defect;
    }
    return {
      id: this.id,
      state: this._state,
      defect: this.defect,
      update_time: this.update_time,
    };
  }

  /**
   * Get the sheet with the given name, or if name is not provided, the first sheet of this workbook.
   *
   * @param {string|null} [sheetName] the name of a sheet
   * @return {Sheet|null} the named sheet if sheetName is truthy (null if not found), else the first sheet.
   */
  getSheet (sheetName) {
    if (sheetName && this.sheetHasBeenPruned(sheetName)) {
      this.addError(
        new ModelError(
          `Cannot get sheet '${sheetName}' because it has been pruned`,
          ModelError.ERROR,
          null,
          'overpruning',
        ),
      );
    }
    if (this._sheets.length) {
      // no sheet name always returns the first one
      const index = sheetName ? this.getSheetIndex(sheetName) : 0;
      if (index != null) {
        return this._sheets[index] || null;
      }
    }
    return null;
  }

  /**
   * @param {string} name
   * @returns {Cell | FormulaError}
   */
  getGlobal (name) {
    const key = name.toLowerCase();
    if (this._globals[key]) {
      return this._globals[key];
    }
    return ERROR_NAME.detailed(`Global name not found: ${name} in workbook ${this.name}`);
  }

  /**
   * @returns {Table[]}
   */
  getTables () {
    return [ ...this._tables.values() ];
  }

  /**
   * @param {string} name
   * @returns {Table | null}
   */
  getTable (name) {
    return this._tables.get(name.toLowerCase()) || null;
  }

  /**
   * Get a sheet of the given index or else, if index is not provided, the first sheet of this workbook.
   *
   * @param {number|null} [index] the index of a sheet
   * @return {Sheet|null} the sheet found at the given index, or else the first sheet if none was given. If no sheets are present this method returns null.
   */
  getSheetByIndex (index) {
    if (this._sheets.length) {
      // no sheet index always returns the first one
      return this._sheets[index || 0] || null;
    }
    return null;
  }

  getSheets () {
    return [ ...this._sheets ];
  }

  /**
   * Get the order index of a sheet with the given name.
   *
   * @param {string} [sheetName] the name of a sheet
   * @return {Number|null} Index of sheet if found, else null.
   */
  getSheetIndex (sheetName) {
    const sn = (sheetName || '').toLowerCase();
    if (!sn) {
      return 0;
    }
    return this.sheetNameToIndex[sn] ?? null;
  }

  /**
   * @param {string | null} [sheetName] the name of a sheet
   * @returns {[number, number]}
   */
  getSheetSize (sheetName) {
    const sheet = this.getSheet(sheetName);
    return sheet ? sheet.getSize() : [ 0, 0 ];
  }

  /**
   * @param {string} sheetName
   */
  sheetHasBeenPruned (sheetName) {
    const match = this.metadata?.pruned_sheets?.find(x => excelEqual(x, sheetName));
    return match != null;
  }

  /**
   * @param {string} cellId
   * @param {string | null} [sheetName]
   * @returns {Cell | null}
   */
  getCell (cellId, sheetName) {
    const sheet = this.getSheet(sheetName);
    if (!sheet) {
      // sheet does not exist, this is a ref error
      return new Cell({ v: ERROR_REF.detailed(`Sheet not found: ${sheetName}`) });
    }
    const [ row, column ] = a1ToRowColumn(cellId);
    return sheet.getCellByCoords(row, column);
  }

  /**
   * @param {ModelError} modelError
   */
  addError (modelError) {
    if (
      modelError.vertexIds.size > 0 &&
      [ ...modelError.vertexIds ].every(
        vid => vid instanceof NameVertexId && vid.name.startsWith(CACHED_FORMULA_CELL_ID_PREFIX),
      )
    ) {
      // Ignore errors on cached formula cells, as they are only in the workbook
      // as an implementation detail, which should be invisible to the end user.
      // End users should see errors from document formulas elsewhere, not
      // associated with workbooks.
      // XXX: remove this when we rework cached formulas to be separate from workbooks.
      return null;
    }

    let error = this._errorsByMsg[modelError.message];
    let seenBefore = false;
    if (!error) {
      modelError.workbook = this;
      error = modelError;
      this._errorsByMsg[error.message] = error;
      this._errors.push(error);
    }
    else {
      seenBefore = true;
      for (const vertexId of modelError.vertexIds) {
        error.vertexIds.add(vertexId);
      }
    }
    for (const vertexId of modelError.vertexIds) {
      this._cellsWithErrors.add(vertexId);
    }
    this.emit('error', {
      workbookId: this.id,
      workbookName: this.name,
      error,
      seenBefore,
    });
    return modelError;
  }

  /**
   * @param {ModelError} modelError
   */
  _removeError (modelError) {
    const errorIndex = this._errors.findIndex(err => err === modelError);
    if (errorIndex !== -1) {
      this._errors.splice(errorIndex, 1);
      delete this._errorsByMsg[modelError.message];
    }
  }

  /**
   * @param {string} sheetName
   * @param {number} index
   * @param {boolean} [hidden]
   * @returns {WorkSheet}
   */
  _addSheet (sheetName, index, hidden) {
    if (typeof sheetName !== 'string') {
      throw new Error('Expected sheetName of type string but received ' + typeof sheetName);
    }
    if (typeof index !== 'number' || index < 0 || index > this._sheets.length) {
      throw new Error(`unable to add sheet at index ${index} workbook has ${this._sheets.length} sheets`);
    }
    const sheet = new WorkSheet(sheetName, this.keyInDepGraph, index, hidden, this.type);
    if (this.mode) {
      sheet._cells.mode = this.mode;
    }
    this._insertSheet(sheet, index);
    return sheet;
  }

  /**
   * @param {Sheet} sheet
   * @param {number} index
   * @returns {Sheet}
   */
  _insertSheet (sheet, index) {
    sheet.on('error', this.addError);
    if (index == null || index === this._sheets.length) {
      // append the new sheet to the list of sheets.
      this.sheetNameToIndex[sheet.name.toLowerCase()] = index;
      this._sheets.push(sheet);
    }
    else {
      [ ...this._sheets ].forEach((existingSheet, i) => {
        const adjustedIndex = i < index ? i : existingSheet.index + 1;
        if (i === index) {
          // push the new sheet into at the correct index.
          this.sheetNameToIndex[sheet.name.toLowerCase()] = index;
          this._sheets[index] = sheet;
        }
        // always calling setIndex but I guess it's harmless in cases
        // where index does not actually change
        existingSheet.setIndex(adjustedIndex);
        this.sheetNameToIndex[existingSheet.name.toLowerCase()] = adjustedIndex;
        this._sheets[adjustedIndex] = existingSheet;
      });
    }

    return sheet;
  }

  /**
   * Add a new empty sheet to the workbook
   * @param {string|null} [sheetName] - if no sheet name is provided a unique sheet name will be generated
   * @param {number} [index] - if no index is provided the sheet will be appended to the list of sheets.
   * @returns {WorkSheet}
   */
  addSheet (sheetName, index) {
    if (sheetName) {
      if (!validateSheetName(sheetName)) {
        throw new Error('invalid sheet name provided' + sheetName);
      }
      else if (this._sheets.some(sheet => sheet.name === sheetName)) {
        throw new Error('workbook already has sheet with name:' + sheetName);
      }
    }

    if (index == null) {
      index = this._sheets.length;
    }
    else if (typeof index !== 'number' || index > this._sheets.length) {
      throw new Error(`unable to add sheet at index ${index} workbook has ${this._sheets.length} sheets`);
    }

    if (!sheetName) {
      sheetName = generateUniqueSheetName(this._sheets);
    }

    if (this._model.writes().length > 0 && index < this._sheets.length) {
      throw new Error('Writes must be reset before adding sheet');
    }
    // (If index _is_ null we are appending a sheet, and that will not confuse
    // write state, so that's safe. And in particular this gets called in the
    // constructor if the workbook has no sheets --- which can happen due to
    // sheet pruning, and can happen in the middle of a session with a write
    // state, upon a WorkbookUpdated event, which was the cause of GRID-3010.

    const newIndex = index;
    for (const collection of [
      this._model._recalcState.changedSinceRecalc,
      ...this._model._graph.vertexIDsWithUnresolvedReferences.values(),
    ]) {
      updateSheetIndices(collection, sheetIndex => (sheetIndex >= newIndex ? sheetIndex + 1 : sheetIndex));
    }
    const sheet = this._addSheet(sheetName, index);
    // update dependencies and recalculate, unless this was just adding an empty initial sheet
    if (!(this._sheets.length === 1 && sheet.cellCount === 0)) {
      this.updateDependencies();
      this._model.recalculate();
    }
    this.emit('addsheet', {
      workbookName: this.name,
      sheetName: sheetName,
      index: index,
    });

    return sheet;
  }

  /**
   * Rename the given sheet to a new name, updating all formulas referencing it
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * @param {string} currentName
   * @param {string} newName must be different from currentName
   * @throws {Error} if no sheet exists named `currentName`
   */
  renameSheet (currentName, newName) {
    assertParserLoaded();
    invariant(currentName !== newName);
    const sheet = this.getSheet(currentName);
    if (sheet == null) {
      throw new Error('No such sheet ' + currentName);
    }
    const existingSheet = this.getSheet(newName);
    if (existingSheet != null && existingSheet !== sheet) {
      throw new Error('Already have a sheet named ' + newName);
    }
    // the case existingSheet == sheet is OK, just changing casing.
    delete this.sheetNameToIndex[sheet.name.toLowerCase()];
    this.sheetNameToIndex[newName.toLowerCase()] = sheet.index;
    sheet.name = newName;
    this._rewriteFormulasReferencingRenamedSheet(sheet, currentName, newName);
    invariant(this._model != null);
    this._model.recalculate();
  }

  /**
   * @param {(formula: string) => string} rewriteFormula
   * @returns {number} number of formulas changed
   */
  rewriteFormulas (rewriteFormula) {
    invariant(this._model != null);
    /** @type {Array<NameVertexId | CellVertexId>} */
    const changedVertexIds = [];
    for (const cell of this.iterateFormulaCells()) {
      if (cell && cell.f && !cell.id.startsWith(CACHED_FORMULA_CELL_ID_PREFIX)) {
        invariant(replaceWorkbookReferences != null);
        const newFormula = rewriteFormula(cell.f);
        if (newFormula !== cell.f) {
          const vertexId = cellToVertexId(cell);
          const reference = vertexIdToReference(this._model, vertexId);
          invariant(reference);
          this.writeCellData(reference, { f: newFormula });
          changedVertexIds.push(vertexId);
        }
      }
    }
    const numChanged = changedVertexIds.length;
    if (numChanged) {
      updateGraphFor(this._model._graph, this, changedVertexIds);
      this._model._recalcState.changedSinceRecalc.push(...changedVertexIds);
      this._model.recalculate();
    }
    return numChanged;
  }

  * iterateFormulaCells () {
    for (const sheet of this._sheets) {
      yield * sheet._cells.iterFormulaCells();
    }
    yield * Object.values(this._globals);
  }

  /**
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * @param {Sheet} sheet
   * @param {string} currentName
   * @param {string} newName
   */
  _rewriteFormulasReferencingRenamedSheet (sheet, currentName, newName) {
    invariant(this._model != null);
    const workbookName = this.name;
    invariant(workbookName);
    this._model._graph.visitIncomingVertexIdsForSheet(this.keyInDepGraph, sheet.index, fromVertexId => {
      const cell = vertexIdToCell(this._model, fromVertexId, true);
      invariant(cell && cell.f, 'cells behind dependency graph edges should always exist and have a formula');
      invariant(replaceSheetReferences != null);
      const newFormula = replaceSheetReferences(cell.f, currentName, newName, workbookName, true);
      if (newFormula !== cell.f) {
        cell.f = newFormula;
        if (cell.sheetIndex == null) {
          // Workbook-scoped defined name, ours to update
          this._updateParsedFormula(cell);
          return;
        }
        // Sheet cell (or sheet-scoped defined name); leave it to its owning Sheet to update it.
        const sheet = this._sheets[cell.sheetIndex];
        if (!sheet) {
          // uh-oh, no sheet with that index. Report this as an error and
          // keep going, rather than throw an error which would abort all
          // the remaining updates in this loop
          this.addError(
            new ModelError(
              'Internal error: cell belongs to sheet not found in the model',
              ModelError.ERROR,
              null,
              'unexpected',
            ),
          );
          return;
        }
        sheet._updateParsedFormula(cell, this);
      }
    });
  }

  /**
   * @param {string} sheetName the name of the sheet to delete. It will be
   *   matched case-insensitively.
   */
  removeSheet (sheetName) {
    invariant(this._model != null);
    const sheet = this.getSheet(sheetName);
    if (sheet == null) {
      throw new Error('No such sheet ' + sheetName);
    }
    if (this._model.writes().length > 0) {
      throw new Error('Writes must be reset before removing a sheet');
    }
    // The order matters:
    // 1. Schedule recalculation of any cell referencing anything in that sheet.
    // 2. Remove the sheet from the list of sheets, and decrement the index of
    //    all sheets after it.
    // 3. Rebuild dependency graph.
    // Step 1 uses the dependency graph to find the cells to recalculate, and
    // step 2 invalidates the dependency graph until rebuild.
    const indexOfSheetToRemove = this._sheets.indexOf(sheet);
    this._markCellsReferencingRemovedSheetForRecalculation(sheet);
    this._sheets.splice(indexOfSheetToRemove, 1);
    for (const sheet of this._sheets.slice(indexOfSheetToRemove)) {
      sheet.setIndex(sheet.index - 1);
    }

    // TODO: Might be easier if dependency graph didn't use sheet indices
    // anymore. That way, vertex IDs would stay the same even after sheets are
    // moved around.
    for (const collection of [
      this._model._recalcState.changedSinceRecalc,
      ...this._model._graph.vertexIDsWithUnresolvedReferences.values(),
    ]) {
      updateSheetIndices(collection, sheetIndex => {
        if (sheetIndex === indexOfSheetToRemove) {
          return null;
        }
        return sheetIndex > indexOfSheetToRemove ? sheetIndex - 1 : sheetIndex;
      });
    }

    delete this.sheetNameToIndex[sheetName.toLowerCase()];
    for (const [ sheetNameLower, previousIndex ] of Object.entries(this.sheetNameToIndex)) {
      if (previousIndex > indexOfSheetToRemove) {
        this.sheetNameToIndex[sheetNameLower] = previousIndex - 1;
      }
    }

    if (this._sheets.length === 0) {
      this.addSheet();
    }
    this.updateDependencies();
    this._model.recalculate();
  }

  /**
   * @param {Sheet} sheetBeingRemoved
   */
  _markCellsReferencingRemovedSheetForRecalculation (sheetBeingRemoved) {
    invariant(this._model != null);
    const done = new Set();
    const sheetIndex = sheetBeingRemoved.index;
    this._model._graph.visitIncomingVertexIdsForSheet(this.keyInDepGraph, sheetBeingRemoved.index, fromVertexId => {
      if (fromVertexId.sheetIndex === sheetIndex || done.has(fromVertexId)) {
        return;
      }
      const cell = vertexIdToCell(this._model, fromVertexId, true);
      if (cell) {
        // Removing a sheet is an edit operation, so this cell should be
        // marked as having been changed (edited).
        this._model._recalcState.changedSinceRecalc.push(fromVertexId);
      }
      done.add(fromVertexId);
    });
  }

  /**
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * @param {Cell} cell a cell object representing a global defined name (that
   *   restriction is relevant so that `cell.id` is its complete cell address,
   *   because that assumption is made here.)
   */
  _updateParsedFormula (cell) {
    invariant(this._model != null);
    updateParsedFormula(cell, this.addError, this.type);
    validateFormula(cell, this.addError, this, this._model._lazyImports);
  }

  /**
   * @return {IterableIterator<Cell>}
   */
  * iterFormulaCells () {
    for (const sheet of this.getSheets()) {
      yield * sheet.iterFormulaCells();
    }
    yield * Object.values(this._globals);
  }

  /**
   * @param {(CellVertexId | NameVertexId)[]} [vertexIDs]
   */
  updateDependencies (vertexIDs) {
    this._model._updateDependenciesFor(this, vertexIDs);
  }

  /**
   * For the given formula cell, replace its existing value with a newly
   * calculated one, in the case where either the new value or the previous
   * value (or both) is a matrix. Determine and apply any blocking/unblocking of
   * other spilling formula cells that should result from this change.
   * @param {Reference} cellRef A reference pointing to `cell`.
   * @param {Cell} cell The formula cell whose value will be replaced.
   * @param {MaybeBoxed<CellValue> | Matrix} newValue The new value.
   * @param {boolean} [isRangeWrite=false] passed to Cells.updateValueAndSpills,
   *   see documentation there
   * @returns {Reference[]} references to all areas whose cells may have
   *   changed, with the area spilled by `cell` (or a single-cell ref to just
   *   that cell if it did not spill) appearing first, followed by any other
   *   areas which may have changed due to spill blocking/unblocking.
   */
  _updateValueAndSpills (cellRef, cell, newValue, isRangeWrite = false) {
    const isSpilled = cell.isSpilled();
    const prevWidth = cell.spillWidth();
    const prevHeight = cell.spillHeight();
    let changeWidth = 1;
    let changeHeight = 1;
    if (isMatrix(newValue)) {
      changeWidth = Math.max(changeWidth, newValue.width);
      changeHeight = Math.max(changeHeight, newValue.height);
    }
    else {
      cell._spill = null; // clear possible stale spill info hanging on cell
    }
    if (isSpilled) {
      changeWidth = Math.max(changeWidth, prevWidth);
      changeHeight = Math.max(changeHeight, prevHeight);
    }
    const changedRef = new Reference(cellRef).offset(0, 0, changeHeight, changeWidth);
    if (
      isMatrix(newValue) &&
      isSpilled &&
      !isRangeWrite &&
      newValue.width === prevWidth &&
      newValue.height === prevHeight
    ) {
      // If dimensions don't change, we can just swap the matrix values
      // without going through the R-Tree.
      // (That won't do in the case of a range write, though.)
      cell._spill.matrix = newValue;
      cell.v = cell._spill.masked ? ERROR_SPILL : newValue.getBoxed(0, 0);
      return [ changedRef ];
    }
    const sheet = this.getSheet(cellRef.sheetName);
    invariant(sheet);
    // Dimensions changed, so we need to re-enter into the R-Tree.
    return sheet._cells
      .updateValueAndSpills(cellRef.range, newValue, isRangeWrite)
      .map(range => new Reference(range, { sheetName: cellRef.sheetName, workbookName: this.name }));
  }

  /**
   * Calculate a new value for the given cell in this workbook. The cell should
   * either be a formula cell, or be a value cell which was previously a formula
   * cell, which gets passed here in order to calculate the spilling effects of
   * its change to a value cell. The cell's unconditional static dependencies
   * are all assumed to be already up-to-date. The up-to-dateness of any other
   * dependencies is checked and an `EvaluationOrderException` is thrown if any
   * are not up-to-date). Assign the new value to the cell, and possibly to
   * other cells due to spilling. Return information about all cells changed.
   *
   * Precondition: Workbook is initialized and has data.
   * @param {Cell} cell
   * @param {boolean} [checkDirty=true] true (the default) to check for dirty
   *     states of dependency cells and throw EvaluationOrderException; false
   *     to disable this check
   * @param {EvaluationContext['recordDependencyUse']} [recordDependencyUse]
   * @returns {CellChangeRecord} a cell change record in which:
   *  - `change` is the change in the cell value (numeric if the cell value
   *    changed from a number to another number, else true if the cell value
   *    changed but not from a number to a number, else false).
   *  - `changedRefs` is a list of references to cell ranges which changed.
   */
  // eslint-disable-next-line no-undefined
  calcCell (cell, checkDirty = true, recordDependencyUse = undefined) {
    const resolver = this._model;
    invariant(resolver != null);
    const sheet = cell.sheetIndex == null ? null : this.getSheetByIndex(cell.sheetIndex);
    const ref = new Reference(cell.id, { sheetName: sheet?.name, workbookName: this.name });
    if (ref.range && !ref.sheetName) {
      throw new Error(`no sheet name for cellId=${cell.id}`);
    }
    let newValue;
    try {
      if (cell.f && cell._ast == null) {
        throw new ParseFailureEvaluationError(cell.f);
      }
      // If evaluating a spill anchor cell, temporarily make its existing spill
      // range invalid, so that cells spilled from it are treated as absent
      // during its formula evaluation, if it references them. “But isn't that a
      // circular dependency rather than just a null?” I hear you ask. Well, not
      // necessarily, because this is the _old_ spill range, and the new formula
      // result might be a different spill range that does not include this cell
      // (indeed the cell's formula might even have changed). If the new formula
      // result is a spill that _does_ include a cell that, the formula depended
      // on, then yes, we probably _do_ need to signal a circular dependency
      // error about that. Not taking that on now.
      const wasValidSpill = cell._spill?.valid ?? false;
      if (cell.isSpillAnchor()) {
        // @ts-expect-error (cell._spill is guaranteed non-null by Cell.isSpillAnchor() returning true)
        cell._spill.valid = false;
      }
      try {
        newValue = this.cellEvaluator.evaluate(cell, ref, sheet, checkDirty, recordDependencyUse, this._model.profile);
      }
      finally {
        if (cell._spill && wasValidSpill) {
          cell._spill.valid = true;
        }
      }
    }
    catch (/** @type {any} */ e) {
      if (e instanceof EvaluationOrderException) {
        throw e;
      }
      if (e instanceof EvaluationError) {
        // evaluateAST assigned `.ast` but didn't have the formula, so assign it here
        invariant(cell.f, 'EvaluationError cannot be thrown for a non-formula cell');
        e.formula = cell.f;
      }
      // Generic message for users; low-level details for us nerds go to Sentry via `origException`
      const userErrorMessage = Object.values(ERROR_MESSAGES_OK_FOR_USERS).includes(e.message)
        ? e.message
        : 'Unexpected error evaluating formula';
      DEV_LOGGING && console.error(`Unexpected error evaluating cell '${ref}' formula: ${cell.f}`, e);
      newValue = ERROR_NAME.detailed(userErrorMessage);
      this.addError(new ModelError(userErrorMessage, ModelError.ERROR, cell, 'evalfailed', e));
      // TODO: maybe find existing WARNING-level ModelError for this cell and promote it to ERROR?
      // Or maybe only do that if an output element depends on this cell? See CLICKUP-2191.
      // And maybe only do it if we are sure we found the `ModelError` that is the _reason_ we
      // don't have a parse result for the formula (which may require some more bookkeeping).
    }
    return this._applyCalcCellResult(newValue, cell, ref);
  }

  /**
   * @param {MaybeBoxedFormulaValue} newValue result of formula evaluation
   * @param {Cell} cell the cell whose formula was evaluated
   * @param {Reference} ref Reference to the cell
   */
  _applyCalcCellResult (newValue, cell, ref) {
    const isDefinedName = cell.isDefinedName;

    if (!isDefinedName && (isMatrix(newValue) || cell.isSpillAnchor())) {
      // References can only be returned from `evaluateAST` if `rawOutput` is
      // true, and we only set it to true when evaluating defined-name formulas.
      // We know `cell` is not a defined name because of the if statement's
      // `!isGlobal` condition.
      invariant(!isRef(newValue) && !isLambda(newValue));
      // @ts-expect-error TypeScript doesn't understand that `newValue` can't be
      // Lambda, even with the invariant.
      return { changedRefs: this._updateValueAndSpills(ref, cell, newValue), change: true };
    }
    else if (isDefinedName && isRef(newValue)) {
      if (newValue.isAddress && !newValue.sheetName && !newValue.workbookName && newValue.ctx?.sheetName) {
        newValue = newValue.withPrefix({ sheetName: newValue.ctx.sheetName });
      }
      newValue = newValue.withContext(this);
    }

    if (isRef(newValue) && newValue.ctx) {
      // Storing a reference as the value of a defined name. Don't keep its
      // recalculation-time evaluation context, because that has stuff like
      // isDirtyFormulaCell and recordDependencyUse that are only legitimate
      // within the recalculation. So replace context with just this workbook,
      // but if the context we are discarding has a different workbook name
      // (e.g. if the name formula is `=SomeOtherName` and that resolved into
      // another workbook), then put it in the explicit workbookName property.
      if (newValue.ctx.workbookName && newValue.ctx.workbookName.toLowerCase() !== this.name.toLowerCase()) {
        newValue = newValue.withPrefix({ workbookName: newValue.ctx.workbookName });
      }
      newValue = newValue.withContext(this);
    }
    else if (isLambda(newValue) && !isDefinedName) {
      newValue = ERROR_CALC_LAMBDA_NOT_ALLOWED;
    }
    const prevValue = cell.v;
    cell._spill = null; // clear possible stale spill info hanging on the cell

    // Treat a context-dependent defined name as always having changed, even if
    // its result didn't change. This forces recalculation to propagate to the
    // name's dependents whenever it propagates to the defined name itself, and
    // the evaluation of each dependent will evaluate the name's formula in the
    // context of that dependent, instead of reusing the value being assigned
    // here. This dodges the problem that evaluating a context-dependent name by
    // itself may result in an error value which does not change from one
    // recalculation to the next, wrongly causing recalculation to not reach its
    // dependent cells even though they may see a different value for the name.
    //
    // Of course, the result of a context-dependent formula when evaluated by
    // itself (not in a sheet cell context) _may_ be nonsensical ... but it may
    // not be, depending on whether and how the context-dependence is dealt with
    // (e.g. a ROW() call without arguments may be wrapped in IFERROR) or even
    // whether the context dependence is actually in effect (e.g. an INDIRECT
    // call may always get fully-specified input; we just mark any INDIRECT call
    // as context-dependent because it _potentially_ is, so we can't be sure it
    // isn't). When the result _is_ nonsensical, that may be a gotcha for
    // element option formulas, and such gotchas are unaddressed for now.
    const treatAsChanged =
      didFormulaCellChange(cell, this.mode, prevValue, newValue) ||
      (ref.name && isNonLiteralNode(cell._ast) && cell._ast.isContextDependent);

    if (treatAsChanged) {
      cell.v = newValue;
      const change = isNum(newValue) && isNum(prevValue) ? unbox(newValue) - prevValue : true;
      return { changedRefs: [ ref ], change };
    }
    return { changedRefs: [], change: false };
  }

  /**
   * Write the given value to the given cell (A1 address or global name)
   *
   * Precondition: Workbook must be initialized
   * @param {string|Reference} ref the address or global name to write to
   * @param {CellValue} val the value to write
   * @return {boolean} true if a write occurred
   */
  write (ref, val) {
    return wbWrite(this, ref, val);
  }

  /**
   * Precondition: Workbook must be initialized
   * @returns {IterableIterator<[string, CellValue, import('./DependencyGraph').KnownVertexId ]>}
   */
  * _writesIter () {
    invariant(this._model != null, 'workbook must be initialized');
    for (const [ key, value ] of this._writes.entries()) {
      const vertexId = VertexId.fromKey(key);
      if (vertexId.workbookKey !== this.keyInDepGraph) {
        continue;
      }
      if (vertexId instanceof NameVertexId) {
        yield [ vertexId.name, value, vertexId ];
      }
      else if (!this._sheets[vertexId.sheetIndex]) {
        // Write state references nonexistent sheet! This should not happen, as:
        // 1. writes to sheet cells should be deleted when sheets are removed
        // 2. a write to a non-existent sheet index should not be applied in the
        //    first place
        // So, surprising ... but still we don't want to break the workflow
        // calling this method by throwing an exception, and don't want to
        // mutate innards during a generator iteration. So just log and ignore.
        console.warn(`Ignoring write to absent sheet ${vertexId.sheetIndex}`);
      }
      else {
        const sheetName = this._sheets[vertexId.sheetIndex].name;
        const stringRef = String(new Reference(vertexId.toRange(), { sheetName }));
        yield [ stringRef, value, vertexId ];
      }
    }
  }

  /**
   * Precondition: Workbook must be initialized
   * @returns {[string, CellValue][]}
   */
  writes () {
    return Array.from(this._writesIter()).map(([ stringRef, value ]) => [ stringRef, value ]);
  }

  /**
   * @param {Workbook} oldWorkbook
   */
  applyWritesFrom (oldWorkbook) {
    for (const [ refStr, value, vertexId ] of oldWorkbook._writesIter()) {
      this._applyWrite(refStr, value, oldWorkbook._sheets.length, vertexId.sheetIndex);
    }
  }

  /**
   * Write value `value` to the given reference, or to the same address in
   * the sheet with index `oldSheetIndex` if sheet count matches `oldSheetCount`
   * @param {string} refStr
   * @param {CellValue} value
   * @param {number} oldSheetCount
   * @param {number | null} oldSheetIndex
   */
  _applyWrite (refStr, value, oldSheetCount, oldSheetIndex) {
    const ref = new Reference(refStr);
    if (!ref.sheetName || this.getSheet(ref.sheetName)) {
      // Workbook-scoped defined name, or sheet reference but the same sheet
      // name exists in the new workbook; no name reconciliation needed.
      this.write(refStr, value);
    }
    else if (this._sheets.length === oldSheetCount && oldSheetIndex != null) {
      // New workbook does not have a sheet by that name. But they have the
      // same number of sheets. Assume it was renamed and go by sheet index.
      const sheetAtSameIndex = this.getSheetByIndex(oldSheetIndex);
      if (sheetAtSameIndex) {
        const sameSheetIndexRef = ref.withPrefix({ sheetName: sheetAtSameIndex.name });
        this.write(sameSheetIndexRef, value);
      }
    }
    // Else we have no good reason to associate this write with any
    // particular place in this new workbook, so just discard it.
  }

  /**
   * Precondition: Workbook must be initialized
   * @param {string | Reference} ref A reference to the cell, or range of cells,
   *     to clear. Defined names are not supported.
   * @returns {boolean} true if any changes were made (so a recalculation is in order).
   */
  clearCells (ref) {
    invariant(this._model != null, 'workbook must be initialized');
    const reference = toRef(ref);
    if (reference?.isAddress) {
      if (this._refPointsToAnotherWorkbook(reference)) {
        throw new Error(
          `Reference points to ${reference.workbookName} which is different from the current workbook name ${this.name}`,
        );
      }
      const sheet = this.getSheet(reference.sheetName);
      if (sheet) {
        const affected = sheet.clearCellsByRange(reference.range);
        if (affected.length > 0) {
          affected.forEach(vertexId => {
            this._clearExistingModelErrors(vertexId);
            this._model._recalcState.changedSinceRecalc.push(vertexId);
          });
          const affectedFormulaCellVertexIDs = affected.map(
            /** @returns {CellVertexId} */
            vertexId => {
              if (vertexId instanceof RangeVertexId) {
                // A range affected by a clear operation represents a spill that
                // was either removed or blocked. Only the anchor cell can
                // contain a formula.
                return vertexId.topLeft();
              }
              return vertexId;
            },
          );
          this.updateDependencies(affectedFormulaCellVertexIDs);
          return true;
        }
      }
    }
    return false;
  }

  resetModel () {
    this._model.reset();
  }

  // Reset all cell values to their initial state (as data was when read). Only
  // values are reset; other cell attributes (function, style) are not affected.
  reset () {
    invariant(this._model != null, 'workbook must be initialized');
    // This "goes full nuclear" to be sure. Could optimize to reset only
    // things that may have been changed.
    for (const sheet of this._sheets) {
      sheet.reset();
    }
    for (const definedName of Object.values(this._globals)) {
      if (isLiteralNode(definedName._ast)) {
        definedName.v = evaluateLiteralASTNode(definedName._ast);
      }
    }
    // reset write history
    this._unneutralizeFormulas();
    this._updateDependenciesForWrittenFormulaCells();
    this._writes.clear();
    this._rangeWrites.length = 0;
  }

  _updateDependenciesForWrittenFormulaCells () {
    /**
     * @type {Array<CellVertexId | NameVertexId>}
     */
    const writtenFormulaCellVertexIDs = [];
    for (const vertexIdKey of this._writes.keys()) {
      const vertexId = VertexId.fromKey(vertexIdKey);
      visitFormulaCellsForVertexId(this._model, vertexId, cell => {
        if (cell.f) {
          const cellVertexId = vertexId instanceof RangeVertexId ? cellToVertexId(cell) : vertexId;
          writtenFormulaCellVertexIDs.push(cellVertexId);
        }
      });
    }
    if (writtenFormulaCellVertexIDs.length) {
      this._model._updateDependenciesFor(this, writtenFormulaCellVertexIDs);
    }
  }

  /**
   * @param {import('./DependencyGraph').KnownVertexId} vertexId
   * @param {CellValue} value
   */
  recordWrite (vertexId, value) {
    if (vertexId instanceof RangeVertexId && !this._writes.has(vertexId.key)) {
      this._rangeWrites.push(vertexId);
    }
    this._writes.set(vertexId.key, value);
  }

  /**
   * @param {CellVertexId | NameVertexId} vertexId
   */
  hasWrite (vertexId) {
    return (
      this._writes.has(vertexId.key) || (vertexId instanceof CellVertexId && this._hasRangeWriteContaining(vertexId))
    );
  }

  /**
   * @param {CellVertexId} vertexId
   */
  _hasRangeWriteContaining (vertexId) {
    for (const rangeVertexId of this._rangeWrites) {
      if (rangeVertexId.contains(vertexId)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Reinstate formulas previously neutralized by range writes
   */
  _unneutralizeFormulas () {
    const cellsWithNeutralizedFormulas = [ ...this.cellsWithNeutralizedFormulas ];
    for (const cell of cellsWithNeutralizedFormulas) {
      invariant(cell.neutralizedFormula != null);
      invariant(cell.f == null);
      cell.f = cell.neutralizedFormula;
      cell.neutralizedFormula = null;
      this.cellsWithNeutralizedFormulas.delete(cell);
    }
  }

  /**
   * @param {Reference} reference
   * @returns {boolean}
   */
  _refPointsToAnotherWorkbook (reference) {
    return reference.workbookName ? !excelEqual(reference.workbookName, this.name) : false;
  }

  /**
   * Write the given cell data attributes (`.v`, `.f`, etc.) to an existing or
   * new cell.
   *
   * If cell value is an error value, normalize it to the corresponding
   * singleton error value in `errorTable`.
   *
   * If `cellData` has a formula, it is parsed and (if parsing succeeds) the AST
   * is stored in the cell object.
   *
   * If `cellData` has an `.f` attribute (whether null or not), the whole
   * workbook's dependency graph is rebuilt (unless you pass `false` for
   * `rebuildDependencyGraph`).
   *
   * Preconditions:
   * - Formula parser has finished importing (`formulaParserReady` has resolved).
   * - Workbook must be initialized.
   * @param {string | Reference} cellRef the cell to write, as a `Reference` or
   *   a string representing one. If this indicates a range, the top-left cell
   *   of that range is written. This must not be a name reference as this
   *   method does not support those yet.
   * @param {CellCSF} cellData object with attributes to write to the cell
   * @returns {Cell} the existing or new `Cell` instance.
   * @throws {Error} if precondition is not satisfied (the formula parser has
   *   not finished importing), or if a string is passed and it fails to parse
   *   as a `Reference`.
   */
  writeCellData (cellRef, cellData) {
    let reference = isRef(cellRef) ? cellRef : new Reference(cellRef, { ctx: this });
    if (reference.isAddress) {
      reference = reference.collapse();
    }
    if (reference.sheetName && !this.getSheet(reference.sheetName)) {
      throw new Error('No such sheet ' + reference.sheetName);
    }
    if (!reference.sheetName && reference.isAddress) {
      reference = reference.withPrefix({ sheetName: this._sheets[0].name });
    }
    if (!reference.workbookName) {
      reference = reference.withPrefix({ workbookName: this.name });
    }
    invariant(this._model != null, 'workbook must be initialized');
    const vertexId = referenceToVertexId(this._model, reference, true);
    if (vertexId.workbookKey !== this.keyInDepGraph) {
      throw new Error('Cannot edit cell in different workbook ' + reference.workbookName);
    }
    if ('f' in cellData) {
      // clear existing model errors recorded on this cell (if the edit did not
      // resolve those errors, then it will simply cause them to be added back).
      this._clearExistingModelErrors(vertexId);
    }
    /** @type {Cell} */
    let cell;
    if (vertexId instanceof NameVertexId) {
      const f = cellData.f || formatCellValueForFormula(cellData.v ?? null);
      const existingDefinedName = vertexIdToCell(this, vertexId);
      if (existingDefinedName) {
        cell = existingDefinedName;
      }
      else {
        const sheet = vertexId.sheetIndex == null ? null : this._sheets[vertexId.sheetIndex];
        invariant(vertexId.sheetIndex == null || sheet != null, 'sheet must exist if vertexId has a sheet index');
        cell = this.setDefinedName(reference.name, f, sheet);
      }
      cell.edit({ ...cellData, f });
      updateParsedFormula(cell, this.addError, this.type);
      validateFormula(cell, this.addError, this, this._model._lazyImports);
      this._model._recalcState.changedSinceRecalc.push(vertexId);
    }
    else {
      const sheet = this.getSheet(reference.sheetName);
      invariant(sheet != null); // else we would have early-exited above
      // Javascript destructuring assignment can't both assign to an existing
      // name and declare a new name. The _cell local is a workaround for that.
      const { cell: _cell, changedRanges } = sheet._writeCellData(reference.range, cellData, this);
      cell = _cell;
      for (const range of changedRanges) {
        const vertexId = vertexIdFromRange(this.keyInDepGraph, sheet.index, range);
        this._model._recalcState.changedSinceRecalc.push(vertexId);
      }
    }
    if ('f' in cellData || 'v' in cellData) {
      const cellVertexId = vertexId instanceof RangeVertexId ? vertexId.topLeft() : vertexId;
      this.updateDependencies([ cellVertexId ]);
    }
    if (vertexId instanceof CellVertexId && ('v' in cellData || 'z' in cellData)) {
      this._model._recalcState.valueEdited.push(vertexId);
    }
    return cell;
  }

  /**
   * @param {CellVertexId | import('./DependencyGraph').NameVertexId | RangeVertexId} vertexId
   */
  _clearExistingModelErrors (vertexId) {
    if (vertexId instanceof RangeVertexId) {
      vertexId.visitCells(cellVertexId => this._clearExistingModelErrors(cellVertexId));
      return;
    }
    if (!this._cellsWithErrors.has(vertexId)) {
      return;
    }
    /**
     * @type {number[]}
     */
    const toDelete = [];
    this._errors.forEach((modelError, modelErrorIdx) => {
      if (modelError.vertexIds.delete(vertexId) && modelError.vertexIds.size === 0) {
        // We deleted the last vertex ID from this error
        toDelete.unshift(modelErrorIdx);
      }
    });
    for (const index of toDelete) {
      const deleted = this._errors.splice(index, 1);
      delete this._errorsByMsg[deleted[0].message];
    }
    this._cellsWithErrors.delete(vertexId);
  }

  /**
   * @param {string} sheetName
   * @param {number} column 0-based column index
   * @param {number} width non-negative
   * @throws {Error} if there is no sheet with sheetName in this workbook, or if col and/or width are invalid
   */
  setColumnWidth (sheetName, column, width) {
    const sheet = this.getSheet(sheetName);
    if (sheet == null) {
      throw new Error('No such sheet ' + sheetName);
    }
    sheet.setColumnWidth(column, width);
  }

  /**
   * Sets the height of a row.
   *
   * One function, SUBTOTAL, may yield different results depending on whether
   * some cells in the range belong to hidden rows or not. Therefore, if the
   * row becomes hidden, or was hidden before this change, a recalculation of
   * formulas referencing cells in the affected row will be required. This call
   * will register state about that, so that the next recalculation will update
   * formula cells as needed. It is up to the caller to make sure a recalculation
   * is eventually triggered.
   *
   * Precondition: Workbook is initialized.
   * @param {string} sheetName
   * @param {number} rowNum 0-based row index
   * @param {number} height non-negative
   * @throws {Error} if there is no sheet with sheetName in this workbook, or if rowNum and/or height are invalid
   */
  setRowHeight (sheetName, rowNum, height) {
    const sheet = this.getSheet(sheetName);
    if (sheet == null) {
      throw new Error('No such sheet ' + sheetName);
    }
    const shouldRecalc = sheet._setRowHeight(rowNum, height);

    if (shouldRecalc) {
      invariant(this._model != null, 'workbook must be initialized');
      this._model._recalcState.changedSinceRecalc.push(
        new RangeVertexId(this.keyInDepGraph, sheet.index, rowNum, 0, rowNum, MAX_COL),
      );
    }
  }

  /**
   * @param {string} name
   * @returns {boolean}
   */
  isGlobal (name) {
    return isStr(name) ? !!this._globals[name.toLowerCase()] : false;
  }

  /**
   * Add a defined name in this workbook with the given formula.
   * If any error is detected in the formula (syntax error, or use of something
   * we do not support), abort and return null if `abortOnError` is true, else
   * go ahead and make the defined name but record the error with this.addError.
   * @template {true | false} [AbortOnError=false]
   * @param {string} name
   * @param {string} formula
   * @param {Sheet | null} [sheet = null] sheet to scope the name to, or null for workbook scope
   * @param {AbortOnError} [abortOnError=false] true to return null and make no change if formula has any errors
   * @param {boolean} [validateNow=true] set to false to skip formula validation here (caller is responsible for it then)
   * @returns {Cell | (AbortOnError extends true ? null : never)}
   */
  setDefinedName (name, formula, sheet, abortOnError, validateNow = true) {
    if (!Reference.from(name)?.name || !formula) {
      throw new Error('Cannot set defined name with invalid name or empty formula');
    }
    // names are case insensitive
    const key = name.toLowerCase();
    // while they are usually called named references, they are really named
    // "cells" as they can contain any formula
    const container = sheet == null ? this._cellContainerForGlobals : sheet._cells._cellContainer;
    const cell = new Cell({ f: formula, v: null }, name, container);
    let hasErrors = false;
    const handleError = abortOnError
      ? () => {
        hasErrors = true;
      }
      : this.addError;
    updateParsedFormula(cell, handleError, this.type);
    if (validateNow) {
      validateFormula(cell, handleError, this, this._model._lazyImports);
    }
    if (abortOnError && (hasErrors || cell._ast == null)) {
      // @ts-expect-error type checker doesn't infer that null is a valid return value
      // ... function overloading would resolve this, but can't do that in JSDoc
      return null;
    }
    const nameCollection = sheet ? sheet.locallyScopedNames : this._globals;
    nameCollection[key] = cell;
    return cell;
  }

  /**
   * Determines whether the specified cell has one or more incoming dependencies but no outgoing ones.
   * @param {number} sheetIndex 0-based sheet index
   * @param {number} row 0-based row index
   * @param {number} column 0-based column index
   * @param {boolean} ignoreRangeDependencies if set to true, then a cell won't be considered a root if
   * it only appears in range references
   * @returns {boolean}
   */
  isDependencyRoot (sheetIndex, row, column, ignoreRangeDependencies = false) {
    const vertexId = new CellVertexId(this.keyInDepGraph, sheetIndex, row, column);
    let hasIncoming = false;
    this._model._graph.visitIncomingEdges(vertexId, (inEdge, stopSignal) => {
      const isRangeRef = inEdge.to.id instanceof RangeVertexId;
      if (!ignoreRangeDependencies || !isRangeRef) {
        hasIncoming = true;
        stopSignal.set();
      }
    });
    const hasOutgoing = this._model._graph.hasOutgoingEdges(vertexId);
    return hasIncoming && !hasOutgoing;
  }

  /**
   * Height of the given column in the given sheet, in pixels.
   * @param {number} rowIndex 1-based row index
   * @param {string} sheetName name of the sheet in which to look up a row height
   */
  rowHeight (rowIndex, sheetName) {
    const sheet = this.getSheet(sheetName);
    return sheet?.row_heights[rowIndex] ?? 16;
  }

  get errorLevel () {
    return this.errors.reduce((maxLevel, d) => (d.level > maxLevel ? d.level : maxLevel), ModelError.NONE);
  }

  get errors () {
    const errors = [];
    if (this.defect) {
      const message = defectMessage[this.defect] || defectMessage.conversion_error;
      const crit = new ModelError(message, ModelError.ERROR);
      crit.type = this.defect;
      errors.push(crit);
    }
    for (const err of this._model._errorsFromModel) {
      if (err.vertexIds.hasWorkbook(this.keyInDepGraph)) {
        errors.push(err);
      }
    }
    const r = errors.concat(this._errors);
    return r;
  }

  /**
   * Move all cells to the right or left of a column index.
   * @param {WorkSheet} sheet
   * @param {number} column 0-based column index
   * @param {number} moveBy the number of positions to move the cells by. If negative, then the cells are moved to the left
   * @returns {RewriteFormula}
   */
  _moveCellsByColumn (sheet, column, moveBy) {
    const top = 0;
    const bottom = MAX_ROW;

    const [ fromLeft, toLeft ] = [ column, column + moveBy ];
    const [ fromRight, toRight ] = moveBy > 0 ? [ MAX_COL - moveBy, MAX_COL ] : [ MAX_COL, MAX_COL + moveBy ];

    const rangeFrom = new Range({ left: fromLeft, right: fromRight, top, bottom });
    const rangeTo = new Range({ left: toLeft, right: toRight, top, bottom });

    const refFrom = new Reference(rangeFrom, { sheetName: sheet.name, workbookName: this.name });
    const refTo = new Reference(rangeTo, { sheetName: sheet.name, workbookName: this.name });

    this.moveCells(refFrom, refTo);
    return this._createRewriteFormulaFunction(refFrom, refTo);
  }

  /**
   * @param {WorkSheet} sheet
   * @param {number} row 0-based row index
   * @param {number} moveBy the number of positions to move the cells by. If negative, then the cells are moved up
   * @returns {RewriteFormula}
   */
  _moveCellsByRow (sheet, row, moveBy) {
    const left = 0;
    const right = MAX_COL;

    const [ fromTop, toTop ] = [ row, row + moveBy ];
    const [ fromBottom, toBottom ] = moveBy > 0 ? [ MAX_ROW - moveBy, MAX_ROW ] : [ MAX_ROW, MAX_ROW + moveBy ];

    const rangeFrom = new Range({ top: fromTop, bottom: fromBottom, left, right });
    const rangeTo = new Range({ top: toTop, bottom: toBottom, left, right });

    const refFrom = new Reference(rangeFrom, { sheetName: sheet.name, workbookName: this.name });
    const refTo = new Reference(rangeTo, { sheetName: sheet.name, workbookName: this.name });

    this.moveCells(refFrom, refTo);
    return this._createRewriteFormulaFunction(refFrom, refTo);
  }

  /**
   * Insert `count` columns at a specific index in the given sheet.
   * @param {string} sheetName
   * @param {number} columnIndex 0-based column index
   * @param {number} count how many columns to insert
   * @param {boolean} toTheRight determines whether the inserted columns are inserted to the
   *   left or right of the column at `columnIndex`.
   * @returns {RewriteFormula}
   */
  insertColumns (sheetName, columnIndex, count, toTheRight) {
    const sheet = this.getSheet(sheetName);
    if (sheet == null) {
      throw new Error('No such sheet ' + sheetName);
    }
    if (count <= 0) {
      throw new Error(`Count ${count} must be greater than 0`);
    }
    const insertAt = toTheRight ? columnIndex + 1 : columnIndex;
    if (insertAt < 0 || insertAt + count - 1 > MAX_COL) {
      throw new Error(`Insertion of ${count} columns at index ${insertAt} is out-of-bounds`);
    }
    const rewriteFormula = this._moveCellsByColumn(sheet, insertAt, count);
    sheet._updateColumnMetadata(columnIndex, true, count);
    return rewriteFormula;
  }

  /**
   * Insert `count` rows at a specific index in the given sheet.
   * @param {string} sheetName
   * @param {number} rowIndex 0-based row index
   * @param {number} count how many rows to insert
   * @param {boolean} below determines whether the inserted rows are inserted below or
   *   above of the row at `rowIndex`.
   * @returns {RewriteFormula}
   */
  insertRows (sheetName, rowIndex, count, below) {
    const sheet = this.getSheet(sheetName);
    if (sheet == null) {
      throw new Error('No such sheet ' + sheetName);
    }
    if (count <= 0) {
      throw new Error(`Count ${count} must be greater than 0`);
    }
    const insertAt = below ? rowIndex + 1 : rowIndex;
    if (insertAt < 0 || insertAt + count - 1 > MAX_ROW) {
      throw new Error(`Insertion of ${count} rows at index ${insertAt} is out-of-bounds`);
    }
    const rewriteFormula = this._moveCellsByRow(sheet, insertAt, count);

    // Adjust row heights
    for (const key of Object.keys(sheet.row_heights)
      .map(Number)
      .sort((a, b) => b - a)) {
      // The `row_heights` map keys are 1-based
      if (key - 1 < insertAt) {
        continue;
      }
      sheet.row_heights[key + count] = sheet.row_heights[key];
      delete sheet.row_heights[key];
    }

    return rewriteFormula;
  }

  /**
   * Delete `count` columns at a specific index in the given sheet.
   * @param {string} sheetName
   * @param {number} columnIndex 0-based column index
   * @param {number} count how many columns should be deleted
   * @returns {RewriteFormula}
   */
  deleteColumns (sheetName, columnIndex, count) {
    const sheet = this.getSheet(sheetName);
    if (sheet == null) {
      throw new Error('No such sheet ' + sheetName);
    }
    if (count < 1) {
      throw new Error(`Count ${count} must be greater than or equal to 1`);
    }
    if (columnIndex < 0 || columnIndex + count - 1 > MAX_COL) {
      throw new Error(`Deletion of ${count} columns at index ${columnIndex} is out-of-bounds`);
    }
    const rewriteFormula = this._moveCellsByColumn(sheet, columnIndex + count, -count);
    sheet._updateColumnMetadata(columnIndex, false, count);
    return rewriteFormula;
  }

  /**
   * Delete `count` rows at a specific index in the given sheet.
   * @param {string} sheetName
   * @param {number} rowIndex 0-based row index
   * @param {number} count how many rows should be deleted
   * @returns {RewriteFormula}
   */
  deleteRows (sheetName, rowIndex, count) {
    const sheet = this.getSheet(sheetName);
    if (sheet == null) {
      throw new Error('No such sheet ' + sheetName);
    }
    if (count < 1) {
      throw new Error(`Count ${count} must be greater than or equal to 1`);
    }
    if (rowIndex < 0 || rowIndex + count - 1 > MAX_ROW) {
      throw new Error(`Deletion of ${count} rows at index ${rowIndex} is out-of-bounds`);
    }
    const rewriteFormula = this._moveCellsByRow(sheet, rowIndex + count, -count);

    // Adjust row heights
    for (const key of Object.keys(sheet.row_heights)
      .map(Number)
      .sort((a, b) => a - b)) {
      // The `row_heights` map keys are 1-based
      const row = key - 1;
      if (row < rowIndex) {
        continue;
      }
      if (row >= rowIndex + count) {
        sheet.row_heights[key - count] = sheet.row_heights[key];
      }
      delete sheet.row_heights[key];
    }

    return rewriteFormula;
  }

  /**
   * @param {string} sheetName
   * @param {number} from 0-based index of first column to move
   * @param {number} to 0-based index of end-position of first column to move
   * @param {number} count the number of columns to move
   * @returns {RewriteFormula}
   */
  moveColumns (sheetName, from, to, count) {
    return moveRowsOrColumns.apply(this, [
      'columns',
      sheetName,
      from,
      to,
      count,
      sheet => {
        sheet.columns = updateColumnInfosOnMoveColumns(sheet.columns, from, to, count);
      },
    ]);
  }

  /**
   * @param {string} sheetName
   * @param {number} from 0-based index of first row to move
   * @param {number} to 0-based index of end-position of first row to move
   * @param {number} count the number of rows to move
   * @returns {RewriteFormula}
   */
  moveRows (sheetName, from, to, count) {
    return moveRowsOrColumns.apply(this, [
      'rows',
      sheetName,
      from,
      to,
      count,
      sheet => {
        sheet.row_heights = updateRowHeightsOnMoveRows(sheet.row_heights, from, to, count);
      },
    ]);
  }

  /**
   * @param {Reference} from
   * @param {Reference} to
   * @returns {RewriteFormula}
   */
  _createRewriteFormulaFunction (from, to) {
    const workbookName = this.name || '';
    const sheetName = this.getSheet()?.name || '';
    return formula => rewriteFormulaAfterMove(workbookName, sheetName, formula, from, to);
  }

  /**
   * Precondition: formula parser has finished importing (`formulaParserReady` has resolved).
   * @param {string|Reference} from Must be the same dimensions as `to`
   * @param {string|Reference} to Must be the same dimensions as `from`
   * @returns {RewriteFormula}
   */
  moveCells (from, to) {
    // The implementation of this method is given its own file for the sake of
    // readability and privacy.
    moveCells(this, from, to);
    return this._createRewriteFormulaFunction(new Reference(from), new Reference(to));
  }

  /**
   * Update the reset state of the cell referenced by `ref`. The reference
   * can be to a cell in a sheet, or a defined name in the Workbook.
   * @param {Reference} ref
   */
  updateCellResetState (ref) {
    if (ref.name) {
      // Since defined names cannot spill into other cells, updating its reset
      // state is as simple as setting `_v = v`.
      const definedNameCell = this.getGlobal(ref.name);
      // Tolerate the defined-name not being found; this can happen e.g. if the
      // reference was to a cached-formula cell that since has been cleared.
      if (!isErr(definedNameCell)) {
        definedNameCell._v = definedNameCell.v;
      }
      return;
    }

    const sheet = this.getSheet(ref.sheetName);
    if (sheet == null) {
      return;
    }
    // Normal reference
    sheet._cells.updateResetState({ row: ref.range.top, column: ref.range.left });
  }

  /**
   * @param {WorkSheet} sheet
   * @param {Cell} cell
   * @returns {Table | null}
   */
  getTableContainingCell (sheet, cell) {
    const [ row, column ] = a1ToRowColumn(cell.id);
    // TODO: This may be slow when there are lots of tables. This may be optimized
    // by using an R-Tree data structure.
    for (const table of this._tables.values()) {
      if (table.sheetName.toLowerCase() !== sheet.name.toLowerCase()) {
        continue;
      }
      if (table.containsCoords(row, column)) {
        return table;
      }
    }
    return null;
  }

  /**
   * Get or create a cached-formula defined-name object for the given formula.
   * Assign it an `ft: 'a'` property; cached-formula cells should not be used
   * for formulas which need single-cell (implicit-intersection) mode).
   * Calculate its value unless `false` is passed for `calculateIfNew`.
   * @param {string | null | undefined} formula
   * @param {boolean} [calculateIfNew=true]
   * @returns {Cell | null}
   */
  getCachedFormulaCell (formula, calculateIfNew = true, prefix = CACHED_FORMULA_CELL_ID_PREFIX) {
    formula = normalizeFormula(formula);
    if (!formula) {
      return null;
    }
    const { cell, id } = this._getExistingCachedFormulaCell(formula, prefix);
    if (!cell) {
      const newCell = this.setDefinedName(id, formula, null, true);
      if (newCell) {
        // Explicitly state that the formula is of array type. This matters if
        // the defined name is being created in a Google Sheets workbook, where
        // formulas default to non-array mode if not explicitly marked this way.
        // We never create defined names for expressions that aren't array-type
        // (such expressions would be context-dependent because of the possible
        // occurrence of implicit intersection operators, so extracting them is
        // would be incorrect).
        newCell.ft = 'a';
        const vertexId = new NameVertexId(this.keyInDepGraph, null, id);
        this._model._updateDependenciesFor(this, [ vertexId ]);
        this._model._recalcState.namesAwaitingRecalc.push(vertexId);
        if (calculateIfNew) {
          this.calcCell(newCell);
        }
        return newCell;
      }
    }
    return cell;
  }

  /**
   * @param {string | null | undefined} formula
   */
  clearCachedFormula (formula) {
    formula = normalizeFormula(formula);
    if (!formula) {
      return null;
    }
    const { cell, id } = this._getExistingCachedFormulaCell(formula);
    if (cell) {
      delete this._globals[id];
      const goneKey = nameVertexIdKey(cell.workbookKey, cell.sheetIndex, id);
      const recalcState = this._model._recalcState;
      recalcState.namesAwaitingRecalc = this._model._recalcState.namesAwaitingRecalc.filter(v => v.key !== goneKey);
      this._model._updateDependenciesFor(this, [ new NameVertexId(this.keyInDepGraph, null, id) ]);
    }
  }

  /**
   * @param {string[]} formulas
   */
  clearCachedFormulasExcept (formulas) {
    const normalizedFormulas = new Set(formulas.map(normalizeFormula));
    if (this && this.type === 'native') {
      /** @type {NameVertexId[]} */
      const deleted = [];
      for (const [ id, cell ] of Object.entries(this._globals)) {
        if (
          cell.id.startsWith(CACHED_FORMULA_CELL_ID_PREFIX) &&
          (!cell.f || !normalizedFormulas.has(normalizeFormula(cell.f)))
        ) {
          delete this._globals[id];
          deleted.push(new NameVertexId(this.keyInDepGraph, null, id));
        }
      }
      this._model._updateDependenciesFor(this, deleted);
      const recalcState = this._model._recalcState;
      const goneKeys = new Set(deleted.map(vid => vid.key));
      recalcState.namesAwaitingRecalc = recalcState.namesAwaitingRecalc.filter(vid => !goneKeys.has(vid.key));
    }
  }

  * allDefinedNames () {
    yield * Object.values(this._globals);
    for (const sheet of this._sheets) {
      yield * Object.values(sheet.locallyScopedNames);
    }
  }

  /**
   * @param {string} formula
   * @param {string} [prefix=CACHED_FORMULA_CELL_ID_PREFIX]
   */
  _getExistingCachedFormulaCell (formula, prefix = CACHED_FORMULA_CELL_ID_PREFIX) {
    let id = `${prefix}${hash(formula)}`;
    /** @type {Cell | null | FormulaError} */
    let cell = this.getGlobal(id);
    while (cell instanceof Cell && cell.f !== formula) {
      // Hash collision on distinct formulas! Unlikely, so handle very simply.
      id += '_';
      cell = this.getGlobal(id);
    }
    cell = isErr(cell) ? null : cell;
    return { cell, id };
  }
}

/**
 * Return true if the given CSF schema version is at or after the version at
 * which formula types (the `t` property of cell CSF objects) were introduced.
 * That threshold version is a dummy placeholder for now, so this always returns
 * false except in some tests that explicitly set this version to test this.
 * @param {string | undefined} schema_version
 * @returns {boolean}
 */
function csfVersionHasFormulaTypes (schema_version) {
  const [ major, minor ] = schema_version?.split('.') || [];
  if (!isFinite(+major) || !isFinite(+minor)) {
    return false;
  }
  return +major > FORMULA_TYPES_CSF_VERSION_PARTS[0] || +minor >= FORMULA_TYPES_CSF_VERSION_PARTS[1];
}

/**
 * @param {Workbook} workbook
 * @param {NameCSF[]} [csf]
 */
function populateNamesFromCSF (workbook, csf) {
  // First create all the defined names, then validate their formulas. This
  // separation is so that we know the full set of lambda names present when we
  // check for unsupported function names in calls.
  const definedNameCells = [];
  // Ignore sheet scopes, i.e. assume they're global, until we get to ENGINE-322
  for (const definedName of csf || []) {
    if (!Reference.from(definedName.name)?.name) {
      // Not a valid defined name
      workbook.addError(new ModelError('Invalid defined name: ' + definedName.name, ModelError.ERROR, [], 'bad_name'));
      continue;
    }
    workbook.names.push(definedName);
    const sheet = definedName.scope ? workbook.getSheet(definedName.scope) : null;
    const cell = workbook.setDefinedName(definedName.name, definedName.value, sheet, false, false);
    definedNameCells.push(cell);
  }
  return definedNameCells;
}

/**
 * @param {Workbook} workbook
 * @param {Cell[]} definedNameCells
 */
function validateFormulas (workbook, definedNameCells) {
  for (const sheet of workbook._sheets) {
    for (const cell of sheet.iterFormulaCells()) {
      validateFormula(cell, workbook.addError, workbook, workbook._model._lazyImports);
    }
  }
  for (const cell of definedNameCells) {
    validateFormula(cell, workbook.addError, workbook, workbook._model._lazyImports);
  }
}

/**
 * @param {Workbook} workbook
 * @param {SheetCSF[]} [sheets]
 * @param {TableCSF[]} [tables]
 * @param {boolean} [willRecalc=true] if set to false, then work that is required to support calculations will be
 *   skipped, and work that cannot be done perfectly without recalc will be done imperfectly, as there will be no recalc
 */
function populateSheetsFromCSF (workbook, sheets, tables, willRecalc = true) {
  /** @type {Record<string, TableCSF[]>} */
  const tablesBySheetName = {};
  for (const sheet of sheets || []) {
    tablesBySheetName[sheet.name.toLowerCase()] = [];
  }
  for (const table of tables || []) {
    tablesBySheetName[table.sheet.toLowerCase()]?.push(table);
  }

  /** @type {DefaultMap<number, Reference[]>} */
  const cellsNeedingInitRecalc = new DefaultMap(() => []);
  (sheets || []).forEach((sheetData, index) => {
    const lazyImports = workbook._model._lazyImports;
    const sheet = workbook._addSheet(sheetData.name, index, sheetData.hidden);
    const needsRecalc = sheet.setData(sheetData, workbook, lazyImports, willRecalc);

    if (sheetData.structure === 'datatable') {
      const tables = tablesBySheetName[sheetData.name.toLowerCase()];
      invariant(tables.length === 1, 'a structured sheet should have one table, got ' + tables.length);
      sheet._convertToStructuredSheet(tables[0]);
    }

    cellsNeedingInitRecalc.get(index).push(...needsRecalc);
  });
  return cellsNeedingInitRecalc;
}

/**
 * @param {Workbook} workbook
 * @param {Map<number, Reference[]>} cellsNeedingInitRecalc
 */
function scheduleForInitRecalc (workbook, cellsNeedingInitRecalc) {
  for (const [ sheetIndex, refs ] of cellsNeedingInitRecalc) {
    for (const ref of refs) {
      // Currently, `setData` only returns sheet cell references. If this
      // invariant ever starts to fail in the tests, you probably need to add
      // `NameVertexId` and/or `RangeVertexId` cases.
      invariant(ref.isAddress && ref.size === 1);
      const cellVertexId = new CellVertexId(workbook.keyInDepGraph, sheetIndex, ref.top, ref.left);
      const cell = vertexIdToCell(workbook, cellVertexId);
      if (cell != null && cell.f && isNonLiteralNode(cell._ast) && !cell._ast.callsUnsupportedFunction) {
        workbook._model._recalcState.needsInitRecalc.push(cellVertexId);
      }
    }
  }
}

/**
 * @param {Workbook} workbook
 * @param {TableCSF[]} [tableCSFs]
 */
function populateTablesFromCSF (workbook, tableCSFs) {
  for (const tableCSF of tableCSFs || []) {
    if (!Reference.from(tableCSF.name)?.name) {
      // Table name is not valid as a defined name
      workbook.addError(new ModelError('Invalid table name: ' + tableCSF.name, ModelError.ERROR, [], 'bad_name'));
      continue;
    }
    const ref = Reference.from(tableCSF.ref, { sheetName: tableCSF.sheet, workbookName: workbook.name });
    if (ref == null) {
      const message = `Invalid table "${tableCSF.name}" with reference "${tableCSF.ref}"`;
      workbook.addError(new ModelError(message, ModelError.ERROR, [], tableCSF.ref));
      continue;
    }
    const table = new Table(tableCSF, ref);
    workbook._tables.set(tableCSF.name.toLowerCase(), table);
    workbook.setDefinedName(tableCSF.name, `${tableCSF.name}[[#Data]]`); // sheet = null; tables are never sheet-scoped
  }
}

/**
 * @param {Workbook} workbook
 * @param {Record<string, string> | undefined} metadataDefects
 */
function populateMetadataDefectsFromCSF (workbook, metadataDefects) {
  if (!metadataDefects) {
    return;
  }

  /** @type {Record<string, Array<CellVertexId | NameVertexId>>} */
  const defects = {};
  for (const [ ref, defect ] of Object.entries(metadataDefects)) {
    if (!defects[defect]) {
      defects[defect] = [];
    }
    let reference = new Reference(ref, { ctx: workbook });
    const sheetIndex = workbook.getSheetIndex(reference.sheetName);
    let vertexId;
    if (reference.isAddress) {
      if (reference.size > 1) {
        // ModelError does not accept RangeVertexIds as references, and we don't expect the defect reference to contain
        // ranges so we fall back on the top left cell if we ever happen to receive a range
        reference = reference.collapse();
      }
      vertexId = new CellVertexId(workbook.keyInDepGraph, sheetIndex || 0, reference.top, reference.left);
    }
    else {
      vertexId = new NameVertexId(workbook.keyInDepGraph, sheetIndex, reference.name);
    }
    defects[defect].push(vertexId);
  }

  for (const [ defect, refs ] of Object.entries(defects)) {
    workbook.addError(
      new ModelError(defectMessage[defect] || defectMessage.conversion_error, ModelError.WARNING, refs, defect),
    );
  }
}

/**
 * @param {WorkbookCSF} csf
 * @return {IterativeCalculationOptions | null}
 */
function readIterativeCalculationSettings (csf) {
  return csf.calculation_properties
    ? {
      iterate: csf.calculation_properties.iterate,
      maxIterations: csf.calculation_properties.iterate_count,
      maxChange: csf.calculation_properties.iterate_delta,
    }
    : null;
}

/**
 * @this {Workbook}
 * @param {'rows' | 'columns'} which
 * @param {string} sheetName
 * @param {number} from 0-based index of first row/column to move
 * @param {number} to 0-based index of end-position of first row/column to move
 * @param {number} count the number of rows/columns to move
 * @param {(sheet: WorkSheet) => void} updateMetadata
 * @returns {RewriteFormula}
 */
function moveRowsOrColumns (which, sheetName, from, to, count, updateMetadata) {
  const sheet = this.getSheet(sheetName);
  if (sheet == null) {
    throw new Error('No such sheet ' + sheetName);
  }

  const moveBy = to - from;
  if (moveBy === 0) {
    return IDENTITY;
  }

  const adjustFrom = moveBy < 0 ? count : 0;
  const adjustTo = moveBy > 0 ? count : 0;

  /** @param {Range} range */
  const ref = range => new Reference(range, { sheetName: sheet.name, workbookName: this.name });

  const createRange = which === 'columns' ? Range.createColumnRange : Range.createRowRange;
  const moveByRowOrColumn = (which === 'columns' ? this._moveCellsByColumn : this._moveCellsByRow).bind(this);
  const fromRange = createRange(from + adjustFrom, from + adjustFrom + count - 1);
  const toRange = createRange(from + adjustTo + moveBy, from + adjustTo + moveBy + count - 1);

  /** @type {RewriteFormula[]} */
  const rewriteFns = [
    moveByRowOrColumn(sheet, to + adjustTo, count), // Insert
    this.moveCells(ref(fromRange), ref(toRange)), // Move
    moveByRowOrColumn(sheet, from + adjustFrom + count, -count), // Delete
  ];
  updateMetadata(sheet);
  return formula => rewriteFns.reduce((formula, fn) => fn(formula), formula);
}

/**
 * Replace each vertex ID with a corresponding one with a new sheet index from
 * the given mapping function, if that differs from the existing sheet index.
 *
 * If the collection is a Set, the elements are expected to be vertex ID _keys_
 * (strings) and will be converted to `VertexId` and back. If it is an array,
 * then they will be expected to be `VertexId`.
 * @param {import('./DependencyGraph').KnownVertexId[] | Set<string> | Array<import('./DependencyGraph').KnownVertexId>} collection
 * @param {(n: number) => number | null} mapSheetIndex
 */
function updateSheetIndices (collection, mapSheetIndex) {
  const vertexIdsAfter = [];
  for (const vertexIdOrKey of collection) {
    let vertexId = typeof vertexIdOrKey === 'string' ? VertexId.fromKey(vertexIdOrKey) : vertexIdOrKey;
    if (vertexId.sheetIndex != null) {
      const newSheetIndex = mapSheetIndex(vertexId.sheetIndex);
      if (newSheetIndex == null) {
        // Sheet removed, so remove vertex ID (i.e. don't add to vertexIdsAfter)
        continue;
      }
      if (newSheetIndex !== vertexId.sheetIndex) {
        vertexId = vertexId.withSheetIndex(newSheetIndex);
      }
    }
    vertexIdsAfter.push(vertexId);
  }
  if (collection instanceof Set) {
    collection.clear();
    for (const vertexId of vertexIdsAfter) {
      collection.add(vertexId.key);
    }
  }
  else {
    collection.splice(0, collection.length);
    for (const vertexId of vertexIdsAfter) {
      collection.push(vertexId);
    }
  }
}

/**
 * Get a workbook by name, or this workbook if no name is given. This is to
 * let a workbook serve as an `EvaluationContext`.
 * @this {Workbook}
 * @param {string | null} [name]
 * @returns {Workbook | undefined}
 */
function resolveWorkbook (name) {
  if (name == null) {
    return this;
  }
  return this._model?.getWorkbook(name);
}

/**
 * Get workbook with the given `keyInDepGraph`
 * @this {Workbook}
 * @param {number} key
 * @returns {Workbook | undefined}
 */
function getWorkbookByKey (key) {
  return key === this.keyInDepGraph ? this : this._model.getWorkbookByKey(key);
}

/**
 * @this {Workbook}
 * @param {string | null} [sheetName]
 * @param {string | null} [workbookName]
 * @returns {Sheet | null}
 */
function resolveSheet (sheetName, workbookName) {
  if (!workbookName || workbookName.toLowerCase() === this.name.toLowerCase()) {
    const sheet = this.getSheet(sheetName);
    if (sheet || !resolvesNamesInOtherWorkbooks(this.mode)) {
      return sheet;
    }
  }
  return this._model.resolveSheet(sheetName, workbookName);
}

/**
 * @this {Workbook}
 * @param {string} name
 * @param {string | null} [sheetName]
 * @returns {Cell | FormulaError} the cell object for the given defined-name, or a #NAME? error if not found
 */
function resolveName (name, sheetName) {
  if (sheetName) {
    const sheet = this.resolveSheet(sheetName);
    return (
      sheet?.locallyScopedNames[name.toLowerCase()] ||
      ERROR_NAME.detailed('Name ' + name + ' not found in sheet ' + sheetName)
    );
  }
  const definedName = this.getGlobal(name);
  if (!isErr(definedName) || !resolvesNamesInOtherWorkbooks(this.mode)) {
    return definedName;
  }
  return this._model.resolveName(name, sheetName);
}

/**
 * @this {Workbook}
 * @param {string} name
 * @param {string | null} [workbookName]
 * @returns {Table | null}
 */
function resolveTable (name, workbookName) {
  if (!workbookName || workbookName.toLowerCase() === this.name.toLowerCase()) {
    const table = this.getTable(name);
    if (table || !resolvesNamesInOtherWorkbooks(this.mode)) {
      return table;
    }
  }
  return this._model.resolveTable(name, workbookName);
}

/**
 * Returns true if the workbook mode permits resolving names in other workbooks.
 * @param {WorkbookMode} workbookMode
 */
function resolvesNamesInOtherWorkbooks (workbookMode) {
  return workbookMode === MODE_GRID_SHEET;
}

/**
 * @param {Cell} cell
 * @param {number} mode
 * @param {FormulaValue} prevValue
 * @param {MaybeBoxedFormulaValue} newValue
 */
function didFormulaCellChange (cell, mode, prevValue, newValue) {
  if (unbox(newValue) !== prevValue) {
    return true;
  }
  const isNumFmtPropagated = !(mode & NO_PROPAGATE_NUMBER_FORMAT_MODES) && !cell.userZ;
  if (!isNumFmtPropagated) {
    return false;
  }
  const newValueNumFmt = isBoxed(newValue) ? newValue.metadata.numberFormat : null;
  const didNumFmtChange = cell.formulaZ !== newValueNumFmt;
  return didNumFmtChange;
}

/**
 * @param {string | null | undefined} f
 */
export function normalizeFormula (f) {
  if (f) {
    if (f.startsWith('=')) {
      f = f.slice(1);
    }
    f = f.trim();
  }
  return f || '';
}

/**
 * @this {Workbook}
 */
function writeState () {
  return this._model.writeState();
}

export default Workbook;
