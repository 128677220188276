import {
  call,
  callLazyArgumentFunction,
  doRangeOperation,
  doIntersectionOperation,
  checkReferenceNotDirty,
} from './evaluate-common.js';
import Reference from './Reference.js';
import { getInfixHandler, getInfixName, UMINUS, UNARY_PERCENT, UPLUS } from './functions/operators.js';
import {
  errorTable,
  ERROR_NAME,
  ERROR_REF,
  ERROR_UNKNOWN,
  BLANK,
  MISSING,
  ERROR_VALUE,
  ERROR_NA,
} from './constants.js';
import { round15Boxed } from './functions/utils-number';
import { isErr } from './functions/utils.js';
import { ANCHORARRAY, ARRAY, ARRAYROW, SINGLE } from './functions/array.js';
import { parseFormula } from './formulaParser/index.js';
import { doStaticReferenceOperation, isStructuredReferenceNode, astIsGoogleArrayFunctionCall } from './ast';
import { isRef, isMatrix, isNum } from '../utils.js';
import { unbox } from './ValueBox.js';
import { COERCE_CELLS, COERCE_SPILLS } from '../mode.js';
import { invariant } from '../validation';
import { a1ToRowColumn } from './referenceParser/a1.js';
import { Lambda, ERROR_CALC_LAMBDA_NOT_ALLOWED } from './lambda';
import { resolveCallee } from './functions/calls';
import { evaluateLet } from './evaluate-let';

/**
 * Low-level error in formula evaluation. The contents should be assumed to be
 * inappropriate to show to end users, should be adorned with the formula and
 * full parsed AST when bubbling up the stack, and should be reported to us for
 * troubleshooting.
 */
export class EvaluationError extends Error {
  /**
   * @param {string | undefined} message
   * @param {ASTNode} [ast]
   * @param {string} [formula]
   * @param {ModeBit} [mode]
   */
  constructor (message, ast, formula, mode) {
    super(message);
    /** @type {ASTNode | undefined} */
    this.ast = ast;
    /** @type {string | undefined} */
    this.formula = formula;
    /** @type {ModeBit | undefined} */
    this.mode = mode;
  }
}

/**
 * @template {CellValue} T
 * @typedef { import("./ValueBox").MaybeBoxed<T> } MaybeBoxed<T>
 */

/**
 * Parse and evaluate the given formula with the given evaluation context.
 * Precondition: the `formulaParser.ready` promise has been resolved.
 * @param {string} formula
 * @param {EvaluationContext} options
 * @return {MaybeBoxedFormulaValue}
 */
export default function evaluateFormula (formula, options) {
  let ast;
  try {
    // @ts-expect-error parseFormula is ensured non-null by the documented precondition
    ast = parseFormula(formula);
  }
  catch (err) {
    return ERROR_NAME.detailed(`Syntax error in formula: ${err}. Formula: ${formula}`);
  }
  let value;
  try {
    value = evaluateAST(ast, options);
  }
  catch (err) {
    if (err instanceof EvaluationError) {
      err.ast = ast;
      err.formula = formula;
    }
    throw err;
  }
  if (!options.allowBoxed) {
    return unbox(value);
  }
  return value;
}

/**
 * Evaluate a formula in the form of an AST.
 *
 * @param {ASTRootNode} ast the root node of the parsed AST for a formula
 * @param {EvaluationContext} opts evaluation context
 * @returns {MaybeBoxedFormulaValue} the result of
 *   formula evaluation (possibly a `FormulaError`, but that's still success for
 *   this function)
 * @throws {FormulaError} if evaluation is shortcircuited (e.g. by unrecognized function name)
 * @throws {EvaluationError} if AST is invalid or an unanticipated internal error occurs so evaluation can't complete.
 *   This error will have its `.ast` property populated but not its `.formula` property.
 */
export function evaluateAST (ast, opts) {
  const singleCell = opts.singleCell && !astIsGoogleArrayFunctionCall(ast);
  /**
   * @type {ReturnType<evaluateAST>}
   */
  let result;
  try {
    result = opts.evaluateASTNode(ast) ?? null;
  }
  catch (err) {
    if (err instanceof EvaluationError) {
      err.ast = ast;
      err.mode = opts.mode;
    }
    throw err;
  }
  if (opts.rawOutput) {
    return result;
  }
  if (singleCell && isRef(result) && result.size > 1) {
    const ret = SINGLE.apply(opts, [ result ]) ?? null;
    return ret;
  }
  if (isRef(result) && result.name) {
    result = result.resolveToNonName(opts);
  }
  if (isRef(result) || isMatrix(result)) {
    if (isRef(result)) {
      checkReferenceNotDirty(result, opts);
      if (!result.ctx) {
        result = result.withContext(opts);
      }
    }
    if (!opts.allowMatrices || result.size === 1 || (isRef(result) && !result.range)) {
      result = result.resolveSingleBoxed();
    }
    else if (isRef(result)) {
      result = result.toMatrix();
    }
  }
  const { coerceNullToZero } = opts;
  if (isMatrix(result) && coerceNullToZero & COERCE_SPILLS) {
    result = result.map(value => value ?? 0);
  }
  if (unbox(result) == null && coerceNullToZero & COERCE_CELLS) {
    result = 0;
  }
  else if (isNum(result)) {
    return round15Boxed(result);
  }
  return unbox(result);
}

/**
 * @this {EvaluationContext}
 * @param {ASTNode} ast
 * @returns {MaybeBoxedFormulaArgument}
 */
export function evaluateASTNodeUnbound (ast) {
  if (ast == null) {
    return MISSING;
  }
  let opts = this;
  invariant(this);
  if (opts.singleCell && astIsGoogleArrayFunctionCall(ast)) {
    opts = { ...opts, singleCell: false };
  }

  if (typeof ast === 'string' || typeof ast === 'number' || typeof ast === 'boolean') {
    return ast;
  }
  else if ('cell' in ast) {
    return Reference.from(ast.cell, { ctx: opts }) ?? BLANK;
  }
  else if ('binop' in ast) {
    // TODO: can skip second arg in some cases if first arg dominates?
    const handler = getInfixHandler(ast.binop);
    if (handler == null) {
      throw new EvaluationError(`Unknown binary operator: "${ast.binop}"`);
    }
    if (ast.args?.length !== 2) {
      throw new EvaluationError(`Binary operator with ${ast.args == null ? 'no' : ast.args.length} args`);
    }
    const operatorFunctionName = getInfixName(ast.binop);
    invariant(operatorFunctionName != null, 'Operator handler exists, so operator function name must exist');
    return call(opts, operatorFunctionName, true, handler, [
      opts.evaluateASTNode(ast.args[0]),
      opts.evaluateASTNode(ast.args[1]),
    ]);
  }
  else if ('unop' in ast && ast.unop === '-') {
    return call(opts, 'UMINUS', true, UMINUS, [ opts.evaluateASTNode(ast.expr) ]);
  }
  else if ('unop' in ast && ast.unop === '+') {
    return call(opts, 'UPLUS', true, UPLUS, [ opts.evaluateASTNode(ast.expr) ]);
  }
  else if ('unop' in ast && ast.unop === '%') {
    return call(opts, 'UNARY_PERCENT', true, UNARY_PERCENT, [ opts.evaluateASTNode(ast.expr) ]);
  }
  else if ('unop' in ast && ast.unop === '#') {
    return call(opts, 'ANCHORARRAY', true, ANCHORARRAY, [ opts.evaluateASTNode(ast.expr) ]);
  }
  else if ('unop' in ast) {
    throw new EvaluationError(`Unknown unary operator: "${ast.unop}"`);
  }
  else if ('call' in ast) {
    const callee = resolveCallee(ast, opts, name => !isErr(opts.resolveName(name)));
    if (isErr(callee)) {
      return callee;
    }
    const { funcName, handler, lazyArgHandler, lambda } = callee;
    if (lambda) {
      return lambda.callWithAST(opts, ast.args);
    }
    if (!funcName) {
      return ERROR_NA.detailed('Cannot call a non-lambda non-function');
    }
    // TODO: special-case more logical functions (AND, OR, etc.) like IF, to skip evaluating unneeded args
    if (funcName === 'ARRAYFORMULA') {
      if (ast.args.length !== 1) {
        return ERROR_NA.detailed(
          `Wrong number of arguments to ARRAYFORMULA. Expected 1 arguments, but got ${ast.args.length} arguments`,
        );
      }
      return { ...opts, singleCell: false }.evaluateASTNode(ast.args[0]);
    }
    if (lazyArgHandler != null) {
      return callLazyArgumentFunction(opts, funcName, lazyArgHandler, ast.args);
    }
    if (!handler) {
      return ERROR_NAME.detailed('Unsupported function ' + funcName);
    }
    invariant(handler);
    const args = ast.args.map(a => opts.evaluateASTNode(a));
    if (funcName === 'QUERY' && isRef(args[0])) {
      specialCaseQueryWithStructuredReference(args, ast.args[0], opts);
    }
    return call(opts, funcName, false, handler, args);
  }
  else if ('prefixed' in ast) {
    // TODO: handle sheet-range prefix (in Waspiary and here)
    const prefixee = opts.evaluateASTNode(ast.prefixed);
    if (isErr(prefixee)) {
      return prefixee;
    }
    if (isRef(prefixee)) {
      const prefix = { sheetName: ast.sheet, workbookName: ast.workbook, dir: ast.dir };
      if (prefixee.name && ast.sheet && !ast.workbook) {
        changeSheetPrefixToWorkbookPrefixIfNoSuchSheet(ast, prefix, opts);
      }
      const ret = prefixee.withPrefix(prefix);
      return ret;
    }
    else {
      throw new EvaluationError(
        `In prefixed reference, prefixee AST evaluated to a non-reference: ${describeNodeOrValue(prefixee)} from ` +
          JSON.stringify(ast.prefixed),
      );
    }
  }
  else if ('range' in ast) {
    // Note: if you make changes here, remember to consider whether something corresponding also needs
    // to happen for the colrange (A:B) and rowrange (1:2) cases further down
    return doRangeOperation(ast.range, opts);
  }
  else if ('colrange' in ast) {
    return Reference.from(ast.colrange.join(':'), { ctx: opts }) ?? BLANK;
  }
  else if ('rowrange' in ast) {
    return Reference.from(ast.rowrange.join(':'), { ctx: opts }) ?? BLANK;
  }
  else if ('name' in ast) {
    return Reference.from(ast.name, { ctx: opts }) ?? BLANK;
  }
  else if ('array' in ast) {
    try {
      return ARRAY(...ast.array.map(row => ARRAYROW(...row.map(v => opts.evaluateASTNode(v) ?? null))));
    }
    catch (e) {
      if (e === ERROR_CALC_LAMBDA_NOT_ALLOWED) {
        return ERROR_CALC_LAMBDA_NOT_ALLOWED;
      }
      throw e;
    }
  }
  else if ('error' in ast) {
    return evaluateErrorASTNode(ast);
  }
  else if ('intersection' in ast) {
    return doIntersectionOperation(ast.intersection, opts);
  }
  else if ('union' in ast) {
    return ERROR_REF.detailed('Reference unions are not yet supported');
  }
  else if ('structured' in ast) {
    return structuredToA1Reference(ast, opts);
  }
  else if ('lambda' in ast) {
    return Lambda.fromAST(ast, opts.lambdaBindings);
  }
  else if ('let' in ast) {
    return evaluateLet(ast, opts);
  }
  else if ('param' in ast) {
    invariant(opts.lambdaBindings, 'param nodes should only be encountered in lambda contexts');
    const binding = opts.lambdaBindings.args.get(ast.param);
    if (binding == null) {
      return ERROR_NAME.detailed('Unexpected unbound parameter in lambda call');
    }
    // The argument is either an AST node that needs to be evaluated, or a value
    // that can simply be returned.
    if ('astNode' in binding) {
      return binding.ctx.evaluateASTNode(binding.astNode);
    }
    else {
      return binding.value;
    }
  }
  else {
    throw new EvaluationError(`Unknown AST node type ${describeNodeOrValue(ast)}`);
  }
}

/**
 * Modify the arguments of a QUERY function call if the first argument is a
 * structured reference, or a name reference resolving to a table, to include
 * the header row of the table (unless the structured reference explicitly
 * specifies the set of rows to include).
 * @param {MaybeBoxedFormulaArgument[]} args
 * @param {ASTNode} firstArgNode
 * @param {EvaluationContext} ctx
 */
function specialCaseQueryWithStructuredReference (args, firstArgNode, ctx) {
  const firstArg = args[0];
  invariant(isRef(firstArg));
  const isStructuredReference = isStructuredReferenceNode(firstArgNode);
  const workbookName = isStructuredReference
    ? getWorkbookNameFromStructuredReferenceASTNode(firstArgNode)
    : firstArg.workbookName;
  const tableName = isStructuredReference ? firstArgNode.table : firstArg.name;
  const table = tableName ? ctx.resolveTable(tableName, workbookName) : null;
  if (table) {
    // Special-case QUERY source table to include header row (which is
    // otherwise omitted in structured references by default)
    if (isStructuredReference) {
      const structured = firstArgNode.structured;
      const keywords = (structured?.keywords || []).map(k => k.toLowerCase());
      if (structured && keywords.length === 0) {
        // No keywords specified, so default to including headers and data.
        keywords.push('[#headers]', '[#data]');
        invariant(ctx.evaluateASTNode);
        args[0] = ctx.evaluateASTNode({ ...firstArgNode, structured: { ...structured, keywords } });
      }
      if ([ '[#all]', '[#headers]' ].some(keyword => keywords.includes(keyword))) {
        // Default header-rows argument to 1 since headers are included.
        args[2] ??= 1;
      }
    }
    else {
      const ref = table.wholeTable({ headers: true, data: true, totals: false, rowIndex: null });
      if (isErr(ref)) {
        args[0] = ref;
        return;
      }
      args[0] = ref.withContext(ctx);
      // Default header-rows argument to 1.
      args[2] ??= 1;
    }
  }
}

/**
 * @param {import('./ast-types').ASTStructuredReferenceNode} ast
 * @param {EvaluationContext} ctx
 */
function structuredToA1Reference (ast, ctx) {
  const { table: tableName } = ast;
  const workbookName = getWorkbookNameFromStructuredReferenceASTNode(ast);
  /** @type {Table | null} */
  let table;
  if (tableName) {
    // Table name was explicitly provided in structured reference (e.g. `=Table[#Data]`)
    table = ctx.resolveTable(tableName, workbookName);
    if (!table) {
      return ERROR_REF.detailed('No such table ' + tableName);
    }
  }
  else {
    // Structured reference without table name (e.g. `=[[#This row], [Column]]`)
    table = ctx.getContextTable?.() || null;
    if (!table) {
      return ERROR_REF.detailed('Structured reference outside of table must specify a table name');
    }
  }
  const structured = ast.structured ?? { keywords: [] };
  // Convert e.g. '[#Data]' to 'data'
  const keywords = (structured.keywords || []).map(kw => kw.slice(2, kw.length - 1).toLowerCase());
  /** @type {number | null} */
  let rowIndex = null;
  if (keywords.includes('this row')) {
    const cellId = ctx.cellId || ctx.cell?.id;
    if (!cellId) {
      return ERROR_VALUE;
    }
    const [ row ] = a1ToRowColumn(cellId);
    rowIndex = row;
  }
  const include = keywords.includes('all')
    ? {
      data: true,
      headers: true,
      totals: true,
      rowIndex: null,
    }
    : {
      data: keywords.length === 0 || keywords.includes('data'),
      headers: keywords.includes('headers'),
      totals: keywords.includes('totals'),
      rowIndex,
    };
  /** @type {Reference | FormulaError} */
  let ref;
  if ('column_range' in structured) {
    const [ from, to ] = structured.column_range;
    ref = table.columnRange(from, to, include);
  }
  else if ('column' in structured) {
    ref = table.column(structured.column, include);
  }
  else {
    ref = table.wholeTable(include);
  }
  if (isErr(ref)) {
    return ref;
  }
  return ref.withContext(ctx);
}

/**
 * @param {ASTLiteralNode} ast
 * @return {CellValue}
 */
export function evaluateLiteralASTNode (ast) {
  if (typeof ast === 'object' && ast && 'error' in ast) {
    return evaluateErrorASTNode(ast);
  }
  return ast;
}

/**
 * @param {{ 'error': string}} ast
 * @returns {FormulaError}
 */
function evaluateErrorASTNode (ast) {
  const err = errorTable[ast.error];
  if (err == null) {
    return ERROR_UNKNOWN.detailed(`Unrecognized error type ${ast.error} in formula`);
  }
  return err;
}

/**
 * Streamlined version of evaluateASTNodeUnbound, for evaluating only static references and returning null otherwise.
 * Ignores workbookName of resolver options, but applies sheetName to unqualified cell addresses.
 * @this {EvaluationContext}
 * @param {ASTNode} ast the AST subtree to evaluate
 * @return {Reference | null | undefined} the static reference that this AST subtree evaluates to, or null if it doesn't
 *   evaluate to a static reference. If a reference is returned, it has `this` as its resolver (`._` property).
 */
export function evaluateStaticReferenceASTNodeUnbound (ast) {
  const ctx = this;
  if (typeof ast !== 'object' || ast == null) {
    return null;
  }
  if ('cell' in ast) {
    return Reference.from(ast.cell, { ctx });
  }
  if ('name' in ast) {
    return Reference.from(ast.name, { ctx });
  }
  if ('colrange' in ast) {
    return Reference.from(ast.colrange.join(':'), { ctx });
  }
  if ('rowrange' in ast) {
    return Reference.from(ast.rowrange.join(':'), { ctx });
  }
  /**
   * @type {FnEvaluateStaticReferenceASTNode}
   */
  const evaluateStaticReferenceASTNode = evaluateStaticReferenceASTNodeUnbound.bind(this);
  if ('prefixed' in ast) {
    const ref = evaluateStaticReferenceASTNode(ast.prefixed);
    if (ref) {
      const prefix = { sheetName: ast.sheet, workbookName: ast.workbook, dir: ast.dir };
      if (ref.name && ast.sheet && !ast.workbook) {
        changeSheetPrefixToWorkbookPrefixIfNoSuchSheet(ast, prefix, this);
      }
      return ref.withPrefix(prefix);
    }
    return ref;
  }
  if ('range' in ast || 'intersection' in ast) {
    // skip union case, not supported for now
    // Note: this is _not_ the typical static handling of range and intersection nodes; they normally get handled in
    // getStaticReferencesFromReferenceOperationNode. The reason they also need handling here merits some explanation.
    // The cases that do land here are the special-case static dependency analysis for HLOOKUP, VLOOKUP and LOOKUP,
    // under getStaticReferencesFromHlookupVlookupCall and getStaticReferencesFromLookupCall. Those call this function
    // directly, instead of getAllStaticReferences, because they need to apply quite different handling to the case of
    // getting a static reference _result_ from the operation, vs. the fallback case of getting conditional static
    // references for the operands themselves when one or both of them cannot be statically evaluated to an address
    // range reference.
    // @ts-expect-error (not splitting this into separate cases just to appease the type checker)
    const args = ast.range ?? ast.intersection;
    const lhs = evaluateStaticReferenceASTNode(args[0]);
    const rhs = evaluateStaticReferenceASTNode(args[1]);
    if (lhs != null && rhs != null) {
      const lhsRange = lhs.resolveToNonName(ctx);
      const rhsRange = rhs.resolveToNonName(ctx);
      if (isRef(lhsRange) && isRef(rhsRange) && !lhsRange.dynamic && !rhsRange.dynamic) {
        return doStaticReferenceOperation(ast, ctx);
      }
      // Couldn't evaluate both operands to static address references, so even if we could operate on them, the
      // resulting reference wouldn't be static. So fall through to returning null.
      //
      // In getStaticReferencesFromReferenceOperationNode, the same situation (concluding that the reference operation
      // cannot be performed statically) is handled by yielding the operands themselves (if they are not dynamic), but
      // marked conditional. See lengthy explanation there. That fallback handling is not appropriate here, as this
      // function is called to get a single static reference if possible, else null.
    }
    // Couldn't evaluate both operands as static references (even name references), so can't operate on them to
    // produce a static reference result. So fall through to returning null.
  }
  if ('structured' in ast) {
    const a1ref = structuredToA1Reference(ast, this);
    const workbookName = getWorkbookNameFromStructuredReferenceASTNode(ast);
    if (isErr(a1ref) && a1ref.code === ERROR_REF.code && workbookName && !this.resolveWorkbook(workbookName)) {
      // Could not convert structured reference to A1 reference because the
      // referenced workbook is not present. Return a name reference in this
      // case, rather than null, so that the referencing cell gets recorded as
      // having an unresolved reference. This is a little hacky --- ideally we
      // should probably represent structured references as their own reference
      // type, and maybe we will. But I'm not changing over to that approach now
      // just to satisfy this one requirement. Hence this workaround.
      invariant(ast.table, 'a structured-reference with a workbook should also have a table');
      const fakeDefinedName = ast.table;
      return new Reference(fakeDefinedName, { workbookName, ctx });
    }
    return isErr(a1ref) ? null : a1ref;
  }
  return null;
}

/**
 * Describe the given AST node or evaluation result value, for error messages.
 * @param {ASTNode | MaybeBoxedFormulaArgument} node
 * @returns {string} a description for human consumption in an error message
 */
function describeNodeOrValue (node) {
  node = unbox(node);
  const basicType = typeof node;
  switch (basicType) {
    case 'string':
    case 'number':
    case 'bigint':
    case 'boolean':
      return `${basicType} ${basicType === 'string' ? `"${node}"` : node}`;
    case 'object':
      if (node == null) {
        return 'null';
      }
      return (
        '{ ' +
        Object.entries(node)
          .map(([ key, value ]) => `${key}: ${typeof value}`)
          .join(', ') +
        ' }'
      );
    default:
      return basicType;
  }
}

/**
 * @param {import('./ast-types').ASTPrefixedStaticReferenceNode} ast
 * @param {{
 *    sheetName: string | undefined;
 *    workbookName: string | undefined;
 *    dir: string | undefined;
 * }} prefix
 * @param {EvaluationContext} ctx
 */
function changeSheetPrefixToWorkbookPrefixIfNoSuchSheet (ast, prefix, ctx) {
  const currentWorkbook = ctx.resolveWorkbook(ctx.workbookName);
  if (!currentWorkbook?.getSheet?.(ast.sheet)) {
    // This is a ref of the form `floo!blar` where `floo` is not a sheet name in the
    // current workbook, so `floo` is taken to be a workbook name, not a sheet name.
    // This is an ambiguity in the grammar which Excel resolves in this conditional
    // way, so we do the same.
    prefix.workbookName = ast.sheet;
    delete prefix.sheetName;
  }
}

/**
 * @param {import('./ast-types').ASTStructuredReferenceNode} ast
 */
function getWorkbookNameFromStructuredReferenceASTNode (ast) {
  // Apiary and Waspiary support the following prefixes for defined
  // names and tables:
  //
  //  - [Workbook]!
  //  - 'Workbook'!
  //  - Workbook!
  //
  // The latter two could be considered Sheet prefixes depending on
  // whether the sheet exists or not, but since we currently don't
  // support sheet-scoped defined names/tables, we consider all of
  // these to be workbook prefixes for now.
  return ast.workbook ?? ast.sheet;
}
