import { TYPE_ALL, TYPE_NONE, TYPE_MISSING, TYPE_ERROR, TYPE_ARRAY, TYPE_RANGE, FLAG_VALUEBOX } from './types';
import Reference from './Reference.js';
import Range from './referenceParser/Range.js';
import Matrix, { isMatrix } from './Matrix.js';
import EvaluationOrderException from '../EvaluationOrderException';
import { ERROR_NAME, ERROR_NA, ERROR_VALUE, ERROR_CALC, MODE_GOOGLE } from './constants.js';
import { INTERSECT } from './functions/operators.js';
import { isErr, isRef } from './functions/utils.js';
import {
  funcSigs,
  operatorSigs,
  SIG_EXCEL_MINARGS,
  SIG_EXCEL_MAXARGS,
  SIG_GOOGLE_MINARGS,
  SIG_GOOGLE_MAXARGS,
  SIG_HEADTYPES,
  SIG_VARTYPES,
  SIG_TAILTYPES,
  SIG_FLAGS,
  SIG_MODE,
} from './signatures.js';
import { now } from '../devutil.js';
import { unbox } from './ValueBox.js';
import { invariant } from '../validation';
import { COERCE_CELLS, describeModeBitfield } from '../mode.js';
import { coerceValue, valueToType } from './coerce';
import { SINGLE } from './functions/array';

/**
 * @template {CellValue} T
 * @typedef { import("./ValueBox").MaybeBoxed<T> } MaybeBoxed<T>
 */

/**
 * If the `EXPECT_DIRTY_REFS` evaluation method is used, then the user takes
 * responsibility for checking that any returned reference is not dirty by
 * calling `checkReferenceNotDirty` before resolving it.
 *
 * ```tsx
 * const value = arg.evaluate(EXPECT_DIRTY_REFS);
 *
 * if (isRef(value)) {
 *   // Throws 'EvaluationOrderException' if the reference is dirty
 *   checkReferenceNotDirty(value);
 *
 *   // This is safe after calling 'checkReferenceNotDirty
 *   const matrix = value.toMatrix();
 * }
 * ```
 */
export const EXPECT_DIRTY_REFS = Symbol('EXPECT_DIRTY_REFS');
export const DISALLOW_DIRTY_REFS = Symbol('DISALLOW_DIRTY_REFS');

/** @typedef {import('./EvaluationContext').SpreadsheetFunction<any[]>} SpreadsheetFunction */

/**
 * Apply a spreadsheet function elementwise to the elements of a spreadsheet array passed
 * as the argument for a parameter that does not accept spreadsheet arrays.
 * @param {EvaluationContext} resolver
 * @param {string} name spreadsheet function name in upper case
 * @param {boolean} isOperator whether or not the function is an operator function (AND, etc.)
 * @param {SpreadsheetFunction} handler the spreadsheet function implementation
 * @param {MaybeBoxedFormulaArgument[]} args
 * @param {SigType[]} parameterTypes
 * @return {Matrix} a matrix of the function results
 */
function mxOps (resolver, name, isOperator, handler, args, parameterTypes) {
  const { annotatedArgs, numCols, numRows, populatedRows, populatedCols } = checkMxOpsArgs(args, parameterTypes);
  const result = new Matrix(numCols, numRows);

  // Loop through result Mx and assign values
  for (let y = 0; y < populatedRows; y += 1) {
    for (let x = 0; x < populatedCols; x += 1) {
      const callArgs = gatherMxCallArgs(annotatedArgs, x, y);
      const value = callMxOpsHandlerAndProcessResult(callArgs, resolver, name, isOperator, handler);
      result.set(x, y, value);
    }
  }

  // Specify the unpopulated areas if resulting matrix is partially populated
  if (result.width > populatedCols || result.height > populatedRows) {
    const defaultValueCallArgs = gatherMxCallArgs(
      annotatedArgs,
      Math.min(result.width - 1, populatedCols),
      Math.min(result.height - 1, populatedRows),
    );
    const defaultValue = callMxOpsHandlerAndProcessResult(defaultValueCallArgs, resolver, name, isOperator, handler);
    result._defaultValue = defaultValue;
  }
  if (result.width > populatedCols) {
    const defaultColumn = Array(populatedRows);
    for (let y = 0; y < populatedRows; y += 1) {
      const defaultColumnCallArgs = gatherMxCallArgs(annotatedArgs, result.populatedWidth, y);
      const defaultColumnValue = callMxOpsHandlerAndProcessResult(
        defaultColumnCallArgs,
        resolver,
        name,
        isOperator,
        handler,
      );
      defaultColumn[y] = defaultColumnValue;
    }
    if (defaultColumn.some(v => v !== result._defaultValue)) {
      result._defaultColumn = defaultColumn;
    }
  }
  if (result.height > populatedRows) {
    const defaultRow = Array(populatedCols);
    for (let x = 0; x < populatedCols; x += 1) {
      const defaultRowCallArgs = gatherMxCallArgs(annotatedArgs, x, result.populatedHeight);
      const defaultRowValue = callMxOpsHandlerAndProcessResult(defaultRowCallArgs, resolver, name, isOperator, handler);
      defaultRow[x] = defaultRowValue;
    }
    if (defaultRow.some(v => v !== result._defaultValue)) {
      result._defaultRow = defaultRow;
    }
  }

  return result;
}

/**
 * @typedef {(
 *   { arg: Matrix, isUnwelcomeMatrix: true } |
 *   { arg: MaybeBoxedFormulaArgument, isUnwelcomeMatrix: false }
 * )} MxOpsArg
 */

/**
 * For each argument, check whether it is a Matrix and the corresponding parameter does not accept matrices.
 * Return a corresponding array of argument values each annotated with the check result, typed so that when the check
 * result is true, the type checker knows the argument to be a Matrix.
 * Also return the maximum height, width, populatedHeight, and populatedWidth, over all such unwelcome matrix arguments.
 * Also coerce each BLANK/MISSING argument value to 0.
 * @param {MaybeBoxedFormulaArgument[]} args
 * @param {number[]} parameterTypes
 */
function checkMxOpsArgs (args, parameterTypes) {
  let numRows = 1;
  let populatedRows = 1;
  let numCols = 1;
  let populatedCols = 1;
  /**
   * @type {MxOpsArg[]} */
  const annotatedArgs = [];
  for (let i = 0; i < args.length; i += 1) {
    const arg = args[i];
    const paramTargetType = parameterTypes[i];
    if (isMatrix(arg) && (paramTargetType & TYPE_ARRAY) === 0) {
      annotatedArgs.push({ arg: arg, isUnwelcomeMatrix: true });
      if (arg.height > numRows) {
        numRows = arg.height;
      }
      if (arg.width > numCols) {
        numCols = arg.width;
      }
      if (arg.populatedHeight > populatedRows) {
        populatedRows = arg.populatedHeight;
      }
      if (arg.populatedWidth > populatedCols) {
        populatedCols = arg.populatedWidth;
      }
    }
    else {
      annotatedArgs.push({ arg, isUnwelcomeMatrix: false });
    }
  }
  return { annotatedArgs, numCols, numRows, populatedRows, populatedCols };
}

/**
 * Gather function call arguments for the element at the specified x/y coordinates.
 * @param {MxOpsArg[]} annotatedArgs
 * @param {number} x
 * @param {number} y
 * @returns {MaybeBoxedFormulaArgument[]}
 */
function gatherMxCallArgs (annotatedArgs, x, y) {
  /** @type {MaybeBoxedFormulaArgument[]} */
  const resultingArgs = [];
  for (const { arg, isUnwelcomeMatrix } of annotatedArgs) {
    if (isUnwelcomeMatrix) {
      resultingArgs.push(arg.getBoxed(x, y));
    }
    else {
      resultingArgs.push(arg);
    }
  }
  return resultingArgs;
}

/**
 * @param {MaybeBoxedFormulaArgument[]} args
 * @param {EvaluationContext} resolver
 * @param {string} name
 * @param {boolean} isOperator
 * @param {SpreadsheetFunction} handler
 * @returns {MaybeBoxed<ArrayValue>}
 */
function callMxOpsHandlerAndProcessResult (args, resolver, name, isOperator, handler) {
  const targetType = TYPE_ALL & ~(TYPE_RANGE | TYPE_MISSING);
  const rawValue = call(resolver, name, isOperator, handler, args);
  let value = coerceValue(ensureUpToDateAndDereference(rawValue, targetType, resolver), targetType, resolver.mode);
  if (isMatrix(value)) {
    value = value.getBoxed(0, 0);
  }
  invariant(!isRef(value) && typeof value !== 'undefined'); // mandated by targetType
  return value;
}

/**
 * @param {FunctionSignature} signature
 * @param {number} argIdx
 * @param {number} numArgs
 */
function getSigType (signature, argIdx, numArgs) {
  const headTypes = signature[SIG_HEADTYPES];
  const varTypes = signature[SIG_VARTYPES];
  const tailTypes = signature[SIG_TAILTYPES];
  if (argIdx < headTypes.length) {
    return headTypes[argIdx];
  }
  if (varTypes.length === 0) {
    return TYPE_NONE;
  }
  if (argIdx < headTypes.length + varTypes.length) {
    return varTypes[argIdx - headTypes.length];
  }
  const numTailTypes = (numArgs - headTypes.length) % varTypes.length;
  if (numTailTypes > tailTypes.length) {
    return TYPE_NONE;
  }
  if (argIdx >= numArgs - numTailTypes) {
    return tailTypes[argIdx - numArgs + numTailTypes];
  }
  return varTypes[(argIdx - headTypes.length) % varTypes.length];
}

/**
 * Dereference `value` if it is a `Reference`, ensuring that all its cells are up-to-date if it is dynamic (so some of
 * them might not be) and not a special case that does not need that.
 * @param {MaybeBoxedFormulaArgument} value the value to map. Can be anything, but if it is not a `Reference`, then this just returns it.
 * @param {number} targetType bit mask of the types acceptable for the parameter this value is to be passed to
 * @param {EvaluationContext} ctx evaluation context
 * @param {string} [funcName] upper-case name of the spreadsheet function to receive this argument
 * @param {number} [i] zero-based index of this argument in the list of arguments
 * @returns {MaybeBoxedFormulaArgument}
 * @throws {EvaluationOrderException} if `Reference` is dynamic,
 */
export function ensureUpToDateAndDereference (value, targetType, ctx, funcName, i) {
  if (!isRef(value)) {
    // Already not a `Reference` so nothing to do here
    return value;
  }

  const skipArg = skipCheckingArgumentForUpToDateness(funcName, i);
  /** @param {Reference} value */
  function maybeCheckUpToDate (value) {
    const checkUpToDate = !skipArg && (value.dynamic || ctx.checkAllReferences);
    if (checkUpToDate && isDirtyRef(value, ctx)) {
      throw new EvaluationOrderException(
        `${funcName} call arg ${i} resolves to cells that are not yet UPTODATE`,
        new Reference(value, { ctx }),
      );
    }
  }

  maybeCheckUpToDate(value);
  if (value.name) {
    // name reference, must dereference to address reference (or whatever else)
    value = value.resolveToNonName(ctx);
    if (!isRef(value)) {
      // something other than a reference, so our job is done
      return value;
    }
    maybeCheckUpToDate(value);
  }
  // at this point `value` is known to be an address Reference
  if ((targetType & TYPE_RANGE) !== 0) {
    // Ranges are allowed, so there's no need to dereference
    return value;
  }
  const range = value.range;
  invariant(range);
  if (range.size > 1) {
    if (ctx.singleCell) {
      return SINGLE.apply(ctx, [ value ]);
    }
    return value.toMatrix();
  }
  return value.resolveSingleBoxed();
}

/**
 * @param {string | undefined} funcName
 * @param {number | undefined} argIndex
 */
function skipCheckingArgumentForUpToDateness (funcName, argIndex) {
  switch (funcName) {
    case 'ROW':
    case 'COLUMN':
    case 'ROWS':
    case 'COLUMNS':
    case 'OFFSET':
      // ROW and ROWS and COLUMN and COLUMNS:
      //    The ROW and COLUMN and ROWS and COLUMNS functions only inspect the
      //    reference itself, not on the values of any cells referenced.
      //
      // OFFSET:
      //    The OFFSET function receives a reference as its first argument.
      //    OFFSET does not read value of the reference. Instead it uses the
      //    reference's position and dimensions to construct a new reference.
      //
      //    Since the original reference will not be evaluated, we skip
      //    checking up-to-dateness to avoid erroneous circular reference
      //    errors.
      return argIndex === 0;
    case 'HLOOKUP':
    case 'VLOOKUP':
    case 'CELL':
      // HLOOKUP and VLOOKUP and CELL check their second arg themselves, as the
      // dependency (if any) is on a subset of that range, and they _must not_
      // require up-to-dateness of any cells outside that range, as that
      // could trigger circular dependencies erroneously.
      return argIndex === 1;
    case 'LOOKUP':
      // the third argument of LOOKUP, as its _actual_ dependency is on
      // a single cell of that range, and it _must not_ require
      // up-to-dateness of any cells outside that range, as that could trigger
      // circular dependencies erroneously.
      return argIndex === 2;
    case 'INTERSECT':
      // Only derives a reference from input references, never looks at values
      return true;
    default:
      return false;
  }
}

/**
 * @param {Reference} reference
 * @param {EvaluationContext} evalContext
 */
function isDirtyRef (reference, evalContext) {
  if (!evalContext.isDirtyFormulaCell) {
    return false;
  }
  const profileEnd = evalContext.profile?.category('isDirtyRef')?.start(evalContext.profile.canonicalize(reference));
  let result = false;
  try {
    reference.visitFormulaCells((formulaCell, stopSignal) => {
      // @ts-expect-error (we ensured above that `evalContext.isDirtyFormulaCell` is there)
      if (evalContext.isDirtyFormulaCell(formulaCell)) {
        // Reference is dynamic, and has been marked dirty and not subsequently updated
        result = true;
        stopSignal.set(); // No need to check more cells
      }
    });
  }
  finally {
    profileEnd?.();
  }
  return result;
}

/**
 * @param {Reference} reference
 * @param {EvaluationContext} options
 * @throws {EvaluationOrderException} if the reference is dirty
 */
export function checkReferenceNotDirty (reference, options) {
  if (isDirtyRef(reference, options)) {
    throw new EvaluationOrderException(`Reference ${reference} resolves to cells that are not yet UPTODATE`, reference);
  }
}

/**
 * Validate that the given number of arguments is acceptable to the given
 * function signature. Return null if so, elsa an error with an appropriate
 * detail message.
 * @param {FunctionSignature} signature
 * @param {number} numArgs
 * @param {ModeBit} mode
 * @returns {FormulaError|null} null if the number of arguments is acceptable according to the
 *   given function signature, else a `#N/A` error with an appropriate detail message.
 */
function validateArgumentCount (signature, numArgs, mode) {
  const minNumArgs = signature[SIG_EXCEL_MINARGS];
  const gMinNumArgs = signature[SIG_GOOGLE_MINARGS];
  const expectedMinArgs = gMinNumArgs !== -1 && mode === MODE_GOOGLE ? gMinNumArgs : minNumArgs;
  if (numArgs < expectedMinArgs) {
    return ERROR_NA.detailed(`Too few arguments. Expected at least ${expectedMinArgs}, got ${numArgs}.`);
  }
  const maxNumArgs = signature[SIG_EXCEL_MAXARGS];
  const gMaxNumArgs = signature[SIG_GOOGLE_MAXARGS];
  const expectedMaxArgs = gMinNumArgs !== -1 && mode === MODE_GOOGLE ? gMaxNumArgs : maxNumArgs;
  if (expectedMaxArgs >= 0 && expectedMaxArgs < numArgs) {
    return ERROR_NA.detailed(`Too many arguments. Expected at most ${expectedMaxArgs}, got ${numArgs}.`);
  }
  return null;
}

/**
 * Some exceptions which occur in function and operator implementations are not
 * fatal, and may occur due to "user error" such as too large input, rather
 * than a bug in our code. Here, we match common cases where an exception is
 * caused by a non-bug, and convert it to a user friendly error value.
 *
 * If we don't convert the exception into a user friendly error value, we
 * simply rethrow it.
 * @param {string} name The name of the function or operator in question
 * @param {unknown} err An exception we've caught in a function or operator implementation
 * @returns {FormulaError} The user friendly error value
 * @throws {unknown} If we did not recognize the exception as being caused by a non-bug
 */
function handleFunctionCallException (name, err) {
  // Firefox uses InternalError when an allocation is too big, for example
  // in the following code:
  // ```
  // let s = 'abc';
  // while (true) s += s;
  // ```
  // Chrome uses RangeError instead. Same goes in case of e.g. infinite recursion.

  if (
    // @ts-expect-error TypeScript doesn't know about InternalError, since it is Firefox specific
    typeof InternalError !== 'undefined' &&
    // @ts-expect-error TypeScript doesn't know about InternalError, since it is Firefox specific
    err instanceof InternalError &&
    /** @type {Error} */ (err).message === 'allocation size overflow'
  ) {
    // A memory allocation error can occur for objects other than strings,
    // but strings are probably the most common case inside of functions.
    return ERROR_VALUE.detailed(`Invalid string length or object size in ${name}`);
  }
  else if (err instanceof RangeError) {
    if (/Invalid ([a-z]+) length/.exec(err.message)) {
      // Example `err.message`: "Invalid string length", "Invalid array length"
      return ERROR_VALUE.detailed(`${err.message} in ${name}`);
    }
    else if (err.message === 'repeat count must be less than infinity and not overflow maximum string size') {
      return ERROR_VALUE.detailed(`Invalid string length in ${name}`);
    }
    else if (err.message === 'Out of memory') {
      return ERROR_VALUE.detailed(`Invalid string length or object size in ${name}`);
    }
  }
  // It's okay to have false negatives in the error message matching we do
  // above (e.g. due to browser differences). In this case the user gets
  // `ERROR_NAME` and we get a notification in Sentry.
  throw err;
}

/**
 * @param {string} name
 * @param {boolean} isOperator
 * @param {ModeBit} mode
 * @returns {FunctionSignature | FormulaError}
 */
function getSignature (name, isOperator, mode) {
  const sigMap = isOperator ? operatorSigs : funcSigs;
  const sigs = sigMap[name];
  // This function is only ever called (via `call`, etc) with function names
  // that exist in `handlers`, and there should never be anything in those
  // lookup tables but not in the signatures lookup.
  invariant(sigs != null);

  let allModes = 0;
  for (const sig of sigs) {
    const sigMode = sig[SIG_MODE];
    if ((sigMode & mode) !== 0) {
      return sig;
    }
    allModes |= sigMode;
  }
  invariant(allModes !== 0);

  return ERROR_NAME.detailed(
    `Unsupported ${isOperator ? 'operator' : 'function'} ${name}. Mode is ` +
      `${describeModeBitfield(mode)} but it's only available in ${describeModeBitfield(allModes)}.`,
  );
}

/**
 * @param {EvaluationContext} options evaluation context
 * @param {string} name spreadsheet function name in upper case
 * @param {boolean} isOperator whether or not the function is an operator function (AND, etc.)
 * @param {SpreadsheetFunction} handler the spreadsheet function implementation
 * @param {MaybeBoxedFormulaArgument[]} args
 * @returns {MaybeBoxedFormulaValue}
 */
export function call (options, name, isOperator, handler, args) {
  const signature = getSignature(name, isOperator, options.mode);
  if (isErr(signature)) {
    return signature;
  }

  // Check arg count and types. Convert references to values, if needed, ensuring up-to-dateness of dynamic references.
  let coercedArgs = prepareFunctionCallArguments(options, name, isOperator, args, signature, handler);
  if (!Array.isArray(coercedArgs)) {
    return coercedArgs;
  }

  if (coercedArgs.length === 0 && (name === 'ROW' || name === 'COLUMN')) {
    if (options.cellId == null) {
      return ERROR_VALUE.detailed('ROW() or COLUMN() without arguments can only be called in the context of a cell');
    }
    coercedArgs = [ Reference.from(options.cellId) ];
  }

  const start = now();
  /**
   * @type {MaybeBoxedFormulaValue}
   */
  let value;
  try {
    value = handler.call(options, ...coercedArgs);
  }
  catch (err) {
    value = handleFunctionCallException(name, err);
  }
  if (options.metricsCallback) {
    options.metricsCallback({
      type: 'call',
      data: {
        isOperator,
        name: name,
        timeTaken: now() - start,
      },
    });
  }
  if (isRef(value)) {
    if (value.ctx == null) {
      value = value.withContext(options);
    }
  }
  else if (isMatrix(value) && value.size === 0) {
    return (options.mode === MODE_GOOGLE ? ERROR_NA : ERROR_CALC).detailed(`Function ${name} returned array of size 0`);
  }
  return value;
}

/**
 * Evaluate a function call to a “lazy-argument function”.
 *
 * A lazy-argument function is one that _requires_ each argument _not_ to be evaluated (in particular, dereferenced)
 * unless and until the function asks for it, which it might not do, depending on the other arguments. This is a hard
 * requirement, not just a speed optimization, because it exists to prevent circular dependency errors from being
 * raised via references that the function does not need in the current recalculation. Such errors would be
 * false-positives that lead to incorrect recalculation results (results different from those that Excel and Google
 * Sheets would produce).
 *
 * This corresponds to `call` but handles arguments differently to serve this requirement of lazy-argument functions.
 *
 * @param {EvaluationContext} opts evaluation context / resolver / options / whatever we're calling this thing
 * @param {string} funcName spreadsheet function name in upper case
 * @param {LazyArgumentFunction} handler lazy-argument spreadsheet function
 * @param {ASTNode[]} args the AST subtrees of each argument in order
 * @return {MaybeBoxedFormulaValue} the function call result
 * @throws {EvaluationOrderException} if a dynamic reference must be dereferenced and not all its cells are up-to-date
 */
export function callLazyArgumentFunction (opts, funcName, handler, args) {
  const signatureOrError = getSignature(funcName, false, opts.mode);
  if (isErr(signatureOrError)) {
    return signatureOrError;
  }
  // Reassignment needed to trick the TypeScript type inferrer to recognize
  // `signature` can't be an error after the if statement above, due to closure
  // capture below.
  const signature = signatureOrError;
  const flags = signature[SIG_FLAGS];

  const argCountError = validateArgumentCount(signature, args.length, opts.mode);
  if (argCountError) {
    return argCountError;
  }

  /**
   * Evaluate, dereference and coerce a single argument of the lazy-argument function.
   * @param {number} argIndex zero-based index of which argument to evaluate and prepare
   * @param {boolean} referencesMightBeDirty true if references may not have been enforced up-to-date
   * @param {boolean} ensureResultUpToDate set to false if it's OK to return a Reference that is not up-to-date
   * @return {MaybeBoxedFormulaArgument}
   * @throws {EvaluationOrderException} if a reference must be dereferenced and not all its cells are up-to-date
   */
  function getSingleArgument (argIndex, referencesMightBeDirty, ensureResultUpToDate) {
    /** @type {MaybeBoxedFormulaArgument} */
    let rawArgument;
    if (referencesMightBeDirty && !opts.checkAllReferences) {
      rawArgument = { ...opts, checkAllReferences: true }.evaluateASTNode(args[argIndex]);
    }
    else {
      rawArgument = opts.evaluateASTNode(args[argIndex]);
    }
    const paramType = getSigType(signature, argIndex, args.length);
    const argsResult = prepareSingleFunctionCallArgument(
      opts,
      funcName,
      false,
      rawArgument,
      paramType,
      flags,
      argIndex,
      ensureResultUpToDate,
    );
    if (!Array.isArray(argsResult)) {
      return argsResult;
    }
    return argsResult[0];
  }

  /** @type {LazyArgument[]} */
  const lazyArgs = args.map((_, i) => {
    return {
      /** @returns {MaybeBoxedFormulaArgument} */
      evaluate: evaluationMethod => {
        return evaluationMethod === EXPECT_DIRTY_REFS
          ? getSingleArgument(i, true, false)
          : getSingleArgument(i, false, true);
      },
    };
  });

  return handler.call(opts, ...lazyArgs);
}

/**
 * @typedef {MaybeBoxedFormulaArgument[] | Matrix | MaybeBoxed<CellValue>} ArgumentsResult
 *   Result of transforming some list of arguments.
 *   Call the original list `args`; then this can be one of:
 *   * `args` itself if all arguments were of types permitted by the corresponding `parameterTypes`
 *   * a new array, same length as `args`, if some arguments needed coercing to a different type
 *   * `FormulaError` if one of `args` is or resolves to an error and the corresponding function parameter type
 *     does not include `TYPE_ERROR`
 *   * `Matrix` or `CellValue` if one of `args` is or resolves to a `Matrix` and
 *     the corresponding function parameter type does not include `TYPE_ARRAY`
 */

/**
 * Dereference and coerce function call arguments to match parameter types. This includes:
 * * check argument count against the function signature
 * * when an argument is a `Reference` and the corresponding parameter does not accept references, dereference it.
 *   If it is dynamic, ensure that all cells in it are up-to-date and throw `EvaluationOrderException` otherwise.
 * * when an argument is of a type that the corresponding parameter does not accept, coerce it to that type using
 *   `coerceValue`.
 *
 * Coerce the given arguments to the corresponding spreadsheet function parameter types, and apply
 * the spreadsheet function elementwise to any array arguments
 * @param {EvaluationContext} options evaluation context
 * @param {string} name function name in upper case
 * @param {boolean} isOperator whether or not the function is an operator function (AND, etc.)
 * @param {MaybeBoxedFormulaArgument[]} args the arguments to coerce
 * @param {FunctionSignature} signature the signature of the function being called
 * @param {SpreadsheetFunction | null} [handler=null] function to apply elementwise over array arguments for parameters
 *   that do not accept arrays. Omit this for lazy-argument functions, for which we do not support this yet.
 * @return {ArgumentsResult} Array of prepared arguments, or Matrix if function was already evaluated
 *   elementwise, or FormulaError if some arguments could not be coerced as needed.
 * @throws {EvaluationOrderException} if a dynamic reference must be dereferenced and not all its cells are up-to-date
 */
export function prepareFunctionCallArguments (options, name, isOperator, args, signature, handler = null) {
  const argCountError = validateArgumentCount(signature, args.length, options.mode);
  if (argCountError) {
    return argCountError;
  }
  const parameterTypes = args.map((_, i) => getSigType(signature, i, args.length));
  const flags = signature[SIG_FLAGS];
  args = args.map((arg, i) => ensureUpToDateAndDereference(arg, parameterTypes[i], options, name, i));
  return coerceArgs(options, name, isOperator, args, parameterTypes, flags, handler);
}

/**
 * Dereference and coerce a single function call argument to match its parameter type. This corresponds to
 * `prepareFunctionCallArguments` but for the lazy-argument case of a single argument.
 * @param {EvaluationContext} options evaluation context / resolver / options / whatever we're calling this
 * @param {string} name function name in upper case
 * @param {boolean} isOperator whether or not the function is an operator function (AND, etc.)
 * @param {MaybeBoxedFormulaArgument} arg the argument to coerce
 * @param {number} parameterType type of the corresponding parameter, a bitmask of the types that the spreadsheet
function accepts for that parameter
 * @param {number} flags
 * @param {number} argIndex the zero-based index of this argument among the function's arguments.
 * @return {ArgumentsResult} Array of prepared arguments, or Matrix if function was already evaluated
elementwise, or FormulaError if some arguments could not be coerced as needed.
 * @throws {EvaluationOrderException} if a dynamic reference must be dereferenced and not all its cells are up-to-date
 */
export function prepareSingleFunctionCallArgument (
  options,
  name,
  isOperator,
  arg,
  parameterType,
  flags,
  argIndex,
  ensureResultUpToDate = true,
) {
  if (isRef(arg) && ensureResultUpToDate) {
    arg = ensureUpToDateAndDereference(arg, parameterType, options, name, argIndex);
  }
  return coerceArgs(options, name, isOperator, [ arg ], [ parameterType ], flags);
}

/**
 * Coerce the given arguments to the corresponding spreadsheet function parameter types, and apply
 * the spreadsheet function elementwise to any array arguments
 * @param {EvaluationContext} options evaluation context
 * @param {string} name function name in upper case
 * @param {boolean} isOperator whether or not the function is an operator function (AND, etc.)
 * @param {MaybeBoxedFormulaArgument[]} args the arguments to coerce
 * @param {SigType[]} parameterTypes types of the corresponding parameters, same length as `args`.
 *   Each element is a bitmask of the types that the spreadsheet function accepts for that parameter
 * @param {number} flags bitmask of function signature flags FLAG_VALUEBOX and FLAG_IS_GOOGLE_ARRAY_FUNCTION
 * @param {SpreadsheetFunction | null} [handler=null] function to apply elementwise over array arguments for parameters
 *   that do not accept arrays. Omit this for lazy-argument functions, for which we do not support this.
 * @return {ArgumentsResult} Array of prepared arguments, or Matrix if function was already evaluated
 *   elementwise, or FormulaError if some arguments could not be coerced as needed.
 * @throws {EvaluationOrderException} if a dynamic reference must be dereferenced and not all its cells are up-to-date
 */
function coerceArgs (options, name, isOperator, args, parameterTypes, flags, handler = null) {
  if ((flags & FLAG_VALUEBOX) === 0) {
    args = args.map(unbox);
  }

  /**
   * @type SigType
   */
  const misfitTypes = args.reduce(
    /** @param {number} acc */ (acc, arg, i) => {
      const argType = valueToType(arg);
      const sigType = parameterTypes[i];
      if (sigType === 0) {
        // FIXME: When the number of arguments is validated such that the branches
        // which return a zero in `getSigType` are unreachable, this should be
        // removed.
        return acc;
      }
      if ((argType & sigType) === 0) {
        acc |= argType;
      }
      return acc;
    },
    TYPE_NONE,
  );

  if (misfitTypes !== TYPE_NONE) {
    if ((misfitTypes & TYPE_ARRAY) !== 0 && handler != null && !options.singleCell) {
      // An array was given as the argument for a parameter which does not
      // accept arrays. Apply mxOps and early-return the result.
      return mxOps(options, name, isOperator, handler, args, parameterTypes);
    }
    const coercedArgs = [];
    for (let i = 0; i < args.length; i += 1) {
      let arg = args[i];
      const argType = valueToType(arg);
      if ((misfitTypes & argType) !== 0) {
        const targetType = parameterTypes[i];
        if (options.singleCell && isMatrix(arg)) {
          arg = arg.getBoxed(0, 0);
        }
        arg = coerceValue(arg, targetType, options.mode);
        if (isErr(arg) && (targetType & TYPE_ERROR) === 0) {
          // We have an error in our hands, but the function doesn't accept errors.
          return arg;
        }
      }
      coercedArgs[i] = arg;
    }
    return coercedArgs;
  }
  return args;
}

/**
 * @param {[ASTNode, ASTNode]} args
 * @param {EvaluationContext} opts
 * @param {FnEvaluateASTNode} [evaluateASTNode] alternative AST node evaluator,
 *   overriding the one in ctx (for use by static reference analysis)
 */
export function doRangeOperation (args, opts, evaluateASTNode) {
  return doReferenceBinaryOperation(args, opts, boundingBox, 'range', evaluateASTNode);
}

/**
 * @param {[ASTNode, ASTNode]} args
 * @param {EvaluationContext} ctx
 * @param {FnEvaluateASTNode} [evaluateASTNode] alternative AST node evaluator,
 *   overriding the one in ctx (for use by static reference analysis)
 */
export function doIntersectionOperation (args, ctx, evaluateASTNode) {
  const intersectionFn =
    /**
     * @param {Reference} lhs
     * @param {Reference} rhs
     */
    (lhs, rhs) => /** @type {Reference} */ (call(ctx, 'INTERSECT', true, INTERSECT, [ lhs, rhs ]));
  return doReferenceBinaryOperation(args, ctx, intersectionFn, 'intersection', evaluateASTNode);
}

/**
 * @param {[ASTNode, ASTNode]} args
 * @param {EvaluationContext} ctx
 * @param {(lhs: Reference, rhs: Reference) => Reference} opFunction
 * @param {string} opName
 * @param {FnEvaluateASTNode} [evaluateASTNode] alternative AST node evaluator,
 *   overriding the one in ctx (for use by static reference analysis)

 */
export function doReferenceBinaryOperation ([ lhsNode, rhsNode ], ctx, opFunction, opName, evaluateASTNode) {
  let lhs = evaluateASTNode ? evaluateASTNode(lhsNode) : ctx.evaluateASTNode(lhsNode);
  if (isRef(lhs) && !lhs.isAddress) {
    lhs = lhs.resolveToNonName(ctx);
  }
  if (isErr(lhs)) {
    return lhs;
  }
  if (!isRef(lhs)) {
    return ERROR_VALUE.detailed(`Cannot evaluate ${opName} because left-hand-side is ${typeof lhs}`);
  }
  let rhs = evaluateASTNode ? evaluateASTNode(rhsNode) : ctx.evaluateASTNode(rhsNode);
  if (isRef(rhs) && !rhs.isAddress) {
    rhs = rhs.resolveToNonName(ctx);
  }
  if (isErr(rhs)) {
    return rhs;
  }
  if (!isRef(rhs)) {
    return ERROR_VALUE.detailed(`Cannot evaluate ${opName} because right-hand-side is ${typeof rhs}`);
  }
  return opFunction(lhs, rhs);
}

/**
 * Find the bounding box of two range references: a `Reference` with a range that covers both of them,
 * and with the workbook and sheet name of the first range reference.
 * (The second reference is assumed to be in the same sheet, without checking.)
 * @param {Reference} first first range reference.
 * @param {Reference} second second range reference.
 * @returns {Reference} a new reference that covers both `first` and `second`
 */
export function boundingBox (first, second) {
  let $source;
  if (first.$source && second.$source) {
    $source = `${first.$source}:${second.$source}`;
  }
  return new Reference(
    new Range({
      top: Math.min(first.range.top, second.range.top),
      left: Math.min(first.range.left, second.range.left),
      bottom: Math.max(first.range.bottom, second.range.bottom),
      right: Math.max(first.range.right, second.range.right),
    }),
    {
      ...first,
      $source,
      dynamic: first.dynamic || second.dynamic,
    },
  );
}

/** @type {Pick<EvaluationContext, 'rawOutput' | 'coerceNullToZero' | 'resolveWorkbook'>} */
export const baseRunOptions = {
  rawOutput: false,
  coerceNullToZero: COERCE_CELLS,
  // eslint-disable-next-line no-undefined
  resolveWorkbook: () => undefined,
};
