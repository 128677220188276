import { DependencyGraph, Edge, KnownVertexId, RangeVertexId } from './DependencyGraph';
import type Model from './Model';
import type Cell from './excel/Cell';

const CLOCK_SKEW_BUFFER_MS = 60 * 1000; // 60 seconds

export function findVertexIdsNeedingInitRecalc (
  model: Model,
  alwaysRecalculateExternalReferencesCutoffMs: number = -Infinity,
) {
  const depGraph = model._graph;
  const workbookKeyToUpdatedAt = new Map<number, number>();

  for (const workbook of model._workbooks) {
    if (workbook.update_time) {
      workbookKeyToUpdatedAt.set(workbook.keyInDepGraph, new Date(workbook.update_time).getTime());
    }
  }

  const out: KnownVertexId[] = [];

  // Non-native workbooks cannot contain outgoing references
  const nativeWorkbooks = model.getWorkbooks().filter(workbook => workbook.type === 'native');

  for (const workbook of nativeWorkbooks) {
    // `-Infinity` causes us to recalculate by default if an update_time is missing.
    const fromUpdatedAt = workbookKeyToUpdatedAt.get(workbook.keyInDepGraph) ?? -Infinity;
    const vertices = depGraph._getVertices(workbook.keyInDepGraph) || [];
    for (const vertex of vertices) {
      if (
        shouldRecalculateVertexId(
          vertex.id,
          depGraph,
          fromUpdatedAt,
          workbookKeyToUpdatedAt,
          alwaysRecalculateExternalReferencesCutoffMs,
        )
      ) {
        out.push(vertex.id);
      }
    }
  }

  return out;
}

function shouldRecalculateVertexId (
  fromVertexId: KnownVertexId,
  depGraph: DependencyGraph<Cell>,
  fromUpdatedAt: number,
  workbookKeyToUpdatedAt: Map<number, number>,
  alwaysRecalculateExternalReferencesCutoffMs: number,
) {
  if (fromVertexId instanceof RangeVertexId) {
    return false;
  }

  const edgePointsToExternalDataThatMayBeNew = (edge: Edge<Cell>): boolean => {
    const toVertexId = edge.to.id;
    if (toVertexId.workbookKey === fromVertexId.workbookKey) {
      return false;
    }
    if (fromUpdatedAt < alwaysRecalculateExternalReferencesCutoffMs) {
      return true;
    }
    const toUpdatedAt = workbookKeyToUpdatedAt.get(toVertexId.workbookKey);
    if (toUpdatedAt == null || !Number.isFinite(toUpdatedAt)) {
      // No (or invalid) update_time, recalculate by-default (err
      // on the side of caution).
      return true;
    }
    if (toUpdatedAt + CLOCK_SKEW_BUFFER_MS < fromUpdatedAt) {
      // The "from" workbook is more recent. Do not recalculate.
      return false;
    }
    // The "to" workbook is more recent. Recalculate.
    return true;
  };

  let result = false;
  depGraph.visitOutgoingEdges(fromVertexId, (edge, stopSignal) => {
    if (edgePointsToExternalDataThatMayBeNew(edge)) {
      result = true;
      stopSignal.set();
    }
  });
  return result;
}
