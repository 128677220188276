const DEFAULT_FLAGS = Object.freeze({
  spilling: false,
});

// Exported only for tests
export class Flags {
  private _flags: { spilling: false };
  /**
   * @param [flags] Initial feature flag state. Object with
   *   string keys and boolean values, or a preexisting `Flags` instance.
   */
  constructor (flags?: Record<string, boolean> | Flags) {
    this._flags = { ...DEFAULT_FLAGS, ...flags };
    this.can = this.can.bind(this);
  }

  /**
   * Reset feature flags to defaults
   */
  reset () {
    this._flags = { ...DEFAULT_FLAGS };
  }

  /**
   * Check if a feature flag is enabled
   * @param flagName Name of the feature flag to check
   * @param ifNotExists Default value to use if the flag has
   *   neither been set to true nor false.
   * @returns Whether the given feature flag is enabled
   */
  can (flagName: string, ifNotExists: boolean | null = false): boolean {
    return this._flags[flagName] ?? ifNotExists;
  }

  /**
   * Enable or disable the given feature flag
   * @param flagName Name of the feature flag whose value to set
   * @param isOn Whether the feature flag should be enabled or disabled
   */
  set (flagName: string, isOn: boolean) {
    this._flags[flagName] = !!isOn;
  }
}

export const flags = new Flags();
