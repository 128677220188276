import { strCompare } from './operators.js';

// glob pattern matcher with built in sorting
//
// special characters:
//    ? = matches any one character
//    * = matches any length of any character
//    ~? = matches "?" [escape syntax for ?]
//    ~* = matches "*" [escape syntax for *]
//
// returns:
//   -1    if pattern is to be sorted before the text alphabetically
//   -0.5  if pattern matches text AND is to be before after the text alphabetically
//    0    if pattern matches text
//    0.5  if pattern matches text AND is to be sorted after the text alphabetically
//    1    if pattern is to be sorted after the text alphabetically

function star (pattern: string, text: string, globbed = false, textIndex = 0, patternIndex = 0): number {
  // shortcut: if this is the end of the pattern then it will always match!
  if (!pattern) {
    if (textIndex === patternIndex && text === '*') {
      return 0;
    }
    return text.length ? -0.5 : 0.5;
  }

  let offs = 0;
  do {
    const m = submatch(pattern, text.slice(offs), globbed, textIndex + 1, patternIndex + 1);
    if (~~m === 0) {
      if (text.slice(0, offs) === '*') {
        return 0;
      }
      return -0.5;
    }
  } while (offs++ < text.length);
  return globbed ? -1 : 1;
}

function submatch (pattern: string, text: string, globbed = false, textIndex = 0, patternIndex = 0): number {
  if (!pattern) {
    return text ? -1 : 0;
  }

  const currGlobChar = pattern.charAt(0);
  const currTextChar = text.charAt(0);

  // any N chars
  if (currGlobChar === '*') {
    return star(pattern.slice(1), text, true, textIndex + 1, patternIndex + 1);
  }

  // any next char
  if (currGlobChar === '?') {
    if (text.length) {
      // move to next char
      return submatch(pattern.slice(1), text.slice(1), true, textIndex + 1, patternIndex + 1);
    }
    else {
      return -1;
    }
  }

  // escaped ~
  if (currGlobChar === '~') {
    const nextGlobChar = pattern.charAt(1);
    if (nextGlobChar === '?') {
      return currTextChar === '?'
        ? submatch(pattern.slice(2), text.slice(1), globbed, textIndex + 1, patternIndex + 1)
        : strCompare(nextGlobChar, currTextChar);
    }
    if (nextGlobChar === '*') {
      return currTextChar === '*'
        ? submatch(pattern.slice(2), text.slice(1), globbed, textIndex + 1, patternIndex + 1)
        : strCompare(nextGlobChar, currTextChar);
    }
  }

  const _cc = text.length ? strCompare(currGlobChar, currTextChar) : 1;
  if (text.length && !_cc) {
    // move to next char
    return submatch(pattern.slice(1), text.slice(1), globbed, textIndex + 1, patternIndex + 1);
  }

  // chars did not match
  if (currGlobChar && currTextChar) {
    return globbed ? -1 : _cc;
  }

  // out of text but still have pattern
  return globbed ? -1 : 1;
}

export function match (pattern: string, text: string): number {
  return submatch(pattern.toLowerCase(), text.toLowerCase());
}
