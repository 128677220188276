import { sortCollate } from './utils-seek';

/**
 * @param {(idx: number) => MaybeBoxed<ArrayValue>} valueGetter
 * @param {boolean} isAscending
 * @returns {(a: number, b: number) => number}
 */
function createSortingCollator (valueGetter, isAscending) {
  return (a, b) => {
    const aVal = valueGetter(a);
    const bVal = valueGetter(b);
    return sortCollate(aVal, bVal, isAscending);
  };
}

/**
 * Given lists of values, return a permutation which sorts the list when
 * applied.
 * @param {((idx: number) => MaybeBoxed<ArrayValue>)[]} orderBys
 * @param {boolean[]} sortOrders
 * @param {number} length
 * @returns {number[]}
 */
export function calcSortingPermutation (orderBys, sortOrders, length) {
  const collators = orderBys.map((orderBy, i) => createSortingCollator(orderBy, sortOrders[i]));
  const permutation = [ ...Array(length).keys() ];
  permutation.sort((a, b) => {
    for (const collator of collators) {
      const cmp = collator(a, b);
      if (cmp !== 0) {
        return cmp;
      }
    }
    return 0;
  });
  return permutation;
}
