import RTree, { type BBox } from './algorithm/rtree';
import { invariant } from './validation';
import Range from './excel/referenceParser/Range.js';
import { DefaultMap } from '@grid-is/collections';
import { type CallbackWithStop, Signal } from './Signal';
import { ASTPath } from './excel/ast-types';

export class VertexId {
  workbookKey: number;
  key: string;

  /**
   * @param workbookKey The dependency graph key of the workbook
   * @param key The key of this vertex ID (which includes the workbook key)
   */
  constructor (workbookKey: number, key: string) {
    this.workbookKey = workbookKey;
    this.key = key;
  }

  static fromKey (key: string): KnownVertexId {
    const vertexId = vertexIdFromKey(key);
    if (vertexId == null) {
      throw new Error(`Invalid vertex ID key "${key}"`);
    }
    return vertexId;
  }

  toString (): string {
    return this.key;
  }

  toRange (): Range | null {
    return null;
  }

  withSheetIndex (newSheetIndex: number) {
    return VertexId.fromKey(this.key.replace(/,\d+!/, `,${newSheetIndex}!`));
  }
}

export class CellVertexId extends VertexId {
  sheetIndex: number;
  rowIndex: number;
  colIndex: number;

  /**
   * @param workbookKey The dependency graph key of the workbook
   * @param sheetIndex Zero-based sheet index
   * @param rowIndex Zero-based row index
   * @param colIndex Zero-based column index
   */
  constructor (workbookKey: number, sheetIndex: number, rowIndex: number, colIndex: number) {
    super(workbookKey, cellVertexIdKey(workbookKey, sheetIndex, rowIndex, colIndex));
    this.sheetIndex = sheetIndex;
    this.rowIndex = rowIndex;
    this.colIndex = colIndex;
  }

  overlaps (otherId: KnownVertexId): boolean {
    if (otherId instanceof CellVertexId) {
      return otherId.key === this.key;
    }
    if ('overlaps' in otherId) {
      return otherId.overlaps(this);
    }
    return false;
  }

  toRange (): Range {
    return new Range({ top: this.rowIndex, left: this.colIndex });
  }

  topLeft (): CellVertexId {
    return this;
  }

  is1x1 (): boolean {
    return true;
  }

  withCoordinates (x: number, y: number): CellVertexId {
    return new CellVertexId(this.workbookKey, this.sheetIndex, y, x);
  }
}

/**
 * @param workbookKey The dependency graph key of the workbook
 * @param sheetIndex zero-based sheet index
 * @param rowIndex zero-based row index
 * @param colIndex zero-based column index
 */
export function cellVertexIdKey (workbookKey: number, sheetIndex: number, rowIndex: number, colIndex: number) {
  return `c${workbookKey},${sheetIndex}!${rowIndex},${colIndex}`;
}

export class NameVertexId extends VertexId {
  sheetIndex: number | null;
  name: string;

  /**
   * @param workbookKey The dependency graph key of the workbook
   * @param sheetIndex Zero-based sheet index (if name is sheet-scoped, else null)
   * @param name The defined name
   */
  constructor (workbookKey: number, sheetIndex: number | null, name: string) {
    super(workbookKey, nameVertexIdKey(workbookKey, sheetIndex, name));
    this.sheetIndex = sheetIndex;
    this.name = name;
  }

  overlaps (otherId: KnownVertexId): boolean {
    return otherId.key === this.key;
  }
}

/**
 * @param workbookKey The dependency graph key of the workbook
 * @param sheetIndex zero-based sheet index
 * @param name non-empty name, will be lower-cased
 */
export function nameVertexIdKey (workbookKey: number, sheetIndex: number | null, name: string) {
  // `g` for global
  const sheetIndexKeyPart = sheetIndex ?? 'g';
  return `n${workbookKey},${sheetIndexKeyPart}!${name.toLowerCase()}`;
}

export class RangeVertexId extends VertexId {
  sheetIndex: number;
  top: number;
  left: number;
  bottom: number;
  right: number;

  /**
   * @param workbookKey The dependency graph key of the workbook
   * @param sheetIndex Zero-based sheet index
   * @param top Zero-based row index
   * @param left Zero-based column index
   * @param bottom Zero-based row index
   * @param right Zero-based column index
   */
  constructor (workbookKey: number, sheetIndex: number, top: number, left: number, bottom: number, right: number) {
    super(workbookKey, `r${workbookKey},${sheetIndex}!${top},${left},${bottom},${right}`);
    invariant(top <= bottom && left <= right, 'Inverted range');
    this.sheetIndex = sheetIndex;
    this.top = top;
    this.left = left;
    this.bottom = bottom;
    this.right = right;
  }

  toRange (): Range {
    return new Range(this);
  }

  topLeft (): CellVertexId {
    return new CellVertexId(this.workbookKey, this.sheetIndex, this.top, this.left);
  }

  /**
   * @param callback function to call with each cell vertex ID
   * @param [bounds] the maximum 0-based x and y coordinates to visit. If not
   *   specified, then all coordinates of this range vertex ID are visited.
   */
  visitCells (callback: CallbackWithStop<CellVertexId>, bounds: { right: number, bottom: number } = this) {
    const bottom = Math.min(this.bottom, bounds.bottom);
    const right = Math.min(this.right, bounds.right);
    const stopSignal = new Signal();
    for (let row = this.top; row <= bottom; row++) {
      for (let col = this.left; col <= right; col++) {
        callback(new CellVertexId(this.workbookKey, this.sheetIndex, row, col), stopSignal);
        if (stopSignal.isSet) {
          return;
        }
      }
    }
  }

  contains (cellId: CellVertexId): boolean {
    invariant(cellId instanceof CellVertexId);
    return (
      this.workbookKey === cellId.workbookKey &&
      this.sheetIndex === cellId.sheetIndex &&
      this.top <= cellId.rowIndex &&
      cellId.rowIndex <= this.bottom &&
      this.left <= cellId.colIndex &&
      cellId.colIndex <= this.right
    );
  }

  overlaps (otherId: KnownVertexId): boolean {
    if (otherId instanceof CellVertexId) {
      return this.contains(otherId);
    }
    if (otherId instanceof RangeVertexId) {
      return (
        this.workbookKey === otherId.workbookKey &&
        this.sheetIndex === otherId.sheetIndex &&
        this.toRange().intersects(otherId.toRange())
      );
    }
    return false;
  }

  is1x1 (): boolean {
    return this.right === this.left && this.bottom === this.top;
  }
}

export type EdgeKind = 'regular' | 'conditional' | 'nonvalue';

export type KnownVertexId = CellVertexId | NameVertexId | RangeVertexId;

export class Vertex<
  TData,
  TId extends KnownVertexId = KnownVertexId,
  TFromId extends KnownVertexId = CellVertexId | NameVertexId,
> {
  id: TId;
  incomingEdges: Edge<TData, TFromId>[];
  outgoingEdges: Edge<TData, TFromId>[];
  data: TData | null;

  constructor (vertexId: TId, data?: TData | null) {
    this.id = vertexId;
    this.incomingEdges = [];
    this.outgoingEdges = [];
    this.data = data ?? null;
  }

  addIncomingEdge (edge: Edge<TData, TFromId>) {
    this.incomingEdges.push(edge);
  }

  addOutgoingEdge (edge: Edge<TData, TFromId>) {
    this.outgoingEdges.push(edge);
  }

  removeIncomingEdge (edge: Edge<TData, TFromId>) {
    const index = this.incomingEdges.indexOf(edge);
    invariant(index !== -1);
    this.incomingEdges.splice(index, 1);
  }

  removeOutgoingEdge (edge: Edge<TData, TFromId>) {
    const index = this.outgoingEdges.indexOf(edge);
    invariant(index !== -1);
    this.outgoingEdges.splice(index, 1);
  }

  get inDegree (): number {
    return this.incomingEdges.length;
  }

  get outDegree (): number {
    return this.outgoingEdges.length;
  }
}

type VertexRTreeNode<TData, TFromId extends KnownVertexId> = BBox & { vertex: Vertex<TData, RangeVertexId, TFromId> };

export class VertexCollection<TData, TFromId extends KnownVertexId = KnownVertexId> {
  /**
   * Each map entry has `key` equal to `value.id.key`. Since `VertexId` keys are
   * distinct between different `VertexId` subclasses, looking up a `Vertex` by
   * a `vertexId.key` is thus guaranteed to return a `Vertex<TData, typeof vertexId>`
   * (or else `undefined`).
   */
  _vertices = new Map<string, Vertex<TData, KnownVertexId, TFromId>>();
  /**
   * Keyed by sheetIndex
   */
  _rangeTrees = new Map<number, RTree<VertexRTreeNode<TData, TFromId>>>();

  [Symbol.iterator] () {
    return this._vertices.values();
  }

  has (vertexId: VertexId): boolean {
    invariant(vertexId instanceof VertexId, `Expected VertexId but got ${typeof vertexId}`);
    return this._vertices.has(vertexId.key);
  }

  get<TId extends KnownVertexId> (vertexId: TId): Vertex<TData, TId> | undefined {
    invariant(vertexId instanceof VertexId, `Expected VertexId but got ${typeof vertexId}`);
    // keys of VertexId types are distinct, so if key is found in the map, the value is of the correct type
    return this._vertices.get(vertexId.key) as Vertex<TData, TId> | undefined;
  }

  getOrCreate<TId extends KnownVertexId> (vertexId: TId, data: TData | null): Vertex<TData, TId> {
    const existingVertex = this._vertices.get(vertexId.key);
    if (existingVertex != null) {
      if (!existingVertex.data && data) {
        existingVertex.data = data;
      }
      // keys of VertexId types are distinct, so if key is found in the map, the value is of the correct type
      return existingVertex as Vertex<TData, TId, TFromId>;
    }
    const vertex = new Vertex<TData, TId>(vertexId, data);
    this._vertices.set(vertexId.key, vertex);
    if (vertex.id instanceof RangeVertexId) {
      this._addRangeToTree(vertex as Vertex<TData, RangeVertexId>);
    }
    return vertex;
  }

  delete (vertexId: KnownVertexId) {
    this._vertices.delete(vertexId.key);
    if (vertexId instanceof RangeVertexId) {
      this._removeRangeFromTree(vertexId);
    }
  }

  _addRangeToTree (vertex: Vertex<TData, RangeVertexId>) {
    const tree = this._getOrCreateRangeTree(vertex.id.sheetIndex);
    const rangeNode = {
      minX: vertex.id.left,
      minY: vertex.id.top,
      maxX: vertex.id.right,
      maxY: vertex.id.bottom,
      vertex: vertex as Vertex<TData, RangeVertexId>,
    };
    tree.insert(rangeNode);
  }

  _removeRangeFromTree (vertexId: RangeVertexId) {
    const tree = this._rangeTrees.get(vertexId.sheetIndex);
    if (tree) {
      const { left: minX, top: minY, right: maxX, bottom: maxY } = vertexId;
      const node = tree.find({ minX, minY, maxX, maxY });
      if (node) {
        tree.remove(node);
      }
    }
  }

  _getOrCreateRangeTree (sheetIndex: number): RTree<VertexRTreeNode<TData, TFromId>> {
    let tree = this._rangeTrees.get(sheetIndex);
    if (tree == null) {
      tree = new RTree();
      this._rangeTrees.set(sheetIndex, tree);
    }
    return tree;
  }

  visitInEdgesOfRangesCovering (cell: CellVertexId, callback: CallbackWithStop<Edge<TData>>) {
    const bbox = { minX: cell.colIndex, minY: cell.rowIndex, maxX: cell.colIndex, maxY: cell.rowIndex };
    this._visitInEdgesOfRangeVertices(cell.sheetIndex, bbox, callback);
  }

  visitInEdgesOfRangesIntersecting (range: RangeVertexId, callback: CallbackWithStop<Edge<TData>>) {
    const bbox = { minX: range.left, minY: range.top, maxX: range.right, maxY: range.bottom };
    this._visitInEdgesOfRangeVertices(range.sheetIndex, bbox, callback);
  }

  _visitInEdgesOfRangeVertices (sheetIndex: number, bbox: BBox, callback: CallbackWithStop<Edge<TData>>) {
    const tree = this._rangeTrees.get(sheetIndex);
    if (tree) {
      tree.visitItemsIntersecting(bbox, (node, stopSignal) => {
        for (const edge of node.vertex.incomingEdges) {
          callback(edge, stopSignal);
          if (stopSignal.isSet) {
            return;
          }
        }
      });
    }
  }

  get size (): number {
    return this._vertices.size;
  }
}

export class Edge<TData, TFromId extends KnownVertexId = CellVertexId | NameVertexId> {
  from: Vertex<TData, TFromId, TFromId>;
  to: Vertex<TData>;
  kind: EdgeKind;

  constructor (from: Vertex<TData, TFromId>, to: Vertex<TData>, kind: EdgeKind) {
    this.from = from;
    this.to = to;
    this.kind = kind;
  }
}

export class DependencyGraph<TData, TFromId extends KnownVertexId = CellVertexId | NameVertexId> {
  _verticesByWorkbook = new Map<number, VertexCollection<TData, TFromId>>();
  /**
   * Maximum X and Y coordinates of any cell to-vertex of an edge in each sheet.
   * Keys are of the form `${workbookKey},${sheetIndex}`. This is used to
   * optimize searches for to-cell edges over a range: we will only search as
   * far as the furthest cell coordinates that may have an edge targeting them.
   */
  _sheetCellBounds = new Map<string, { right: number, bottom: number }>();
  /**
   * Vertex IDs for formula cells / defined names in which a reference failed
   * to resolve because the named workbook or named sheet was not present.
   * These are retried when a a workbook is added or updated in the graph.
   * Keys are workbook keys for workbook names specified in references, null
   * for references that do not specify a workbook; values are sets of vertex
   * ID keys of the cells/defined-names containing the referencing formulas.
   */
  vertexIDsWithUnresolvedReferences = new DefaultMap<number | null, Set<string>>(() => new Set());

  edgeToASTPaths = new DefaultMap<Edge<TData>, ASTPath[]>(() => []);

  volatiles: (CellVertexId | NameVertexId)[] = [];

  _getVertices (workbookKey: number) {
    return this._verticesByWorkbook.get(workbookKey);
  }

  _getOrCreateVertices (workbookKey: number) {
    let vertices = this._verticesByWorkbook.get(workbookKey);
    if (!vertices) {
      vertices = new VertexCollection();
      this._verticesByWorkbook.set(workbookKey, vertices);
    }
    return vertices;
  }

  visitRangeVertices (cb: (vertex: Vertex<TData, RangeVertexId>) => void) {
    for (const vertices of this._verticesByWorkbook.values()) {
      // Since we only want range vertices, it is likely quicker to go through
      // range trees than iterate all vertices
      for (const rangeTree of vertices._rangeTrees.values()) {
        rangeTree.visitAll(rtreeNode => {
          cb(rtreeNode.vertex);
        });
      }
    }
  }

  /**
   * Record that the given vertex ID is volatile (has some dependencies beyond
   * those represented in this graph, so is not certain to be up-to-date when all
   * its dependencies in the graph are up-to-date).
   */
  setVolatile (vertexId: CellVertexId | NameVertexId) {
    this.volatiles.push(vertexId);
  }

  /**
   * Remove record (if there is one) of the given vertex ID being volatile.
   */
  clearVolatile (vertexID: CellVertexId | NameVertexId) {
    const volatileIndex = this.volatiles.findIndex(vid => vid.key === vertexID.key);
    if (volatileIndex !== -1) {
      this.volatiles.splice(volatileIndex, 1);
    }
  }

  /**
   * Create the edge in the graph representing a dependency by a formula cell at
   * `from` on the cell/name/range at `to`, or update that edge if it already
   * exists (to reflect a change in dependency kind, and/or to record a new AST
   * path if the formula references this target more than once).
   */
  addDependency (
    from: TFromId,
    to: KnownVertexId,
    kind: EdgeKind = 'regular',
    astPath: ASTPath | null = null,
    fromData: TData | null = null,
    toData: TData | null = null,
  ) {
    const fromVertex = this._getOrCreateVertices(from.workbookKey).getOrCreate(from, fromData);
    const toVertex = this._getOrCreateVertices(to.workbookKey).getOrCreate(to, toData);
    let edge = new Edge(fromVertex, toVertex, kind);
    const existingEdge = this._getExistingEdgeWithSameVertices(edge);
    if (!existingEdge) {
      fromVertex.addOutgoingEdge(edge);
      toVertex.addIncomingEdge(edge);
      if (to instanceof CellVertexId) {
        this._updateSheetCellBounds(to);
      }
    }
    else {
      if (existingEdge.kind === 'conditional' && edge.kind === 'regular') {
        existingEdge.kind = 'regular';
      }
      else if (existingEdge.kind === 'nonvalue' && edge.kind !== 'nonvalue') {
        existingEdge.kind = edge.kind;
      }
      edge = existingEdge;
    }
    if (astPath && astPath.length > 0) {
      const edgePathList = this.edgeToASTPaths.get(edge);
      const lastEdgePath = edgePathList[edgePathList.length - 1];
      if (lastEdgePath?.[lastEdgePath.length - 1] !== astPath[astPath.length - 1]) {
        edgePathList.push(astPath);
      }
      // Else it's the previous astPath repeated, e.g. `SUMIF(D:D, ">0", D:D)`,
      // where the same range reference appears twice in the same AST node. This
      // should be sufficient to avoid most repeated AST paths, but may fail in
      // cases where another range reference appears between the two references
      // to the same range, e.g. `SUMIF(D:D, ">0", E:E). That's OK, it will just
      // cause inefficiency but not incorrect behavior.
    }
  }

  /**
   * @param targetWorkbookKey key corresponding to the explicit workbook name in
   *   the reference, null if no explicit workbook name
   * @param fromVertexId
   */
  recordUnresolvedReference (targetWorkbookKey: number | null, fromVertexId: TFromId) {
    const vertexIdSet = this.vertexIDsWithUnresolvedReferences.get(targetWorkbookKey);
    vertexIdSet.add(fromVertexId.key);
  }

  removeVertex (vertexId: VertexId) {
    const vertices = this._getVertices(vertexId.workbookKey);
    if (!vertices) {
      return;
    }
    vertices._vertices.delete(vertexId.key);
    if (vertexId instanceof RangeVertexId) {
      vertices._removeRangeFromTree(vertexId);
    }
  }

  _updateSheetCellBounds (vertexId: CellVertexId) {
    const { rowIndex: bottom, colIndex: right } = vertexId;
    const sheetKey = `${vertexId.workbookKey},${vertexId.sheetIndex}`;
    const cellBounds = this._sheetCellBounds.get(sheetKey);
    if (!cellBounds) {
      this._sheetCellBounds.set(sheetKey, { right, bottom });
    }
    else {
      if (right > cellBounds.right) {
        cellBounds.right = right;
      }
      if (bottom > cellBounds.bottom) {
        cellBounds.bottom = bottom;
      }
    }
  }

  isDependedOn (vertexId: KnownVertexId): boolean {
    let found = false;
    this.visitIncomingEdges(vertexId, (_, stop) => {
      found = true;
      stop.set();
    });
    return found;
  }

  hasDependency (from: TFromId, to: KnownVertexId, kind: EdgeKind = 'regular') {
    const fromVertex = this._getOrCreateVertices(from.workbookKey).get(from);
    const toVertex = this._getOrCreateVertices(to.workbookKey).get(to);
    if (fromVertex && toVertex) {
      const edge = new Edge(fromVertex, toVertex, kind);
      const existingEdge = this._getExistingEdgeWithSameVertices(edge);
      if (existingEdge && existingEdge.kind === edge.kind) {
        invariant(fromVertex.outgoingEdges.includes(existingEdge));
        invariant(toVertex.incomingEdges.includes(existingEdge));
        return true;
      }
    }
    if (fromVertex && to instanceof CellVertexId) {
      for (const outEdge of fromVertex.outgoingEdges) {
        if (outEdge.kind === kind && outEdge.to.id instanceof RangeVertexId && outEdge.to.id.contains(to)) {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Find and return any existing edge that has the same `from` and `to` (but
   * not necessarily the same `kind`).
   */
  _getExistingEdgeWithSameVertices (edge: Edge<TData>): Edge<TData> | null {
    if (edge.from.outDegree < edge.to.inDegree) {
      for (const outEdge of edge.from.outgoingEdges) {
        if (outEdge.to === edge.to) {
          return outEdge;
        }
      }
    }
    else {
      for (const inEdge of edge.to.incomingEdges) {
        if (inEdge.from === edge.from) {
          return inEdge;
        }
      }
    }
    return null;
  }

  /**
   * Returns an out-edge iterator for the specified cell or defined name.
   */
  visitOutgoingEdges (from: TFromId, callback: CallbackWithStop<Edge<TData>>) {
    const fromVertex = this._getVertices(from.workbookKey)?.get(from);
    if (fromVertex) {
      const stopSignal = new Signal();
      for (const edge of fromVertex.outgoingEdges) {
        callback(edge, stopSignal);
        if (stopSignal.isSet) {
          return;
        }
      }
    }
  }

  getOutgoingEdges (from: TFromId): Edge<TData>[] {
    const result: Edge<TData>[] = [];
    this.visitOutgoingEdges(from, edge => {
      result.push(edge);
    });
    return result;
  }

  hasOutgoingEdges (from: TFromId): boolean {
    const fromVertex = this._getVertices(from.workbookKey)?.get(from);
    return !!fromVertex && fromVertex.outDegree > 0;
  }

  visitIncomingEdges (to: VertexId, callback: CallbackWithStop<Edge<TData, TFromId>>) {
    if (to instanceof RangeVertexId) {
      this._visitInEdgesOfRange(to, callback);
    }
    else {
      invariant(to instanceof CellVertexId || to instanceof NameVertexId);
      this._visitInEdgesOfCellOrName(to, callback);
    }
  }

  getIncomingEdges (to: VertexId): Edge<TData>[] {
    const result: Edge<TData>[] = [];
    this.visitIncomingEdges(to, edge => {
      result.push(edge);
    });
    return result;
  }

  hasIncomingEdges (to: TFromId) {
    let found = false;
    this.visitIncomingEdges(to, (_, stop) => {
      found = true;
      stop.set();
    });
    return found;
  }

  _visitInEdgesOfRange (to: RangeVertexId, callback: CallbackWithStop<Edge<TData>>) {
    const sheetKey = `${to.workbookKey},${to.sheetIndex}`;
    const cellBounds = this._sheetCellBounds.get(sheetKey);
    const vertices = this._getVertices(to.workbookKey);
    if (!vertices) {
      return;
    }
    // Check for incoming edges only up to the furthest cell coordinates of any
    // `CellVertexId` that an edge has been observed to target. If no cellBounds
    // have been recorded for this sheet, that means no edge has targeted an
    // individual cell in this sheet, so no need to search for such edges.
    if (cellBounds) {
      to.visitCells((cellId, stopSignal) => {
        const cellVertex = vertices.get(cellId);
        if (cellVertex) {
          for (const edge of cellVertex.incomingEdges) {
            callback(edge, stopSignal);
            if (stopSignal.isSet) {
              return;
            }
          }
        }
      }, cellBounds);
    }
    vertices.visitInEdgesOfRangesIntersecting(to, callback);
  }

  _visitInEdgesOfCellOrName (to: CellVertexId | NameVertexId, callback: CallbackWithStop<Edge<TData>>) {
    const vertices = this._getVertices(to.workbookKey);
    if (!vertices) {
      return;
    }
    const toVertex = vertices.get(to);
    const stopSignal = new Signal();
    if (toVertex) {
      for (const edge of toVertex.incomingEdges) {
        callback(edge, stopSignal);
        if (stopSignal.isSet) {
          return;
        }
      }
    }
    if (to instanceof CellVertexId) {
      vertices.visitInEdgesOfRangesCovering(to, callback);
    }
  }

  visitIncomingVertices (to: VertexId, callback: CallbackWithStop<Vertex<TData, TFromId, TFromId>>) {
    this.visitIncomingEdges(to, (inEdge, stopSignal) => {
      callback(inEdge.from, stopSignal);
    });
  }

  getIncomingVertices (to: VertexId): Array<Vertex<TData, TFromId, TFromId>> {
    const result: Array<Vertex<TData, TFromId, TFromId>> = [];
    this.visitIncomingVertices(to, vertex => {
      result.push(vertex);
    });
    return result;
  }

  /**
   * Visit all vertex IDs which are the `from` of edges where `to` is in the
   * given sheet.
   */
  visitIncomingVertexIdsForSheet (workbookKey: number, sheetIndex: number, callback: CallbackWithStop<TFromId>) {
    const done = new Set();
    const vertices = this._getVertices(workbookKey);
    if (!vertices) {
      return;
    }
    const stopSignal = new Signal();
    for (const vertex of vertices) {
      const id = vertex.id;
      if (id.sheetIndex === sheetIndex && (id instanceof CellVertexId || id instanceof RangeVertexId)) {
        for (const edge of vertex.incomingEdges) {
          const vertexId = edge.from.id;
          if (!done.has(vertexId)) {
            callback(vertexId, stopSignal);
            if (stopSignal.isSet) {
              return;
            }
            done.add(vertexId);
          }
        }
      }
    }
  }

  nodeCount (): number {
    let count = 0;
    for (const vertices of this._verticesByWorkbook.values()) {
      for (const vertex of vertices._vertices.values()) {
        if (vertex.incomingEdges.length === 0 && vertex.outgoingEdges.length === 0) {
          continue;
        }
        count++;
      }
    }
    return count;
  }

  edgeCount (): number {
    const set = new Set();
    for (const vertices of this._verticesByWorkbook.values()) {
      for (const vertex of vertices._vertices.values()) {
        for (const edge of vertex.incomingEdges) {
          set.add(edge);
        }
        for (const edge of vertex.outgoingEdges) {
          set.add(edge);
        }
      }
    }
    return set.size;
  }

  toString (): string {
    return dumpOutgoing(this);
  }
}

export function dumpOutgoing<TData> (
  graph: DependencyGraph<TData, KnownVertexId>,
  repr?: (vertexId: KnownVertexId) => string,
) {
  if (!repr) {
    repr = vertexId => vertexId.key;
  }
  let result = '--== Dependency Graph ==--\n';
  for (const vertices of graph._verticesByWorkbook.values()) {
    for (const vertex of vertices._vertices.values()) {
      if (vertex.outDegree > 0) {
        result += repr(vertex.id) + '\n';
        for (const edge of vertex.outgoingEdges) {
          result += `  -> ${repr(edge.to.id)} (${edge.kind})\n`;
        }
      }
    }
  }
  return result;
}

export function vertexIdFromRange (workbookKey: number, sheetIndex: number, range: Range) {
  return range.size === 1
    ? new CellVertexId(workbookKey, sheetIndex, range.top, range.left)
    : new RangeVertexId(workbookKey, sheetIndex, range.top, range.left, range.bottom, range.right);
}

function vertexIdFromKey (key: string): KnownVertexId | null {
  if (key === '') {
    return null;
  }
  const firstChar = key[0];
  if (firstChar === 'c') {
    const m = key.match(/^c(\d+),(\d+)!(\d+),(\d+)$/);
    if (!m) {
      return null;
    }
    return new CellVertexId(Number(m[1]), Number(m[2]), Number(m[3]), Number(m[4]));
  }
  else if (firstChar === 'r') {
    const m = key.match(/^r(\d+),(\d+)!(\d+),(\d+),(\d+),(\d+)$/);
    if (!m) {
      return null;
    }
    return new RangeVertexId(Number(m[1]), Number(m[2]), Number(m[3]), Number(m[4]), Number(m[5]), Number(m[6]));
  }
  else if (firstChar === 'n') {
    const m = key.match(/^n(\d+),(\d+|g)!(.+)$/);
    if (!m) {
      return null;
    }
    return new NameVertexId(Number(m[1]), m[2] === 'g' ? null : Number(m[2]), m[3]);
  }
  return null;
}
