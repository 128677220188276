import Reference from './excel/Reference.js';
import { DEV_LOGGING } from './devutil.js';
import { invariant } from './validation';
import Matrix from './excel/Matrix.js';
import { referenceToVertexId } from './dep-graph-helpers.js';
import { isCellValue, isErr } from './typeguards.js';
import { isLiteralNode } from './excel/ast-common';
import { box } from './excel/ValueBox.js';

/**
 * @param {Workbook} wb
 * @param {string|Reference} ref
 * @returns {Reference | null}
 */
function resolveTargetReference (wb, ref) {
  let refProper = typeof ref === 'string' ? Reference.from(ref.replace(/^=/, '')) : ref;
  if (refProper) {
    if (refProper.workbookName && refProper.workbookName.toLowerCase() !== wb.name.toLowerCase()) {
      throw new Error('Tried to write to reference specifying different workbook');
    }
    /** @type {{workbookName: string, sheetName?: string}} */
    const prefix = { workbookName: wb.name };
    if (refProper.isAddress && !refProper.sheetName) {
      prefix.sheetName = wb.getSheetByIndex(0)?.name || 'Sheet1';
    }
    refProper = refProper.withPrefix(prefix);
  }
  const definedNamesTraversed = new Set();
  while (refProper && !refProper.isAddress && !wb._refPointsToAnotherWorkbook(refProper)) {
    const definedName = wb.getGlobal(refProper.name);
    if (isErr(definedName) || definedNamesTraversed.has(definedName)) {
      // Reference cycle!
      return null;
    }
    definedNamesTraversed.add(definedName);
    if (isNamedValue(definedName)) {
      return refProper;
    }
    const ctx = refProper.ctx;
    refProper = Reference.from(definedName.f?.replace(/^=/, '') || '');
    if (refProper && ctx) {
      refProper = refProper.withContext(ctx);
    }
  }
  return refProper;
}

/**
 * Returns true if the given defined name object is a “named value”, i.e. a
 * defined name whose formula is a literal value. Such a defined name may be
 * written directly, as opposed to formula defined names, which may be written
 * “through” to the referenced cell if their formulas are references, else
 * cannot be written to.
 * @param {Cell} definedName
 */
function isNamedValue (definedName) {
  return isLiteralNode(definedName._ast);
}

/**
 * @param {Workbook} wb
 * @param {string|Reference} refLike the address or global name to write to
 * @param {CellValue} val the value to write
 * @return {boolean} true if a write occurred
 */
export function wbWrite (wb, refLike, val) {
  invariant(wb._model != null, 'workbook must be initialized');
  const ref = resolveTargetReference(wb, refLike);

  if (!ref || wb._refPointsToAnotherWorkbook(ref)) {
    if (DEV_LOGGING) {
      console.error(`Can't write to cell ${ref}`);
    }
    return false;
  }

  invariant(isCellValue(val), 'Can only write cell values');

  if (ref.name) {
    const definedName = wb.getGlobal(ref.name);
    invariant(!isErr(definedName), 'name exists in wb, else resolveTargetReference would not have returned it');
    definedName.v = val;
  }
  else {
    const sheet = wb.getSheet(ref.sheetName);
    if (sheet == null) {
      // sheet does not exist in the workbook, so abort
      return false;
    }
    const unneutralizedFormulaCell = sheet._splitUpOverlappingRangeWrites(ref);
    if (unneutralizedFormulaCell) {
      wb.cellsWithNeutralizedFormulas.delete(unneutralizedFormulaCell);
    }
    if (ref.size === 1) {
      writeSingleCell(wb, sheet, ref, val);
    }
    else {
      writeRange(wb, sheet, ref, val);
    }
  }
  const vertexId = referenceToVertexId(wb._model, ref);
  wb.recordWrite(vertexId, val);
  wb._model._recalcState.writtenSinceRecalc.push(vertexId);
  return true;
}

/**
 * @param {Workbook} wb
 * @param {WorkSheet} sheet
 * @param {Reference} ref
 * @param {MaybeBoxed<CellValue>} val
 */
function writeSingleCell (wb, sheet, ref, val) {
  // We are intentionally not neutralizing formulas when writing to
  // a single cell. See https://github.com/GRID-is/Apiary/pull/1014.
  const cell = sheet.makeCell(ref.range);
  // Retain the formula-assigned number format, if present.
  if (cell.formulaZ) {
    val = box(val, { numberFormat: cell.formulaZ });
  }
  wb._updateValueAndSpills(ref, cell, val, true);
}

/**
 * @param {Workbook} wb
 * @param {WorkSheet} sheet
 * @param {Reference} ref
 * @param {CellValue} val
 */
function writeRange (wb, sheet, ref, val) {
  for (const cellToOverwrite of sheet.iterAnchorCellsInRange(ref.range)) {
    if (cellToOverwrite.isBlank()) {
      continue;
    }
    cellToOverwrite.v = null; // to allow range-write matrix node to override
    if (cellToOverwrite.f) {
      wb.cellsWithNeutralizedFormulas.add(cellToOverwrite);
      cellToOverwrite.neutralizedFormula = cellToOverwrite.f;
      cellToOverwrite.f = null;
    }
  }
  const cell = sheet.makeCell(ref.range);
  wb._updateValueAndSpills(ref, cell, new Matrix(ref.width, ref.height, val), true);
}
