import Matrix from './Matrix.js';
import FormulaError from './FormulaError';
import type Reference from './Reference';
import type { MaybeBoxed } from './ValueBox';
import { Lambda } from './lambda';

/**
 * If the `EXPECT_DIRTY_REFS` evaluation method is used, then the user takes
 * responsibility for checking that any returned reference is not dirty by
 * calling `checkReferenceNotDirty` before resolving it.
 *
 * ```tsx
 * const value = arg.evaluate(EXPECT_DIRTY_REFS);
 *
 * if (isRef(value)) {
 *   // Throws 'EvaluationOrderException' if the reference is dirty
 *   checkReferenceNotDirty(value);
 *
 *   // This is safe after calling 'checkReferenceNotDirty
 *   const matrix = value.toMatrix();
 * }
 * ```
 */
export const EXPECT_DIRTY_REFS = Symbol('EXPECT_DIRTY_REFS');
export const DISALLOW_DIRTY_REFS = Symbol('DISALLOW_DIRTY_REFS');

export type EvaluationMethod = typeof EXPECT_DIRTY_REFS | typeof DISALLOW_DIRTY_REFS;

export type CellValue = string | number | boolean | FormulaError | null;

/**
 * A cell value known not to be an error or blank
 */
export type CellValueAtom = Exclude<CellValue, FormulaError | null>;

/**
 * Values that can be stored inside arrays
 */
export type ArrayValue = CellValue | Lambda;

/**
 * A value that can result from a spreadsheet formula, known not to be boxed
 */
export type FormulaValue = ArrayValue | Reference | Matrix;

/**
 * A value that can result from a spreadsheet formula, possibly boxed
 */
export type MaybeBoxedFormulaValue = MaybeBoxed<ArrayValue> | Reference | Matrix;

/**
 * Any FormulaValue, or undefined to mean missing
 */
export type FormulaArgument = FormulaValue | undefined;

/**
 * Any MaybeBoxedFormulaValue, or undefined to mean missing
 */
export type MaybeBoxedFormulaArgument = MaybeBoxedFormulaValue | undefined;

/**
 * any FormulaValue except Matrix, or undefined to mean missing.
 */
export type NonMatrixFormulaArgument = Exclude<FormulaArgument, Matrix>;

export const TYPE_NONE = 0;
export const TYPE_ERROR = 1;
export const TYPE_BLANK = 2;
export const TYPE_MISSING = 4;
export const TYPE_NUM = 8;
export const TYPE_BOOL = 16;
export const TYPE_STRING = 32;
export const TYPE_RANGE = 64;
export const TYPE_ARRAY = 128;
export const TYPE_LAMBDA = 256;
export const TYPE_ALL =
  TYPE_ERROR | TYPE_BLANK | TYPE_MISSING | TYPE_NUM | TYPE_BOOL | TYPE_STRING | TYPE_RANGE | TYPE_ARRAY | TYPE_LAMBDA;

export const FLAG_VALUEBOX = 1;
export const FLAG_IS_GOOGLE_ARRAY_FUNCTION = 2;

export interface LazyArgument<T extends MaybeBoxedFormulaArgument = MaybeBoxedFormulaArgument> {
  evaluate: (evaluationMethod: EvaluationMethod) => T,
}

/**
 * Spreadsheet function which may not require all its arguments, and requests only those it does require.
 * Those it does not require _should not_ be evaluated, and in particular, references in them _must not_ be
 * enforced up-to-date.
 */
export type LazyArgumentFunction = (...args: LazyArgument[]) => MaybeBoxed<CellValue> | Matrix | Reference;
