import { ERROR_VALUE, ERROR_NA } from '../constants.js';
import { fmtColor, fromRGB, toRGB, hsl2rgb, namedColors } from './canvas-color.js';
import { toNum } from './utils.js';
import { isNum, isStr, isErr } from '../../utils.js';

const reI = '\\s*([+-]?\\d+)\\s*';
const reN = '\\s*([+-]?\\d*\\.?\\d+(?:[eE][+-]?\\d+)?)\\s*';
const reP = '\\s*([+-]?\\d*\\.?\\d+(?:[eE][+-]?\\d+)?)%\\s*';
const reHex = /^#([0-9a-f]{3}|[0-9a-f]{6})$/i;
const reRgbInteger = new RegExp('^rgb\\(' + [ reI, reI, reI ] + '\\)$', 'i');
const reRgbPercent = new RegExp('^rgb\\(' + [ reP, reP, reP ] + '\\)$', 'i');
const reHslPercent = new RegExp('^hsl\\(' + [ reN, reP, reP ] + '\\)$', 'i');

const colorCache = {};

const alignToAnchor = {
  left: '',
  center: 'middle',
  right: 'end',
  0: '',
  1: 'middle',
  2: 'end',
};

const linearInterpolate = (a, b, t) => {
  return a * (1 - t) + b * t;
};

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// functions that deal with color
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

// RGB(r, g, b)
export function RGB (r, g, b) {
  r = toNum(r);
  g = toNum(g);
  b = toNum(b);
  if (isErr(r)) {
    return r;
  }
  if (isErr(g)) {
    return g;
  }
  if (isErr(b)) {
    return b;
  }
  return fromRGB(r, g, b);
}

// HSL(h, s, l)
export function HSL (h, s, l) {
  h = toNum(h);
  s = toNum(s);
  l = toNum(l);
  if (isErr(h)) {
    return h;
  }
  if (isErr(s)) {
    return s;
  }
  if (isErr(l)) {
    return l;
  }
  if (h < 0 || s < 0 || l < 0 || s > 1 || l > 1) {
    return ERROR_VALUE;
  }
  h = (h % 360) + +(h < 0) * 360;
  s = s || 0;
  l = l || 0;
  const m2 = l + (l < 0.5 ? l : 1 - l) * s;
  const m1 = 2 * l - m2;
  return fromRGB(
    hsl2rgb(h >= 240 ? h - 240 : h + 120, m1, m2),
    hsl2rgb(h, m1, m2),
    hsl2rgb(h < 120 ? h + 240 : h - 120, m1, m2),
  );
}

/**
 * COLOR(colorString)
 * @param {number | string} r
 */
export function COLOR (r) {
  if (isNum(r) || isErr(r)) {
    return r;
  }
  if (isStr(r)) {
    r = r.toLowerCase();
    if (r in namedColors) {
      return namedColors[r];
    }
    if (r in colorCache) {
      return colorCache[r];
    }

    const hex = reHex.exec(r);
    if (hex) {
      if (r.length === 7) {
        colorCache[r] = parseInt(hex[1], 16);
      }
      if (r.length === 4) {
        const n = parseInt(hex[1], 16);
        colorCache[r] =
          ((n & 0xf00) << 12) |
          ((n & 0xf00) << 8) |
          (((n & 0xf0) << 8) | ((n & 0xf0) << 4)) |
          (((n & 0xf) << 4) | (n & 0xf));
      }
      return colorCache[r] || ERROR_VALUE;
    }
    let rgb = reRgbInteger.exec(r);
    if (rgb) {
      colorCache[r] = RGB(rgb[1], rgb[2], rgb[3]);
      return colorCache[r];
    }
    rgb = reRgbPercent.exec(r);
    if (rgb) {
      colorCache[r] = fromRGB((+rgb[1] / 100) * 255, (+rgb[2] / 100) * 255, (+rgb[3] / 100) * 255);
      return colorCache[r];
    }
    const hsl = reHslPercent.exec(r);
    if (hsl) {
      colorCache[r] = RGB(hsl[1], hsl[2], hsl[3]);
      return colorCache[r];
    }
  }
  return 0;
}

// MIXCOLOR(balance, color1, color2)
export function MIXCOLOR (t, color1, color2) {
  if (isErr(color1)) {
    return color1;
  }
  if (color1 == null || color2 == null) {
    return null;
  }
  if (!color1) {
    color1 = 0;
  }
  if (typeof color1 !== 'number') {
    color1 = COLOR(color1);
  }

  if (isErr(color2)) {
    return color2;
  }
  if (!color2) {
    color2 = 0;
  }
  if (typeof color2 !== 'number') {
    color2 = COLOR(color2);
  }

  t = toNum(t);
  if (isErr(t)) {
    return t;
  }
  if (t < 0 || t > 1) {
    return ERROR_VALUE;
  }

  const ca = toRGB(color1);
  const cb = toRGB(color2);
  return fromRGB(
    linearInterpolate(ca[0], cb[0], t),
    linearInterpolate(ca[1], cb[1], t),
    linearInterpolate(ca[2], cb[2], t),
  );
}

// COLORSCALE(balance, color1, color2, color3, color4, ...)
export function COLORSCALE (t, ...range) {
  t = toNum(t);
  if (isErr(t)) {
    return t;
  }
  if (t < 0 || t > 1) {
    return ERROR_VALUE;
  }
  const pairs = range.length - 1;
  const s = Math.min(Math.floor(t * pairs), pairs - 1);
  const t2 = t / (1 / pairs) - s;
  return MIXCOLOR(t2, range[s], range[s + 1]);
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// functions that deal with drawing shapes
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

// LINE(x1, y1, x2, y2, color=black, lineWidth=1)
/**
 * @this {any}
 */
export function LINE (x1, y1, x2, y2, color, lineWidth) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  x1 = toNum(x1);
  x2 = toNum(x2);
  y1 = toNum(y1);
  y2 = toNum(y2);
  lineWidth = lineWidth == null ? 1 : toNum(lineWidth);
  if (isErr(x1)) {
    return x1;
  }
  if (isErr(x2)) {
    return x2;
  }
  if (isErr(y1)) {
    return y1;
  }
  if (isErr(y2)) {
    return y2;
  }
  if (isErr(color)) {
    return color;
  }
  if (isErr(lineWidth)) {
    return lineWidth;
  }
  if (lineWidth <= 0) {
    return false;
  }

  this.canvasElement('line', {
    stroke: fmtColor(color ? COLOR(color) : 0),
    strokeWidth: lineWidth,
    x1: x1,
    y1: y1,
    x2: x2,
    y2: y2,
  });
  return true;
}

/**
 * @this {any} */ // RECT(x, y, width, height, color=blak)
export function RECT (x, y, width, height, color) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  x = toNum(x);
  y = toNum(y);
  width = toNum(width);
  height = toNum(height);
  if (isErr(x)) {
    return x;
  }
  if (isErr(y)) {
    return y;
  }
  if (isErr(width)) {
    return width;
  }
  if (isErr(height)) {
    return height;
  }
  if (isErr(color)) {
    return color;
  }

  this.canvasElement('rect', {
    fill: fmtColor(color ? COLOR(color) : 0),
    x: x,
    y: y,
    width: width,
    height: height,
  });
  return true;
}

/**
 * @this {any} */ // SRECT(x, y, width, height, color=black, lineWidth1)
export function SRECT (x, y, width, height, color, lineWidth) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  x = toNum(x);
  y = toNum(y);
  width = toNum(width);
  height = toNum(height);
  lineWidth = lineWidth == null ? 1 : toNum(lineWidth);
  if (isErr(x)) {
    return x;
  }
  if (isErr(y)) {
    return y;
  }
  if (isErr(width)) {
    return width;
  }
  if (isErr(height)) {
    return height;
  }
  if (isErr(color)) {
    return color;
  }

  this.canvasElement('rect', {
    stroke: fmtColor(color ? COLOR(color) : 0),
    fill: 'none',
    strokeWidth: lineWidth,
    x: x,
    y: y,
    width: width,
    height: height,
  });
  return true;
}

/**
 * @this {any} */ // ELLIPSE(x, y, rx, ry, color=blak)
export function ELLIPSE (x, y, rx, ry, color) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  x = toNum(x);
  y = toNum(y);
  rx = toNum(rx);
  ry = toNum(ry);
  if (isErr(x)) {
    return x;
  }
  if (isErr(y)) {
    return y;
  }
  if (isErr(rx)) {
    return rx;
  }
  if (isErr(ry)) {
    return ry;
  }
  if (isErr(color)) {
    return color;
  }
  if (rx < 0 || ry < 0) {
    return ERROR_VALUE;
  }

  this.canvasElement('ellipse', {
    fill: fmtColor(color ? COLOR(color) : 0),
    cx: x || 0,
    cy: y || 0,
    rx: rx || 0,
    ry: (ry === null ? rx : ry) || 0,
  });
  return true;
}

/**
 * @this {any} */ // SELLIPSE(cx, cy, rx, ry, color=black, lineWidth1)
export function SELLIPSE (x, y, rx, ry, color, w) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  x = toNum(x);
  y = toNum(y);
  rx = toNum(rx);
  ry = toNum(ry);
  w = w == null ? 1 : toNum(w);
  if (isErr(x)) {
    return x;
  }
  if (isErr(y)) {
    return y;
  }
  if (isErr(rx)) {
    return rx;
  }
  if (isErr(ry)) {
    return ry;
  }
  if (isErr(color)) {
    return color;
  }

  this.canvasElement('ellipse', {
    fill: 'none',
    stroke: fmtColor(color ? COLOR(color) : 0),
    strokeWidth: w,
    cx: x || 0,
    cy: y || 0,
    rx: rx || 0,
    ry: (ry === null ? rx : ry) || 0,
  });
  return true;
}

/**
 * @this {any} */ // POLYLINE(xs, ys, color=black, lineWidth1)
export function POLYLINE (xs, ys, c, w) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  w = w == null ? 1 : toNum(w);
  if (isErr(xs)) {
    return xs;
  }
  if (isErr(ys)) {
    return ys;
  }
  if (isErr(w)) {
    return w;
  }
  // may be range or matrix
  if (!xs || !xs.resolveRange) {
    return ERROR_NA;
  }
  if (!ys || !ys.resolveRange) {
    return ERROR_NA;
  }

  const xv = xs.resolveRange();
  const yv = ys.resolveRange();
  const mx = Math.min(xv.length, yv.length);
  const path = [];
  for (let i = 0; i < mx; i++) {
    const x = xv[i];
    const y = yv[i];
    path.push([ x, y ]);
  }

  this.canvasElement('polyline', {
    fill: 'none',
    stroke: fmtColor(c ? COLOR(c) : 0),
    strokeWidth: w,
    points: path.join(' '),
  });
  return true;
}

/**
 * @this {any} */ // POLYGON(xs, ys, color=blak)
export function POLYGON (xs, ys, c) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  if (isErr(xs)) {
    return xs;
  }
  if (isErr(ys)) {
    return ys;
  }
  // may be range or matrix
  if (!xs || !xs.resolveRange) {
    return ERROR_NA;
  }
  if (!ys || !ys.resolveRange) {
    return ERROR_NA;
  }

  const xv = xs.resolveRange();
  const yv = ys.resolveRange();
  const mx = Math.min(xv.length, yv.length);
  const path = [];
  for (let i = 0; i < mx; i++) {
    const x = toNum(xv[i]);
    const y = toNum(yv[i]);
    if (isErr(x)) {
      return x;
    }
    if (isErr(y)) {
      return y;
    }
    path.push([ x, y ]);
  }

  this.canvasElement('polygon', {
    fill: fmtColor(c ? COLOR(c) : 0),
    points: path.join(' '),
  });
  return true;
}

/**
 * @this {any} */ // SPOLYGON(xs, ys, color=black, lineWidth1)
export function SPOLYGON (xs, ys, c, w) {
  if (!this || !this.canvasElement) {
    return ERROR_VALUE;
  }
  w = w == null ? 1 : toNum(w);
  if (isErr(xs)) {
    return xs;
  }
  if (isErr(ys)) {
    return ys;
  }
  if (isErr(w)) {
    return w;
  }
  // may be range or matrix
  if (!xs || !xs.resolveRange) {
    return ERROR_NA;
  }
  if (!ys || !ys.resolveRange) {
    return ERROR_NA;
  }

  const xv = xs.resolveRange();
  const yv = ys.resolveRange();
  const mx = Math.min(xv.length, yv.length);
  const path = [];
  for (let i = 0; i < mx; i++) {
    const x = xv[i];
    const y = yv[i];
    path.push([ x, y ]);
  }

  this.canvasElement('polygon', {
    fill: 'none',
    stroke: fmtColor(c ? COLOR(c) : 0),
    strokeWidth: w,
    points: path.join(' '),
  });
  return true;
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// functions that deal with printing text
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-

/**
 * @this {any} */ // FONT(size, famly
export function FONT (size, family) {
  if (!this || !this.canvasOption) {
    return ERROR_VALUE;
  }
  size = toNum(size);
  if (isErr(size)) {
    return size;
  }
  if (size < 1) {
    return ERROR_VALUE;
  }

  if (family != null) {
    if (!isStr(family)) {
      return ERROR_VALUE;
    }
    this.canvasOption('font', family);
  }

  this.canvasOption('fontSize', size);
  return true;
}

/**
 * @this {any} */ // PRINT(text, x, y, color=black, alignment=let)
export function PRINT (text, x, y, color, align) {
  x = toNum(x);
  y = toNum(y);
  if (isErr(x)) {
    return x;
  }
  if (isErr(y)) {
    return y;
  }
  if (isErr(color)) {
    return color;
  }
  if (isErr(align)) {
    return align;
  }
  if (!this || !this.canvasOption || !this.canvasElement) {
    return ERROR_VALUE;
  }

  let anchor = '';
  if (align != null) {
    anchor = alignToAnchor[align];
  }
  if (!isStr(anchor)) {
    return ERROR_VALUE;
  }

  this.canvasElement(
    'text',
    {
      fill: fmtColor(color ? COLOR(color) : 0),
      textAnchor: anchor,
      fontFamily: this.canvasOption('font'),
      fontSize: this.canvasOption('fontSize'),
      x: x,
      y: y,
      dy: '.32em',
    },
    text || '',
  );
  return true;
}

// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
// functions that deal with coordinates
// -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
/**
 * @this {any}
 */
export function TRANSLATE (x, y) {
  if (!this || !this.canvasOption) {
    return ERROR_VALUE;
  }
  x = toNum(x);
  y = toNum(y);
  if (isErr(x)) {
    return x;
  }
  if (isErr(y)) {
    return y;
  }
  this.canvasOption('translate', [ x, y ]);
  return true;
}

// export function SCALE (x, y) {
//   if (!this || !this.canvas) { return ERROR_VALUE; }
//   x = toNum(x);
//   y = y == null ? x : toNum(y);
//   if (isErr(x)) { return x; }
//   if (isErr(y)) { return y; }
//   this.canvas.scale[0] = x;
//   this.canvas.scale[1] = y;
//   return true;
// }

// export function ROTATE (a) {
//   if (!this || !this.canvas) { return ERROR_VALUE; }
//   a = toNum(a);
//   if (isErr(a)) { return a; }
//   this.canvas.rotation = a;
//   return true;
// }

// export function RESET () {
//   if (!this || !this.canvas) { return ERROR_VALUE; }
//   this.canvas.translate = [ 0, 0 ];
//   this.canvas.scale = [ 0, 0 ];
//   this.canvas.rotation = 0;
//   return true;
// }

/**
 * @this {any}
 */
export function WIDTH () {
  if (!this || !this.canvasOption) {
    return ERROR_VALUE;
  }
  return Math.floor(this.canvasOption('width'));
}

/**
 * @this {any}
 */
export function HEIGHT () {
  if (!this || !this.canvasOption) {
    return ERROR_VALUE;
  }
  return Math.floor(this.canvasOption('height'));
}
