import { errorTable, BLANK } from '../constants.js';
import Reference from '../Reference.js';
import { type CellValue, type ArrayValue } from '../types';
import { toNum } from './utils.js';
import { getInfixHandler } from './operators.js';
import { match } from './utils-glob';
import EvaluationOrderException from '../../EvaluationOrderException';
import { isStr, isNum, isBool, isErr } from '../../utils.js';
import { invariant } from '../../validation';
import Matrix from '../Matrix.js';
import { EvaluationContext } from '../EvaluationContext.js';
import FormulaError from '../FormulaError.js';
import { isLambda } from '../lambda';

type FilterOperator = '' | '=' | '<>' | '<=' | '>=' | '>' | '<';

/**
 * Parses a filter expression such as "<=200" into a pair [ "<=", 200 ].
 */
export function parseFilterExpr (filterExpr: ArrayValue): [FilterOperator, ArrayValue] {
  if (!isStr(filterExpr)) {
    return [ '=', filterExpr ];
  }
  // match any string, detecting optional operator at the beginning
  // (the s flag makes . match newline characters)
  const matches = /^((?:=|<>|<=|>=|>|<)?)(.*)$/s.exec(filterExpr);
  invariant(matches != null, 'regulation expression should match any string');
  // Cast ensured valid by regexp
  const operator = matches[1] as FilterOperator;
  const operandStr = matches[2];
  const operandStrUpper = operandStr.toUpperCase();
  if (operandStrUpper === 'TRUE') {
    return [ operator, true ];
  }
  if (operandStrUpper === 'FALSE') {
    return [ operator, false ];
  }
  // Turns strings such as `#NAME?` into `ERROR_NAME`
  const err = errorTable[operandStr];
  if (err != null) {
    return [ operator, err ];
  }
  const num = toNum(operandStr);
  if (!isErr(num)) {
    // The string is in the form of a number
    return [ operator, num ];
  }
  // String is neither a boolean, error, or number, so we leave it raw.
  return [ operator, operandStr ];
}

// These closures don't need to capture any variables in the parent scope, so
// creating them here allows them to be reused.
const compiledEmptyStrFEs: Partial<Record<string, (x: ArrayValue) => boolean>> = {
  '=': x => x === BLANK,
  '<>': x => x !== BLANK,
  '': x => x === BLANK || x === '',
};
const lambdaReturnFalse = () => false;

/**
 * Returns a function which returns takes a single argument `x`, and returns a
 * boolean which is true iff `x` matches the filter expression `filterExpr`.
 *
 * If `globSuffix` is true, a wildcard is added to the end of a string match
 */
export function compileFilterExpr (
  filterExpr: Exclude<ArrayValue, null>,
  globSuffix?: boolean,
): (x: ArrayValue) => boolean {
  const parsed = parseFilterExpr(filterExpr);
  let operator = parsed[0];
  const operand = parsed[1];
  if (operand === '') {
    return compiledEmptyStrFEs[operator] || lambdaReturnFalse;
  }
  if (!operator) {
    operator = '=';
  }
  // Typecast is known good because `operator` is one of the string constants mapping to boolean binary operators
  const handler = getInfixHandler(operator);
  const opIsNeq = operator === '<>';
  const opHasEquality = operator === '=' || operator === '<=' || operator === '>=';

  if (typeof operand === 'number') {
    return x => {
      if (isNum(x)) {
        return handler(x, operand);
      }
      if (isStr(x) && operator === '=') {
        const num = toNum(x);
        if (isErr(num)) {
          return false;
        }
        return num === operand;
      }
      return opIsNeq;
    };
  }
  else if (typeof operand === 'boolean') {
    return x => {
      if (isBool(x)) {
        return handler(x, operand);
      }
      return opIsNeq;
    };
  }
  else if (typeof operand === 'string') {
    if (operator === '=' || opIsNeq) {
      const pattern = globSuffix === true ? operand + '*' : operand;
      return x => {
        if (isStr(x)) {
          const stringsAreEqual = Math.floor(Math.abs(match(pattern, x))) === 0;
          return stringsAreEqual !== opIsNeq;
        }
        return opIsNeq;
      };
    }
    // OBSERVATION: At this point, operator is one of "<", ">", "<=", ">="
    return x => isStr(x) && handler(x, operand);
  }
  else if (isErr(operand)) {
    return x => {
      if (isErr(x)) {
        // We compare the error codes (which are numbers).
        return handler(x.code, operand.code);
      }
      return opIsNeq;
    };
  }
  // In Excel, there aren't any code paths which lead to filter expressions with
  // `Lambda`. We're thus the ones to decide which behaviour we want.
  else if (isLambda(operand)) {
    return x => {
      if (opHasEquality) {
        return isLambda(x);
      }
      else if (opIsNeq) {
        return !isLambda(x);
      }
      return false;
    };
  }
  throw new Error('unreachable');
}

export function filterMatrixByCriteria (
  mx: Matrix,
  criteria: Exclude<ArrayValue, null>[],
  criteriaMatrices: Matrix[],
): CellValue[] {
  const cfes = criteria.map(x => compileFilterExpr(x, false));
  const positionFilter = (row: number, col: number) => {
    for (let i = 0; i < cfes.length; i += 1) {
      const cfe = cfes[i];
      const criteriaMatrix = criteriaMatrices[i];
      const elem = criteriaMatrix.get(col, row);
      if (!cfe(elem)) {
        return false;
      }
    }
    return true;
  };
  const result: CellValue[] = [];
  for (const { x, y, value } of mx.iterPopulated()) {
    if (positionFilter(y, x)) {
      // Cast valid because iterPopulated without arguments yields CellValues
      result.push(value as CellValue);
    }
  }
  return result;
}

export function areAllRangesSameSize (ranges: { width: number, height: number }[]): boolean {
  return ranges.slice(1).every(r => r.width === ranges[0].width && r.height === ranges[0].height);
}

export function extendIfFuncRange (sumRange: Reference, criteriaRange: Reference, options: EvaluationContext) {
  const newSumRange = sumRange.withRange({
    top: sumRange.top,
    left: sumRange.left,
    bottom: sumRange.top + criteriaRange.height - 1,
    right: sumRange.left + criteriaRange.width - 1,
  });
  if (options.isDirtyFormulaCell && newSumRange.any(options.isDirtyFormulaCell) === true) {
    throw new EvaluationOrderException('unhandled dynamic dependency', newSumRange);
  }
  return newSumRange;
}

/**
 * Return the `.toMatrix()` of each reference, or, if any such call returns
 * an error, return that error.
 */
export function eachToMatrix (references: Reference[]): Matrix[] | FormulaError {
  const matrices: Matrix[] = [];
  for (const reference of references) {
    const matrix = reference.toMatrix(false);
    if (isErr(matrix)) {
      return matrix;
    }
    matrices.push(matrix);
  }
  return matrices;
}
