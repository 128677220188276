import ModelError from './ModelError.js';
import { visitAllStaticReferences, visitAllCallNodes, isNonLiteralNode, ReferenceWithASTPath } from './excel/ast';
import { isLiteralNode } from './excel/ast-common';
import { evaluateAST, evaluateStaticReferenceASTNodeUnbound } from './excel/evaluate.js';
import { VOLATILES } from './excel/constants.js';
import { AssertionError, invariant } from './validation';
import { cellToVertexId, referenceToVertexId, vertexIdToCell } from './dep-graph-helpers.js';
import {
  CellVertexId,
  DependencyGraph,
  NameVertexId,
  KnownVertexId,
  RangeVertexId,
  VertexId,
  EdgeKind,
} from './DependencyGraph';
import { NameNotFoundError, SheetNotFoundError, WorkbookNotFoundError } from './errors';
import wbNameToKey from './wbNameToKey.js';
import { COERCE_NONE } from './mode.js';
import type Workbook from './Workbook.js';
import Cell from './excel/Cell.js';
import Reference from './excel/Reference.js';
import { EvaluationContext } from './excel/EvaluationContext.js';
import { ASTNode, ASTCallNode } from './excel/ast-types.js';
import { LinkedList } from '@grid-is/collections';

/**
 * Adds/updates the dependency graph for all vertices (or just given vertices)
 * of a workbook. This function doesn't have any side effects on the given
 * workbook. Please keep it that way!
 */
export default function updateGraphFor (
  graph: DependencyGraph<Cell>,
  wb: Workbook,
  vertexIDs?: Iterable<CellVertexId | NameVertexId>,
): { errors: ModelError[] } {
  const alreadyInGraph = graph._verticesByWorkbook.has(wb.keyInDepGraph);
  const errors: ModelError[] = [];
  const newlyTransitivelyContextDependent = new Set<Cell>();

  if (alreadyInGraph && vertexIDs) {
    for (const vertexID of vertexIDs) {
      removeOutgoingEdges(graph, vertexID);
      graph.clearVolatile(vertexID);
      const cell = vertexIdToCell(wb, vertexID);
      if (cell?.f && isNonLiteralNode(cell._ast)) {
        const wasContextDependent = cell._ast.isContextDependent;
        tryAddFormulaCell(graph, wb, cell, errors);
        if (!wasContextDependent && cell._ast.isContextDependent) {
          newlyTransitivelyContextDependent.add(cell);
        }
      }
    }
    propagateTransitiveContextDependence(graph, newlyTransitivelyContextDependent);
    return { errors };
  }

  if (alreadyInGraph) {
    removeWorkbookFromDependencyGraph(graph, wb);
  }

  // Initialize the vertex collection for the workbook. This does not
  // automatically happen in the case of a workbook with no vertices, which
  // can create issues because we use the vertex collections to track which
  // workbooks have already been added.
  graph._getOrCreateVertices(wb.keyInDepGraph);

  for (const sheet of wb._sheets) {
    for (const cell of sheet.iterFormulaCells()) {
      tryAddFormulaCell(graph, wb, cell, errors);
    }
    for (const cell of Object.values(sheet.locallyScopedNames)) {
      tryAddFormulaCell(graph, wb, cell, errors);
    }
  }
  for (const nameCell of Object.values(wb._globals)) {
    if (isNonLiteralNode(nameCell._ast)) {
      const wasContextDependent = nameCell._ast.isContextDependent;
      tryAddFormulaCell(graph, wb, nameCell, errors);
      if (!wasContextDependent && nameCell._ast.isContextDependent) {
        newlyTransitivelyContextDependent.add(nameCell);
      }
    }
  }

  const vertexIDsWithUnresolvedReferences = new Set([
    // Collect vertex IDs pointing to this workbook that were previously
    // unresolved, since adding the workbook may now cause them to resolve.
    ...(graph.vertexIDsWithUnresolvedReferences.get(wb.keyInDepGraph) || []),
    // Also collected vertex IDs not pointing to any specific workbook that
    // were previously unresolved, since they may now resolve to defined
    // names or tables within this workbook.
    ...(graph.vertexIDsWithUnresolvedReferences.get(null) || []),
  ]);
  for (const vertexIdKey of [ ...vertexIDsWithUnresolvedReferences ]) {
    // Will be re-added in tryAddFormulaCell if it still has unresolved ref
    vertexIDsWithUnresolvedReferences.delete(vertexIdKey);
    const vertexId = VertexId.fromKey(vertexIdKey);
    invariant(!(vertexId instanceof RangeVertexId), 'ranges cannot have dependencies, only dependents');
    const cell = vertexIdToCell(wb._model, vertexId);
    if (!cell) {
      continue;
    }
    const referencingWorkbook = wb._model.getWorkbookByKey(vertexId.workbookKey);
    if (!referencingWorkbook) {
      continue;
    }
    if (isNonLiteralNode(cell._ast)) {
      const wasContextDependent = cell._ast.isContextDependent;
      const dependencyAdded = tryAddFormulaCell(graph, referencingWorkbook, cell, errors);
      if (dependencyAdded) {
        wb._model._recalcState.changedSinceRecalc.push(vertexId);
      }
      if (!wasContextDependent && cell._ast.isContextDependent) {
        newlyTransitivelyContextDependent.add(cell);
      }
    }
  }
  propagateTransitiveContextDependence(graph, newlyTransitivelyContextDependent);
  return { errors };
}

function propagateTransitiveContextDependence (
  graph: DependencyGraph<Cell>,
  newlyTransitivelyContextDependent: Set<Cell>,
) {
  const queue = new LinkedList(newlyTransitivelyContextDependent);
  let newlyTransitivelyContextDependentCell: Cell | undefined;
  while ((newlyTransitivelyContextDependentCell = queue.removeFirst())) {
    const vertexID = cellToVertexId(newlyTransitivelyContextDependentCell);
    const dependents = graph.getIncomingVertices(vertexID);
    for (const dependent of dependents) {
      if (dependent.id instanceof NameVertexId) {
        const dependentCell = dependent.data;
        invariant(isNonLiteralNode(dependentCell?._ast));
        if (!dependentCell?._ast.isContextDependent) {
          dependentCell._ast.isContextDependent = true;
          queue.append(dependentCell);
        }
      }
    }
  }
}

function removeOutgoingEdges (graph: DependencyGraph<Cell>, vertexID: CellVertexId | NameVertexId) {
  const vertexCollection = graph._getVertices(vertexID.workbookKey);
  if (!vertexCollection) {
    return null;
  }
  const vertex = vertexCollection.get(vertexID);
  if (!vertex) {
    return;
  }
  for (const edge of vertex.outgoingEdges) {
    const toVertexCollection = graph._getVertices(edge.to.id.workbookKey);
    if (!toVertexCollection) {
      continue;
    }
    const toVertex = toVertexCollection.get(edge.to.id);
    if (toVertex) {
      const index = toVertex.incomingEdges.indexOf(edge);
      invariant(index !== -1);
      toVertex.incomingEdges.splice(index, 1);
      if (toVertex.incomingEdges.length === 0 && toVertex.outgoingEdges.length === 0) {
        toVertexCollection.delete(toVertex.id);
      }
    }
  }
  vertex.outgoingEdges = [];
  if (!vertex.incomingEdges.length) {
    vertexCollection.delete(vertexID);
  }
}

/**
 * @returns true if a dependency edge was added
 */
function tryAddFormulaCell (graph: DependencyGraph<Cell>, wb: Workbook, cell: Cell, errors: ModelError[]): boolean {
  try {
    return addFormulaCell(graph, wb, cell);
  }
  catch (err: unknown) {
    if (err instanceof AssertionError || !(err instanceof Error)) {
      throw err;
    }
    errors.push(
      new ModelError(
        'Unexpected error building dependency graph: ' + err.message,
        ModelError.ERROR,
        cell,
        'unexpected',
        err,
      ),
    );
    return false;
  }
}

/**
 * Remove vertices and edges of the given workbook from the dependency graph.
 * Record vertex IDs in other workbooks which had references into this workbook
 * which now cease to work (so that they can be revisited if the workbook is
 * later added again), and record them as changed in recalculation state, so
 * that they will be recalculated (now with the references to this workbook
 * evaluating as #REF!, which may or may not affect their formula result).
 */
export function removeWorkbookFromDependencyGraph (graph: DependencyGraph<Cell>, workbook: Workbook) {
  const workbookKey = workbook.keyInDepGraph;
  const vertices = graph._getVertices(workbookKey);
  if (vertices == null) {
    return;
  }

  const referencedThisWorkbook = new Set<NameVertexId | CellVertexId>();

  for (const vertex of vertices._vertices.values()) {
    for (const edge of vertex.incomingEdges) {
      const fromVertex = edge.from;
      if (fromVertex.id.workbookKey === workbookKey) {
        // no need to remove in the workbook's subgraph, we will GC all of it
        continue;
      }
      fromVertex.removeOutgoingEdge(edge);
      referencedThisWorkbook.add(fromVertex.id);
    }
    for (const edge of vertex.outgoingEdges) {
      const toVertex = edge.to;
      const toId = toVertex.id;
      if (toId.workbookKey === workbookKey) {
        // no need to remove in the workbook's subgraph, we will GC all of it
        continue;
      }
      toVertex.removeIncomingEdge(edge);
      if (toVertex.inDegree === 0 && toVertex.outDegree === 0) {
        graph.removeVertex(toId);
      }
    }
  }

  // Formulas that reference this workbook should be recalculated now that
  // the references will evaluate as #REF!, which they probably didn't before.
  // And they should be recorded as having unresolved references into this
  // workbook, so that they can be looked at again if a workbook by this name is
  // added later.
  for (const vertexId of referencedThisWorkbook) {
    // pass null for the target workbook key, because the reference _may_ have
    // not specified a workbook and just been implicitly resolved into this
    // workbook. It _may_ have explicitly specified this workbook, but it is not
    // really worthwhile to go check.
    graph.recordUnresolvedReference(null, vertexId);
    workbook._model._recalcState.changedSinceRecalc.push(vertexId);
  }

  // Remove sheet cell bounds for this workbook
  for (const key of graph._sheetCellBounds.keys()) {
    const sheetCellBoundsWorkbookKey = Number(key.split(',')[0]);
    if (sheetCellBoundsWorkbookKey === workbookKey) {
      graph._sheetCellBounds.delete(key);
    }
  }

  graph._verticesByWorkbook.delete(workbookKey);
  for (const vertexId of [ ...graph.volatiles ]) {
    if (vertexId.workbookKey === workbookKey) {
      graph.clearVolatile(vertexId);
    }
  }
}

/**
 * @returns true if a dependency edge was added
 */
function addFormulaCell (graph: DependencyGraph<Cell>, wb: Workbook, cell: Cell): boolean {
  const refsWithPaths = wbGetStaticReferences(wb, cell);

  if (!refsWithPaths.length) {
    return false;
  }

  const fromVertexId = cellToVertexId(cell);
  let edgeAdded = false;
  let volatile = false;
  for (const [ ref, astPath ] of refsWithPaths) {
    if (ref === 'volatile') {
      volatile = true;
      continue;
    }
    invariant(ref.ctx != null, 'Reference from static analysis should always have a resolver');
    let targetVertexId: KnownVertexId;
    try {
      targetVertexId = referenceToVertexId(wb._model, ref);
    }
    catch (e) {
      if (e instanceof SheetNotFoundError || e instanceof WorkbookNotFoundError || e instanceof NameNotFoundError) {
        const workbookKey = e.workbookName ? wbNameToKey(e.workbookName) : null;
        graph.recordUnresolvedReference(workbookKey, fromVertexId);
        continue;
      }
      throw e;
    }

    const edgeKind = determineEdgeKind(ref);
    if (edgeKind === 'nonvalue' && !targetIsEditable(wb, targetVertexId)) {
      continue;
    }
    let targetCell: Cell | null | undefined;
    if (!(targetVertexId instanceof RangeVertexId)) {
      targetCell = vertexIdToCell(wb._model, targetVertexId);
    }
    graph.addDependency(fromVertexId, targetVertexId, edgeKind, astPath, cell, targetCell);
    edgeAdded = true;
    invariant(isNonLiteralNode(cell._ast));
    if (ref.conditional) {
      cell._ast.hasConditionalDependencies = true;
    }
    if (
      targetVertexId instanceof NameVertexId &&
      fromVertexId instanceof NameVertexId &&
      isNonLiteralNode(targetCell?._ast) &&
      targetCell._ast.isContextDependent &&
      !cell._ast.isContextDependent
    ) {
      invariant(isNonLiteralNode(cell._ast));
      cell._ast.isContextDependent = true;
    }
  }

  if (volatile || isVolatileFormula(cell._ast)) {
    graph.setVolatile(cellToVertexId(cell));
  }
  return edgeAdded;
}

function determineEdgeKind (ref: Reference): EdgeKind {
  if (ref.nonValue) {
    return 'nonvalue';
  }
  return ref.conditional ? 'conditional' : 'regular';
}

function targetIsEditable (wb: Workbook, targetVertexId: KnownVertexId): boolean {
  if (targetVertexId.workbookKey === wb.keyInDepGraph) {
    return wb.editable;
  }
  const targetWorkbook = wb._model.getWorkbookByKey(targetVertexId.workbookKey);
  return !!targetWorkbook?.editable;
}

/**
 * Find all static references of the given formula cell or defined name
 * @return an array of the static references of the formula (or volatile
 *   markers), along with the path of AST nodes from root to parent of the
 *   static reference expression.
 */
export function wbGetStaticReferences (wb: Workbook, cell: Cell): ReferenceWithASTPath[] {
  if (cell._ast == null) {
    // formula failed to parse, so there's no point in (and no information for) adding it to the dependency graph
    return [];
  }

  const sheet = cell.sheetIndex != null ? wb._sheets[cell.sheetIndex] : null;
  const refsWithPaths: ReferenceWithASTPath[] = [];
  const opts: EvaluationContext = {
    ...wb,
    cell,
    cellId: cell.id,
    workbookName: wb.name,
    sheetName: sheet?.name,
    coerceNullToZero: COERCE_NONE,
    evaluateAST,
    getContextTable: () => (sheet ? wb.getTableContainingCell(sheet, cell) : null),
  };
  const evaluateStaticReferenceASTNode = evaluateStaticReferenceASTNodeUnbound.bind(opts);
  visitAllStaticReferences([], cell._ast, { ...opts, evaluateStaticReferenceASTNode }, refWithPath => {
    refsWithPaths.push(refWithPath);
  });
  return refsWithPaths;
}

function isVolatileFunction (funcName: ASTCallNode['call']) {
  return typeof funcName === 'string' && VOLATILES.has(funcName.toUpperCase());
}

/**
 * Determine whether the given formula is volatile, meaning it may yield different results in successive evaluations
 * with everything else in the model unchanged.
 */
function isVolatileFormula (ast: ASTNode): boolean {
  if (isLiteralNode(ast) || 'lambda' in ast) {
    // Top-level lambda definition is not volatile, but formulas _calling_ such
    // a lambda may be. Any lambda definitions _inside_ the formula (not at the
    // top level) containing volatile-function calls will cause the formula to
    // be marked volatile even if they are never actually called. This may cause
    // needless repeated evaluations of such formulas, which we _could_ avoid
    // with some extra analysis work. This is not really different from
    // conditional usage of volatile functions that never actually happens, we
    // still treat such formulas as volatile, as there are diminishing returns
    // in trying to rule out volatility in such cases.
    return false;
  }
  let result = false;
  visitAllCallNodes(ast, (functionNode, stop) => {
    const { call, args } = functionNode;
    if (typeof call !== 'string') {
      return;
    }
    if (isVolatileFunction(call)) {
      result = true;
      stop.set();
    }
    if (/query/i.exec(call) || args.length > 1) {
      // QUERY is volatile if the SQL includes a call to now(). The SQL may be
      // constructed dynamically or referenced from somewhere, but we can check
      // now() occurring case-insensitively in the SQL string or the expression
      // that constructs it. That will catch most cases. It _can_ yield false
      // positives, but that remote edge case will just the formula to be
      // needlessly volatile, causing extra work that may impact performance;
      // it will not cause incorrectness. I _think_ the now() function is the
      // only way for a QUERY call to be volatile at the time of this writing.
      const queryStringArg = args[1] === 'string' ? args[1] : JSON.stringify(args[1]);
      if (/\bnow *\( *\)/i.test(queryStringArg)) {
        result = true;
        stop.set();
      }
    }
  });
  return result;
}
