import Cell from './Cell';
import { EvaluationContext } from './EvaluationContext';
import { ASTLetNode } from './ast-types';
import { MaybeBoxedFormulaArgument } from './types';
import { isRef } from '../utils.js';

export function evaluateLet (ast: ASTLetNode, opts: EvaluationContext) {
  const evaluatedArgs = new Map();
  const nameOverrideContext = makeLetContext(opts, evaluatedArgs);
  for (const [ argName, argAst ] of Object.entries(ast.let)) {
    evaluatedArgs.set(argName.toUpperCase(), nameOverrideContext.evaluateASTNode(argAst));
  }
  const result = nameOverrideContext.evaluateASTNode(ast.expr);
  // If LET result is a name reference, to one of its parameters, then return
  // the evaluated result of that parameter.
  if (isRef(result) && result.name) {
    const nameUpper = result.name.toUpperCase();
    if (evaluatedArgs.has(nameUpper)) {
      return evaluatedArgs.get(nameUpper);
    }
  }
  return result;
}

function makeLetContext (
  ctx: EvaluationContext,
  evaluatedArgs: Map<string, MaybeBoxedFormulaArgument>,
): EvaluationContext {
  return {
    ...ctx,
    resolveName: (name: string, sheetName?: string | null) => {
      if (!sheetName) {
        const nameUpper = name.toUpperCase();
        const evaluatedArg = evaluatedArgs.get(nameUpper);
        if (typeof evaluatedArg !== 'undefined' || evaluatedArgs.has(nameUpper)) {
          const fakeCell = new Cell({});
          fakeCell.v = evaluatedArg ?? null;
          return fakeCell;
        }
      }
      return ctx.resolveName(name, sheetName);
    },
  };
}
