import { invariant } from '../../validation';
import { ERROR_NA, ERROR_VALUE, MODE_EXCEL, MODE_GOOGLE } from '../constants.js';
import { checkReferenceNotDirty, DISALLOW_DIRTY_REFS, EXPECT_DIRTY_REFS } from '../evaluate-common.js';
import { ERROR_CALC_LAMBDA_NOT_ALLOWED, isLambda } from '../lambda';
import Matrix, { isMatrix } from '../Matrix.js';
import Reference, { isRef } from '../Reference.js';
import { unbox } from '../ValueBox.js';
import { implicitIntersection } from './array';
import { isBool, isErr } from './utils.js';

/**
 * Spreadsheet function IF, special case because only one of its two branch arguments should be evaluated.
 * @this {EvaluationContext}
 * @param {LazyArgument<Matrix | MaybeBoxed<boolean>>} conditionArg
 * @param {LazyArgument} thenArg
 * @param {LazyArgument} [elseArg]
 * @returns {MaybeBoxedFormulaValue}
 */
function IF (conditionArg, thenArg, elseArg) {
  const condition = conditionArg.evaluate(DISALLOW_DIRTY_REFS);
  if (isErr(condition)) {
    return condition;
  }
  if (isMatrix(condition)) {
    return elementwiseIF(condition, thenArg, elseArg, this);
  }
  else {
    return scalarIF(unbox(condition), thenArg, elseArg);
  }
}

/**
 * @param {Matrix} conditionsMatrix
 * @param {LazyArgument} thenArg
 * @param {LazyArgument | undefined} elseArg
 * @param {EvaluationContext} evaluationContext
 * @returns {Matrix}
 */
function elementwiseIF (conditionsMatrix, thenArg, elseArg, evaluationContext) {
  const result = new Matrix(conditionsMatrix.width, conditionsMatrix.height);
  const trueMatrix = getArgAsMatrix(thenArg, result.width, result.height, evaluationContext);
  const falseMatrix = getArgAsMatrix(elseArg, result.width, result.height, evaluationContext);
  // Resize resulting matrix if either the TRUE or FALSE matrices are larger
  result.width = Math.max(result.width, trueMatrix.width, falseMatrix.width);
  result.height = Math.max(result.height, trueMatrix.height, falseMatrix.height);
  const populatedHeight = Math.max(
    conditionsMatrix.populatedHeight,
    trueMatrix.populatedHeight,
    falseMatrix.populatedHeight,
  );
  const populatedWidth = Math.max(
    conditionsMatrix.populatedWidth,
    trueMatrix.populatedWidth,
    falseMatrix.populatedWidth,
  );

  const excelMode = evaluationContext.mode === MODE_EXCEL;
  // Populate the results matrix
  /**
   * @param {number} x
   * @param {number} y
   */
  const chooseElement = (x, y) => {
    const conditionElement = conditionsMatrix.get(x, y);
    if (isErr(conditionElement)) {
      return conditionElement;
    }
    else {
      const chosenMatrix = conditionElement ? trueMatrix : falseMatrix;
      const element = chosenMatrix.getBoxed(x, y);
      if (excelMode && unbox(element) == null) {
        return 0;
      }
      return element;
    }
  };
  for (let y = 0; y < populatedHeight; y++) {
    for (let x = 0; x < populatedWidth; x++) {
      result.set(x, y, chooseElement(x, y));
    }
    if (result.width > populatedWidth) {
      result._defaultColumn[y] = chooseElement(populatedWidth, y);
    }
  }
  if (result.height > populatedHeight) {
    result._defaultRow = [ ...Array(populatedWidth).keys() ].map(x => chooseElement(x, populatedHeight));
    if (result.width > populatedWidth) {
      result._defaultValue = chooseElement(populatedWidth, populatedHeight);
    }
  }
  return result;
}

/**
 * @param {LazyArgument|undefined} arg
 * @param {number} desiredWidth
 * @param {number} desiredHeight
 * @param {EvaluationContext} evaluationContext
 * @returns {Matrix}
 */
function getArgAsMatrix (arg, desiredWidth, desiredHeight, evaluationContext) {
  if (!arg) {
    return new Matrix(desiredWidth, desiredHeight, false);
  }
  let argument = arg.evaluate(EXPECT_DIRTY_REFS);
  if (isRef(argument)) {
    argument = argument.resolveToNonName(evaluationContext);
  }
  if (isMatrix(argument)) {
    return argument;
  }
  /** @type {MaybeBoxed<ArrayValue>} */
  let value;
  if (isRef(argument)) {
    checkReferenceNotDirty(argument, evaluationContext);
    if (argument.size > 1) {
      const matrixOrError = argument.toMatrix(false);
      if (isErr(matrixOrError)) {
        return new Matrix(desiredWidth, desiredHeight, matrixOrError);
      }
      else {
        return matrixOrError;
      }
    }
    value = argument.resolveSingle();
  }
  else {
    value = argument ?? null;
  }
  return new Matrix(desiredWidth, desiredHeight, value);
}

/**
 * @param {boolean} condition
 * @param {LazyArgument} thenArg
 * @param {LazyArgument} [elseArg]
 * @returns {MaybeBoxedFormulaValue}
 */
function scalarIF (condition, thenArg, elseArg) {
  const argToEvaluate = condition ? thenArg : elseArg;
  if (!argToEvaluate) {
    return false;
  }
  // Within the chosen branch of an IF function, references are not guaranteed to be up-to-date even if they are not
  // marked as dynamic (because we mark them as conditional in the dependency graph). So while evaluating the chosen
  // branch argument expression for the IF function, we must check _all_ references, not just dynamic ones.
  // Except, EXCEPT! The _result_ of the argument expression, if that is a reference, does _not_ need to be up-to-date
  // (and indeed _must not_ be enforced to be up-to-date, lest we invite false-positive circular dependency errors);
  // instead we will simply mark it dynamic and return it, and then it is up to whoever receives it to enforce it
  // up-to-date if _they_ need it to be up-to-date.
  const value = argToEvaluate.evaluate(EXPECT_DIRTY_REFS);
  if (isRef(value) && !value.dynamic) {
    return new Reference(value, { dynamic: true });
  }
  return value ?? null;
}

/**
 * @this {EvaluationContext}
 * @param {LazyArgument<Matrix | MaybeBoxed<CellValue> | undefined>} valueArg
 * @param {LazyArgument} [valueIfErrorArg]
 * @returns {MaybeBoxedFormulaValue }
 */
function IFERROR (valueArg, valueIfErrorArg) {
  return ifPredicate.call(this, valueArg, valueIfErrorArg, isErr);
}

/**
 * @this {EvaluationContext}
 * @param {LazyArgument<Matrix | MaybeBoxed<CellValue> | undefined>} valueArg
 * @param {LazyArgument} [valueIfNaArg]
 * @returns {FormulaValue}
 */
function IFNA (valueArg, valueIfNaArg) {
  /** @param {MaybeBoxedFormulaArgument} value */
  const predicate = value => isErr(value) && unbox(value).code === ERROR_NA.code;
  return ifPredicate.call(this, valueArg, valueIfNaArg, predicate);
}

/**
 * Common implementation for IFERROR and IFNA functions.
 *
 * Returns `value` (arg 0) if `predicate(value)` returns false, else
 * returns `valueIfPredicate` (arg 1).
 *
 * If `value` is non-scalar (matrix or reference), do this
 * element-wise, projecting singleton dimensions as appropriate.
 *
 * @this {EvaluationContext}
 * @param {LazyArgument<MaybeBoxedFormulaArgument>} valueArg
 * @param {LazyArgument|undefined} valueIfPredicateArg
 * @param {(value: MaybeBoxedFormulaArgument) => boolean} predicate
 * @returns {FormulaValue}
 */
function ifPredicate (valueArg, valueIfPredicateArg, predicate) {
  /** @returns {Exclude<FormulaValue, import('../lambda').Lambda>} */
  const getFallback = () => {
    const value = unbox(valueIfPredicateArg?.evaluate(EXPECT_DIRTY_REFS));
    if (isLambda(value)) {
      return ERROR_CALC_LAMBDA_NOT_ALLOWED;
    }
    return value ?? null;
  };

  const rawValue = valueArg.evaluate(DISALLOW_DIRTY_REFS);
  let value = isRef(rawValue) ? rawValue.toMatrix() : unbox(rawValue ?? null);

  if (this.mode === MODE_GOOGLE && isMatrix(value) && value.width === 1 && value.height === 1) {
    value = value.get(0, 0);
  }

  if (predicate(value)) {
    return getFallback();
  }

  if (isMatrix(value)) {
    const noValueMatchesPredicate = value.every(value => !predicate(value));
    if (this.mode === MODE_GOOGLE && noValueMatchesPredicate) {
      return value;
    }

    /** @type {Matrix} */
    let fallback;

    const fallbackArg = getFallback();
    if (isMatrix(fallbackArg)) {
      fallback = fallbackArg;
    }
    else if (isRef(fallbackArg)) {
      checkReferenceNotDirty(fallbackArg, this);
      const matOrErr = fallbackArg.toMatrix();
      fallback = isErr(matOrErr) ? Matrix.of(matOrErr) : matOrErr;
    }
    else {
      fallback = Matrix.of(fallbackArg);
    }

    const width = Math.max(value.width, fallback.width);
    const height = Math.max(value.height, fallback.height);
    const populatedWidth = Math.max(value.populatedWidth, fallback.populatedWidth);
    const populatedHeight = Math.max(value.populatedHeight, fallback.populatedHeight);

    const mat = new Matrix(width, height);
    mat.setData(Array.from({ length: populatedHeight }).map(() => Array.from({ length: populatedWidth })));
    const widthEqual = populatedWidth === width;
    const heightEqual = populatedHeight === height;
    if (widthEqual !== heightEqual) {
      if (!heightEqual) {
        mat._defaultRow = Array.from({ length: populatedWidth });
      }
      else if (!widthEqual) {
        mat._defaultColumn = Array.from({ length: populatedHeight });
      }
    }

    const valueMatrix = value;
    const predicateResultMatrix = value.map(predicate);

    return mat.map((_, { x, y }) => {
      if (predicateResultMatrix.get(x, y)) {
        return fallback.get(x, y);
      }
      return valueMatrix.get(x, y);
    });
  }

  return value;
}

/**
 * @this {EvaluationContext}
 * @param  {...LazyArgument<MaybeBoxedFormulaArgument>} args
 * @returns {MaybeBoxedFormulaArgument}
 */
function IFS (...args) {
  if (args.length % 2) {
    // Excel throws a syntax error if the number of arguments is odd
    return ERROR_VALUE;
  }

  /** @type {(CellValue|Matrix)[]} */
  const conditions = [];
  /** @type {(Exclude<MaybeBoxedFormulaArgument, Reference>)[]} */
  const values = [];

  let hasEncounteredMatrix = false;

  for (let i = 0; i < args.length; i += 2) {
    const conditionArg = args[i];
    const condition = unbox(conditionArg.evaluate(DISALLOW_DIRTY_REFS) ?? null);
    invariant(isMatrix(condition) || isErr(condition) || isBool(condition)); // Signature guarantees this

    const getValue = () => {
      const valueArg = args[i + 1];
      let value = valueArg.evaluate(EXPECT_DIRTY_REFS) ?? null;
      if (isRef(value)) {
        value = value.resolveToNonName();
      }
      if (!isRef(value)) {
        return value ?? null;
      }
      if (this.mode === MODE_GOOGLE && this.singleCell && value.size > 1) {
        value = implicitIntersection(this.cellId, value);
      }
      return value;
    };

    if (isMatrix(condition)) {
      const considerMatrixToBeSeen = this.mode !== MODE_GOOGLE || condition.size > 1;
      if (considerMatrixToBeSeen) {
        hasEncounteredMatrix = true;
      }

      // The value is always evaluated if a matrix argument has
      // been seen before.
      let value = getValue();

      if (isRef(value)) {
        checkReferenceNotDirty(value, this);
        value = value.toMatrix();
      }

      conditions.push(condition);
      values.push(value);
      continue;
    }

    if (!isValidCondition(condition) && !hasEncounteredMatrix) {
      // We haven't seen a Matrix before. We can return an error
      // without any Matrix operations, and without evaluating
      // the value.
      return isErr(condition) ? condition : ERROR_VALUE;
    }

    if (condition || hasEncounteredMatrix) {
      let value = getValue();

      // Google is strict about argument size. In the case where the
      // condition (which is guaranteed to be a single value) is truthy
      // AND the value is a non-1x1 Matrix or Reference, we need to
      // make sure that we return a 'mismatched range sizes' error.
      const isGoogleModeAndCanShortCircuit = this.mode === MODE_GOOGLE && !hasEncounteredMatrix;
      const valueIsNot1x1 = (isMatrix(value) || isRef(value)) && value.size !== 1;

      if (isGoogleModeAndCanShortCircuit && valueIsNot1x1) {
        const { width, height } = /** @type {Matrix|Reference} */ (value);
        return ERROR_VALUE.detailed(
          'IFS has mismatched range sizes. ' +
            'Expected row count: 1. column count: 1. ' +
            `Actual row count: ${height}, column count: ${width}.`,
        );
      }

      if (!hasEncounteredMatrix) {
        // The condition is truthy, and we haven't seen a Matrix
        // before. We can avoid Matrix operations and return early.
        return value;
      }

      // The condition is either truthy or falsy. However, as we've
      // seen a Matrix before, it doesn't matter.
      //
      // We cannot skip processing the value as it is used to determine
      // the size of the output Matrix.

      if (isRef(value)) {
        checkReferenceNotDirty(value, this);
        value = value.toMatrix();
      }

      conditions.push(condition);
      values.push(value);
      continue;
    }

    // The condition is not invalid or truthy, and we haven't
    // seen a Matrix before.
    //
    // We will not evaluate the value to avoid causing a circular
    // reference. Since this condition has no effect on the output
    // we can omit and the condition and value.
    //
    // UNLESS we are in Google mode and this is the first condition.
    // In Google mode, the first argument controls the result size.
    //
    if (this.mode === MODE_GOOGLE && i === 0) {
      conditions.push(condition);
      values.push(null);
    }
  }

  // Make output matrix have #N/A in any element not assigned by any of the arguments
  conditions.push(true);
  values.push(ERROR_NA);

  // In Google mode, the first argument determines the output matrix size. Otherwise
  // the output matrix's [ width, height ] is [ max(widths), max(heights) ].
  const argsDeterminingSize = this.mode === MODE_GOOGLE ? [ conditions[0] ] : [ ...conditions, ...values ];

  let width = 1;
  let height = 1;
  for (const value of argsDeterminingSize) {
    if (!isMatrix(value)) {
      continue;
    }
    if (width < value.width) {
      width = value.width;
    }
    if (height < value.height) {
      height = value.height;
    }
  }

  const result = new Matrix(width, height);
  result.setData(Array.from({ length: height }).map(() => Array.from({ length: width })));
  const assigned = result.map(() => false);
  let nAssigned = 0;

  /**
   * @param {number} x
   * @param {number} y
   * @param {MaybeBoxed<ArrayValue> | undefined} value
   */
  function assignResultElementIfNotAssigned (x, y, value) {
    if (assigned.get(x, y)) {
      return; // Already filled
    }
    assigned.set(x, y, true);
    nAssigned++;
    result.set(x, y, value ?? null);
  }

  /**
   * @param {Exclude<MaybeBoxedFormulaArgument, Reference>} cellValueOrMatrix
   * @param {[width: number, height: number]} conditionDimensions
   * @param {[x: number, y: number]} coords
   */
  function populateResultMatrixFrom (cellValueOrMatrix, conditionDimensions, coords) {
    const [ xCoord, yCoord ] = coords;
    const [ condW, condH ] = conditionDimensions;

    const fillWidth = xCoord === 0 && condW === 1;
    const fillHeight = yCoord === 0 && condH === 1;
    const xBound = fillWidth ? width : xCoord + 1;
    const yBound = fillHeight ? height : yCoord + 1;

    for (let x = xCoord; x < xBound; x++) {
      for (let y = yCoord; y < yBound; y++) {
        assignResultElementIfNotAssigned(x, y, getValueAt(x, y, cellValueOrMatrix));
      }
    }
  }

  /**
   * @param {Exclude<MaybeBoxedFormulaArgument, Reference>} valueOrMatrix
   * @param {ArrayValue} condition
   * @param {[number, number]} dimensions
   * @param {[number, number]} coords
   */
  function conditionallyPopulateResultMatrixFrom (valueOrMatrix, condition, dimensions, coords) {
    if (isErr(condition)) {
      populateResultMatrixFrom(condition, dimensions, coords);
      return;
    }
    if (!isValidCondition(condition)) {
      populateResultMatrixFrom(ERROR_VALUE, dimensions, coords);
      return;
    }
    if (!condition) {
      return;
    }
    populateResultMatrixFrom(valueOrMatrix, dimensions, coords);
  }

  const nTotal = width * height;

  // eslint-disable-next-line no-unmodified-loop-condition
  for (let i = 0; nAssigned < nTotal && i < conditions.length; i++) {
    const condition = conditions[i];
    const valueOrMatrix = values[i];

    if (this.mode === MODE_GOOGLE) {
      for (const arg of [ condition, valueOrMatrix ]) {
        const [ w, h ] = getValueDimensions(arg);
        const exceeds1x1 = w !== 1 || h !== 1;
        const dimensionsDoNotMatch = w !== result.width || h !== result.height;
        if (exceeds1x1 && dimensionsDoNotMatch) {
          return ERROR_VALUE.detailed(
            'IFS has mismatched range sizes. ' +
              `Expected row count: ${result.height}. column count: ${result.width}. ` +
              `Actual row count: ${h}, column count: ${w}.`,
          );
        }
      }
    }

    if (isMatrix(condition)) {
      /** @type {[number, number]} */
      const dimensions = [ condition.width, condition.height ];

      const xBound = condition.width > 1 ? width : 1;
      const yBound = condition.height > 1 ? height : 1;

      for (let x = 0; x < xBound; x++) {
        for (let y = 0; y < yBound; y++) {
          const cond = condition.get(x, y);
          conditionallyPopulateResultMatrixFrom(valueOrMatrix, cond, dimensions, [ x, y ]);
        }
      }
    }
    else {
      conditionallyPopulateResultMatrixFrom(valueOrMatrix, condition, [ 1, 1 ], [ 0, 0 ]);
    }
  }

  if (width === 1 && height === 1) {
    return result.get(0, 0);
  }
  return result;
}

/**
 * @param {ArrayValue} cellValue
 * @returns {boolean}
 */
function isValidCondition (cellValue) {
  return typeof cellValue === 'boolean' || typeof cellValue === 'number' || cellValue == null;
}

/**
 * @param {MaybeBoxedFormulaArgument} value
 * @returns {[number, number]}
 */
function getValueDimensions (value) {
  return isMatrix(value) ? [ value.width, value.height ] : [ 1, 1 ];
}

/**
 * @param {number} x
 * @param {number} y
 * @param {Exclude<MaybeBoxedFormulaArgument, Reference>} valueOrMatrix
 */
function getValueAt (x, y, valueOrMatrix) {
  if (!isMatrix(valueOrMatrix)) {
    return valueOrMatrix;
  }
  return valueOrMatrix.get(x, y);
}

/** @type {Partial<Record<string, LazyArgumentFunction>>} */
export const lazyArgumentFunctions = {
  IF: /** @type {LazyArgumentFunction} */ (IF),
  IFERROR: /** @type {LazyArgumentFunction} */ (IFERROR),
  IFNA: /** @type {LazyArgumentFunction} */ (IFNA),
  IFS: /** @type {LazyArgumentFunction} */ (IFS),
};
