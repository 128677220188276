import { ERROR_VALUE } from './excel/constants.js';
import Reference from './excel/Reference.js';
import { isErr, isNum } from './utils.js';
import { findRootBrent } from './excel/functions/utils-math';
import type { Model } from './Model';
import FormulaError from './excel/FormulaError';
import { invariant } from './validation';

/**
 * Record of having evaluated the function at coordinate x
 */
type Point = {
  x: number,
  y: number | FormulaError,
};

/**
 * Record of having evaluated the function at coordinate x where the result was
 * a number
 */
type NumericPoint = { x: number, y: number };

function isNumeric (point: Point | null | undefined): point is NumericPoint {
  return point != null && typeof point.y === 'number';
}

type IndexedPoint = { index: number, point: Point };

type IndexedNumericPoint = { index: number, point: NumericPoint };

function isNumericIndexed (ip: { index: number, point?: Point | null }): ip is IndexedNumericPoint {
  return ip.point != null && typeof ip.point.y === 'number';
}

const SCALE_STEP = 2;

/**
 * Very high curvatures imply that a parabola approximation will not be useful.
 */
const MAX_CURVATURE = 1e5;

/**
 * Extremely large values are unlikely to be correct in real-world goal-seek
 * scenarios.
 */
const MAX_X = 1e14;

class MaximumEvaluationsExceededError extends Error {}

class FoundZero {
  public readonly x: number;
  constructor (x: number) {
    this.x = x;
  }
}

class FoundOppositeSign {
  public readonly x: number;
  constructor (x: number) {
    this.x = x;
  }
}

class FunctionRootFinder {
  public func: (x: number) => number | FormulaError;
  public initialValue: number;
  public epsilon: number;
  public maxEvaluations: number;
  private readonly points: Point[];
  private readonly allValuesTried: Set<number>;
  public readonly initialResult: number | FormulaError;
  private readonly intervalsRuledOut: [number, number][] = [];
  public readonly initialSign: 1 | -1;
  /**
   * Points that are just one epsilon apart do not form a ruled-out interval
   * if one of them has an absolute value of at least this.
   */
  private readonly minAbsValueToRuleOut: number;
  /**
   * Points that are just one epsilon apart do not form a ruled-out interval
   * if their absolute difference exceeds this.
   */
  private maxAbsDiffToRuleOut: number = 10;
  private throwOnOZeroOrOppositeSign: boolean = true;

  /**
   * Make a memoizing wrapper for the given function.
   * @param func the actual function
   * @param initialValue an initial value at which the function is known
   * @param initialResult the result of the function at initialValue. Should be
   *   nonzero, else this class should not be instantiated.
   */
  constructor (
    func: (x: number) => number | FormulaError,
    initialValue: number,
    initialResult: number,
    epsilon: number,
    maxEvaluations: number,
  ) {
    invariant(isFinite(initialResult) && initialResult !== 0);
    this.func = func;
    this.initialValue = initialValue;
    this.epsilon = epsilon;
    this.maxEvaluations = maxEvaluations;
    this.initialResult = initialResult;
    this.initialSign = initialResult < 0 ? -1 : 1;
    this.allValuesTried = new Set([ initialValue ]);
    this.points = [ { x: initialValue, y: initialResult } ];
    this.minAbsValueToRuleOut = this.epsilon * this.maxAbsDiffToRuleOut;
  }

  findRoot (): number | null {
    const absInitialValueOrOneTimesLots = Math.abs(this.initialValue || 1) * 1e6;
    const bounds: [number, number] = [
      Math.min(-1, Math.max(-MAX_X, -absInitialValueOrOneTimesLots)),
      Math.max(1, Math.min(MAX_X, absInitialValueOrOneTimesLots)),
    ];
    let bracket: [number, number];
    try {
      const rootOrBracket = this.findRootBracket(bounds);
      if (isNum(rootOrBracket)) {
        return rootOrBracket;
      }
      if (rootOrBracket == null) {
        return rootOrBracket;
      }
      bracket = rootOrBracket;
    }
    catch (e) {
      if (e instanceof FoundOppositeSign) {
        // We found a point with opposite sign. So find the nearest recorded
        // point (where we know the function has the initial sign) and make a
        // bracket of these two
        const { index } = this.findPoint(e.x);
        let counterX: number;
        if (index === this.points.length) {
          counterX = this.points[index - 1].x;
        }
        else {
          counterX = this.points[index].x;
        }
        if (Math.abs(counterX - this.initialValue) < Math.abs(counterX - e.x)) {
          // Bracket counterpoint is not close to the found opposite-sign point.
          // Brent root finding is rather inefficient in this case. So try to
          // find a closer counterpoint, to start Brent off better.
          this.throwOnOZeroOrOppositeSign = false;
          counterX = this.findCloserBracketCounterpoint(e.x, counterX);
        }
        bracket = [ e.x, counterX ].sort((a, b) => a - b) as [number, number];
      }
      else {
        throw e;
      }
    }
    // No longer want a throw when we find a zero or opposite-sign point; that
    // was helpful in finding a root bracket, unhelpful after that.
    this.throwOnOZeroOrOppositeSign = false;
    // Broaden bracket by epsilon so that Brent root finder considers limits valid
    bracket[0] -= this.epsilon * 2;
    bracket[1] += this.epsilon * 2;
    const [ a, b ] = bracket;
    const initialValue = this.initialValue;
    const brentGuess = initialValue >= a && initialValue <= b ? initialValue : (a + b) * 0.5;
    const root = findRootBrent(x => this.eval(x), bracket, this.epsilon, this.maxEvaluations, brentGuess);
    return isErr(root) ? null : root;
  }

  /**
   * Try to find a point closer to x than counterX, at which the sign of the
   * function is `this.initialSign`.
   */
  private findCloserBracketCounterpoint (x: number, counterX: number) {
    let p = Math.max(0.001, this.epsilon / Math.abs(x - counterX));
    while (p < 0.5) {
      const closerCounterX = (1 - p) * x + p * counterX;
      const closerCounterY = this.eval(closerCounterX);
      if (isErr(closerCounterY)) {
        break;
      }
      if (Math.sign(closerCounterY) === this.initialSign) {
        return closerCounterX;
      }
      p *= 2;
    }
    return counterX;
  }

  /**
   * Start out seeking rightward from the initial value, and alternate between
   * left and right, or stick to one direction if the other has been ruled out.
   * Stop when both have been ruled out. Increase step size after each pair of
   * right-and-left seeks.
   *
   * After each seek, check out the roots of a first-and-second-derivative,
   * approximation or its extremum if it has no roots, at any points that we
   * have not already tried that from.
   *
   * @param bounds the furthest to look in either direction
   */
  private findRootBracket (bounds: [number, number]): [left: number, right: number] | number | null {
    const [ lowerBound, upperBound ] = bounds;
    const sign = this.initialSign;
    let stepSize = minimumStepSize(this.epsilon, this.initialValue);
    type Seek = {
      /**
       * Decide the x to seek to, and the next seek to do after that
       */
      step: () => { x: number, nextSeek: Seek | null },
      /**
       * Make a bracket of this seek step. This gets called when we have made a
       * seek and found a point with opposite sign.
       */
      bracket: (x: number) => [number, number],
    };
    let seekRight: Seek | null;
    let seekLeft: Seek | null = {
      step: () => {
        let x = this.left - stepSize;
        if (x <= lowerBound) {
          seekLeft = null;
          x = lowerBound;
        }
        stepSize *= SCALE_STEP;
        return { x, nextSeek: seekRight || seekLeft || null };
      },
      bracket: (x: number) => [ x, this.left ],
    };
    seekRight = {
      step: () => {
        let x = this.right + stepSize;
        if (x >= upperBound) {
          seekRight = null;
          x = upperBound;
        }
        return { x, nextSeek: seekLeft || seekRight || null };
      },
      bracket: (x: number) => [ this.right, x ],
    };

    let seek: Seek | null = seekRight;
    while (seek) {
      const { x, nextSeek } = seek.step();
      const y = this.eval(x);
      if (isNum(y) && Math.sign(y) !== sign) {
        return seek.bracket(x);
      }
      const root = this.findRootByDerivatives(bounds);
      if (root != null) {
        return root.x;
      }
      seek = nextSeek;
    }
    return null;
  }

  private eval (x: number): number | FormulaError {
    if (this.allValuesTried.has(x)) {
      return this.findPoint(x).point!.y;
    }
    if (this.maxEvaluations <= 0) {
      throw new MaximumEvaluationsExceededError();
    }
    const y = this.func(x);
    if (this.throwOnOZeroOrOppositeSign) {
      if (y === 0) {
        throw new FoundZero(x);
      }
      if (isNum(y) && Math.sign(y) !== this.initialSign) {
        throw new FoundOppositeSign(x);
      }
    }
    this.maxEvaluations -= 1;
    this.recordPoint({ x, y });
    return y;
  }

  private recordPoint (point: Point) {
    const { point: existingPoint, index } = this.findPoint(point.x);
    if (!existingPoint) {
      this.points.splice(index, 0, { x: point.x, y: point.y });
      if (isErr(point.y) || Math.abs(point.y) > this.minAbsValueToRuleOut) {
        this.ruleOutVeryCloseNeighbors(index, point);
      }
      this.allValuesTried.add(point.x);
    }
    return { index, point: existingPoint || point };
  }

  private ruleOutVeryCloseNeighbors (index: number, point: Point) {
    let leftmostIndex = index;
    let lastPoint = point;
    for (let i = index - 1; i >= 0; i--) {
      const left = this.points[i];
      if (
        left.x < lastPoint.x - this.epsilon ||
        (isNum(left.y) &&
          (Math.abs(left.y) < this.minAbsValueToRuleOut ||
            (isNum(lastPoint.y) && Math.abs(lastPoint.y - left.y) > this.maxAbsDiffToRuleOut)))
      ) {
        break;
      }
      leftmostIndex = i;
      lastPoint = left;
    }
    let rightmostIndex = index;
    lastPoint = point;
    for (let i = index + 1; i < this.points.length; i++) {
      const right = this.points[i];
      if (
        right.x > lastPoint.x + this.epsilon ||
        (isNum(right.y) &&
          (Math.abs(right.y) > this.minAbsValueToRuleOut ||
            (isNum(lastPoint.y) && Math.abs(lastPoint.y - right.y) > this.maxAbsDiffToRuleOut)))
      ) {
        break;
      }
      rightmostIndex = i;
      lastPoint = right;
    }
    if (leftmostIndex !== rightmostIndex) {
      this.ruleOut(leftmostIndex, rightmostIndex);
    }
  }

  private ruleOut (leftPointIndex: number, rightPointIndex: number) {
    let leftmostCoordinate = this.points[leftPointIndex].x;
    let rightmostCoordinate = this.points[rightPointIndex].x;
    let leftIntervalIndex: number | null = null;
    let rightIntervalIndex: number | null = null;
    for (let i = 0; i < this.intervalsRuledOut.length; i++) {
      const [ left, right ] = this.intervalsRuledOut[i];
      if (right >= leftmostCoordinate) {
        if (leftIntervalIndex == null) {
          leftIntervalIndex = i;
          leftmostCoordinate = Math.min(leftmostCoordinate, left);
        }
        if (left <= rightmostCoordinate) {
          rightIntervalIndex = i;
          rightmostCoordinate = Math.max(rightmostCoordinate, right);
          break;
        }
      }
    }
    const newInterval: [number, number] = [ leftmostCoordinate, rightmostCoordinate ];
    if (rightIntervalIndex != null) {
      // rightIntervalIndex is assigned only after leftIntervalIndex is assigned
      invariant(leftIntervalIndex != null);
      this.intervalsRuledOut.splice(leftIntervalIndex, rightIntervalIndex - leftIntervalIndex + 1, newInterval);
    }
    else if (leftIntervalIndex != null) {
      this.intervalsRuledOut.splice(leftIntervalIndex, Infinity, newInterval);
    }
    else {
      this.intervalsRuledOut.push(newInterval);
    }
  }

  private recordPointAt (x: number) {
    return this.recordPoint({ x, y: this.eval(x) });
  }

  private get left () {
    return this.points[0].x;
  }

  private get right () {
    return this.points[this.points.length - 1].x;
  }

  /**
   * Find the first index at which this.points[i].x >= x, or else return the
   * number of points if no point has point.x >= x. Return the point also, if
   * its `point.x` is _exactly_ x.
   *
   * Use binary search, to be O(log n) in the number of points.
   */
  private findPoint (x: number, acceptSame = true): { index: number, point?: Point | null } {
    let k = 0;
    let n = this.points.length;
    let index = n >> 1;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      // Loop invariant: k <= i < n
      const point = this.points[index];
      if (acceptSame && point.x === x) {
        return { point, index };
      }
      if (point.x < x) {
        k = index + 1;
        if (k === n) {
          return { index: k };
        }
        const seekRight = (n - index) >> 1;
        invariant(seekRight > 0);
        index += seekRight;
      }
      else {
        n = index;
        if (k === n) {
          return { index };
        }
        // point.x > x
        const seekLeft = (index - k + 1) >> 1;
        if (seekLeft === 0) {
          // no further left to go
          return { index };
        }
        index -= seekLeft;
      }
    }
  }

  private isRuledOut (x: number) {
    if (!isFinite(x) || Math.abs(x) > MAX_X) {
      return true;
    }
    if (this.allValuesTried.has(x)) {
      const obs = this.findPoint(x);
      if (isNumericIndexed(obs) && Math.abs(obs.point.y) > this.epsilon) {
        return true;
      }
    }
    for (const [ left, right ] of this.intervalsRuledOut) {
      if (x < left) {
        // intervals are in order; all remaining intervals are to the right of
        // this one, so x is to the left of all of them
        return false;
      }
      if (x <= right) {
        return true;
      }
    }
    return false;
  }

  private findPointLeftOf (x: number, minDelta: number, maxDelta: number, index?: number): IndexedPoint | undefined {
    const minX = x - maxDelta;
    const maxX = x - minDelta;
    if (index == null) {
      index = this.findPoint(x).index;
    }
    for (let i = index - 1; i >= 0; i--) {
      const candidate = this.points[i];
      if (!candidate || candidate.x < minX) {
        return;
      }
      if (candidate.x <= maxX) {
        return { index: i, point: candidate };
      }
    }
  }

  private findPointRightOf (index: number, x: number, minDelta: number, maxDelta: number): IndexedPoint | undefined {
    const minX = x + minDelta;
    const maxX = x + maxDelta;
    const N = this.points.length;
    for (let i = index + 1; i < N; i++) {
      const candidate = this.points[i];
      if (!candidate || candidate.x > maxX) {
        return;
      }
      if (candidate.x > minX) {
        return { index: i, point: candidate };
      }
    }
  }

  /**
   * Estimate the first derivative (gradient) and second derivative (curvature)
   * of this function at the given coordinate.
   * @param point the point at which to estimate the derivatives
   * @param index the index of that point
   * @returns the gradient and curvature at that coordinate, or just the
   *   gradient if no curvature can be determined, or null if neither can be
   *   determined
   */
  private derivativesAt (point: NumericPoint, index: number): { gradient?: number | null, curvature?: number | null } {
    const x = point.x;
    const minDelta = minimumStepSize(1e-4, x);
    const maxDelta = Math.max(minDelta * 16, minimumStepSize(this.epsilon, x));
    const right = { index, point };
    const leftOfIndex =
      this.findPointLeftOf(x, minDelta, maxDelta, index) || this.recordPointAt(Math.min(x - minDelta, x));
    if (!isNumericIndexed(leftOfIndex)) {
      return {};
    }
    const left = leftOfIndex;
    invariant(x - left.point.x <= maxDelta + 1e-13);
    invariant(right.point.x - x <= maxDelta + 1e-13);
    const leftRightMid = (left.point.x + right.point.x) * 0.5;
    const gradientAtLeftRightMid = estimateGradient(left.point, right.point);
    const rightRight =
      this.findPointRightOf(right.point.x, minDelta, maxDelta, right.index) ||
      this.recordPointAt(right.point.x + minDelta);
    if (isNumericIndexed(rightRight)) {
      const rightRightMid = (rightRight.point.x + right.point.x) * 0.5;
      const gradientAtRightRightMid = estimateGradient(right.point, rightRight.point);
      const gradient = lerp(leftRightMid, gradientAtLeftRightMid, rightRightMid, gradientAtRightRightMid, x);
      const curvature =
        gradientAtLeftRightMid == null || gradientAtRightRightMid == null
          ? null
          : estimateGradient(
            { x: leftRightMid, y: gradientAtLeftRightMid },
            { x: rightRightMid, y: gradientAtRightRightMid },
          );
      return { gradient, curvature };
    }
    return { gradient: gradientAtLeftRightMid };
  }

  * derivativeEstimates (): IterableIterator<{
    point: NumericPoint,
    gradient: number | null | undefined,
    curvature: number | null | undefined,
  }> {
    if (this.points.length < 3) {
      return;
    }
    let right: Point | null = null;
    let mid: Point | null = null;
    let left: Point | null = null;
    for (let i = this.points.length - 1; i >= 0; i--) {
      right = mid;
      mid = left;
      left = this.points[i];
      if (!(right && mid && left && isNumeric(right) && isNumeric(mid) && isNumeric(left))) {
        continue;
      }
      invariant(right.x > mid.x && mid.x > left.x);
      const point = mid;
      const { gradient, curvature } = this.derivativesAt(point, i + 1);
      yield { point, gradient, curvature };
    }
  }

  private findRootByDerivatives (bounds: [number, number]): NumericPoint | undefined {
    for (const { point, gradient = null, curvature = null } of [ ...this.derivativeEstimates() ]) {
      if (!gradient && !curvature) {
        if (gradient === 0 && Math.abs(point.y) < this.epsilon * Math.max(this.epsilon, 1e-6)) {
          return point;
        }
        continue;
      }
      const root = this.findRootByDerivativesFrom(point, gradient, curvature, bounds);
      if (root != null) {
        return root;
      }
    }
  }

  /**
   * Seek from startingPoint using the given starting gradient and curvature,
   * looking for either a root of the function or a point at which it has the
   * opposite sign. Return immediately if either is found. Else return nothing.
   * @param startingPoint A recorded point on this function
   * @param startingGradient estimate of the first derivative at that point
   * @param startingCurvature estimate of the second derivative at that point
   * @param lowerBound don't seek to the left of this
   * @param upperBound don't seek to the right of this
   */
  private findRootByDerivativesFrom (
    startingPoint: NumericPoint,
    startingGradient: number | null,
    startingCurvature: number | null,
    [ lowerBound, upperBound ]: [number, number],
  ): NumericPoint | undefined {
    let gradient: number | null = startingGradient;
    let curvature = startingCurvature;
    let point = startingPoint;
    const sign = Math.sign(startingPoint.y);
    // startingPoint is a recorded point on this function, and we do not record
    // any opposing-sign points until the Brent root finding stage.
    invariant(sign === this.initialSign);
    while (gradient != null && point.x > lowerBound && point.x < upperBound) {
      if (curvature) {
        const absCurvature = Math.abs(curvature);
        if (absCurvature > MAX_CURVATURE) {
          curvature = null;
        }
      }
      const nextX = this.decideNextXFromDerivatives(point, gradient, curvature);
      if (nextX == null || this.isRuledOut(nextX)) {
        return;
      }
      const { point: nextPoint, index } = this.recordPointAt(nextX);
      if (!isNumeric(nextPoint)) {
        return;
      }
      if (Math.abs(point.x - nextPoint.x) < this.epsilon) {
        // We are taking a step smaller than epsilon, so it's closing time. If
        // the function value is smaller than epsilon, we call it a root; this
        // is typically when the goal function touches but does not cross zero.
        // This heuristic may fail on sharp peaks/troughs at zero, like in
        // `f(x) = 2 abs(x)` where the slope is big so we may be within epsilon
        // of the correct value horizontally but still fail this check; we will
        // incorrectly report failure in that case. But we will have written the
        // winning value to the control cell, so probably still a useful result.
        const result = Math.abs(nextPoint.y) > Math.abs(point.y) ? point : nextPoint;
        // eslint-disable-next-line no-undefined
        return Math.abs(result.y) < this.epsilon ? result : undefined;
      }
      point = nextPoint;
      ({ gradient = null, curvature = null } = this.derivativesAt(point, index));
    }
  }

  private decideNextXFromDerivatives ({ x, y }: NumericPoint, gradient: number, curvature: number | null) {
    let nextValueToTry: number | null = null;
    if (curvature) {
      // Nonzero curvature estimate; try roots of the parabola described by
      // the gradient and curvature, or the extremum if there are no roots.
      const a = curvature * 0.5;
      const b = gradient - 2 * a * x;
      const c = y - a * x * x - b * x;
      const discriminant = b * b - 4 * a * c;
      const extremum = -b / (2 * a);
      if (discriminant >= 0) {
        const sqrtDiscriminantOver2a = Math.sqrt(discriminant) / curvature;
        // Bias with epsilon to prefer rightward root when all else about equal
        const rightOfAxis = x >= extremum - this.epsilon;
        const closestZero = rightOfAxis ? extremum + sqrtDiscriminantOver2a : extremum - sqrtDiscriminantOver2a;
        if (!this.isRuledOut(closestZero)) {
          nextValueToTry = closestZero;
        }
      }
      else if (((curvature > 0 && y > 0) || (curvature < 0 && y < 0)) && !this.isRuledOut(extremum)) {
        nextValueToTry = extremum;
      }
    }
    if (nextValueToTry == null && gradient) {
      const zeroFromGradient = -y / gradient;
      const step = zeroFromGradient * Math.min(1, 1000 * this.epsilon);
      nextValueToTry = x + step;
    }
    while (nextValueToTry != null && this.isRuledOut(nextValueToTry) && Math.abs(nextValueToTry - x) > this.epsilon) {
      // try smaller steps until we reach something not ruled out
      nextValueToTry = lerp(0, x, 1, nextValueToTry, 0.8);
    }
    return nextValueToTry;
  }
}

/**
 * Estimate gradient based on finite differences from (x1, y1) to (x2, y2).
 * If the difference in either coordinate is too small relative to the
 * magnitude of either point in that coordinate, return null to indicate
 * that no reliable estimate is available.
 */
function estimateGradient (p1: NumericPoint, p2: NumericPoint): number | null {
  const xdiff = p2.x - p1.x;
  if (Math.abs(xdiff) < Math.max(Math.abs(p1.x), Math.abs(p2.x)) * 1e-11) {
    return null;
  }
  const ydiff = p2.y - p1.y;
  if (Math.abs(ydiff) > Math.abs(xdiff) * 1e11) {
    return null;
  }
  return ydiff / xdiff;
}

/**
 * Interpolate/extrapolate linearly between (x1, y1) and (x2, y2) for x.
 *
 * This is interpolation if x1 < x < x2, else extrapolation. Either way it is
 * just evaluating the line function formed by those two fixed points, at x.
 */
function lerp(x1: number, y1: number, x2: number, y2: number, x: number): number;
function lerp(x1: number, y1: number | null, x2: number, y2: number | null, x: number): number | null;
function lerp (x1: number, y1: number | null, x2: number, y2: number | null, x: number): number | null {
  return y1 == null || y2 == null ? null : y1 + ((x - x1) * (y2 - y1)) / (x2 - x1);
}

export function goalSeek (
  model: Model,
  controlCell: Reference,
  targetCell: Reference,
  targetValue: number,
): number | FormulaError {
  invariant(controlCell && controlCell.isAddress && controlCell.size === 1);
  invariant(targetCell && targetCell.isAddress && targetCell.size === 1);
  const initialValue = model.readValue(`=${controlCell}`);
  if (!isNum(initialValue)) {
    return ERROR_VALUE.detailed('Control cell must contain a number');
  }
  let firstWrite = true;
  function goalFunction (controlCellValue: number) {
    invariant(isFinite(controlCellValue));
    model.write(controlCell, controlCellValue, true, !firstWrite);
    // Skip volatiles in all writes except the first (so that they update when
    // goal-seek starts, but then don't update on every recalc during goal-seek)
    firstWrite = false;
    const result = model.readValue(`=${targetCell}`);
    if (isErr(result)) {
      return result;
    }
    else if (!isNum(result)) {
      return ERROR_VALUE.detailed('Non-numeric target cell result');
    }
    return result - targetValue;
  }
  const { maxChange: epsilon, maxIterations } = model.iterativeCalculationSettings();
  const initialResult = goalFunction(initialValue);
  if (isErr(initialResult) || initialResult === 0) {
    return initialResult;
  }
  const rootFinder = new FunctionRootFinder(goalFunction, initialValue, initialResult, epsilon, maxIterations);
  let root: number | null;
  try {
    root = rootFinder.findRoot();
  }
  catch (err) {
    if (err instanceof MaximumEvaluationsExceededError) {
      return ERROR_VALUE.detailed(`Goal seek aborted after ${maxIterations} iterations`);
    }
    else if (err instanceof FoundZero) {
      return err.x;
    }
    else {
      throw err;
    }
  }
  if (root == null) {
    // May have found an acceptable value at bracket limits, which Brent root
    // finding considers not a solution but we would be wrong to report failure.
    // Try to detect that by the probably too simple and handwavy heuristic of
    // checking whether the target cell is within epsilon of the target value
    // (even though epsilon is just the `maxChange` value from iterative
    // calculation settings, so really configuring _horizontal_ tolerance...)
    const targetCellValue = model.readValue(`=${targetCell}`);
    if (isNum(targetCellValue) && Math.abs(targetValue - targetCellValue) <= epsilon) {
      const controlCellValue = model.readValue(`=${controlCell}`);
      if (isNum(controlCellValue)) {
        return controlCellValue;
      }
    }
    return ERROR_VALUE.detailed('Goal seek failed');
  }
  model.write(controlCell, root);
  return root;
}

/**
 * Minimum step size for open-ended seeking and derivative estimation, for a
 * given starting value and given epsilon. This is the largest of:
 *
 * * epsilon
 *
 * * 1e-9, because very small step sizes can lead to extremely many iterations
 *   and are unlikely to be useful enough to justify that
 *
 * * a millionth of the magnitude of the value, so that we don't try to use
 *   gradients and curvature with ≈zero bits of floating-point precision when
 *   the initial value is big
 */
function minimumStepSize (epsilon: number, value: number) {
  return Math.max(1e-6, epsilon, 1e-6 * Math.abs(value));
}
