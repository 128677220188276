import { ERROR_VALUE, ERROR_REF, ERROR_NA, MISSING } from '../constants.js';
import { isErr, isRef, isMatrix, toNum, isBool } from './utils.js';
import FormulaError from '../FormulaError';
import Reference from '../Reference.js';
import { isNum, isStr } from '../../utils.js';
import type Matrix from '../Matrix.js';
import type { EvaluationContext } from '../EvaluationContext.js';
import {
  ArrayValue,
  FormulaValue,
  NonMatrixFormulaArgument,
  CellValue,
  CellValueAtom,
  FormulaArgument,
} from '../types.js';
import { invariant } from '../../validation';
import EvaluationOrderException from '../../EvaluationOrderException';
import { isLambda, Lambda } from '../lambda';

/**
 * CELL(info_type, [reference])
 *
 * Returns the requested information about the specified cell.
 *
 * If reference is omitted the information is returned for the last cell that was changed.
 * BT: which doesn't make sense for GRID as most of the time we don't know this.
 *
 * Google Sheets supports info types:
 *   ADDRESS, COL, COLOR, CONTENTS, PREFIX, ROW, TYPE, WIDTH
 * Excel Online, Excel Mobile, and Excel Starter supports info types:
 *   ADDRESS, COL, CONTENTS, ROW, TYPE.
 *
 * @see https://support.office.com/en-us/article/cell-function-51bd39a5-f338-4dbe-a33f-955d67c2b2cf
 */
export function CELL (
  this: EvaluationContext,
  info_type: string,
  reference: Reference | string,
): Matrix | string | number | boolean | FormulaError {
  const type = info_type.toLowerCase();
  if (type === 'gridsupport') {
    let funcName: ArrayValue;
    if (isRef(reference)) {
      const anchorRef = reference.size > 1 ? reference.collapse() : reference;
      if (this.isDirtyFormulaCell && anchorRef.any(this.isDirtyFormulaCell) === true) {
        throw new EvaluationOrderException('CELL("gridsupport", X) where X is not up-to-date', anchorRef);
      }
      funcName = anchorRef.resolveSingle();
    }
    else {
      funcName = reference;
    }
    if (isErr(funcName)) {
      return funcName;
    }
    else if (!isStr(funcName)) {
      return ERROR_VALUE.detailed('CELL("gridsupport", X) requires X to be a string (a function name)');
    }
    else {
      funcName = funcName.toUpperCase();
      invariant(this.supportedFunctionNames);
      return this.supportedFunctionNames.has(funcName);
    }
  }

  const ref = Reference.from(reference);
  if (!ref || !ref.isAddress) {
    return ERROR_REF;
  }

  if (type === 'address') {
    const prefix = isSameSheet(ref, this.sheetName || '', this.workbookName || '')
      ? { sheetName: '', workbookName: '' }
      : {
        sheetName: ref.sheetName || ref.ctx?.sheetName || this.sheetName || '',
        workbookName: ref.workbookName || ref.ctx?.workbookName || this.workbookName || '',
      };
    return ref.collapse().withPrefix(prefix)
      .toString(true);
  }
  else if (type === 'col') {
    return ref.range.right + 1;
  }
  else if (type === 'row') {
    return ref.range.top + 1;
  }
  else if (type === 'color') {
    // The value 1 if the cell is formatted in color for negative values; otherwise returns 0 (zero).
    // BT: Google Sheets says it supports this but I have been unable to make it work
    return 0;
  }
  else if (type === 'contents') {
    // Value of the upper-left cell in reference; not a formula.
    const v = ref.collapse().resolveSingle();
    // Guaranteed because `ref` is a range reference, not a defined name.
    invariant(!isLambda(v));
    return v == null ? 0 : v;
  }
  else if (type === 'prefix') {
    // returns:
    // - single quote (') if the cell contains left-aligned text
    // - double quote (") if the cell contains right-aligned text
    // - caret (^) if the cell contains centered text
    // - backslash (\) if the cell contains fill-aligned text
    // - empty ("") if cell contains anything else
    return '';
  }
  else if (type === 'type') {
    const v = ref.collapse().resolveSingle();
    if (v == null) {
      return 'b';
    }
    if (isStr(v)) {
      return 'l';
    }
    return 'v';
  }
  else if (type === 'width') {
    // "width": Column width of the cell, rounded off to an integer.
    //    Each unit of column width is equal to the width of one character in the default font size.
    //    Note: This value is not supported in Excel Online, Excel Mobile, and Excel Starter.
  }
  return ERROR_VALUE;
}

function isSameSheet (ref: Reference, sheetName: string, workbookName: string) {
  return (
    (ref.workbookName || ref.ctx?.workbookName || '').toLowerCase() === (workbookName || '').toLowerCase() &&
    (ref.sheetName || ref.ctx?.sheetName || '').toLowerCase() === (sheetName || '').toLowerCase()
  );
}

/**
 * ERROR.TYPE(reference)
 * Returns a number corresponding to the error value in a different cell.
 */
export function ERROR_TYPE (ref: string | number | boolean | FormulaError): number | FormulaError {
  if (isErr(ref) && ref.code >= 1 && ref.code <= 7) {
    return ref.code;
  }
  // for _anything_ else, empty cells included...
  return ERROR_NA;
}

/**
 * INFO(type_text)
 * The text string returning useful information about the environment.
 * @see https://support.office.com/en-us/article/info-function-725f259a-0e4b-49b3-8b52-58815c69acae
 */
export function INFO (type: string): string | number | FormulaError {
  switch (type) {
    case 'directory':
    case 'osversion':
    case 'system':
      return '';
    case 'release':
      // FIXME: =INFO("release") should emit engine version (from package.json?)
      return '0.0.0';
    case 'numfile':
      // TODO: Should be able to know how many sheets are in the current workbook
      return 0;
    case 'recalc':
      return 'Automatic'; // the only supported mode
    case 'origin':
      // FIXME: =INFO("origin") depends on the reference style: sheets may excpect RC?
      return '$A:$A$1';
  }
  return ERROR_VALUE;
}

/**
 * ISBLANK(value)
 * Checks whether the referenced cell is empty.
 */
export function ISBLANK (val: CellValue | Lambda): boolean {
  return val == null;
}

/**
 * ISOMITTED(value)
 * Checks whether the value is `MISSING`, which occurs when a formula argument
 * is left blank, e.g. `=GCD(6, ,84)`.
 */
export function ISOMITTED (val: FormulaArgument): boolean {
  return val === MISSING;
}

/**
 * ISERR(value)
 * Checks whether a value is an error other than `#N/A`.
 */
export function ISERR (val: FormulaError | Lambda | CellValueAtom): boolean {
  return isErr(val) && val !== ERROR_NA;
}

/**
 * ISERROR(value)
 * Checks whether a value is an error.
 */
export function ISERROR (val: FormulaError | Lambda | CellValueAtom): boolean {
  return isErr(val);
}

/**
 * ISEVEN(value)
 * Checks whether the provided value is even.
 */
export function ISEVEN (val: NonMatrixFormulaArgument): boolean | FormulaError {
  if (isBool(val)) {
    return ERROR_VALUE;
  }
  val = toNum(val);
  if (isErr(val)) {
    return val;
  }
  return Math.floor(val) % 2 === 0;
}

/**
 * ISFORMULA(cell)
 * Checks whether a value is a formula.
 */
export function ISFORMULA (ref: Reference): boolean {
  const cell = ref.collapse().resolveCell();
  return !!(cell && cell.f);
}

/**
 * ISLOGICAL(value)
 * Checks whether a value is `TRUE` or `FALSE`.
 */
export function ISLOGICAL (val: CellValue): boolean {
  return !!val === val;
}

/**
 * ISNA(value)
 * Checks whether a value is the error `#N/A`.
 */
export function ISNA (val: FormulaError | Lambda | CellValueAtom): boolean {
  return isErr(val) && val.code === ERROR_NA.code;
}

/**
 * ISNONTEXT(value)
 * Checks whether a value is non-textual.
 */
export function ISNONTEXT (val: CellValue): boolean {
  return typeof val !== 'string';
}

/**
 * ISNUMBER(value)
 * Checks whether a value is a number.
 */
export function ISNUMBER (val: ArrayValue): boolean {
  return typeof val === 'number';
}

/**
 * ISODD(value)
 * Checks whether the provided value is odd.
 */
export function ISODD (val: CellValue): boolean | FormulaError {
  if (val === !!val) {
    return ERROR_VALUE;
  }
  val = toNum(val);
  if (isErr(val)) {
    return val;
  }
  return Math.floor(val) % 2 === 1;
}

/**
 * ISREF(value)
 * Checks whether a value is a valid cell reference.
 */
export function ISREF (val: FormulaValue): boolean {
  return isRef(val);
}

/**
 * ISTEXT(value)
 * Checks whether a value is text.
 */
export function ISTEXT (val: ArrayValue): boolean {
  return typeof val === 'string';
}

/**
 * N(value)
 * Returns the argument provided if it is a number or error, or 1 if it is TRUE, else 0.
 * @returns `value` if it (or the single cell it references) is a number, or 1 if TRUE, else 0
 */
export function N (value: string | number | boolean | Reference | null): number | FormulaError {
  let val = isRef(value) ? value.collapse().resolveSingle() : value;
  if (isStr(val)) {
    val = 0;
  }
  else if (isBool(val)) {
    val = +val;
  }
  // Guaranteed because `val` can only be a range reference rather than a defined name.
  invariant(!isLambda(val));
  return val ?? 0;
}

/**
 * NA()
 * Returns the "value not available" error, `#N/A`.
 */
export function NA () {
  return ERROR_NA;
}

/**
 * SHEET([value])
 * The sheet number of the referenced sheet.
 */
export function SHEET (): number {
  return 1;
}

/**
 * SHEETS([reference])
 * The number of sheets in a reference.
 */
export function SHEETS () {
  return null;
}

/**
 * TYPE(value)
 * Returns a number associated with the type of data passed into the function.
 */
export function TYPE (val: Matrix | FormulaError | Lambda | CellValueAtom): number {
  // Array: 64
  if (isMatrix(val)) {
    return 64;
  }
  // Error: 16
  if (isErr(val)) {
    return 16;
  }
  // Bool: 4
  if (!!val === val) {
    return 4;
  }
  // Text: 2
  if (isStr(val)) {
    return 2;
  }
  // Number: 1
  if (isNum(val)) {
    return 1;
  }
  // Other, e.g. Lambdas
  return 128;
}
